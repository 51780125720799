import { gql } from '@apollo/client';

const ACTIVITY_STREAM_ADDED_SUBSCRIPTION = gql`
    subscription activityStreamAdded($ticketId: ID!) {
        activityStreamAdded(ticketID: $ticketId) {
            _id
            activityActor {
                actorType
                actorID
            }
            activityVerb
            activityObject {
                objectType
                value
                prevValue
                valueObject {
                    priority
                    dueDate
                    responsible
                    tags {
                        name
                    }
                    checklists {
                        title
                    }
                    entries {
                        name
                    }
                    storageData {
                        _id
                        storageName
                        storageQty
                    }
                    suppliersReferences {
                        _id
                        foreignID
                        articleCode
                        qty
                        unit
                        price
                    }
                }
            }
            activityTarget {
                targetType
                foreignID
            }
            createdAt
            archived
        }
    }
`;

export { ACTIVITY_STREAM_ADDED_SUBSCRIPTION };
