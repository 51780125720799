import { gql } from '@apollo/client';

const GET_USERS = gql`
    query {
        getUsers {
            name
            firstName
            email
            fullName: firstName
            _id
            mobileNumber
            avatar: profilePic {
                thumbnail
            }
            organizations {
                organizationID
                teamsIDs
            }
            roles
            disabled
        }
    }
`;

const GET_CURRENT_USER = gql`
    query getCurrentUser($_id: ID!) {
        getSingleUser(id: $_id) {
            code
            user {
                name
                firstName
                profilePic {
                    regularSize
                    thumbnail
                }
                email
                organizations {
                    organizationID
                    teamsIDs
                }
                roles
                _id
            }
        }
    }
`;

// get user by Auth provider subID
const GET_CURRENT_USER_BY_SUB = gql`
    query getCurrentUserbySubID($sub: ID!) {
        getUsers(user: { awsPayload: { sub: $sub } }) {
            name
            firstName
            profilePic {
                regularSize
                thumbnail
            }
            userSettings {
                language
                theme
            }
            email
            mobileNumber
            notifications {
                email
                dailyMail
                dailyTicketOverview
                overdueTicketsMail {
                    active
                    weekdays
                }
                push {
                    active
                    deviceName
                    subscription {
                        endpoint
                        keys {
                            p256dh
                            auth
                        }
                    }
                }
            }
            organizations {
                organizationID
                teamsIDs
            }
            roles
            _id
        }
    }
`;

// get current User from local cache - written to cache in Navbar.jsx
const GET_CURRENT_USER_FROM_CACHE = gql`
    {
        currentUser @client {
            _id
            name
            firstName
            profilePic {
                regularSize
                thumbnail
            }
            email
            mobileNumber
            organizations {
                organizationID
                teamsIDs
            }
            roles
        }
    }
`;

const SAVE_USER_SETTINGS = gql`
    mutation updateUserSettings($user: UpdateUserInput) {
        updateUser(user: $user) {
            code
            user {
                _id
                name
                firstName
                email
                mobileNumber
                organizations {
                    organizationID
                    teamsIDs
                }
                roles
                profilePic {
                    regularSize
                    thumbnail
                }
                userSettings {
                    language
                    theme
                }
                notifications {
                    email
                    dailyMail
                    overdueTicketsMail {
                        active
                        weekdays
                    }
                    dailyTicketOverview
                    push {
                        active
                        deviceName
                        subscription {
                            endpoint
                            keys {
                                p256dh
                                auth
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_FILES = gql`
    mutation fetchFiles($accessRootFolder: String!) {
        createSignedGetURL(accessRootFolder: $accessRootFolder) {
            img: signedRequest
            url
        }
    }
`;

const GET_SINGLE_FILE = gql`
    mutation fetchSignleFile($accessRootFolder: String!, $file: String!) {
        createSingleSignedGetURL(accessRootFolder: $accessRootFolder, file: $file) {
            code
            signedRequest
            url
        }
    }
`;

const GET_CURRENT_APP_VERSION = gql`
    query {
        getCurrentAppVersion {
            version
            relatedToApp
            versionUpdatedBy
            updatedAt
            updateComment
            updateHistory {
                version
                relatedToApp
                versionUpdatedBy
                updatedAt
                updateComment
            }
            _id
        }
    }
`;

const UPDATE_APP_VERSION = gql`
    mutation updateAppVersion($version: String!, $relatedToApp: String, $versionUpdatedBy: String, $updateComment: String, $id: ID!) {
        updateAppVersion(
            appVersion: {
                _id: $id
                version: $version
                relatedToApp: $relatedToApp
                versionUpdatedBy: $versionUpdatedBy
                updateComment: $updateComment
            }
        ) {
            code
            appVersion {
                version
                relatedToApp
                versionUpdatedBy
                updatedAt
                updateComment
                updateHistory {
                    version
                    relatedToApp
                    versionUpdatedBy
                    updatedAt
                    updateComment
                }
            }
        }
    }
`;

const CREATE_ACTIVITY = gql`
    mutation createActivity($activity: ActivityInput) {
        createActivity(activity: $activity) {
            code
            success
            message
            activity {
                _id
                activityActor {
                    actorType
                    actorID
                }
                activityVerb
                activityObject {
                    objectType
                    value
                    prevValue
                }
                activityTarget {
                    targetType
                    foreignID
                }
                createdAt
                archived
            }
        }
    }
`;

const GET_ORGANIZATIONS = gql`
    query getOrganizations {
        getOrganizations {
            code
            organizations {
                _id
                companyName
                teams {
                    _id
                    name
                }
            }
        }
    }
`;

const GET_SINGLE_USER = gql`
    query getSingleUser($userID: ID!) {
        getSingleUser(id: $userID) {
            code
            user {
                name
                firstName
                email
                mobileNumber
                fullName: firstName
                _id
                avatar: profilePic {
                    thumbnail
                }
                organizations {
                    organizationID
                    teamsIDs
                }
                roles
                disabled
            }
        }
    }
`;

const CREATE_USER = gql`
    mutation submitUserData($user: UserInput) {
        createUser(user: $user) {
            code
            user {
                name
                firstName
                email
                mobileNumber
                fullName: firstName
                _id
                avatar: profilePic {
                    thumbnail
                }
                organizations {
                    organizationID
                    teamsIDs
                }
                roles
                disabled
            }
        }
    }
`;

const UPDATE_USER = gql`
    mutation submitUserData($user: UserAdminInput) {
        adminUpdateUser(user: $user) {
            code
            success
            user {
                name
                firstName
                email
                fullName: firstName
                _id
                avatar: profilePic {
                    thumbnail
                }
                organizations {
                    organizationID
                    teamsIDs
                }
                roles
                disabled
            }
        }
    }
`;

const DELETE_USER = gql`
    mutation deleteUser($userID: ID!) {
        deleteUser(id: $userID) {
            code
            success
            message
            user {
                _id
            }
        }
    }
`;

export {
    GET_USERS,
    GET_CURRENT_USER,
    GET_CURRENT_USER_BY_SUB,
    GET_CURRENT_USER_FROM_CACHE,
    SAVE_USER_SETTINGS,
    GET_FILES,
    GET_SINGLE_FILE,
    GET_CURRENT_APP_VERSION,
    UPDATE_APP_VERSION,
    CREATE_ACTIVITY,
    GET_ORGANIZATIONS,
    GET_SINGLE_USER,
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
};
