export const ncrTemplateActions = {
    RESET_NCR: 'RESET_NCR',
    SET_NCR: 'SET_NCR',
    SET_NCR_CUSTOM_FIELDS: 'SET_NCR_CUSTOM_FIELDS',
    SET_NCR_TEMPLATE: 'SET_NCR_TEMPLATE',
    SET_NCR_TEMPLATE_ATTACHMENTS: 'SET_NCR_TEMPLATE_ATTACHMENTS',
    SET_NCR_RESPONSIBLE: 'SET_NCR_RESPONSIBLE',
    SET_NCR_REWORK_PROCESS_STEP: 'SET_NCR_REWORK_PROCESS_STEP',
    UPDATE_NCR_REWORK_WORKFLOW_STEPS: 'UPDATE_NCR_REWORK_WORKFLOW_STEPS',
    UPDATE_NCR_REWORK_WORKFLOW_STATUS: 'UPDATE_NCR_REWORK_WORKFLOW_STATUS',
    UPDATE_NCR_IMMEDIATE_MEASURE_SECTION_WORKFLOW_RESPONSIBLE: 'UPDATE_NCR_IMMEDIATE_MEASURE_SECTION_WORKFLOW_RESPONSIBLE',
};

export const setNCR = payload => {
    return {
        type: ncrTemplateActions.SET_NCR,
        payload,
    };
};

export const setNCRResponsible = payload => {
    return {
        type: ncrTemplateActions.SET_NCR_RESPONSIBLE,
        payload,
    };
};

export const setNCRCustomFields = payload => {
    return {
        type: ncrTemplateActions.SET_NCR_CUSTOM_FIELDS,
        payload,
    };
};

export const setNCRTemplate = payload => {
    return {
        type: ncrTemplateActions.SET_NCR_TEMPLATE,
        payload,
    };
};

export const updateNCRTemplateAttachments = payload => {
    return {
        type: ncrTemplateActions.SET_NCR_TEMPLATE_ATTACHMENTS,
        payload,
    };
};

export const resetNcr = () => {
    return {
        type: ncrTemplateActions.RESET_NCR,
    };
};

export const setNCRReworkProcessStep = payload => {
    return {
        type: ncrTemplateActions.SET_NCR_REWORK_PROCESS_STEP,
        payload,
    };
};

export const updateNCRSectionWorkflowSteps = payload => {
    return {
        type: ncrTemplateActions.UPDATE_NCR_REWORK_WORKFLOW_STEPS,
        payload,
    };
};

export const updateNCRSectionWorkflowStatus = payload => {
    return {
        type: ncrTemplateActions.UPDATE_NCR_REWORK_WORKFLOW_STATUS,
        payload,
    };
};

export const updateNcrImmediateMeasureSectionWorkflowResponsible = payload => {
    return {
        type: ncrTemplateActions.UPDATE_NCR_IMMEDIATE_MEASURE_SECTION_WORKFLOW_RESPONSIBLE,
        payload,
    };
};
