import { memo, useMemo } from 'react';

import UserDropdown from '../../../../../../../common/components/UserDropdown';

const StepResponsibleDropdownComponent = ({ value, isDisabled, isLoading, handleUpdate }) => {
    const handleChange = data => {
        handleUpdate([data]);
    };

    const memoizedValue = useMemo(() => {
        const user = value ? value[0] : null;

        return user
            ? {
                  avatar: user?.avatar || user?.profilePic?.thumbnail || '',
                  fullName: user?.fullName || `${user?.firstName} ${user?.name}` || '',
                  value: user?._id || user?.value || '',
              }
            : null;
    }, [value]);

    return <UserDropdown value={memoizedValue} isDisabled={isDisabled} isLoading={isLoading} onChange={handleChange} />;
};

const StepResponsibleDropdown = memo(StepResponsibleDropdownComponent);

export default StepResponsibleDropdown;
