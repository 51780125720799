import React, { memo, useState } from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CheckIcon from '../components/icons/CheckIcon';
import { getValidationMessages, isPasswordValid } from './passwordValidation';

const CompleteRegister = ({ user, model, exceptionMessage, handleBackClick, handleValueChange, completeNewPassword }) => {
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    return (
        <React.Fragment>
            <div className="login-info-container">
                <div className="login-info-label">{t(`authentication.registerComplete.label`)}</div>
                {React.Children.toArray(
                    getValidationMessages().map(message => {
                        return (
                            <div className="login-info-validation">
                                <div className={`login-info-validation-icon ${!message.isValid(model.newPassword.trim()) ? 'hidden' : ''}`}>
                                    <CheckIcon />
                                </div>
                                <div className="login-info-validation-message">{message.label}</div>
                            </div>
                        );
                    })
                )}
            </div>
            <div className={`input password ${exceptionMessage?.passwordException ? 'error' : ''}`}>
                <TextField
                    label={t(`authentication.registerComplete.password`)}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    value={model.newPassword || ''}
                    onChange={handleValueChange.bind({}, 'newPassword')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {exceptionMessage?.passwordException && (
                    <div className="c-error">
                        <ErrorIcon />
                        {exceptionMessage.passwordException}
                    </div>
                )}
            </div>
            {exceptionMessage?.generalException && (
                <div className="c-error">
                    <ErrorIcon />
                    {exceptionMessage.generalException}
                </div>
            )}
            <div className="login-info-actions">
                <div className="action-link" onClick={handleBackClick}>
                    {t(`authentication.registerComplete.back`)}
                </div>
                <div className="action-button">
                    <Button disabled={!isPasswordValid(model.newPassword.trim())} onClick={completeNewPassword.bind({}, model, user)}>
                        {t(`authentication.registerComplete.submit`)}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default memo(CompleteRegister);
