import React from 'react';
import { useTheme } from '@mui/material';

const ScrapIcon = () => {
    const theme = useTheme();

    return (
        <svg width="39" height="50" viewBox="0 0 39 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.4999 10.9993V15.176C19.4999 16.226 20.7599 16.7393 21.4833 15.9926L27.9933 9.48263C28.4599 9.01596 28.4599 8.29262 27.9933 7.82596L21.4833 1.31596C20.7599 0.592625 19.4999 1.10596 19.4999 2.15596V6.33262C9.18659 6.33262 0.833252 14.686 0.833252 24.9993C0.833252 27.426 1.29992 29.7593 2.16325 31.8826C2.79325 33.446 4.79992 33.866 5.98992 32.676C6.61992 32.046 6.87659 31.0893 6.52659 30.2493C5.84992 28.6393 5.49992 26.8426 5.49992 24.9993C5.49992 17.276 11.7766 10.9993 19.4999 10.9993ZM33.0099 17.3226C32.3799 17.9526 32.1233 18.9326 32.4733 19.7493C33.1266 21.3826 33.4999 23.156 33.4999 24.9993C33.4999 32.7226 27.2233 38.9993 19.4999 38.9993V34.8226C19.4999 33.7726 18.2399 33.2593 17.5166 34.006L11.0066 40.516C10.5399 40.9826 10.5399 41.706 11.0066 42.1726L17.5166 48.6826C18.2399 49.406 19.4999 48.8926 19.4999 47.866V43.666C29.8133 43.666 38.1666 35.3126 38.1666 24.9993C38.1666 22.5726 37.6999 20.2393 36.8366 18.116C36.2066 16.5526 34.1999 16.1326 33.0099 17.3226Z"
                fill={theme?.palette.mode === 'light' ? '#16ABA1' : '#52DBCE'}
            />
        </svg>
    );
};

export default ScrapIcon;
