import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../../AutoSaveTextField';

const UpdateDescriptionInputComponent = ({ value, disabled, handleUpdate }) => {
    const { t } = useTranslation();

    const handleBlur = event => {
        handleUpdate('basicInformation', {
            basicInformation: {
                description: event.target.value,
            },
        });
    };

    return (
        <AutoSaveTextField
            value={value}
            disabled={disabled}
            label={t('report.form.description')}
            rows={2}
            onBlur={handleBlur}
            multiline
            fullWidth
        />
    );
};

const UpdateDescriptionInput = memo(UpdateDescriptionInputComponent);

export default UpdateDescriptionInput;
