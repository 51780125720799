import i18n from '../../utils/i18n';

const { t } = i18n;

export const ncrInfoTabsIndex = {
    INFO_TAB: 0,
    IMMEDIATE_TAB: 1,
    CORRECTIVE_TAB: 2,
    RATING_TAB: 3,
};

export const ncrActivitiesTabsIndex = {
    ALL_TAB: 0,
    CHAT_TAB: 1,
    ACTIVITIES_TAB: 2,
};

export const measureIncidentTypeOptions = [
    {
        label: t('ncrInfo.corrective.correctiveMeasure'),
        value: 'Corrective measure',
    },
    {
        label: t('ncrInfo.corrective.preventiveMeasure'),
        value: 'Preventive measure',
    },
];
