import React, { memo } from 'react';

import FollowerMultipleDropdown from '../../FollowerMultipleDropdown';

const UpdateFollowerDropdownComponent = ({ value, disabled, loading, handleUpdate }) => {
    const handleChange = data => {
        handleUpdate({ follower: data?.map(value => value?.value) || [] });
    };

    return <FollowerMultipleDropdown onChange={handleChange} disabled={disabled} value={value} />;
};

const UpdateFollowerDropdown = memo(UpdateFollowerDropdownComponent);

export default UpdateFollowerDropdown;
