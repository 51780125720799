import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';

import RequestInput from './inputs/RequestInput';

const RequestSpecialSectionComponent = ({ clearanceRequest, handleUpdate, disabled }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Box marginTop="2em" marginBottom="0.25em">
                <Typography variant="overline" textTransform="uppercase">
                    {t('ncrInfo.immediate.requestSpecialRelease')}
                </Typography>
            </Box>
            <Divider sx={{ marginBottom: '1.5em' }} />
            <RequestInput value={clearanceRequest} handleUpdate={handleUpdate} disabled={disabled} />
        </div>
    );
};

const RequestSpecialSection = memo(RequestSpecialSectionComponent);

export default RequestSpecialSection;
