import React from 'react';
import { useTranslation } from 'react-i18next';

import BubbleMask1 from '../../../resources/images/BubbleMask1.png';
import BubbleMask2 from '../../../resources/images/BubbleMask2.png';
import BubbleMask3 from '../../../resources/images/BubbleMask3.png';

import './AuthLayout.css';

const AuthLayout = props => {
    const { t } = useTranslation();

    return (
        <div className="auth-layout">
            <div className="bubble-1">
                <img src={BubbleMask1} alt="bubbleMask1" />
            </div>
            <div className="bubble-2">
                <img src={BubbleMask2} alt="bubbleMask2" />
            </div>
            <div className="bubble-3">
                <img src={BubbleMask3} alt="bubbleMask3" />
            </div>
            <div className="auth-container">
                <div className="app-name-container">{t(`menu.appName`)}</div>
                {props.children}
            </div>
        </div>
    );
};

export default AuthLayout;
