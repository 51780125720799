import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { useNcrOverviewContext } from '../../store/NCROverviewProvider';

import { getNcrArchivedOptions } from '../../../../common/enums/ncrArchivedOptions';

const NcrOverviewArchivedFilter = () => {
    const { t } = useTranslation();
    const { archivedFilter, setNcrOverviewArchivedFilter } = useNcrOverviewContext();

    const ncrArchivedOptions = getNcrArchivedOptions();

    const memoizedValue = archivedFilter ? ncrArchivedOptions.find(status => status.value === archivedFilter) : null;

    return (
        <Autocomplete
            value={memoizedValue}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={ncrArchivedOptions}
            getOptionLabel={option => option.title}
            renderInput={params => <TextField {...params} fullWidth label={t('report.form.archived')} />}
            onChange={(_, data) => setNcrOverviewArchivedFilter(data.value)}
        />
    );
};

export default NcrOverviewArchivedFilter;
