import { gql } from '@apollo/client';

export const ADD_NCR_SECTION_FROM_OWN_TEMPLATE = gql`
    mutation AddNCRSectionFromOwnTemplate($section: NCRSectionInput!) {
        addNCRSectionFromOwnTemplate(section: $section) {
            code
            success
            message
            ncr {
                _id
                name
                scraps {
                    _id
                    piecesToDeliver
                    piecesToRemake
                    discardedPieces
                    piecesInStock
                    responsible {
                        responsibleID
                    }
                    resolvedResponsible {
                        name
                        firstName
                        profilePic {
                            thumbnail
                        }
                    }
                    status
                    costAssignments {
                        value
                        currency
                        costCenter
                    }
                    resolvedWorkflows {
                        _id
                        name {
                            _id
                            language
                            value
                        }
                        ncrID
                        resolvedWorkflowSteps {
                            _id
                            name {
                                _id
                                language
                                value
                            }
                            resolvedResponsible {
                                name
                                firstName
                                profilePic {
                                    thumbnail
                                }
                            }
                            status
                            dueDate
                            ncrID
                            ncrSectionID
                            ncrSectionType
                            availableActions {
                                _id
                                label {
                                    _id
                                    language
                                    value
                                }
                                caption {
                                    _id
                                    language
                                    value
                                }
                                trigger
                            }
                        }
                    }
                }
                clearances {
                    _id
                    comments
                    clearanceRequest
                    clearanceResponse
                    responsible {
                        responsibleID
                    }
                    resolvedResponsible {
                        name
                        firstName
                        email
                        _id
                    }
                    status
                    costAssignments {
                        value
                        currency
                        costCenter
                    }
                    approvalStatus
                    customInputElements {
                        _id
                        name
                        required
                        defaultValue
                        value
                        customInputElementType
                        label {
                            _id
                            language
                            value
                            formattedValue
                        }
                    }
                    workflowsIDs
                    resolvedWorkflows {
                        _id
                        name {
                            _id
                            language
                            value
                            formattedValue
                        }
                        ncrID
                        workflowType
                        resolvedWorkflowSteps {
                            _id
                            status
                            dueDate
                            workflowID
                            ncrID
                            workflowStepTemplateID
                            resolvedResponsible {
                                name
                                firstName
                                profilePic {
                                    thumbnail
                                }
                            }
                            ncrSectionID
                            ncrSectionType
                            name {
                                _id
                                language
                                value
                                formattedValue
                            }
                            availableActions {
                                _id
                                trigger
                                caption {
                                    formattedValue
                                    value
                                    language
                                    _id
                                }
                                label {
                                    formattedValue
                                    value
                                    language
                                    _id
                                }
                            }
                        }
                    }
                }
                reworks {
                    _id
                    status
                    processRoutings {
                        processSteps {
                            _id
                            stepName {
                                value
                                language
                            }
                            stepNumber
                            stepStatus
                            responsible {
                                responsibleID
                            }
                            resolvedResponsible {
                                name
                                firstName
                                _id
                            }
                            plannedDurations {
                                processTime
                            }
                            resolvedActualWorkplaces {
                                _id
                                workPlaceName {
                                    _id
                                    language
                                    value
                                }
                            }
                            schedulingData {
                                dueDate
                            }
                        }
                    }
                    responsible {
                        responsibleID
                    }
                    resolvedResponsible {
                        name
                        firstName
                        _id
                    }
                    costAssignments {
                        value
                        currency
                        costCenter
                    }
                    workflowsIDs
                    resolvedWorkflows {
                        _id
                        name {
                            _id
                            language
                            value
                        }
                        workflowType
                        ncrID
                        resolvedWorkflowSteps {
                            _id
                            name {
                                _id
                                language
                                value
                            }
                            resolvedResponsible {
                                name
                                firstName
                                profilePic {
                                    thumbnail
                                }
                            }
                            status
                            ncrID
                            workflowID
                            workflowStepTemplateID
                            ncrSectionID
                            ncrSectionType
                            availableActions {
                                _id
                                label {
                                    _id
                                    language
                                    value
                                }
                                caption {
                                    _id
                                    language
                                    value
                                }
                                trigger
                            }
                            dueDate
                        }
                    }
                }
            }
        }
    }
`;
