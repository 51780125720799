import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid, Typography } from '@mui/material';

import { isArrayWithItems } from './../../../utils/common';
import { ncrStatus } from '../../enums/ncrStatus';

import CostCenterInput from './CostCenterInput';
import CostInput from './CostInput';

const CostSectionComponent = ({ path, disabled, data, handleUpdate }) => {
    const { t } = useTranslation();

    const costAssignment = isArrayWithItems(data?.costAssignments) ? data?.costAssignments[data?.costAssignments?.length - 1] : null;
    const statusDone = useMemo(() => [ncrStatus.DONE, ncrStatus.REJECTED].includes(data.status), [data.status]);
    const operation = isArrayWithItems(data?.costAssignments) ? 'update' : 'add';

    return (
        <Box marginBottom="2em">
            <Box marginTop="2em" marginBottom="0.25em">
                <Typography variant="overline" textTransform="uppercase">
                    {t('ncrInfo.immediate.cost')}
                </Typography>
            </Box>
            <Divider sx={{ marginBottom: '1.5em' }} />

            <Grid width="100%" container spacing={6}>
                <Grid item sm={12} md={5}>
                    <CostInput
                        costAssignment={costAssignment}
                        handleUpdate={handleUpdate}
                        path={path}
                        disabled={statusDone || disabled}
                        operation={operation}
                    />
                </Grid>
                <Grid item sm={12} md={5}>
                    <CostCenterInput
                        costAssignment={costAssignment}
                        handleUpdate={handleUpdate}
                        path={path}
                        disabled={statusDone || disabled}
                        operation={operation}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

const NcrCostSection = memo(CostSectionComponent);

export default NcrCostSection;
