import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';

const MenuLink = styled(Link)(() => ({
    color: 'inherit',
    textDecoration: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
}));

export default MenuLink;
