import { Container } from '@mui/material';

import NCRTemplateProvider from '../../common/contexts/ncrTemplate/NCRTemplateProvider';

import CreateNCRForm from './components/CreateNCRForm';

const ReportPage = () => {
    return (
        <NCRTemplateProvider>
            <Container maxWidth="md">
                <CreateNCRForm />
            </Container>
        </NCRTemplateProvider>
    );
};

export default ReportPage;
