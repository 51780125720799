import { gql } from '@apollo/client';

export const GET_OVERVIEW_PAGINATED_NCRS = gql`
    query GetPaginatedNCRs($filter: String, $limit: Int, $skip: Int, $sort: String) {
        getPaginatedNCRs(filter: $filter, limit: $limit, skip: $skip, sort: $sort) {
            code
            success
            message
            ncrs {
                _id
                name
                status
                archived
                basicInformation {
                    externalReference
                    productionOrderID
                    failureCategory {
                        name
                        failureMode
                    }
                }
                ncrCreatedBy {
                    createdAt
                }
                ncrModifiedBy {
                    modifiedAt
                }
                resolvedResponsible {
                    name
                    firstName
                    _id
                    profilePic {
                        thumbnail
                    }
                }
            }
            metadata {
                total
            }
        }
    }
`;
