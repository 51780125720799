import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { GET_PAGINATED_FAILURE_CATEGORIES } from '../operations/queries/getPaginatedFailureCategories';

export const useGetPaginatedFailureCategoriesOptions = () => {
    const [failureCategoriesOptions, setFailureCategoriesOptions] = useState([]);

    const [getPaginatedFailureCategories, { loading: loadingFailureCategories }] = useLazyQuery(GET_PAGINATED_FAILURE_CATEGORIES, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            if (data?.getPaginateFailureCategories?.success) {
                mapFailureCategoriesOptions(data?.getPaginateFailureCategories?.failureCategory);

                if (data?.getPaginateFailureCategories?.metadata?.hasNext) {
                    getPaginatedFailureCategories({
                        variables: {
                            limit: data?.getPaginateFailureCategories?.metadata?.limit,
                            skip: data?.getPaginateFailureCategories?.metadata?.skip + data?.getPaginateFailureCategories?.metadata?.limit,
                        },
                    });
                }
            }
        },
    });

    useEffect(() => {
        getPaginatedFailureCategories({
            variables: {
                limit: 100,
                skip: 0,
                filter: JSON.stringify({
                    usedBy: 'ncr',
                }),
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mapFailureCategoriesOptions = failureCategory => {
        setFailureCategoriesOptions(prev => {
            let options = [];

            failureCategory?.forEach(element => {
                const elements = element.failureModes.map(failureMode => {
                    return {
                        name: element.name,
                        failureMode: failureMode.name,
                    };
                });

                options = [...options, ...elements];
            });

            return [...prev, ...options];
        });
    };

    return [failureCategoriesOptions, loadingFailureCategories];
};
