import React from 'react';
import { useTheme } from '@mui/material';

const SpecialReleaseIcon = () => {
    const theme = useTheme();

    return (
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M43.2168 11.0002L44.3601 8.52688L46.8335 7.38354C47.7435 6.96354 47.7435 5.68021 46.8335 5.26021L44.3601 4.11688L43.2168 1.66688C42.7968 0.756875 41.5135 0.756875 41.0935 1.66688L39.9501 4.14021L37.5001 5.28354C36.5901 5.70354 36.5901 6.98688 37.5001 7.40688L39.9735 8.55021L41.1168 11.0002C41.5135 11.9102 42.8201 11.9102 43.2168 11.0002Z"
                fill={theme?.palette.mode === 'light' ? '#16ABA1' : '#52DBCE'}
            />
            <path
                d="M16.3833 11.0002L17.5266 8.52688L20 7.38354C20.91 6.96354 20.91 5.68021 20 5.26021L17.5266 4.14021L16.3833 1.66688C15.9866 0.756875 14.68 0.756875 14.2833 1.66688L13.14 4.14021L10.6666 5.28354C9.75663 5.70354 9.75663 6.98688 10.6666 7.40688L13.14 8.55021L14.2833 11.0002C14.68 11.9102 15.9866 11.9102 16.3833 11.0002Z"
                fill={theme?.palette.mode === 'light' ? '#16ABA1' : '#52DBCE'}
            />
            <path
                d="M41.1168 28.4999L39.9735 30.9732L37.5001 32.1166C36.5901 32.5366 36.5901 33.8199 37.5001 34.2399L39.9735 35.3832L41.1168 37.8566C41.5368 38.7666 42.8201 38.7666 43.2401 37.8566L44.3835 35.3832L46.8335 34.2166C47.7435 33.7966 47.7435 32.5132 46.8335 32.0932L44.3601 30.9499L43.2168 28.4766C42.8201 27.5899 41.5135 27.5899 41.1168 28.4999Z"
                fill={theme?.palette.mode === 'light' ? '#16ABA1' : '#52DBCE'}
            />
            <path
                d="M36.8234 18.28L30.2201 11.6766C29.3101 10.7666 27.8401 10.7666 26.9301 11.6766L0.843389 37.74C-0.0666113 38.65 -0.0666113 40.12 0.843389 41.03L7.44672 47.6333C8.35672 48.5433 9.82672 48.5433 10.7367 47.6333L36.8001 21.57C37.7334 20.6833 37.7334 19.19 36.8234 18.28ZM28.6567 23.1566L25.3667 19.8666L28.5867 16.6466L31.8767 19.9366L28.6567 23.1566Z"
                fill={theme?.palette.mode === 'light' ? '#16ABA1' : '#52DBCE'}
            />
        </svg>
    );
};

export default SpecialReleaseIcon;
