import { useMutation } from '@apollo/client';

import { ncrStatus } from '../../../../enums/ncrStatus';

import { UPDATE_WORKFLOW_STEP_IN_APPROVAL_MODE } from './../../operations/mutations/updateWorkflowStepInApprovalMode';

export const useUpdateWorkflowStepInApprovalMode = () => {
    const [atomic, { loading }] = useMutation(UPDATE_WORKFLOW_STEP_IN_APPROVAL_MODE);

    const handleUpdateWorkflowStepInApprovalMode = ({ currentWorkflowStepId }) => {
        return atomic({
            variables: {
                currentWorkflowStepId: {
                    _id: currentWorkflowStepId,
                    status: ncrStatus.DONE,
                },
            },
            refetchQueries: ['GetSingleNCR'],
        });
    };

    return [handleUpdateWorkflowStepInApprovalMode, loading];
};
