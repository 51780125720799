import React from 'react';
import { useTranslation } from 'react-i18next';

import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { notificationsSeverityByRequestStatus } from '../../../../providers/notification/constants';
import { useNotificationContext } from '../../../../providers/notification/store/notification.context';

import { useRemoveSectionFromNCR } from '../hooks/useRemoveSectionFromNCR';

const DiscardModal = ({ ncrId, sectionId, isOpened, toggleModal }) => {
    const { t } = useTranslation();

    const { setNotification } = useNotificationContext();

    const [handleRemoveSectionFromNCR, loading] = useRemoveSectionFromNCR();

    const handleApprovalAction = () => {
        handleRemoveSectionFromNCR({ ncrId, sectionId })
            .then(response => {
                if (response?.data?.removeSectionFromNCR?.success) {
                    setNotification({ severity: notificationsSeverityByRequestStatus[response?.data?.removeSectionFromNCR?.success] });
                    toggleModal();
                }
            })
            .catch(_ => {
                setNotification({ severity: notificationsSeverityByRequestStatus[false] });
            });
    };

    return (
        <Dialog fullWidth maxWidth="sm" onClose={toggleModal} open={isOpened}>
            <DialogTitle sx={{ textAlign: 'center', marginBottom: '2em' }} variant="body1">
                <Typography variant="h5">{t('ncrInfo.immediate.actions.discard.title')}</Typography>
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'center' }}>
                <Typography variant="body1" sx={{ marginBottom: '1em' }}>
                    {t('ncrInfo.immediate.actions.discard.description')}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', direction: 'row', justifyContent: 'center', paddingBottom: '2em' }}>
                <Button sx={{ cursor: 'pointer' }} onClick={toggleModal} variant="outlined" color="primary">
                    <Typography variant="button" sx={{ cursor: 'pointer' }}>
                        {t('ncrInfo.immediate.actions.discard.backButton')}
                    </Typography>
                </Button>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleApprovalAction}
                    startIcon={<NotInterestedOutlinedIcon />}
                    variant="contained"
                    color="error">
                    <Typography variant="button" sx={{ cursor: 'pointer' }}>
                        {t('ncrInfo.immediate.actions.discard.submitButton')}
                    </Typography>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default DiscardModal;
