import React from 'react';
import { useTranslation } from 'react-i18next';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { styled } from '@mui/system';

const UnknownErrorRoot = styled('div')(({ theme }) => ({
    height: '82vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 22,
    padding: 24,

    '& .MuiSvgIcon-root': {
        fontSize: 52,
        marginRight: 20,
    },
}));

const UnknownPage = () => {
    const { t } = useTranslation();

    return (
        <UnknownErrorRoot>
            <HighlightOffIcon />
            {t('common.unknown-page')}
        </UnknownErrorRoot>
    );
};

export default UnknownPage;
