import { Fragment } from 'react';
import { createPortal } from 'react-dom';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFloatingButtonWrapper = styled(Box)(({ _theme }) => ({
    position: 'fixed',
    bottom: '24px',
    zIndex: 1000,
    left: '50%',
    marginLeft: '-114px', // btn width : 2
}));

const StyledFloatingButton = styled(Button)(({ _theme }) => ({
    width: 218,
    height: 52,
    fontSize: '1em',
}));

const defaultIcon = <AddRoundedIcon />;

/**
 * This portal is using for rendering floating action buttons and modal window with creation form.
 * Examples: New User (Admin page), New NCR (NCR Overview page), etc.
 * The props of the FloatingActionButtonPortal are limited but feel free to update this list if needed.
 * Please use MUI API: https://mui.com/material-ui/api/button/
 * The `children` prop should contains modal window with creation form. Also, it can be some another component.
 */
const FloatingActionButtonPortal = ({ label, sx, variant, color, onClick, startIcon, children }) => {
    const divContainer = document.getElementById('floating-button');

    if (!divContainer) {
        return <Fragment />;
    }

    return createPortal(
        <StyledFloatingButtonWrapper>
            <StyledFloatingButton
                variant={variant || 'contained'}
                color={color || 'primary'}
                startIcon={startIcon || defaultIcon}
                onClick={onClick}
                sx={sx}>
                {label}
            </StyledFloatingButton>
            {children}
        </StyledFloatingButtonWrapper>,
        divContainer
    );
};

export default FloatingActionButtonPortal;
