import { useState } from 'react';

import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { LoadingButton } from '@mui/lab';
import { Box, Collapse, Paper, Stack, Typography } from '@mui/material';

import NcrBasicInfoForm from '../../../../../../../common/components/ncr/NcrBasicInfoForm';
import NcrActivitiesTabs from '../../../ncr-info/activities/NcrActivitiesTabs';

const NcrInfoAccordion = ({ ncr }) => {
    const [isBasicInfoCollapsed, collapseBasicInfo] = useState(false);
    const [isActivitiesCollapsed, collapseActivitiesInfo] = useState(false);

    return (
        <Box style={{ margin: 0 }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                <LoadingButton
                    style={{ borderRadius: 8, cursor: 'pointer' }}
                    onClick={() => {
                        collapseBasicInfo(!isBasicInfoCollapsed);
                        collapseActivitiesInfo(false);
                    }}
                    startIcon={<InfoRoundedIcon />}
                    variant={isBasicInfoCollapsed ? 'contained' : 'outlined'}
                    color="info">
                    <Typography variant="button">Basic Info</Typography>
                </LoadingButton>

                <LoadingButton
                    style={{ borderRadius: 8, cursor: 'pointer' }}
                    onClick={() => {
                        collapseBasicInfo(false);
                        collapseActivitiesInfo(!isActivitiesCollapsed);
                    }}
                    startIcon={<ChatRoundedIcon />}
                    variant={isActivitiesCollapsed ? 'contained' : 'outlined'}
                    color="info">
                    <Typography variant="button">Activities</Typography>
                </LoadingButton>
            </Stack>
            <Collapse in={isBasicInfoCollapsed}>
                <Paper style={{ borderRadius: 16, padding: '1.5em', marginTop: '2em' }} elevation={3}>
                    <NcrBasicInfoForm ncr={ncr} />
                </Paper>
            </Collapse>
            <Collapse in={isActivitiesCollapsed}>
                <Paper style={{ borderRadius: 16, padding: '1.5em', marginTop: '2em' }} elevation={3}>
                    <NcrActivitiesTabs ncr={ncr} ncrId={ncr?._id} />
                </Paper>
            </Collapse>
        </Box>
    );
};

export default NcrInfoAccordion;
