import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';

import { resetUsersTableFilters, setUsersTableFilter, setUsersTableSearchFilter, toggleUsersTableFilters } from './usersTableActions';
import { usersTableReducer, userTableInitialState } from './usersTableReducer';

export const NcrOverviewContext = createContext(userTableInitialState);

const UsersTableProvider = ({ children }) => {
    const [state, dispatch] = useReducer(usersTableReducer, userTableInitialState);

    const memoizedSetUsersTableSearchFilter = useCallback(filter => dispatch(setUsersTableSearchFilter(filter)), [dispatch]);
    const memoizedResetUsersTableFilters = useCallback(_ => dispatch(resetUsersTableFilters()), [dispatch]);
    const memoizedToggleUsersTableFilters = useCallback(_ => dispatch(toggleUsersTableFilters()), [dispatch]);
    const memoizedSetUsersTableFilter = useCallback(payload => dispatch(setUsersTableFilter(payload)), [dispatch]);

    const usersTableContextValue = useMemo(() => {
        return {
            searchFilter: state.searchFilter,
            filtersOpened: state.filtersOpened,
            lastNameFilter: state.lastNameFilter,
            firstNameFilter: state.firstNameFilter,
            emailFilter: state.emailFilter,
            roleFilter: state.roleFilter,
            setUsersTableSearchFilter: memoizedSetUsersTableSearchFilter,
            resetUsersTableFilters: memoizedResetUsersTableFilters,
            toggleUsersTableFilters: memoizedToggleUsersTableFilters,
            setUsersTableFilter: memoizedSetUsersTableFilter,
        };
    }, [
        state.searchFilter,
        state.filtersOpened,
        state.lastNameFilter,
        state.firstNameFilter,
        state.emailFilter,
        state.roleFilter,
        memoizedSetUsersTableSearchFilter,
        memoizedResetUsersTableFilters,
        memoizedToggleUsersTableFilters,
        memoizedSetUsersTableFilter,
    ]);

    return <UsersTableContext.Provider value={usersTableContextValue}>{children}</UsersTableContext.Provider>;
};

const UsersTableContext = createContext(userTableInitialState);

export const useUsersTableContext = () => {
    const context = useContext(UsersTableContext);

    if (context === undefined) {
        return userTableInitialState;
    }

    return context;
};

export default UsersTableProvider;
