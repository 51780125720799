import { useMemo } from 'react';

import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '../../../common/constants/constants';

import { useLazySearchPaginatedWorkflowStepsByNCR } from './useLazyGetPaginatedMyTasks';
import { useMyTasksTableColumns } from './useMyTasksTableColumns';
import { useGetOrganizations } from './useGetOrganizations';

export const useMyTasksTableData = userId => {
    const { organizations } = useGetOrganizations();
    const [items, loading, fetchData] = useLazySearchPaginatedWorkflowStepsByNCR(userId);
    const [columns] = useMyTasksTableColumns();

    const rows = useMemo(() => {
        if (!items || !organizations) {
            return [];
        }

        return (
            items?.workflowSteps?.map(item => {
                return {
                    rowId: item._id,
                    description: item.description,
                    name: item?.resolvedNCR?.name,
                    ncrId: item.ncrID,
                    ncrSectionID: item.ncrSectionID,
                    ncrSectionType: item.ncrSectionType,
                    externalReference: item.resolvedNCR?.basicInformation?.externalReference || 'N/A',
                    dueDate: moment(item.dueDate).format(DEFAULT_DATE_FORMAT),
                    responsible: !!item?.resolvedResponsible?.length ? item?.resolvedResponsible[0] : null,
                    teams: item.organizations?.map(organization => {
                        const teams = organizations?.map(org => org.teams).flat(1);

                        return organization?.teamsIDs
                            ?.map(teamId => teams?.find(team => team?._id === teamId)?.name)
                            ?.filter(team => !!team?.length);
                    }),
                };
            }) || []
        );
    }, [items, organizations]);

    const total = useMemo(() => {
        if (!items) {
            return 0;
        }

        return items.metadata.total;
    }, [items]);

    return [rows, columns, loading, total, fetchData];
};
