import { useMutation } from '@apollo/client';
import { UPDATE_NCR } from '../operations/mutations/updateNCR';

export const useUpdateNCR = updateCallback => {
    const [updateNCR, { loading }] = useMutation(UPDATE_NCR, {
        update: updateCallback,
        refetchQueries: ['GetPaginatedNCRs'],
    });

    const handleUpdate = (ncrId, newModel = {}) => {
        return updateNCR({
            variables: {
                ncr: {
                    _id: ncrId,
                    ...newModel,
                },
            },
        });
    };

    return [handleUpdate, loading];
};
