import { useTranslation } from 'react-i18next';

import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';

const ConfirmationModal = ({ isOpen, title, handleConfirm, handleModalClose, disabledAction }) => {
    const { t } = useTranslation();

    return (
        <Dialog onClose={handleModalClose} open={isOpen}>
            <DialogTitle>{title}</DialogTitle>
            <Grid container spacing={4} sx={{ marginBottom: '1em' }}>
                <Grid item xs={6}>
                    <Box display="flex" sx={{ justifyContent: 'end' }}>
                        <Button variant="contained" color="primary" onClick={handleModalClose} disabled={disabledAction}>
                            {t('common.action-buttons.no')}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" sx={{ justifyContent: 'start' }}>
                        <Button variant="contained" color="error" onClick={handleConfirm} disabled={disabledAction}>
                            {t('common.action-buttons.yes')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ConfirmationModal;
