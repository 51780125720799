import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';

import { GET_ORGANIZATIONS, GET_SINGLE_USER, UPDATE_USER } from '../../../../../common/gqlQueries/queries';

import UserTemplateWrapper from '../templates/UserTemplateWrapper';

const EditUser = ({ overlayData, setOverlayData, handleModalClose }) => {
    const params = useParams();
    const userId = overlayData.userId || params.userId;

    const [isDataSaved, setIsDataSaved] = useState(true);

    const { data: userData } = useQuery(GET_SINGLE_USER, {
        variables: { userID: userId },
        fetchPolicy: 'cache-and-network',
    });

    const [user, setUser] = useState(null);

    useEffect(() => {
        if (userData) {
            setUser(userData.getSingleUser?.user);
        }
    }, [userData]);

    const [updateUser] = useMutation(UPDATE_USER, {
        refetchQueries: ['getCurrentUserbySubID'],
        onCompleted: data => {
            if (data.adminUpdateUser?.code === '200') {
                setUser(data.adminUpdateUser.user);
            }
        },
    });

    const { data: organizationData } = useQuery(GET_ORGANIZATIONS, { fetchPolicy: 'cache-and-network' });

    return (
        <UserTemplateWrapper
            isEdit
            organizations={organizationData?.getOrganizations?.organizations || []}
            overlayData={overlayData}
            setOverlayData={setOverlayData}
            handleModalClose={handleModalClose}
            user={user}
            isDataSaved={isDataSaved}
            setIsDataSaved={setIsDataSaved}
            handleSubmit={updateUser}
        />
    );
};

export default EditUser;
