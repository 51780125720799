import { ncrMyTasksActions } from './NCRMyTasksActions';

export const ncrMyTasksInitialState = {
    searchFilter: '',
    dateRangeFilter: {
        from: null,
        to: null,
    },
    myTask: true,
    selectedTeams: [],
    failureCategoryFilter: null,
    pagination: {
        page: 0,
        sortBy: 'dueDate',
        order: 'asc',
    },
};

export const ncrMyTasksReducer = (state, action) => {
    switch (action.type) {
        case ncrMyTasksActions.SET_NCR_MY_TASKS_SEARCH_FILTER: {
            return {
                ...state,
                searchFilter: action.payload,
                pagination: {
                    ...state.pagination,
                    page: 0,
                },
            };
        }
        case ncrMyTasksActions.SET_NCR_MY_TASKS_DATE_RANGE_FILTER: {
            return {
                ...state,
                dateRangeFilter: action.payload,
            };
        }
        case ncrMyTasksActions.SET_NCR_MY_TASKS_FAILURE_CATEGORY_FILTER: {
            return {
                ...state,
                failureCategoryFilter: action.payload,
            };
        }
        case ncrMyTasksActions.RESET_NCR_MY_TASKS_FILTERS: {
            return ncrMyTasksInitialState;
        }
        case ncrMyTasksActions.SET_NCT_MY_TASKS_MY_TASK: {
            return {
                ...state,
                myTask: action.payload,
            };
        }
        case ncrMyTasksActions.SET_NCR_MY_TASK_SELECTED_TEAMS: {
            return {
                ...state,
                selectedTeams: action.payload,
            };
        }

        case ncrMyTasksActions.SET_NCR_MY_TASKS_TABLE_PAGE: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.payload,
                },
            };
        }
        case ncrMyTasksActions.SET_NCR_MY_TASKS_TABLE_SORTING: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    sortBy: action.payload.sortBy,
                    order: action.payload.order,
                },
            };
        }
        default:
            return ncrMyTasksInitialState;
    }
};
