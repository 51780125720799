/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { Box } from '@mui/material';

import { useFiles } from '../../../common/hooks/useFiles';
import { notificationsSeverityByRequestStatus } from '../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../common/providers/notification/store/notification.context';

import { AWS_S3_BUCKET_EHS_USER_FOLDER } from '../../../configs/env';

import { useUpdateStatusCapaTicket } from '../../ncr-info/components/tabs/ncr-corrective/hooks/useUpdateStatusCapaTicket';

import { updateTicketStatusInCache } from '../constants';

import { useTicketKanbanBoardContext } from '../store/TicketsKanbanBoardContext';

import DoneColumn from './drag-and-drop/DoneColumn';
import EditCapaTicketModal from './drag-and-drop/EditCapaTicketModal';
import InWorkColumn from './drag-and-drop/InWorkColumn';
import OpenColumn from './drag-and-drop/OpenColumn';

const TicketsKanbanBoardContainer = () => {
    const [handleFetchUserImages, userImages] = useFiles(AWS_S3_BUCKET_EHS_USER_FOLDER);

    const [handleUpdateStatusCapaTicket] = useUpdateStatusCapaTicket();

    const { setNotification } = useNotificationContext();

    const { searchFilter, dateRangeFilter, incidentTypeFilter, responsibleFilter } = useTicketKanbanBoardContext();

    useEffect(() => {
        handleFetchUserImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDragEnd = result => {
        if (!result.destination) return;

        const { source, destination, draggableId } = result;

        const sourceDroppableId = source.droppableId.split('_');
        const [_sourceColumnId, sourseStatus] = sourceDroppableId;

        const destinationDroppableId = destination.droppableId.split('_');
        const [_destinationColumnId, destinationStatus] = destinationDroppableId;

        if (source.droppableId !== destination.droppableId) {
            const capaTicket = {
                _id: draggableId,
                status: destinationStatus,
            };

            handleUpdateStatusCapaTicket(capaTicket)
                .then(result => {
                    if (result?.data?.updateCapaTicket?.success) {
                        setNotification({ severity: notificationsSeverityByRequestStatus[result?.data?.updateCapaTicket?.success] });
                    }
                })
                .catch(() => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                });

            updateTicketStatusInCache(capaTicket, sourseStatus, { searchFilter, dateRangeFilter, incidentTypeFilter, responsibleFilter });
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                overflow: 'auto',
                height: 'calc(100vh - 300px)',
                justifyContent: 'space-between',
            }}>
            <DragDropContext onDragEnd={onDragEnd}>
                <OpenColumn userImages={userImages} />
                <InWorkColumn userImages={userImages} />
                <DoneColumn userImages={userImages} />
            </DragDropContext>
            <EditCapaTicketModal updateCache />
        </Box>
    );
};

export default TicketsKanbanBoardContainer;
