import { useContext } from 'react';

import FollowerMultipleDropdown from '../../../../common/components/FollowerMultipleDropdown';
import { NcrTemplateContext } from '../../../../common/contexts/ncrTemplate/store/context';

const FollowerInput = ({ value, disabled }) => {
    const { setNCR } = useContext(NcrTemplateContext);

    const handleChange = value => {
        setNCR({ value: value?.map(value => value?.value), path: 'follower' });
    };

    return <FollowerMultipleDropdown onChange={handleChange} disabled={disabled} value={value} />;
};

export default FollowerInput;
