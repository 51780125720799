import { useState } from 'react';

import { useQuery } from '@apollo/client';

import { GET_USERS } from '../../../../common/gqlQueries/queries';
import { isArrayWithItems } from './../../../../utils/common';

export const useGetUsersData = () => {
    const [initialUsersData, setInitialUsersData] = useState([]);
    const [usersData, setUsersData] = useState([]);

    const { loading } = useQuery(GET_USERS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: response => {
            const users = response?.getUsers;

            if (users) {
                setInitialUsersData([...users]);
                setUsersData([...users]);
            }
        },
    });

    const searchBy = (searchFilter, user) =>
        user.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
        user.firstName.toLowerCase().includes(searchFilter.toLowerCase()) ||
        user.email.toLowerCase().includes(searchFilter.toLowerCase());

    const filterBy = ({ searchFilter, lastNameFilter, firstNameFilter, emailFilter, roleFilter }) => {
        setUsersData(_ => {
            if (!lastNameFilter && !firstNameFilter && !emailFilter && !isArrayWithItems(roleFilter)) {
                return initialUsersData.filter(user => searchBy(searchFilter, user));
            }

            return initialUsersData
                .filter(user => searchBy(searchFilter, user))
                .filter(user => user.name.toLowerCase().includes(lastNameFilter.toLowerCase()))
                .filter(user => user.firstName.toLowerCase().includes(firstNameFilter.toLowerCase()))
                .filter(user => user.email.toLowerCase().includes(emailFilter.toLowerCase()))
                .filter(user => (roleFilter.length ? roleFilter.some(filter => user.roles.includes(filter.value)) : true));
        });
    };

    return [usersData, loading, filterBy];
};
