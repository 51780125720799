import React from 'react';

import { ncrUserRolesArray } from '../../../../../common/constants/userRoles';
import { useUserSettingsContext } from '../../../../../common/providers/userSettings/store/userSettings.context';

import i18n from '../../../../../utils/i18n';
import { userRole } from '../../../../../utils/userRoles';

const userDataProvider = ComposedComponent => {
    return props => {
        const { userData } = useUserSettingsContext();

        const currentUserId = userData._id;
        const roles = userData.roles;
        const currentUserOrganizations = userData.organizations;

        const isDisableActionForTeamAdmin = !roles?.includes(userRole.admin) && roles?.includes(userRole.teamAdmin);
        const appRoles = isDisableActionForTeamAdmin ? ncrUserRolesArray.filter(role => role !== userRole.admin) : ncrUserRolesArray;

        if (isDisableActionForTeamAdmin && currentUserOrganizations && !props.model.organizations.length) {
            props.handleChangeWithoutDelay(
                'organizations',
                currentUserOrganizations.map(organization => ({
                    organizationID: organization.organizationID,
                    teamsIDs: organization.teamsIDs,
                }))
            );
        }

        const roleOptions = ncrUserRolesArray
            .map(role => {
                return {
                    title: i18n.t(`roles.${role}`),
                    value: role,
                };
            })
            .sort((a, b) => a?.value?.localeCompare(b?.value));

        const organizationOptions = props.organizations
            .map(x => {
                return {
                    value: x._id,
                    title: x.companyName,
                };
            })
            .sort((a, b) => a?.value?.localeCompare(b?.value));

        const getTeamOptions = () => {
            const organizationIds = props.model.organizations.map(opt => opt.organizationID);
            const organizationObjects = props.organizations.filter(org => organizationIds.some(orgId => orgId === org._id));

            const teams = organizationObjects
                .map(org =>
                    org.teams.map(team => {
                        return {
                            ...team,
                            organizationId: org._id,
                        };
                    })
                )
                .flat();

            return teams
                .filter(team => team.name)
                .map(team => {
                    return {
                        value: team._id,
                        title: team.name,
                        orgaizationId: team.organizationId,
                    };
                });
        };

        const handleUserRoleChange = (event, options) => {
            const currentRoles = props.model.roles
                ? props.model.roles.filter(currentRole => appRoles.every(appRole => currentRole !== appRole))
                : [];
            const newRoles = options.map(opt => opt.value);

            const resultRoles = [...currentRoles, ...newRoles];

            props.handleChangeWithoutDelay('roles', resultRoles);
        };

        const handleOrganizationChange = (event, options) => {
            const newModel = options.map(option => {
                const organization = props.model.organizations.find(org => org.organizationID === option.value);

                if (organization) {
                    return {
                        organizationID: organization.organizationID,
                        teamsIDs: organization.teamsIDs,
                    };
                } else {
                    return {
                        organizationID: option.value,
                        teamsIDs: [],
                    };
                }
            });

            props.handleChangeWithoutDelay('organizations', newModel);
        };

        const handleTeamChange = (event, options) => {
            const newModel = props.model.organizations.map(organization => {
                return {
                    organizationID: organization.organizationID,
                    teamsIDs: [],
                };
            });

            options.forEach(opt => {
                const organizationIndex = props.model.organizations.findIndex(org => org.organizationID === opt.orgaizationId);

                newModel[organizationIndex].teamsIDs.push(opt.value);
            });

            props.handleChangeWithoutDelay('organizations', newModel);
        };

        return (
            <ComposedComponent
                {...props}
                roleOptions={roleOptions}
                organizationOptions={organizationOptions}
                getTeamOptions={getTeamOptions}
                disableEmailInput={props.isEdit && currentUserId !== props.model._id}
                isDisableActionForTeamAdmin={isDisableActionForTeamAdmin}
                handleUserRoleChange={handleUserRoleChange}
                handleOrganizationChange={handleOrganizationChange}
                handleTeamChange={handleTeamChange}
            />
        );
    };
};

export default userDataProvider;
