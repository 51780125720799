import { gql } from '@apollo/client';

export const ADD_NEW_STEP_TO_NCR = gql`
    mutation AtomicNCR($atomics: [NCRAtomic!]!) {
        atomicNCR(atomics: $atomics) {
            code
            success
            message
            ncr {
                _id
                name
                reworks {
                    _id
                    processRoutings {
                        processSteps {
                            _id
                            stepName {
                                language
                                value
                            }
                            stepNumber
                            plannedDurations {
                                processTime
                            }
                            schedulingData {
                                dueDate
                            }
                            resolvedResponsible {
                                _id
                                name
                                firstName
                                profilePic {
                                    thumbnail
                                }
                            }
                            stepStatus
                            resolvedActualWorkplaces {
                                _id
                                workplaceType
                                workPlaceName {
                                    _id
                                    language
                                    value
                                }
                            }
                            workplaces {
                                _id
                                workplaceType
                                workPlaceName {
                                    _id
                                    language
                                    value
                                }
                            }
                            actualWorkplaces {
                                workplaceType
                                workPlaceName {
                                    _id
                                    language
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
