import { Chip } from '@mui/material';

import i18n from '../../../utils/i18n';

import { ncrStatus } from '../../enums/ncrStatus';

const { t } = i18n;

const status = {
    [ncrStatus.OPEN]: t('statuses.open'),
    [ncrStatus.IN_WORK]: t('statuses.inWork'),
    [ncrStatus.DONE]: t('statuses.done'),
    [ncrStatus.REJECTED]: t('statuses.rejected'),
};

const NcrStatusLabel = ({ value }) => {
    return <Chip label={status[value]} />;
};

export default NcrStatusLabel;
