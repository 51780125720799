import { memo } from 'react';

import { Grid, LinearProgress } from '@mui/material';

import NcrBasicInfoForm from '../../../../../../../common/components/ncr/NcrBasicInfoForm';
import AttachmentSection from './components/AttachmentsSections';

const InfoTabComponent = ({ ncr, loading }) => {
    return (
        <Grid container spacing={4}>
            {loading && (
                <Grid item xs={12}>
                    <LinearProgress color="primary" sx={{ visibility: loading ? 'visible' : 'hidden' }} />
                </Grid>
            )}
            <Grid item xs={12}>
                <NcrBasicInfoForm ncr={ncr} loading={loading} />
            </Grid>
            <Grid item xs={12}>
                <AttachmentSection mediaInputs={ncr?.basicInformation?.mediaInputs} />
            </Grid>
        </Grid>
    );
};

const InfoTab = memo(InfoTabComponent);

export default InfoTab;
