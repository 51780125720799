import NCROverviewProvider from '../../../ncr-overview/store/NCROverviewProvider';
import BinPage from './bin/BinPage';

const BinPageWrapper = () => {
    return (
        <NCROverviewProvider>
            <BinPage />
        </NCROverviewProvider>
    );
};

export default BinPageWrapper;
