import i18n from '../../utils/i18n';

export const ncrUserRoles = {
    admin: 'admin',
    reporter: 'NCRReporter',
    ticketResponsible: 'NCRTicketResponsible',
    taskResponsible: 'NCRTaskResponsible',
    qualityManager: 'NCRQualityManager',
};

export const reportPageRoles = [ncrUserRoles.admin, ncrUserRoles.reporter, ncrUserRoles.qualityManager];

export const inboxPageRoles = [ncrUserRoles.admin, ncrUserRoles.qualityManager];

export const ncrOverviewPageRoles = [
    ncrUserRoles.admin,
    ncrUserRoles.ticketResponsible,
    ncrUserRoles.taskResponsible,
    ncrUserRoles.qualityManager,
];

export const ncrIdOverviewPageRoles = [ncrUserRoles.admin, ncrUserRoles.taskResponsible, ncrUserRoles.qualityManager];

export const tasksPageRoles = [ncrUserRoles.admin, ncrUserRoles.taskResponsible, ncrUserRoles.qualityManager];

export const ticketsKanbanBoardPageRoles = [ncrUserRoles.admin, ncrUserRoles.ticketResponsible, ncrUserRoles.qualityManager];

export const ncrUserRolesArray = [
    ncrUserRoles.admin,
    ncrUserRoles.reporter,
    ncrUserRoles.taskResponsible,
    ncrUserRoles.ticketResponsible,
    ncrUserRoles.qualityManager,
];

export const getFilteredNcrUserRoles = allRoles => {
    return allRoles
        ?.map(role => {
            if (!ncrUserRolesArray?.includes(role)) {
                return null;
            }

            return i18n.t(`roles.${role}`);
        })
        ?.filter(role => role);
};
