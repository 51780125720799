import { useMemo } from 'react';

import { useGetOrganizations } from './useGetOrganizations';

export const useGetTeamsOptions = () => {
    const { organizations } = useGetOrganizations();

    return useMemo(() => {
        if (!organizations) {
            return [];
        }

        return organizations
            ?.map(org => org.teams)
            .flat(1)
            .map(team => ({
                value: team._id,
                title: team.name,
            }));
    }, [organizations]);
};
