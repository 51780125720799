import { useTranslation } from 'react-i18next';

export const useNcrCostsTableColumns = () => {
    const { t } = useTranslation();

    let columns = [
        {
            field: 'measure',
            headerName: t('ncrInfo.valuation.table.measure'),
            width: 200,
            valueGetter: params => params.row.measure,
            renderCell: params => {
                if (!params.row?.measure) {
                    return 'N/A';
                }

                return params.row?.measure;
            },
        },
        {
            field: 'costCenter',
            headerName: t('ncrInfo.valuation.table.costCenter'),
            width: 280,
            valueGetter: params => params.row.costCenter,
            renderCell: params => {
                if (!params.row?.costCenter) {
                    return 'N/A';
                }

                return params.row?.costCenter;
            },
        },
        {
            field: 'costValue',
            headerName: t('ncrInfo.valuation.table.realCost'),
            width: 100,
            valueGetter: params => params.row.costValue,
            renderCell: params => {
                if (!params.row?.costValue) {
                    return 'N/A';
                }

                return params.row?.costValue;
            },
        },
    ];

    return [columns];
};
