import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { usePaginatedWorkplaces } from '../../../../../../../common/components/ncr/hooks/usePaginatedWorkplacesOptions';

const StepWorkplaceDropdownComponent = ({ value, disabled, handleUpdate, ...props }) => {
    const { t } = useTranslation();

    const [workplaceOptions, isLoading] = usePaginatedWorkplaces();

    return (
        <Autocomplete
            {...props}
            options={workplaceOptions || []}
            getOptionLabel={option => option.title}
            value={value}
            fullWidth
            loading={isLoading}
            disabled={isLoading}
            renderInput={params => <TextField {...params} label={t('ncrInfo.immediate.table.workplace')} />}
            onChange={(_, data) => handleUpdate(data)}
        />
    );
};

const StepWorkplaceDropdown = memo(StepWorkplaceDropdownComponent);

export default StepWorkplaceDropdown;
