import React, { useContext } from 'react';

import { themeMode } from '@5thindustry/factory_portal.theme.theme-provider/dist/theme-provider';

import { userSettingsInitialState } from './userSettings.reducer';

export const { light, dark } = themeMode;

export const UserSettingsContext = React.createContext(userSettingsInitialState);

export const useUserSettingsContext = () => {
    const context = useContext(UserSettingsContext);

    if (context === undefined) {
        return userSettingsInitialState;
    }

    return context;
};
