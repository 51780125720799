import { useMutation } from '@apollo/client';

import { ADD_NCR_SECTION_FROM_OWN_TEMPLATE } from '../operations/mutations/addNCRSectionFromOwnTemplate';

export const useCreateNCRFromOwnTemplate = () => {
    const [addNCRSectionFromOwnTemplate, { data, loading: loadingCreateNCRFromNCRTemplate, error }] =
        useMutation(ADD_NCR_SECTION_FROM_OWN_TEMPLATE);

    return [addNCRSectionFromOwnTemplate, { data, loadingCreateNCRFromNCRTemplate, error }];
};
