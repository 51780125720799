import React from 'react';
import { useTranslation } from 'react-i18next';

import BuildIcon from '@mui/icons-material/Build';
import { styled } from '@mui/system';

const UnknownErrorRoot = styled('div')(({ theme }) => ({
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 22,
    padding: 24,

    '& .MuiSvgIcon-root': {
        fontSize: 52,
        marginRight: 20,
    },
}));

const UnknownError = () => {
    const { t } = useTranslation();

    return (
        <UnknownErrorRoot>
            <BuildIcon />
            {t('common.unknown-error')}
        </UnknownErrorRoot>
    );
};

export default UnknownError;
