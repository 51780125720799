import { gql } from '@apollo/client';

export const UPDATE_WORKFLOW_STEP_IN_APPROVAL_MODE = gql`
    mutation UpdateWorkflowStep($currentWorkflowStepId: WorkflowStepInput!) {
        updateCurrentStep: updateWorkflowStep(workflowStep: $currentWorkflowStepId) {
            success
            workflowStep {
                _id
                status
            }
        }
    }
`;
