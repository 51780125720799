import { Tooltip, Typography } from '@mui/material';

import { useUserSettingsContext } from '../../../../common/providers/userSettings/store/userSettings.context';
import { getValueByLanguage } from '../../../../utils/common';

const DescriptionColumn = ({ value }) => {
    const { language } = useUserSettingsContext();

    if (!value) {
        return null;
    }

    const description = getValueByLanguage(value, language);

    return (
        <Tooltip title={description} placement="top">
            <Typography variant="body2" noWrap>
                {description}
            </Typography>
        </Tooltip>
    );
};

export default DescriptionColumn;
