import { useMutation } from '@apollo/client';

import { UPDATE_CAPA_TICKET } from '../../../../../../common/gqlQueries/capaTickets';

export const useUpdateCapaTicket = () => {
    const [updateCapaTicket, { loading }] = useMutation(UPDATE_CAPA_TICKET);

    const handleUpdateCapaTicket = (capaTicket, refetchQueriesList = []) => {
        return updateCapaTicket({
            variables: {
                capaTicket,
            },
            refetchQueries: refetchQueriesList,
        });
    };

    return [handleUpdateCapaTicket, { loading }];
};
