import { ncrUserRolesArray } from '../common/constants/userRoles';
import { isArrayWithItems } from './common';
import i18n from './i18n';

export const userRole = {
    admin: 'admin',
    auditResponsible: 'auditResponsible',
    safetyAdmin: 'safetyAdmin',
    safetyAuditResponsible: 'safetyAuditResponsible',
    protocolAdmin: 'protocolAdmin',
    protocolSupervisor: 'protocolSupervisor',
    protocolOperator: 'protocolOperator',
    protocolPlanner: 'protocolPlanner',
    maintenanceOperator: 'maintenanceOperator',
    teamAdmin: 'teamAdmin',
    purchaser: 'purchaser',
    productionPlanner: 'productionPlanner',
    orderManager: 'orderManager',
    processOperator: 'processOperator',
    NCRReporter: 'NCRReporter',
    NCRTicketResponsible: 'NCRTicketResponsible',
    NCRTaskResponsible: 'NCRTaskResponsible',
    NCRQualityManager: 'NCRQualityManager',
};

export const appUserRoles = [userRole.admin];

export const getUserRolesString = userRolesList => {
    if (!isArrayWithItems(userRolesList)) {
        return '';
    }

    const userRoles = userRolesList.filter(userRole => ncrUserRolesArray.some(appRole => userRole === appRole));

    return userRoles.map(x => i18n.t(`roles.${x}`)).join(', ');
};

export const getTeamById = (teamId, organizations) => {
    if (!isArrayWithItems(organizations)) {
        return [];
    }

    const teams = organizations
        .map(org =>
            org.teams.map(team => {
                return {
                    ...team,
                    organizationId: org._id,
                };
            })
        )
        .flat();

    return teams.find(team => team._id === teamId);
};

export const userRoleBadges = {
    [userRole.admin]: {
        key: userRole.admin,
        label: i18n.t('roles.badges.admin'),
    },
    [userRole.safetyAdmin]: {
        key: userRole.safetyAdmin,
        label: i18n.t('roles.badges.safetyAdmin'),
    },
    [userRole.protocolAdmin]: {
        key: userRole.protocolAdmin,
        label: i18n.t('roles.badges.protocolAdmin'),
    },
    [userRole.auditResponsible]: {
        key: userRole.auditResponsible,
        label: i18n.t('roles.badges.auditResponsible'),
    },
    [userRole.safetyAuditResponsible]: {
        key: userRole.safetyAuditResponsible,
        label: i18n.t('roles.badges.safetyAuditResponsible'),
    },
    [userRole.protocolSupervisor]: {
        key: userRole.protocolSupervisor,
        label: i18n.t('roles.badges.protocolSupervisor'),
    },
    [userRole.protocolOperator]: {
        key: userRole.protocolOperator,
        label: i18n.t('roles.badges.protocolOperator'),
    },
    [userRole.protocolPlanner]: {
        key: userRole.protocolPlanner,
        label: i18n.t('roles.badges.protocolPlanner'),
    },
    [userRole.maintenanceOperator]: {
        key: userRole.maintenanceOperator,
        label: i18n.t('roles.badges.maintenanceOperator'),
    },
    [userRole.teamAdmin]: {
        key: userRole.teamAdmin,
        label: i18n.t('roles.badges.teamAdmin'),
    },
    [userRole.purchaser]: {
        key: userRole.purchaser,
        label: i18n.t('roles.badges.purchaser'),
    },
    [userRole.productionPlanner]: {
        key: userRole.productionPlanner,
        label: i18n.t('roles.badges.productionPlanner'),
    },
    [userRole.orderManager]: {
        key: userRole.orderManager,
        label: i18n.t('roles.badges.orderManager'),
    },
    [userRole.processOperator]: {
        key: userRole.processOperator,
        label: i18n.t('roles.badges.operator'),
    },
    [userRole.NCRReporter]: {
        key: userRole.NCRReporter,
        label: i18n.t('roles.badges.NCRReporter'),
    },
    [userRole.NCRTicketResponsible]: {
        key: userRole.NCRTicketResponsible,
        label: i18n.t('roles.badges.NCRTicketResponsible'),
    },
    [userRole.NCRTaskResponsible]: {
        key: userRole.NCRTaskResponsible,
        label: i18n.t('roles.badges.NCRTaskResponsible'),
    },
    [userRole.NCRQualityManager]: {
        key: userRole.NCRQualityManager,
        label: i18n.t('roles.badges.NCRQualityManager'),
    },
};
