import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../AutoSaveTextField';

const CostCenterInputComponent = ({ costAssignment, disabled, path, operation, handleUpdate }) => {
    const { t } = useTranslation();

    const onBlurChange = event => {
        const newValue = event?.target?.value;

        if ((costAssignment?.costCenter || '') !== newValue) {
            handleUpdate(
                operation === 'update' ? `${path}.costAssignments.0` : `${path}.costAssignments`,
                {
                    costAssignment: {
                        value: costAssignment?.value,
                        currency: 'EUR',
                        costCenter: newValue,
                    },
                },
                operation
            );
        }
    };

    return (
        <AutoSaveTextField
            disabled={disabled}
            value={costAssignment?.costCenter}
            label={t('ncrInfo.immediate.costCentre')}
            onBlur={onBlurChange}
            onKeyPress={event => {
                event.key === 'Enter' && onBlurChange(event);
            }}
            fullWidth
        />
    );
};

const CostCenterInput = memo(CostCenterInputComponent);

export default CostCenterInput;
