import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const MeasureDueOnDatePicker = ({ value, handleUpdate }) => {
    const { t } = useTranslation();

    return (
        <DatePicker
            label={t('ncrInfo.corrective.dueOn')}
            value={value}
            onChange={handleUpdate}
            renderInput={params => <TextField {...params} fullWidth />}
        />
    );
};

export default MeasureDueOnDatePicker;
