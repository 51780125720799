import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSubscription } from '@apollo/client';
import { Box, LinearProgress, Tab, Tabs } from '@mui/material';

import useGetSingleNCRFromNetwork from '../../../../../../common/hooks/useGetSingleNCRFromNetwork';
import { notificationsSeverityByRequestStatus } from '../../../../../../common/providers/notification/constants';
import { setNotification } from '../../../../../../common/providers/notification/store/notification.actions';
import { ACTIVITY_STREAM_ADDED_SUBSCRIPTION } from '../../../../../../common/subscriptions/subscriptions';

import { ncrActivitiesTabsIndex } from '../../../../constants';

import { useUserImages } from '../../../../../../common/hooks/useUserImages';

import CreateMessageActivity from './CreateMessageActivity';
import ActivityActivitiesTab from './tabs/ActivityActivitiesTab';
import ActivityAllTab from './tabs/ActivityAllTab';
import ActivityChatTab from './tabs/ActivityChatTab';

import { ncrStatus } from '../../../../../../common/enums/ncrStatus';

const tabContent = {
    [ncrActivitiesTabsIndex.ALL_TAB]: {
        render: props => <ActivityAllTab {...props} />,
    },
    [ncrActivitiesTabsIndex.CHAT_TAB]: {
        render: props => <ActivityChatTab {...props} />,
    },
    [ncrActivitiesTabsIndex.ACTIVITIES_TAB]: {
        render: props => <ActivityActivitiesTab {...props} />,
    },
};

const NcrActivitiesTabs = ({ ncrId }) => {
    const { t } = useTranslation();

    const [ncr, loading] = useGetSingleNCRFromNetwork(ncrId);

    const [images] = useUserImages();

    const [activeTabIndex, setActiveTabIndex] = useState(ncrActivitiesTabsIndex.ALL_TAB);

    const handleTabIndexChange = (_event, newTabIndex) => {
        setActiveTabIndex(newTabIndex);
    };

    const [activities, setActivities] = useState([]);

    const { data: activityStreamData, error } = useSubscription(ACTIVITY_STREAM_ADDED_SUBSCRIPTION, {
        fetchPolicy: 'no-cache',
        variables: {
            ticketId: ncrId,
        },
    });

    useEffect(() => {
        if (error) {
            setNotification({ severity: notificationsSeverityByRequestStatus[false] });
        }
    }, [error]);

    useEffect(() => {
        if (!!ncr?.resolvedActivities?.length && !activities.length) {
            setActivities(ncr?.resolvedActivities?.slice().reverse());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ncr]);

    useEffect(() => {
        if (activityStreamData?.activityStreamAdded) {
            setActivities(prev => [activityStreamData.activityStreamAdded, ...prev]);
        }
    }, [activityStreamData]);

    return (
        <Box marginBottom="2em">
            <Box marginBottom="2em">
                <Tabs
                    value={activeTabIndex}
                    onChange={handleTabIndexChange}
                    variant="fullWidth"
                    style={{ boxShadow: '0px 16px 14px -16px rgba(0, 0, 0, 0.16)' }}>
                    <Tab label={t('activities.tabs.all')} tabIndex={ncrActivitiesTabsIndex.ALL_TAB} />
                    <Tab label={t('activities.tabs.chat')} tabIndex={ncrActivitiesTabsIndex.CHAT_TAB} />
                    <Tab label={t('activities.tabs.activities')} tabIndex={ncrActivitiesTabsIndex.ACTIVITIES_TAB} />
                </Tabs>
            </Box>
            <LinearProgress color="primary" sx={{ visibility: loading ? 'visible' : 'hidden' }} />
            {!loading && tabContent[activeTabIndex]?.render({ allActivities: activities, images: images })}
            {!loading && activeTabIndex !== ncrActivitiesTabsIndex.ACTIVITIES_TAB && (
                <CreateMessageActivity ncrId={ncrId} disabled={ncr?.status === ncrStatus.DONE} />
            )}
        </Box>
    );
};

export default NcrActivitiesTabs;
