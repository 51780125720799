export const ncrSectionType = {
    REWORK: 'rework',
    SCRAP: 'scrap',
    CLEARANCE: 'clearance',
};

export const ncrSectionKeyByType = {
    [ncrSectionType.REWORK]: 'reworks',
    [ncrSectionType.SCRAP]: 'scraps',
    [ncrSectionType.CLEARANCE]: 'clearances',
};
