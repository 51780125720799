import React, { memo } from 'react';

import { Hub } from 'aws-amplify';
import { Authenticator, Greetings, SignUp } from 'aws-amplify-react';
import { detect } from 'detect-browser';

import BrowserWarning from '../authentication/BrowserWarning';
import ForgotPassword from '../authentication/ForgotPassword';
import AuthLayout from '../authentication/layout/AuthLayout';
import SignIn from '../authentication/SignIn';

const withAuthenticator = ComposedComponent => {
    class hoc extends React.Component {
        state = {
            authState: '',
        };

        componentDidMount() {
            Hub.listen('auth', authData => {
                if (authData.payload.event === 'signOut') {
                    this.handleStateChange('signIn', null);
                }
            });
        }

        handleStateChange = authState => {
            this.setState({ authState });
        };

        render() {
            const browser = detect();

            if (browser && browser.name === 'ie') {
                return <BrowserWarning />;
            }

            return (
                <Authenticator hideDefault={true} hide={[Greetings, SignUp]} onStateChange={this.handleStateChange}>
                    {this.state?.authState && (
                        <React.Fragment>
                            {this.state?.authState !== 'signedIn' && (
                                <AuthLayout>
                                    {this.state.authState === 'signIn' && <SignIn {...this.props} onStateChange={this.handleStateChange} />}
                                    {this.state.authState === 'forgotPassword' && (
                                        <ForgotPassword {...this.props} onStateChange={this.handleStateChange} />
                                    )}
                                </AuthLayout>
                            )}
                            {this.state.authState === 'signedIn' && <ComposedComponent {...this.props} />}
                        </React.Fragment>
                    )}
                </Authenticator>
            );
        }
    }

    return memo(hoc);
};

export default withAuthenticator;
