import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';

import AutoSaveTextField from '../../../../common/components/AutoSaveTextField';
import BarcodeReaderModal from '../../../../common/components/BarcodeReaderModal';

import { useNcrTemplateContext } from '../../../../common/contexts/ncrTemplate/store/context';
import { useModal } from '../../../../common/hooks/useModal';

const ExternalReferenceLocationComponent = ({ value, disabled }) => {
    const { t } = useTranslation();

    const [isOpened, toggleModal] = useModal(false);

    const { setNCR } = useNcrTemplateContext();

    const handleChange = event => {
        setNCR({ value: event?.target?.value, path: 'basicInformation.externalReferenceLocation' });
    };

    const handleResult = result => {
        if (!!result && isOpened) {
            setNCR({ value: result, path: 'basicInformation.externalReferenceLocation' });
            toggleModal();
        }
    };

    return (
        <>
            <AutoSaveTextField
                value={value}
                disabled={disabled}
                InputProps={{
                    endAdornment: <QrCodeRoundedIcon onClick={toggleModal} />,
                }}
                label={t('report.form.locationReference')}
                onBlur={handleChange}
                fullWidth
            />
            <BarcodeReaderModal isOpen={isOpened} handleModalClose={toggleModal} handleResult={handleResult} />
        </>
    );
};

const ExternalReferenceLocation = memo(ExternalReferenceLocationComponent);

export default ExternalReferenceLocation;
