import React, { useEffect, useState } from 'react';

import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const NcrCheckbox = ({ label, handleChange, disabled, value = false }) => {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(value === 'on');
    }, [value]);

    const handleUpdate = event => {
        setIsChecked(event.target.checked);
        handleChange(event);
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={<Checkbox checked={isChecked} onChange={handleUpdate} />}
                label={label}
                disabled={disabled}
            />
        </FormGroup>
    );
};

export default NcrCheckbox;
