import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogTitle, Grid } from '@mui/material';
import { Box } from '@mui/system';

import { notificationsSeverityByRequestStatus } from '../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../common/providers/notification/store/notification.context';

import { updateTicketStatusInCache } from '../../constants';

import MeasureDescriptionInput from '../../../ncr-info/components/tabs/ncr-corrective/components/root-cause/measures/create-measure-modal/inputs/MeasureDescriptionInput';
import MeasureDueOnDatePicker from '../../../ncr-info/components/tabs/ncr-corrective/components/root-cause/measures/create-measure-modal/inputs/MeasureDueOnDatePicker';
import MeasureIncidentTypeDropdown from '../../../ncr-info/components/tabs/ncr-corrective/components/root-cause/measures/create-measure-modal/inputs/MeasureIncidentTypeDropdown';
import MeasureResponsibleDropdown from '../../../ncr-info/components/tabs/ncr-corrective/components/root-cause/measures/create-measure-modal/inputs/MeasureResponsibleDropdown';
import MeasureStatusDropdown from '../../../ncr-info/components/tabs/ncr-corrective/components/root-cause/measures/create-measure-modal/inputs/MeasureStatusDropdown';
import MeasureTitleInput from '../../../ncr-info/components/tabs/ncr-corrective/components/root-cause/measures/create-measure-modal/inputs/MeasureTitleInput';

import { useUpdateCapaTicket } from '../../../ncr-info/components/tabs/ncr-corrective/hooks/useUpdateCapaTicket';

import { useTicketKanbanBoardContext } from '../../store/TicketsKanbanBoardContext';

const refetchQueriesList = ['GetPaginatedCapaTickets'];

const EditCapaTicketModal = ({ updateCache }) => {
    const { t } = useTranslation();

    const [handleUpdateCapaTicket] = useUpdateCapaTicket();

    const { setNotification } = useNotificationContext();

    const { searchFilter, dateRangeFilter, incidentTypeFilter, responsibleFilter, activeCapaTicket, setActiveCapaTicket } =
        useTicketKanbanBoardContext();

    const handleModalClose = () => {
        setActiveCapaTicket(null);
    };

    const handleUpdate = useCallback(
        capaTicket => {
            handleUpdateCapaTicket(capaTicket, refetchQueriesList)
                .then(result => {
                    if (result?.data?.updateCapaTicket?.success) {
                        setActiveCapaTicket(result?.data?.updateCapaTicket?.capaTicket);

                        setNotification({ severity: notificationsSeverityByRequestStatus[result?.data?.updateCapaTicket?.success] });
                    }
                })
                .catch(() => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                });
        },
        [handleUpdateCapaTicket, setActiveCapaTicket, setNotification]
    );

    const handleTitle = useCallback(
        value => {
            handleUpdate({
                _id: activeCapaTicket?._id,
                action: value,
            });
        },
        [handleUpdate, activeCapaTicket?._id]
    );

    const handleDescription = useCallback(
        value => {
            handleUpdate({
                _id: activeCapaTicket?._id,
                description: value,
            });
        },
        [handleUpdate, activeCapaTicket?._id]
    );

    const handleResponsible = useCallback(
        value => {
            handleUpdate({
                _id: activeCapaTicket?._id,
                responsible: [
                    {
                        responsibleID: value,
                    },
                ],
            });
        },
        [handleUpdate, activeCapaTicket?._id]
    );

    const handleDueOn = useCallback(
        value => {
            handleUpdate({
                _id: activeCapaTicket?._id,
                dueDate: value,
            });
        },
        [handleUpdate, activeCapaTicket?._id]
    );

    const handleIncidentType = useCallback(
        value => {
            handleUpdate({
                _id: activeCapaTicket?._id,
                incidentType: {
                    name: value,
                },
            });
        },
        [handleUpdate, activeCapaTicket?._id]
    );

    const handleStatus = useCallback(
        value => {
            const capaTicket = {
                _id: activeCapaTicket?._id,
                status: value,
            };

            handleUpdate(capaTicket);

            if (updateCache) {
                updateTicketStatusInCache(capaTicket, activeCapaTicket?.status, {
                    searchFilter,
                    dateRangeFilter,
                    incidentTypeFilter,
                    responsibleFilter,
                });
            }
        },
        [handleUpdate, activeCapaTicket, searchFilter, dateRangeFilter, incidentTypeFilter, responsibleFilter, updateCache]
    );

    if (!activeCapaTicket?._id) {
        return null;
    }

    return (
        <Dialog onClose={handleModalClose} open={!!activeCapaTicket?._id}>
            <DialogTitle sx={{ textAlign: 'center', fontSize: '24px' }}>{t('common.context-menu-options.edit')}</DialogTitle>
            <Box sx={{ minWidth: '600px', padding: '16px 28px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <MeasureTitleInput handleUpdate={handleTitle} value={activeCapaTicket?.action} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <MeasureDescriptionInput handleUpdate={handleDescription} value={activeCapaTicket?.description} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <MeasureResponsibleDropdown handleUpdate={handleResponsible} value={activeCapaTicket?.resolvedResponsible} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MeasureDueOnDatePicker handleUpdate={handleDueOn} value={activeCapaTicket?.dueDate} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MeasureIncidentTypeDropdown handleUpdate={handleIncidentType} value={activeCapaTicket?.incidentType?.name} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MeasureStatusDropdown handleUpdate={handleStatus} value={activeCapaTicket?.status} />
                    </Grid>
                </Grid>
                {/* <Box sx={{ marginTop: '2em' }}>
                    <MeasureAttachmentsSection ticketId={capaTicketId} updateMeasureAttachments={setMeasureImages} />
                </Box> */}
            </Box>
        </Dialog>
    );
};

export default EditCapaTicketModal;
