import { themeMode } from '@5thindustry/factory_portal.theme.theme-provider/dist/theme-provider';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDroppableBox = styled(Box)(({ theme }) => ({
    border: ' 1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '16px',
    height: 'calc(100vh - 300px)',
    width: '31%',
    padding: '16px',
    background: theme.palette.mode === themeMode.light ? '#EDF2F7' : '#4e4e4e',
}));
