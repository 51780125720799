import { ticketsKanbanBoardActions } from './TicketsKanbanBoardActions';

export const ticketsKanbanBoardInitialState = {
    searchFilter: '',
    dateRangeFilter: {
        from: null,
        to: null,
    },
    responsibleFilter: null,
    incidentTypeFilter: null,
    activeCapaTicket: null,
};

export const ticketsKanbanBoardReducer = (state, action) => {
    switch (action.type) {
        case ticketsKanbanBoardActions.SET_ACTIVE_CAPA_TICKET: {
            return {
                ...state,
                activeCapaTicket: action.payload,
            };
        }
        case ticketsKanbanBoardActions.SET_TICKETS_KANBAN_BOARD_SEARCH: {
            return {
                ...state,
                searchFilter: action.payload,
            };
        }
        case ticketsKanbanBoardActions.SET_TICKETS_KANBAN_BOARD_DATE_RANGE: {
            return {
                ...state,
                dateRangeFilter: action.payload,
            };
        }
        case ticketsKanbanBoardActions.SET_TICKETS_KANBAN_BOARD_RESPONSIBLE: {
            return {
                ...state,
                responsibleFilter: action.payload,
            };
        }
        case ticketsKanbanBoardActions.SET_TICKETS_KANBAN_BOARD_INCIDENT_TYPE: {
            return {
                ...state,
                incidentTypeFilter: action.payload,
            };
        }

        case ticketsKanbanBoardActions.RESET_TICKETS_KANBAN_BOARD_FILTERS: {
            return ticketsKanbanBoardInitialState;
        }

        default:
            return ticketsKanbanBoardInitialState;
    }
};
