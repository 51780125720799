import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../../../../../../../../common/components/AutoSaveTextField';

const PiecesToRemakeComponent = ({ value, scrapId, disabled, handleUpdate }) => {
    const { t } = useTranslation();

    const handleBlur = event => {
        const newValue = +event.target.value;

        if (value !== newValue) {
            handleUpdate(
                `scraps.$[scrapId]`,
                {
                    scrap: { piecesToRemake: newValue },
                },
                'update',
                { scrapId }
            );
        }
    };

    return (
        <AutoSaveTextField
            onlyPositiveDigits
            type="number"
            value={value}
            disabled={disabled}
            label={t('ncrInfo.immediate.piecesToRemake')}
            onBlur={handleBlur}
            onKeyPress={event => {
                event.key === 'Enter' && handleBlur(event);
            }}
            fullWidth
        />
    );
};

export const PiecesToRemakeInput = memo(PiecesToRemakeComponent);

export default PiecesToRemakeInput;
