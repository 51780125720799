import { Avatar, Stack } from '@mui/material';

import { stringAvatar } from '../../utils/common';

const UserNameWithAvatar = ({ avatarSrc, userName }) => {
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            {avatarSrc ? <Avatar alt={userName} src={avatarSrc} /> : <Avatar alt={userName} src={avatarSrc} {...stringAvatar(userName)} />}
            <div>{userName}</div>
        </Stack>
    );
};

export default UserNameWithAvatar;
