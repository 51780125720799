import { useTranslation } from 'react-i18next';

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { InputAdornment } from '@mui/material';

import AutoSaveTextField from './AutoSaveTextField';

const SearchInput = ({ value, onChange, onClear, ...props }) => {
    const { t } = useTranslation();

    return (
        <AutoSaveTextField
            {...props}
            value={value}
            onChange={onChange}
            label={t('common.search.placeholder')}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                        {!!value && <ClearRoundedIcon onClick={onClear} />}
                        <SearchRoundedIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchInput;
