import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Stack, Typography } from '@mui/material';

import { getValueByLanguage } from '../../../../utils/common';
import { ncrSectionType } from '../../../enums/ncrSectionType';
import { workflowStepActionTrigger } from '../../../enums/workflowStepActionTrigger';

import { useUserSettingsContext } from '../../../providers/userSettings/store/userSettings.context';

import { useModal } from './../../../hooks/useModal';

import ApprovalModal from './modals/ApprovalModal';
import DiscardModal from './modals/DiscardModal';
import RejectModal from './modals/RejectModal';
import TransitionModal from './modals/TransitionModal';

const { TRANSITION, DISCARD, APPROVAL, REJECTION } = workflowStepActionTrigger;

const modals = {
    [TRANSITION]: ({ ncrId, sectionId, isOpened, toggleModal, workflowStepId, nextWorkflowStepId, workflowId }) => (
        <TransitionModal
            ncrId={ncrId}
            sectionId={sectionId}
            isOpened={isOpened}
            toggleModal={toggleModal}
            workflowStepId={workflowStepId}
            nextWorkflowStepId={nextWorkflowStepId}
            workflowId={workflowId}
        />
    ),
    [APPROVAL]: ({
        ncrId,
        sectionId,
        isOpened,
        toggleModal,
        workflowStepId,
        nextWorkflowStepId,
        workflowId,
        handleNcrUpdate,
        path,
        childrenIds,
        type,
    }) => (
        <ApprovalModal
            ncrId={ncrId}
            sectionId={sectionId}
            isOpened={isOpened}
            toggleModal={toggleModal}
            workflowStepId={workflowStepId}
            nextWorkflowStepId={nextWorkflowStepId}
            workflowId={workflowId}
            handleNcrUpdate={handleNcrUpdate}
            path={path}
            childrenIds={childrenIds}
            type={type}
        />
    ),
    [REJECTION]: ({
        ncrId,
        sectionId,
        isOpened,
        toggleModal,
        workflowStepId,
        nextWorkflowStepId,
        workflowId,
        handleNcrUpdate,
        path,
        childrenIds,
        type,
    }) => (
        <RejectModal
            ncrId={ncrId}
            sectionId={sectionId}
            isOpened={isOpened}
            toggleModal={toggleModal}
            workflowStepId={workflowStepId}
            nextWorkflowStepId={nextWorkflowStepId}
            workflowId={workflowId}
            handleNcrUpdate={handleNcrUpdate}
            path={path}
            childrenIds={childrenIds}
            type={type}
        />
    ),
    [DISCARD]: ({ ncrId, sectionId, isOpened, toggleModal }) => (
        <DiscardModal ncrId={ncrId} sectionId={sectionId} isOpened={isOpened} toggleModal={toggleModal} />
    ),
};

const WorkflowStepAvailableActions = ({
    ncrId,
    sectionId,
    resolvedWorkflowStep,
    nextWorkflowStepId,
    workflowId,
    handleNcrUpdate,
    handleErpUpdate,
    path,
    childrenIds,
    type,
    hasConnector,
    disabled,
}) => {
    const { t } = useTranslation();

    const [activeTrigger, setActiveTrigger] = useState('');
    const [isOpened, toggleModal] = useModal(false);

    const { language } = useUserSettingsContext();

    const availableActions = resolvedWorkflowStep?.availableActions || [];

    const handleActionClick = trigger => {
        setActiveTrigger(trigger);
        toggleModal();
    };

    const hasErpUpdate = type === ncrSectionType.REWORK && hasConnector;

    return (
        <Stack direction="row" justifyContent={hasErpUpdate ? 'space-between' : 'flex-end'}>
            {hasErpUpdate && (
                <Button sx={{ cursor: 'pointer', maxWidth: '240px' }} onClick={handleErpUpdate} variant="contained" color="primary">
                    <Typography variant="button" sx={{ cursor: 'pointer' }}>
                        {t('ncrInfo.erpUpdate')}
                    </Typography>
                </Button>
            )}
            <Stack spacing={4} direction="row-reverse" justifyContent="end">
                {availableActions.map((action, index) => {
                    const actionName = getValueByLanguage(action.label, language);

                    return (
                        <Button
                            key={action._id}
                            disabled={disabled}
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleActionClick(action.trigger)}
                            variant="contained"
                            color={index === 0 ? 'success' : 'error'}>
                            <Typography variant="button" sx={{ cursor: 'pointer' }}>
                                {actionName}
                            </Typography>
                        </Button>
                    );
                })}
                {(activeTrigger &&
                    modals[activeTrigger] &&
                    modals[activeTrigger]({
                        ncrId,
                        sectionId,
                        isOpened,
                        toggleModal,
                        workflowStepId: resolvedWorkflowStep?._id,
                        nextWorkflowStepId,
                        workflowId,
                        handleNcrUpdate,
                        path,
                        childrenIds,
                        type,
                    })) ||
                    null}
            </Stack>
        </Stack>
    );
};

export default WorkflowStepAvailableActions;
