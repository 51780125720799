import { createContext, useContext } from 'react';
import { ticketsKanbanBoardInitialState } from './TicketsKanbanBoardReducer';

export const TicketKanbanBoardContext = createContext(ticketsKanbanBoardInitialState);

export const useTicketKanbanBoardContext = () => {
    const context = useContext(TicketKanbanBoardContext);

    if (context === undefined) {
        return ticketsKanbanBoardInitialState;
    }

    return context;
};
