export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
};

export const truncateString = (str, size) => {
    if (str && str.length > size) {
        return `${str.slice(0, size)} ...`;
    }
    return str;
};
