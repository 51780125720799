import { gql } from '@apollo/client';

export const GET_PAGINATED_WORKPLACES = gql`
    query getPaginateWorkplaces($filter: String, $limit: Int, $skip: Int, $sort: String) {
        getPaginateWorkplaces(skip: $skip, limit: $limit, sort: $sort, filter: $filter) {
            code
            workplaces {
                _id
                capacity {
                    defaultCapacity
                }
                workplaceType
                workPlaceName {
                    language
                    value
                    formattedValue
                }
                processStepReferences {
                    foreignID
                }
            }
        }
    }
`;
