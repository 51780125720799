import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogTitle, Grid } from '@mui/material';
import { Box } from '@mui/system';

import MeasureDescriptionInput from './inputs/MeasureDescriptionInput';
import MeasureDueOnDatePicker from './inputs/MeasureDueOnDatePicker';
import MeasureIncidentTypeDropdown from './inputs/MeasureIncidentTypeDropdown';
import MeasureResponsibleDropdown from './inputs/MeasureResponsibleDropdown';
import MeasureStatusDropdown from './inputs/MeasureStatusDropdown';
import MeasureTitleInput from './inputs/MeasureTitleInput';
import MeasureAttachmentsSection from './MeasureAttachmentsSection';

import { stepStatus } from '../../../../../../../../../common/enums/stepStatus';
import { notificationsSeverityByRequestStatus } from '../../../../../../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../../../../../../common/providers/notification/store/notification.context';

import createMongoObjectId from '../../../../../../../../../utils/mongo';

import { useCreateCapaTicket } from '../../../../hooks/useCreateCapaTicket';

const CreateMeasureModal = ({ isOpen, handleModalClose, ncrId, capaDetailId }) => {
    const { t } = useTranslation();

    const { setNotification } = useNotificationContext();

    const [handleCreateCapaTicket] = useCreateCapaTicket();

    const [measureTitle, setMeasureTitle] = useState(null);
    const [measureDescription, setMeasureDescription] = useState(null);
    const [measureResponsible, setMeasureResponsible] = useState(null);
    const [measureDueOn, setMeasureDueOn] = useState(null);
    const [measureIncidentType, setMeasureIncidentType] = useState(null);
    const [measureImages, setMeasureImages] = useState([]);
    const [measureStatus, setMeasureStatus] = useState(stepStatus.OPEN);

    const capaTicketId = createMongoObjectId();

    const resetState = useCallback(() => {
        setMeasureTitle(null);
        setMeasureDescription(null);
        setMeasureResponsible(null);
        setMeasureDueOn(null);
        setMeasureIncidentType(null);
        setMeasureStatus(stepStatus.OPEN);
    }, [setMeasureTitle, setMeasureDescription, setMeasureResponsible, setMeasureDueOn, setMeasureIncidentType, setMeasureStatus]);

    const handleCreateMeasure = () => {
        if (measureTitle && capaDetailId && ncrId) {
            handleCreateCapaTicket({
                _id: capaTicketId,
                action: measureTitle,
                description: measureDescription,
                status: measureStatus,
                dueDate: measureDueOn,
                images: measureImages || [],
                responsible: [
                    {
                        responsibleID: measureResponsible,
                    },
                ],
                incidentType: {
                    name: measureIncidentType,
                },

                ticketType: 'capa',
                externalReference: [
                    {
                        NCRCapaDetailId: capaDetailId,
                        NCRId: ncrId,
                    },
                ],
            })
                .then(response => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[true] });

                    handleModalClose();
                })
                .catch(_ => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                });
        }
    };

    useEffect(() => {
        if (!isOpen) {
            resetState();
        }
    }, [isOpen, resetState]);

    return (
        <Dialog onClose={handleModalClose} open={isOpen} sx={{ marginTop: '40px', '& .MuiPaper-root': { overflow: 'hidden' } }}>
            <DialogTitle sx={{ textAlign: 'center', fontSize: '24px' }}>{t('ncrInfo.corrective.captureCorrectiveAction')}</DialogTitle>
            <Box sx={{ minWidth: '600px', padding: '16px 28px', marginBottom: '30px', overflowY: 'auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <MeasureTitleInput handleUpdate={setMeasureTitle} value={measureTitle} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <MeasureDescriptionInput handleUpdate={setMeasureDescription} value={measureDescription} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <MeasureResponsibleDropdown handleUpdate={setMeasureResponsible} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MeasureDueOnDatePicker handleUpdate={setMeasureDueOn} value={measureDueOn} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MeasureIncidentTypeDropdown handleUpdate={setMeasureIncidentType} value={measureIncidentType} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MeasureStatusDropdown handleUpdate={setMeasureStatus} value={measureStatus} />
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: '2em' }}>
                    <MeasureAttachmentsSection ticketId={capaTicketId} updateMeasureAttachments={setMeasureImages} />
                </Box>
            </Box>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <LoadingButton
                    disabled={!measureTitle}
                    variant="contained"
                    color="primary"
                    onClick={handleCreateMeasure}
                    startIcon={<ReportProblemRoundedIcon />}
                    loadingPosition="start">
                    {t('ncrInfo.corrective.createMeasure')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default CreateMeasureModal;
