import React from 'react';
import { useTranslation } from 'react-i18next';

import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { Button, Typography } from '@mui/material';

import { useUsersTableContext } from '../../store/usersTableProvider';

const UsersTableFiltersButton = () => {
    const { t } = useTranslation();
    const { toggleUsersTableFilters } = useUsersTableContext();

    return (
        <Button variant="contained" color="primary" startIcon={<FilterListRoundedIcon />} onClick={toggleUsersTableFilters}>
            <Typography variant="button" style={{ cursor: 'pointer' }}>
                {t('common.action-buttons.filters')}
            </Typography>
        </Button>
    );
};

export default UsersTableFiltersButton;
