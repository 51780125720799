import { memo, useState } from 'react';

import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import { Box, Button, Divider, LinearProgress } from '@mui/material';

import { useJwtToken } from '../../../utils/awsAuth';

import FileUploader from './FileUploader';

const UploadNewAttachment = ({
    label,
    fetchImages,
    handleSetTileData,
    allowedTypes,
    targetFolder,
    webcamConfig,
    maxNumberOfFiles,
    allowUniqueName,
    allowUniqueNameWithName,
    facingMode,
    useWebcam,
    compressBeforeUpload,
    language,
    themeMode,
    setLoading,
    loading,
    openAlert,
    addAttachments,
    disabled,
}) => {
    const [isOpened, setIsOpened] = useState(false);

    const token = useJwtToken();

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                    disabled={disabled}
                    variant="text"
                    color="primary"
                    startIcon={<CameraAltRoundedIcon />}
                    onClick={() => setIsOpened(true)}>
                    {label}
                </Button>
            </Box>
            <Divider />
            <LinearProgress color="primary" sx={{ visibility: loading ? 'visible' : 'hidden' }} />

            <FileUploader
                useWebcam
                allowedTypes={allowedTypes}
                isOpened={isOpened}
                toggleModal={setIsOpened}
                themeMode={themeMode}
                language={language}
                token={token}
                webcamConfig={webcamConfig}
                addAttachments={addAttachments}
                targetFolder={targetFolder}
            />
        </>
    );
};

export default memo(UploadNewAttachment);
