import React, { useCallback } from 'react';

import SearchInput from '../../../../common/components/SearchInput';

import { useTicketKanbanBoardContext } from '../../store/TicketsKanbanBoardContext';

const TicketKanbanBoardSearchInput = () => {
    const { searchFilter, setTicketsKanbanBoardSearch } = useTicketKanbanBoardContext();

    const memoizedChangeHandler = useCallback(
        event => {
            setTicketsKanbanBoardSearch(event.target.value);
        },
        [setTicketsKanbanBoardSearch]
    );

    return (
        <SearchInput
            fullWidth={false}
            value={searchFilter}
            onKeyPress={event => {
                event.key === 'Enter' && memoizedChangeHandler(event);
            }}
            onBlur={memoizedChangeHandler}
            onClear={() => setTicketsKanbanBoardSearch('')}
        />
    );
};

export default TicketKanbanBoardSearchInput;
