import { useQuery } from '@apollo/client';

import { GET_CAPA_TICKET } from '../../../../../../common/gqlQueries/capaTickets';

export const useGetCapaTicket = (filter = null) => {
    const { data, loading, error } = useQuery(GET_CAPA_TICKET, {
        fetchPolicy: 'cache-and-network',
        variables: {
            filter,
        },
    });

    return { data, loading, error };
};
