import { gql } from '@apollo/client';

export const UPDATE_WORKFLOW_STEP = gql`
    mutation UpdateWorkflowStep($workflowStep: WorkflowStepInput!) {
        updateWorkflowStep(workflowStep: $workflowStep) {
            code
            workflowStep {
                _id
                responsible {
                    _id
                    responsibleID
                }
                resolvedResponsible {
                    _id
                    firstName
                    name
                    profilePic {
                        thumbnail
                    }
                }
            }
        }
    }
`;
