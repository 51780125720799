import { gql } from '@apollo/client';

export const ERP_UPDATE = gql`
    mutation PullDataFromERP($pullData: PullDataToNCRFromERPInput!) {
        pullDataFromERP(pullData: $pullData) {
            code
            success
        }
    }
`;
