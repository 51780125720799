import React from 'react';
import { useTranslation } from 'react-i18next';

import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { Button, Typography } from '@mui/material';

import { useNcrOverviewContext } from '../../store/NCROverviewProvider';

const NcrOverviewFiltersButton = () => {
    const { t } = useTranslation();
    const { toggleNcrOverviewFilters } = useNcrOverviewContext();

    return (
        <Button
            variant="contained"
            color="primary"
            startIcon={<FilterListRoundedIcon />}
            onClick={toggleNcrOverviewFilters}
            style={{ paddingInline: '2rem' }}>
            <Typography variant="button" style={{ cursor: 'pointer' }}>
                {t('common.action-buttons.filters')}
            </Typography>
        </Button>
    );
};

export default NcrOverviewFiltersButton;
