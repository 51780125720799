import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { GET_USERS } from '../gqlQueries/queries';

export const useGetUsersOptions = () => {
    const { data, loading } = useQuery(GET_USERS, {
        fetchPolicy: 'cache-first',
        variables: {
            user: {
                disabled: false,
            },
        },
    });

    const userOptions = useMemo(
        () =>
            data?.getUsers?.map(
                user =>
                    ({
                        avatar: user.avatar?.thumbnail,
                        fullName: `${user.firstName} ${user.name}`,
                        roles: user?.roles,
                        value: user._id,
                    } || [])
            ) || [],
        [data?.getUsers]
    );

    return [userOptions, loading];
};
