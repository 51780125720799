import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import UserDropdown from '../../../../common/components/UserDropdown';

import { useTicketKanbanBoardContext } from '../../store/TicketsKanbanBoardContext';

const TicketKanbanBoardResponsibleDropdown = () => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { responsibleFilter, setTicketsKanbanBoardResponsible } = useTicketKanbanBoardContext();

    return <UserDropdown value={responsibleFilter} onChange={setTicketsKanbanBoardResponsible} sx={{ width: mobile ? '100%' : 300 }} />;
};

export default TicketKanbanBoardResponsibleDropdown;
