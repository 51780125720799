import React from 'react';

import { Box, Skeleton } from '@mui/material';

import { useModal } from '../../../../../../../../common/hooks/useModal';

import { useGetCapaTicket } from '../../../hooks/useGetCapaTicket';

import EditCapaTicketModal from '../../../../../../../tickets-kanban-board/components/drag-and-drop/EditCapaTicketModal';
import CreateMeasureModal from './create-measure-modal/CreateMeasureModal';
import CreateMeasureButton from './CreateMeasureButton';
import MeasuresTicketList from './MeasuresTicketList';

const MeasuresContent = ({ ncrId, capaDetailId, userImages }) => {
    const [isOpened, toggleModal] = useModal(false);

    const getCapaTicketsFilter = {
        $and: [
            {
                'externalReference.NCRId': ncrId,
            },
            {
                'externalReference.NCRCapaDetailId': capaDetailId,
            },
            {
                deleted: false,
            },
        ],
    };

    const { data, loading } = useGetCapaTicket(JSON.stringify(getCapaTicketsFilter));

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '16px 0px' }}>
                <CreateMeasureButton handleOpenModal={toggleModal} />
            </Box>
            {loading && <Skeleton variant="rounded" height={120} />}
            <MeasuresTicketList tickets={data?.getCapaTickets} userImages={userImages} />
            <EditCapaTicketModal />
            <CreateMeasureModal handleModalClose={toggleModal} isOpen={isOpened} ncrId={ncrId} capaDetailId={capaDetailId} />
        </Box>
    );
};

export default MeasuresContent;
