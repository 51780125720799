export const usersTableActions = {
    SET_USERS_TABLE_SEARCH_FILTER: 'SET_USERS_TABLE_SEARCH_FILTER',
    RESET_USERS_TABLE_FILTERS: 'RESET_USERS_TABLE_FILTERS',
    TOGGLE_USERS_TABLE_FILTERS: 'TOGGLE_USERS_TABLE_FILTERS',
    SET_USERS_TABLE_FILTER: 'SET_USERS_TABLE_FILTER',
};

export const setUsersTableSearchFilter = payload => {
    return {
        type: usersTableActions.SET_USERS_TABLE_SEARCH_FILTER,
        payload,
    };
};

export const resetUsersTableFilters = () => {
    return {
        type: usersTableActions.RESET_USERS_TABLE_FILTERS,
    };
};

export const toggleUsersTableFilters = () => {
    return {
        type: usersTableActions.TOGGLE_USERS_TABLE_FILTERS,
    };
};

export const setUsersTableFilter = payload => {
    return {
        type: usersTableActions.SET_USERS_TABLE_FILTER,
        payload,
    };
};
