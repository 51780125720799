import { useCallback } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import PrintIcon from '@mui/icons-material/Print';
import { Box, Button, Grid, LinearProgress, Stack, Step, StepLabel, Stepper, Tooltip, Typography } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { DataGrid } from '@mui/x-data-grid';

import Accordion from '../../../../../../../common/components/accordion/Accordion';
import AccordionSummary from '../../../../../../../common/components/accordion/AccordionSummary';
import NcrCostSection from '../../../../../../../common/components/ncr/NcrCostSection';
import NcrSectionStatus from '../../../../../../../common/components/ncr/NcrSectionStatus';
import ResponsibleWorkflowStepInput from '../../../../../../../common/components/ncr/ResponsibleWorkflowStepInput';
import WorkflowStepAvailableActions from '../../../../../../../common/components/ncr/workflowStepAction/WorkflowStepAvailableActions';
import TableNoDataOverlay from '../../../../../../../common/components/table/TableNoDataOverlay';
import AddNewStep from './AddNewStep';

import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../../../common/constants/constants';
import { getValueByLanguage } from '../../../../../../../utils/common';

import { ncrSectionType } from '../../../../../../../common/enums/ncrSectionType';
import { ncrStatus } from '../../../../../../../common/enums/ncrStatus';

import { useCollapse } from '../../../../../../../common/hooks/useCollapse';
import { useAtomicNCR } from '../../../../../../inbox/hooks/useAtomicNCR';
import { useNcrReworkProcessStepsTableData } from '../../hooks/useNcrReworkProcessStepsTableData';

import { ncrSectionFromERPType } from '../../../../../../../common/enums/ncrSectionFromERPType';
import { notificationsSeverityByRequestStatus } from '../../../../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../../../../common/providers/notification/store/notification.context';
import { useUserSettingsContext } from '../../../../../../../common/providers/userSettings/store/userSettings.context';
import { useErpUpdate } from '../../hooks/useErpUpdate';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { getReworkStepsPdfDefinition } from '../../pdf-view/reworkStepsPdfDefinition';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ReworkView = ({ ncr, rework, index, loading }) => {
    const navigate = useNavigate();
    const { ncrId, sectionType, sectionId } = useParams();

    const isSectionCollapsed = sectionType === ncrSectionType.REWORK && rework._id === sectionId;
    const [isCollapsed, collapse] = useCollapse(isSectionCollapsed);

    const { language } = useUserSettingsContext();
    const { setNotification } = useNotificationContext();

    const [handleAtomicUpdate] = useAtomicNCR();
    const [handleErpUpdate] = useErpUpdate();

    const reworkStatusDone = [ncrStatus.DONE, ncrStatus.REJECTED].includes(rework.status) || loading;
    const isSectionDisabled = reworkStatusDone || ncr.status === ncrStatus.DONE;

    const [rows, columns, atomicLoading] = useNcrReworkProcessStepsTableData({
        reworkId: rework._id,
        ncr,
        disableDelete: isSectionDisabled || false,
    });

    const handleAccordionCollapseChange = () => {
        const path = generatePath('/ncr-overview/:ncrId/:sectionType/:sectionId', {
            ncrId,
            sectionId: rework._id,
            sectionType: ncrSectionType.REWORK,
        });

        collapse();
        navigate(path);
    };

    const handleNcrUpdate = useCallback(
        (path, values, operation = 'update', childIds = {}) => {
            const childrenIds = { reworkId: rework?._id, ...childIds };

            if (ncrId && values) {
                handleAtomicUpdate({ ncrId, operation, path, values, childrenIds })
                    .then(result => {
                        setNotification({ severity: notificationsSeverityByRequestStatus[result.data.atomicNCR.success] });
                    })
                    .catch(_ => {
                        setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                    });
            }
        },
        [ncrId, rework?._id, handleAtomicUpdate, setNotification]
    );

    const handleErpUpdateClick = () => {
        handleErpUpdate({
            ncrId: ncr._id,
            sectionType: ncrSectionFromERPType.REWORK_PROCESS_ROUTING,
            sectionId: rework._id,
        })
            .then(result => {
                setNotification({ severity: notificationsSeverityByRequestStatus[result.data.pullDataFromERP.success] });
            })
            .catch(_ => {
                setNotification({ severity: notificationsSeverityByRequestStatus[false] });
            });
    };

    if (!rework) {
        return null;
    }

    const [resolvedWorkflow] = rework.resolvedWorkflows;

    const workflowName = getValueByLanguage(resolvedWorkflow?.name, language);
    const doneStepIndex = resolvedWorkflow.resolvedWorkflowSteps?.findLastIndex(step => step?.status === ncrStatus.DONE);
    const activeStep = doneStepIndex === -1 ? 0 : doneStepIndex + 1;

    const resolvedWorkflowStep = resolvedWorkflow?.resolvedWorkflowSteps[activeStep];
    const nextWorkflowStepId = resolvedWorkflow?.resolvedWorkflowSteps[activeStep + 1]?._id;

    const printReworkSteps = () => {
        pdfMake.createPdf(getReworkStepsPdfDefinition(ncr, rework?._id, language)).open();
    };

    return (
        <Box style={{ borderRadius: 16 }}>
            <Accordion
                disableGutters
                elevation={3}
                sx={{ width: '100%', padding: '1.5em' }}
                expanded={isCollapsed}
                onChange={handleAccordionCollapseChange}>
                <AccordionSummary>
                    <Typography variant="h6" noWrap component="div">
                        {workflowName}
                    </Typography>
                    <Box marginLeft="auto">
                        <NcrSectionStatus status={rework.status} />
                    </Box>

                    {loading && <LinearProgress />}
                </AccordionSummary>
                <MuiAccordionDetails sx={{ padding: '1em 0' }}>
                    <Stack spacing={4} marginBottom="2em">
                        <Grid width="100%" container spacing={4}>
                            <Grid item sm={12} md={5}>
                                <ResponsibleWorkflowStepInput
                                    sectionId={rework._id}
                                    isDisabled={isSectionDisabled}
                                    value={resolvedWorkflowStep?.resolvedResponsible}
                                    workflowStepId={resolvedWorkflowStep?._id}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box height="100%">
                                    <Stepper activeStep={activeStep} sx={{ alignItems: 'center', height: '100%' }}>
                                        {resolvedWorkflow?.resolvedWorkflowSteps
                                            ?.filter(item => item)
                                            ?.map(workflowStep => (
                                                <Step key={workflowStep._id} completed={workflowStep?.status === ncrStatus.DONE}>
                                                    <StepLabel sx={{ textTransform: 'capitalize' }}>
                                                        {getValueByLanguage(workflowStep?.name, language)}
                                                    </StepLabel>
                                                </Step>
                                            ))}
                                    </Stepper>
                                </Box>
                            </Grid>

                            <Grid item sm={12} md={1}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Tooltip title="Print">
                                        <Button variant="outlined" onClick={printReworkSteps}>
                                            <PrintIcon />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                        <DataGrid
                            sx={{
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 600,
                                },
                            }}
                            rows={rows || []}
                            columns={columns || []}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: TableNoDataOverlay,
                            }}
                            loading={atomicLoading}
                            autoHeight
                            disableColumnMenu
                            disableSelectionOnClick
                            hideFooterSelectedRowCount
                            pageSize={DEFAULT_TABLE_PAGE_SIZE}
                            editMode="cell"
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                    </Stack>
                    {!isSectionDisabled && <AddNewStep rework={rework} index={index} />}
                    <NcrCostSection
                        data={rework}
                        handleUpdate={handleNcrUpdate}
                        path={'reworks.$[reworkId]'}
                        disabled={isSectionDisabled}
                    />

                    <WorkflowStepAvailableActions
                        ncrId={ncrId}
                        sectionId={rework._id}
                        resolvedWorkflowStep={resolvedWorkflowStep}
                        nextWorkflowStepId={nextWorkflowStepId}
                        workflowId={resolvedWorkflow?._id}
                        handleNcrUpdate={handleNcrUpdate}
                        handleErpUpdate={handleErpUpdateClick}
                        path={'reworks.$[reworkId]'}
                        childrenIds={{ reworkId: rework._id }}
                        type="rework"
                        hasConnector={rework?.hasConnector}
                        disabled={isSectionDisabled}
                    />
                </MuiAccordionDetails>
            </Accordion>
        </Box>
    );
};

export default ReworkView;
