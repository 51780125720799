import { createContext, useContext, useReducer } from 'react';

import { clearNotification, setNotification } from './notification.actions';
import { notificationInitialState, notificationReducer } from './notification.reducer';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(notificationReducer, notificationInitialState);

    const value = {
        ...state,
        setNotification: payload => dispatch(setNotification(payload)),
        clearNotification: () => dispatch(clearNotification()),
    };

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export const useNotificationContext = () => {
    const context = useContext(NotificationContext);

    if (context === undefined) {
        return notificationInitialState;
    }

    return context;
};
