import React from 'react';

import { Grid } from '@mui/material';

import { findTileElement } from '../../../../../../../../common/utils/fileManagement';

import CapaTicketWrapper from '../../../../../../../tickets-kanban-board/components/drag-and-drop/CapaTicketWrapper';

const MeasuresTicketList = ({ tickets, userImages }) => {
    return (
        <Grid container spacing={2}>
            {tickets?.map(ticket => {
                const userImage = findTileElement(ticket?.resolvedResponsible?.profilePic?.thumbnail, userImages);

                return (
                    <Grid key={ticket?._id} item xs={12} md={4}>
                        <CapaTicketWrapper ticket={ticket} avatarSrc={userImage?.img} overviewPage />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default MeasuresTicketList;
