import React from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { isArrayWithItems } from '../../../../../utils/common';

import { useUsersTableContext } from '../../store/usersTableProvider';

const getUsersTableEmailOptions = users => {
    if (!isArrayWithItems(users)) {
        return [];
    }

    return users
        .map(user => {
            return {
                title: user.email,
                value: user.email,
            };
        })
        .sort((a, b) => a?.value?.localeCompare(b?.value));
};

const UsersTableEmailFilter = ({ users, value }) => {
    const { t } = useTranslation();
    const { setUsersTableFilter } = useUsersTableContext();

    const options = getUsersTableEmailOptions(users);

    const inputValue = value ? options.find(option => option.value === value) : null;

    return (
        <Autocomplete
            value={inputValue}
            fullWidth
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            options={options}
            getOptionLabel={option => option.title}
            renderInput={params => <TextField {...params} label={t('adminPage.usersTable.email')} />}
            onChange={(_, data) => {
                setUsersTableFilter({ key: 'emailFilter', value: data?.value });
            }}
        />
    );
};

export default UsersTableEmailFilter;
