import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

import { ContextMenu } from '@5thindustry/factory_portal.components.contextmenu/dist/contextmenu';

import NcrStatusLabel from '../../../common/components/ncr/NcrStatusLabel';
import UserNameWithAvatar from '../../../common/components/UserNameWithAvatar';

import { useCascadeUpdateManyNCRs } from './useCascadeUpdateManyNCRs';
import { useUserImages } from '../../../common/hooks/useUserImages';
import { useUserAvatar } from './../../../common/hooks/useUserAvatar';

export const useNcrOverviewTableColumns = () => {
    const { t } = useTranslation();

    const [images] = useUserImages();
    const [avatar] = useUserAvatar();

    const [cascadeUpdate] = useCascadeUpdateManyNCRs();

    const columns = useMemo(() => {
        return [
            {
                field: 'name',
                headerName: t('ncrOverview.orderId'),
                width: 140,
                renderCell: params => {
                    return (
                        <Link
                            style={{
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                            to={`/ncr-overview/${params.id}`}>
                            {params.value}
                        </Link>
                    );
                },
            },
            {
                field: 'externalReference',
                headerName: t('ncrOverview.externalReference'),
                width: 260,
                valueGetter: params => params.row.externalReference,
            },
            {
                field: 'failureCategory',
                headerName: t('ncrOverview.failureCategory'),
                width: 280,
                valueGetter: params => params.row.failureCategory,
            },
            { field: 'ncrModifiedBy.modifiedAt', headerName: t('ncrOverview.modifiedAt'), type: 'date', width: 120 },
            {
                field: 'ncrCreatedBy.createdAt',
                headerName: t('ncrOverview.createdAt'),
                type: 'date',
                width: 120,
            },
            {
                field: 'responsible',
                minWidth: 220,
                headerName: t('ncrOverview.responsible'),
                renderCell: params => {
                    if (!params.row.responsible) {
                        return null;
                    }

                    const avatarSrc = avatar.getUserAvatarByThumbnail(
                        images,
                        params?.row?.responsible?.profilePic?.thumbnail
                    );

                    return (
                        <UserNameWithAvatar
                            avatarSrc={avatarSrc}
                            userName={`${params.formattedValue.firstName} ${params.formattedValue.name}`}
                        />
                    );
                },
            },
            {
                field: 'status',
                headerName: t('ncrOverview.status'),
                width: 160,
                valueGetter: params => params.row.status,
                renderCell: params => {
                    return <NcrStatusLabel value={params?.row?.status} />;
                },
            },
            {
                field: 'contextMenu',
                width: 80,
                sortable: false,
                headerName: '',
                valueGetter: params => params.row,
                renderCell: params => {
                    let ncr = params?.row;

                    return (
                        <IconButton
                            disableRipple
                            disableTouchRipple
                            onClick={event => {
                                event.ignoreRowClick = true;
                            }}>
                            <ContextMenu
                                options={[
                                    ncr.archived
                                        ? {
                                              icon: <UnarchiveIcon />,
                                              key: `unarchive-${ncr?.rowId}`,
                                              label: t('common.context-menu-options.unarchive'),
                                              onClick: () => cascadeUpdate([ncr?.rowId], 'archived', false),
                                          }
                                        : {
                                              icon: <ArchiveIcon />,
                                              key: `archive-${ncr?.rowId}`,
                                              label: t('common.context-menu-options.archive'),
                                              onClick: () => cascadeUpdate([ncr?.rowId], 'archived', true),
                                          },
                                    {
                                        icon: <DeleteIcon />,
                                        key: `delete-${ncr?.rowId}`,
                                        label: t('common.context-menu-options.delete'),
                                        onClick: () => cascadeUpdate([ncr?.rowId], 'deleted', true),
                                    },
                                ]}
                            />
                        </IconButton>
                    );
                },
            },
        ];
    }, [t, images, avatar, cascadeUpdate]);

    return [columns];
};
