import { Box, Container, Paper, Stack } from '@mui/material';

import NcrOverviewFilters from './components/filters/NcrOverviewFilters';
import NcrOverviewTable from './components/ncr-table/NcrOverviewTable';
import NewNcrButton from './components/NewNcrButton';

import NCROverviewProvider from './store/NCROverviewProvider';

const NcrOverviewPage = () => {
    return (
        <NCROverviewProvider>
            <Container maxWidth="xl">
                <Paper elevation={3}>
                    <Box width={'100%'} p={'1.5em'}>
                        <Stack spacing={4}>
                            <NcrOverviewFilters />
                            <NcrOverviewTable />
                        </Stack>
                    </Box>
                </Paper>
            </Container>
            <NewNcrButton />
        </NCROverviewProvider>
    );
};

export default NcrOverviewPage;
