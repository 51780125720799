import React, { useEffect } from 'react';

import { useFiles } from '../../../../../../common/hooks/useFiles';

import { AWS_S3_BUCKET_EHS_USER_FOLDER } from '../../../../../../configs/env';

import RootCauseView from './root-cause/RootCauseView';

const RootCauseList = ({ ncr, loading }) => {
    const [handleFetchUserImages, userImages] = useFiles(AWS_S3_BUCKET_EHS_USER_FOLDER);

    useEffect(() => {
        handleFetchUserImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!ncr) {
        return null;
    }

    return (
        <>
            {ncr?.capaDetails?.map(capaDetail => {
                return (
                    <RootCauseView
                        key={capaDetail._id}
                        capaDetail={capaDetail}
                        ncrId={ncr?._id}
                        loading={loading}
                        userImages={userImages}
                    />
                );
            })}
        </>
    );
};

export default RootCauseList;
