import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';

import StyledTab from '../../../../common/components/tabs/StyledTab';
import StyledTabs from '../../../../common/components/tabs/StyledTabs';
import NcrCorrectiveTab from './ncr-corrective/NcrCorrectiveTab';
import NcrImmediateTab from './ncr-immediate/NcrImmediateTab';
import NcrInfoTab from './ncr-info/NcrInfoTab';
import NcrValuationTab from './ncr-valuation/NcrValuationTab';

import { useSharedGetSingleNCR } from '../../../../common/components/ncr/hooks/useSharedGetSingleNCR';

import { DEFAULT_ACTIVE_TAB_INDEX } from '../../../../common/constants/constants';
import { ncrInfoTabsIndex } from '../../constants';
import FinishNCRButton from '../FinishNcrButton';

const { INFO_TAB, IMMEDIATE_TAB, CORRECTIVE_TAB, RATING_TAB } = ncrInfoTabsIndex;

const tabContent = {
    [INFO_TAB]: {
        render: ({ ncr, loading }) => <NcrInfoTab ncr={ncr} loading={loading} />,
    },
    [IMMEDIATE_TAB]: {
        render: ({ ncr, loading }) => <NcrImmediateTab ncr={ncr} loading={loading} />,
    },
    [CORRECTIVE_TAB]: {
        render: ({ ncr, loading }) => <NcrCorrectiveTab ncr={ncr} loading={loading} />,
    },
    [RATING_TAB]: {
        render: ({ ncr, loading }) => <NcrValuationTab ncr={ncr} loading={loading} />,
    },
};

const NcrInfoTabs = () => {
    const { t } = useTranslation();
    const { ncrId, sectionType } = useParams();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [activeTabIndex, setActiveTabIndex] = useState(sectionType ? 1 : DEFAULT_ACTIVE_TAB_INDEX);

    const [ncr, loading] = useSharedGetSingleNCR(ncrId);

    const handleChange = (_event, newValue) => {
        setActiveTabIndex(newValue);
    };

    useEffect(() => {
        if (ncr) {
            localStorage.setItem('pageTitle', JSON.stringify(`NCR | ${ncr.name}`));
            window && window.dispatchEvent(new Event('setPageTitle'));
        }
    }, [ncr]);

    return (
        <>
            <Box display={mobile ? ' ' : 'flex'} sx={{ marginBottom: '2em' }}>
                <Box sx={{ width: 'fit-content' }}>
                    <StyledTabs value={activeTabIndex} onChange={handleChange}>
                        <StyledTab label={t('ncrInfo.tabs.info')} tabIndex={0} />
                        <StyledTab label={t('ncrInfo.tabs.immediate')} tabIndex={1} />
                        <StyledTab label={t('ncrInfo.tabs.corrective')} tabIndex={2} />
                        <StyledTab label={t('ncrInfo.tabs.valuation')} tabIndex={3} />
                    </StyledTabs>
                </Box>
                <Box sx={{ marginLeft: 'auto', height: '100%' }}>
                    <FinishNCRButton ncrId={ncrId} status={ncr?.status} />
                </Box>
            </Box>

            {tabContent[activeTabIndex]?.render({ ncr, loading })}
        </>
    );
};

export default NcrInfoTabs;
