import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Card, CardActions, CardContent, CardHeader, Collapse, Divider, Grid, Typography } from '@mui/material';

import { useNcrTemplateContext } from '../../../common/contexts/ncrTemplate/store/context';
import { useUserSettingsContext } from '../../../common/providers/userSettings/store/userSettings.context';

import { customInputElementType } from '../../../common/enums/customInputElementType';

import { useCollapse } from '../../../common/hooks/useCollapse';
import { useGetNCRTemplates } from '../hooks/useGetNCRTemplates';

import { getValueByLanguage } from '../../../utils/common';

import CreateNCRAttachments from './attachments/CreateNCRAttachments';
import NcrCheckbox from './customFields/NcrCheckbox';
import NcrInput from './customFields/NcrInput';
import NcrSelect from './customFields/NcrSelect';
import AffectedPiecesInput from './inputs/AffectedPiecesInput';
import CreatedByInput from './inputs/CreatedByInput';
import CreateNCRButton from './inputs/CreateNCRButton';
import DescriptionInput from './inputs/DescriptionInput';
import ExternalReferenceLocation from './inputs/ExternalReferenceLocation';
import FailureCodeDropdown from './inputs/FailureCodeDropdown';
import FollowerInput from './inputs/FollowerInput';
import PriorityDropdown from './inputs/PriorityDropdown';
import ReferenceInput from './inputs/ReferenceInput';
import ResponsibleDropdown from './inputs/ResponsibleDropdown';
import TagsDropdown from './inputs/TagsDropdown';
import TotalAmountInput from './inputs/TotalAmountInput';

export const fields = language => {
    return {
        [customInputElementType.SELECT]: props => {
            const label = getValueByLanguage(props.label, language);

            const { setNCRCustomFields } = useNcrTemplateContext();

            const handleChange = value => {
                setNCRCustomFields({ value, id: props._id });
            };

            const options = props.choices.map(option => {
                return {
                    label: getValueByLanguage(option.label, language),
                    value: option.value,
                };
            });

            return (
                <NcrSelect
                    disabled={props.readOnly}
                    loading={props.loading}
                    options={options}
                    label={label}
                    labelKey={'label'}
                    valueKey={'value'}
                    handleChange={handleChange}
                />
            );
        },

        [customInputElementType.TEXT]: props => {
            const { setNCRCustomFields } = useNcrTemplateContext();

            const handleChange = event => {
                setNCRCustomFields({ value: event?.target?.value, id: props._id });
            };

            const label = getValueByLanguage(props.label, language);

            return <NcrInput disabled={props.readOnly} label={label} onBlur={handleChange} />;
        },

        [customInputElementType.NUMBER]: props => {
            const { setNCRCustomFields } = useNcrTemplateContext();

            const handleChange = event => {
                setNCRCustomFields({ value: event?.target?.value, id: props._id });
            };

            const label = getValueByLanguage(props.label, language);

            return <NcrInput disabled={props.readOnly} type="number" label={label} onBlur={handleChange} />;
        },

        [customInputElementType.CHECKBOXES]: props => {
            const [choices] = props.choices;
            const { label } = choices;
            const title = getValueByLanguage(label, language);

            const { setNCRCustomFields } = useNcrTemplateContext();

            const handleChange = event => {
                const { value } = event.target;

                setNCRCustomFields({ value, id: props._id });
            };

            return <NcrCheckbox disabled={props.readOnly} label={title} handleChange={handleChange} />;
        },
    };
};

const CreateNCRForm = () => {
    const { language } = useUserSettingsContext();
    const { ncr, setNCRTemplate } = useNcrTemplateContext();

    const { t } = useTranslation();
    const [isCollapsed, collapse] = useCollapse(false);

    const [ncrTemplate, loadingNCRTemplates] = useGetNCRTemplates();

    useEffect(() => {
        if (!ncr && ncrTemplate) {
            setNCRTemplate(ncrTemplate);
            collapse(true);
        }
    }, [ncr, ncrTemplate, setNCRTemplate, collapse]);

    return (
        <>
            <Card sx={{ marginBottom: '1em', height: 'calc(100vh - 130px)' }}>
                <Box padding="1em">
                    <CardHeader sx={{ textAlign: 'center' }} title={t('report.form.title')} />
                    <CardContent sx={{ overflowY: 'auto', height: 'calc(100vh - 280px) !important', marginBottom: '1em' }}>
                        <Box marginBottom="0.25em">
                            <Typography variant="overline">{t('ncrInfo.basicInformation')}</Typography>
                        </Box>
                        <Divider
                            sx={{
                                marginBottom: '2em',
                            }}
                        />
                        <Box paddingBottom="1em">
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <ReferenceInput value={ncr?.basicInformation?.externalReference} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ExternalReferenceLocation value={ncr?.basicInformation?.externalReferenceLocation} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AffectedPiecesInput value={ncr?.basicInformation?.quantityData?.defected} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TotalAmountInput value={ncr?.basicInformation?.quantityData?.total} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FailureCodeDropdown
                                        value={{
                                            name: ncr?.basicInformation?.failureCategory?.name,
                                            failureMode: ncr?.basicInformation?.failureCategory?.failureMode,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TagsDropdown />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <PriorityDropdown value={ncr?.priority} />
                                </Grid>
                                <Grid item xs={12}>
                                    <DescriptionInput value={ncr?.basicInformation?.description} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CreatedByInput />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FollowerInput value={ncr?.follower} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ResponsibleDropdown value={ncr?.resolvedResponsible} />
                                </Grid>
                            </Grid>
                        </Box>

                        {/* MORE INFO */}
                        <Box paddingBottom="1em">
                            <LoadingButton
                                variant="text"
                                loadingPosition="start"
                                loading={loadingNCRTemplates}
                                onClick={collapse}
                                startIcon={isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                                {isCollapsed ? t('common.expand-button.showLess') : t('common.expand-button.showMore')}
                            </LoadingButton>
                        </Box>

                        <Collapse in={isCollapsed}>
                            <Box marginBottom="0.25em">
                                <Typography variant="overline">{t('report.customFields')}</Typography>
                            </Box>
                            <Divider
                                sx={{
                                    marginBottom: '2em',
                                }}
                            />

                            <Box paddingBottom="1em">
                                <Grid container spacing={4}>
                                    {!!ncrTemplate?.basicInformation &&
                                        ncrTemplate?.basicInformation?.customInputElements.map(field => {
                                            const item = fields(language)[field.customInputElementType];

                                            return (
                                                <Fragment key={field._id}>
                                                    {item && (
                                                        <Grid item xs={12} sm={6}>
                                                            {item && item(field)}
                                                        </Grid>
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                </Grid>
                            </Box>
                        </Collapse>

                        <Box marginBottom="0.25em">
                            <Typography variant="overline">{t('ncrInfo.attachments')}</Typography>
                        </Box>
                        <Divider
                            sx={{
                                marginBottom: '2em',
                            }}
                        />
                        <CreateNCRAttachments />
                    </CardContent>

                    <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CreateNCRButton ncrTemplateId={ncrTemplate?._id} />
                    </CardActions>
                </Box>
            </Card>
        </>
    );
};

export default CreateNCRForm;
