import { gql } from '@apollo/client';

export const GET_PAGINATED_FAILURE_CATEGORIES = gql`
    query GetPaginateFailureCategories($limit: Int, $skip: Int, $filter: String) {
        getPaginateFailureCategories(limit: $limit, skip: $skip, filter: $filter) {
            code
            success
            message
            metadata {
                total
                limit
                hasNext
                skip
            }
            failureCategory {
                name
                failureModes {
                    name
                    description
                }
                _id
            }
        }
    }
`;
