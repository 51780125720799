import { usersTableActions } from './usersTableActions';

export const userTableInitialState = {
    filtersOpened: false,
    searchFilter: '',
    lastNameFilter: '',
    firstNameFilter: '',
    emailFilter: '',
    roleFilter: [],
};

export const usersTableReducer = (state, action) => {
    switch (action.type) {
        case usersTableActions.SET_USERS_TABLE_SEARCH_FILTER: {
            return {
                ...state,
                searchFilter: action.payload,
            };
        }
        case usersTableActions.RESET_USERS_TABLE_FILTERS: {
            return {
                ...userTableInitialState,
                filtersOpened: state.filtersOpened,
            };
        }
        case usersTableActions.TOGGLE_USERS_TABLE_FILTERS: {
            return {
                ...state,
                filtersOpened: !state.filtersOpened,
            };
        }
        case usersTableActions.SET_USERS_TABLE_FILTER: {
            const { key, value } = action.payload;

            return {
                ...state,
                [key]: value || '',
            };
        }

        default:
            return userTableInitialState;
    }
};
