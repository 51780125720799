import { useContext } from 'react';
import { createContext, useMemo, useReducer } from 'react';

import {
    resetNcrOverviewFilters,
    setNcrOverviewArchivedFilter,
    setNcrOverviewDateRangeFilter,
    setNcrOverviewFailureCategoryFilter,
    setNcrOverviewSearchFilter,
    setNcrOverviewStatusFilter,
    setNcrOverviewTablePage,
    setNcrOverviewTableSorting,
    toggleNcrOverviewFilters,
    setNrcOverviewSelectedTeams,
} from './NCROverviewActions';

import { ncrOverviewInitialState, ncrOverviewReducer } from './NCROverviewReducer';

export const NcrOverviewContext = createContext(ncrOverviewInitialState);

const NCROverviewProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ncrOverviewReducer, ncrOverviewInitialState);

    const NcrOverviewContextValue = useMemo(() => {
        return {
            ...state,
            page: state.pagination.page,
            sortBy: state.pagination.sortBy,
            order: state.pagination.order,
            status: state.statusFilter,
            teams: state.selectedTeams,
            archived: state.archivedFilter,
            setNcrOverviewSearchFilter: filter => dispatch(setNcrOverviewSearchFilter(filter)),
            setNcrOverviewStatusFilter: filter => dispatch(setNcrOverviewStatusFilter(filter)),
            setNcrOverviewDateRangeFilter: filter => dispatch(setNcrOverviewDateRangeFilter(filter)),
            setNcrOverviewFailureCategoryFilter: filter => dispatch(setNcrOverviewFailureCategoryFilter(filter)),
            setNcrOverviewArchivedFilter: filter => dispatch(setNcrOverviewArchivedFilter(filter)),
            resetNcrOverviewFilters: () => dispatch(resetNcrOverviewFilters()),
            toggleNcrOverviewFilters: () => dispatch(toggleNcrOverviewFilters()),
            setNcrOverviewTablePage: page => dispatch(setNcrOverviewTablePage(page)),
            setNcrOverviewTableSorting: payload => dispatch(setNcrOverviewTableSorting(payload)),
        };
    }, [state]);

    return <NcrOverviewContext.Provider value={NcrOverviewContextValue}>{children}</NcrOverviewContext.Provider>;
};

export const useNcrOverviewContext = () => {
    const context = useContext(NcrOverviewContext);

    if (context === undefined) {
        return ncrOverviewInitialState;
    }

    return context;
};

export default NCROverviewProvider;
