import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Box, TextField } from '@mui/material';

import { useGetUsersOptions } from '../hooks/useGetUsersOptions';
import { useUserAvatar } from '../hooks/useUserAvatar';
import { useUserImages } from '../hooks/useUserImages';

import UserNameWithAvatar from './UserNameWithAvatar';

const UserDropdownComponent = ({ value, selectedUserId, isDisabled, isLoading, onChange, required, ...props }) => {
    const { t } = useTranslation();

    const [userOptions, getUserOptionsLoading] = useGetUsersOptions();
    const [images, getUserImages] = useUserImages();

    const [avatar] = useUserAvatar();

    return (
        <Autocomplete
            {...props}
            loading={isLoading || getUserOptionsLoading || getUserImages}
            disabled={isDisabled || isLoading || getUserOptionsLoading || getUserImages}
            options={userOptions}
            getOptionLabel={option => option.fullName}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={value}
            variant="outlined"
            fullWidth
            renderOption={(props, option) => {
                const avatarSrc = avatar.getUserAvatarByThumbnail(images, option.avatar);

                return (
                    <Box component="li" sx={{ cursor: 'pointer' }} {...props}>
                        <UserNameWithAvatar avatarSrc={avatarSrc} userName={option.fullName} />
                    </Box>
                );
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    required={required}
                    fullWidth
                    error={required && !value?.value}
                    label={t('report.form.responsible')}
                />
            )}
            onChange={(_, data) => onChange(data)}
        />
    );
};

const UserDropdown = memo(UserDropdownComponent);

export default UserDropdown;
