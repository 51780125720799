import React from 'react';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import ConfirmationModal from '../../../../common/components/ConfirmationModal';
import TicketCard from '../../../../common/components/TicketCard';

import { useCollapse } from '../../../../common/hooks/useCollapse';

import { notificationsSeverityByRequestStatus } from '../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../common/providers/notification/store/notification.context';
import { useUpdateCapaTicket } from '../../../ncr-info/components/tabs/ncr-corrective/hooks/useUpdateCapaTicket';

import { removeCapaTicketFromCache } from '../../constants';

import { useTicketKanbanBoardContext } from '../../store/TicketsKanbanBoardContext';

const CapaTicketWrapper = ({ ticket, avatarSrc, overviewPage }) => {
    const { t } = useTranslation();

    const [handleUpdateCapaTicket] = useUpdateCapaTicket();

    const { searchFilter, dateRangeFilter, incidentTypeFilter, responsibleFilter, setActiveCapaTicket } = useTicketKanbanBoardContext();

    const { setNotification } = useNotificationContext();
    const [isCollapsed, collapse] = useCollapse(false);

    const capaTicketsMenuOptions = [
        {
            icon: <EditIcon />,
            key: 'edit_open',
            label: t('common.context-menu-options.edit'),
            onClick: () => {
                setActiveCapaTicket(ticket);
            },
        },
        {
            icon: <DeleteIcon />,
            key: 'delete_open',
            label: t('common.context-menu-options.delete'),
            onClick: () => {
                collapse();
            },
        },
    ];

    const removeCurrentTicket = () => {
        collapse();

        handleUpdateCapaTicket(
            {
                _id: ticket?._id,
                deleted: true,
            },
            ['GetCapaTickets']
        )
            .then(result => {
                if (result?.data?.updateCapaTicket?.success) {
                    if (!overviewPage) {
                        removeCapaTicketFromCache(
                            result?.data?.updateCapaTicket?.capaTicket._id,
                            result?.data?.updateCapaTicket?.capaTicket.status,
                            searchFilter,
                            dateRangeFilter,
                            incidentTypeFilter,
                            responsibleFilter
                        );
                    }

                    setNotification({ severity: notificationsSeverityByRequestStatus[result?.data?.updateCapaTicket?.success] });
                }
            })
            .catch(() => {
                setNotification({ severity: notificationsSeverityByRequestStatus[false] });
            });
    };

    return (
        <>
            <TicketCard
                title={ticket?.action}
                subtitle={ticket?.description}
                tags={ticket?.tags}
                avatarSrc={avatarSrc}
                contextMenuOptions={capaTicketsMenuOptions}
            />

            <ConfirmationModal
                handleModalClose={collapse}
                isOpen={isCollapsed}
                title={t('capaTicketsKanbanBoard.removeTitle')}
                handleConfirm={removeCurrentTicket}
            />
        </>
    );
};

export default CapaTicketWrapper;
