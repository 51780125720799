import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../../../../../../common/components/AutoSaveTextField';

const FinalCommentInputComponent = ({ value, disabled, handleUpdate }) => {
    const { t } = useTranslation();

    const handleBlur = event => {
        handleUpdate({ finalComment: event.target.value });
    };

    return (
        <AutoSaveTextField
            value={value}
            disabled={disabled}
            label={t('ncrInfo.immediate.comments')}
            rows={3}
            onBlur={handleBlur}
            multiline
            fullWidth
            onKeyPress={event => {
                event.key === 'Enter' && handleBlur(event);
            }}
        />
    );
};

const FinalCommentInput = memo(FinalCommentInputComponent);

export default FinalCommentInput;
