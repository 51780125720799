import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Button } from '@mui/material';

import { useTicketKanbanBoardContext } from '../../store/TicketsKanbanBoardContext';

const TicketKanbanBoardClearButton = () => {
    const { resetTicketsKanbanBoardFilters } = useTicketKanbanBoardContext();

    return (
        <Button variant="outlined" color="primary" onClick={resetTicketsKanbanBoardFilters}>
            <ClearRoundedIcon />
        </Button>
    );
};

export default TicketKanbanBoardClearButton;
