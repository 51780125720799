import React from 'react';
import { useTheme } from '@mui/material';

const ReworkIcon = () => {
    const theme = useTheme();

    return (
        <svg width="27" height="42" viewBox="0 0 27 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.339966 36.8201L0.923299 42.0001L4.7033 38.4067L11.1666 20.6734C9.57997 20.2767 8.17996 19.4834 7.03663 18.3867L0.339966 36.8201Z"
                fill={theme?.palette.mode === 'light' ? '#16ABA1' : '#52DBCE'}
            />
            <path
                d="M19.9633 18.3867C18.8199 19.4834 17.3966 20.2767 15.8333 20.6734L22.2966 38.4067L26.0766 42.0001L26.6833 36.8201L19.9633 18.3867Z"
                fill={theme?.palette.mode === 'light' ? '#16ABA1' : '#52DBCE'}
            />
            <path
                d="M20.36 13.0667C21.06 9.42667 18.96 6.20667 15.8333 5.08667V2.33333C15.8333 1.05 14.7833 0 13.5 0C12.2167 0 11.1667 1.05 11.1667 2.33333V5.08667C8.46 6.06667 6.5 8.63333 6.5 11.6667C6.5 15.96 10.3733 19.3667 14.8067 18.55C17.56 18.0367 19.8233 15.82 20.36 13.0667ZM13.5 14C12.2167 14 11.1667 12.95 11.1667 11.6667C11.1667 10.3833 12.2167 9.33333 13.5 9.33333C14.7833 9.33333 15.8333 10.3833 15.8333 11.6667C15.8333 12.95 14.7833 14 13.5 14Z"
                fill={theme?.palette.mode === 'light' ? '#16ABA1' : '#52DBCE'}
            />
        </svg>
    );
};

export default ReworkIcon;
