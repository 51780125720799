import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { useGetTagsOptions } from '../hooks/useGetTagsOptions';

const UpdateTagsDropdownComponent = ({ value = [], disabled, loading, handleUpdate }) => {
    const { t } = useTranslation();

    const [tagsOptions, loadingTagsOptions] = useGetTagsOptions();

    const handleChange = data => {
        handleUpdate({
            tags: data.map(tag => {
                return {
                    _id: tag.value,
                    name: tag.label,
                    colorCode: tag.colorCode,
                    usedBy: tag.usedBy,
                };
            }),
        });
    };

    const mappedValues = useMemo(() => {
        return value?.length ? tagsOptions?.filter(tag => value?.some(selectedTag => selectedTag._id === tag.value)) : [];
    }, [value, tagsOptions]);

    const memoizedValue = useMemo(() => {
        return mappedValues.map(item => ({
            ...item,
            usedBy: 'ncr',
        }));
    }, [mappedValues]);

    return (
        <Autocomplete
            multiple
            disableCloseOnSelect
            limitTags={3}
            value={memoizedValue}
            loading={loading || loadingTagsOptions}
            disabled={loading || loadingTagsOptions || disabled}
            options={tagsOptions}
            getOptionLabel={option => option.label}
            variant="outlined"
            fullWidth
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={params => <TextField {...params} label={t('report.form.tags')} />}
            onChange={(_, data) => handleChange(data)}
        />
    );
};

const UpdateTagsDropdown = memo(UpdateTagsDropdownComponent);

export default UpdateTagsDropdown;
