import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

const Accordion = styled(props => <MuiAccordion {...props} />)(({ _theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

export default Accordion;
