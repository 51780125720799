import React from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../../../../../../../../../../common/components/AutoSaveTextField';

const MeasureTitleInput = ({ value, handleUpdate, disabled }) => {
    const { t } = useTranslation();

    const handleChange = event => {
        if (value !== event?.target?.value) {
            handleUpdate(event?.target?.value);
        }
    };

    return (
        <AutoSaveTextField
            required
            disabled={disabled}
            onBlur={handleChange}
            value={value || ''}
            label={t('ncrInfo.corrective.title')}
            rows={1}
            multiline
            fullWidth
            error={!value}
        />
    );
};

export default MeasureTitleInput;
