import { useEffect } from 'react';

import { Stack } from '@mui/material';

import { useUsersTableData } from '../hooks/useUsersTableData';

import { useUsersTableContext } from '../store/usersTableProvider';

import NewUserButton from './create/NewUserButton';
import EditUser from './edit/EditUser';
import UsersTableFilters from './filters/UsersTableFilters';
import UsersTable from './table/UsersTable';

const UsersPageWrapper = () => {
    const { searchFilter, lastNameFilter, firstNameFilter, emailFilter, roleFilter } = useUsersTableContext();

    const [rows, columns, loading, filterBy, userOverlayData, setUserOverlayData, setSortModel] = useUsersTableData();

    useEffect(() => {
        filterBy({ searchFilter, lastNameFilter, firstNameFilter, emailFilter, roleFilter });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilter, emailFilter, firstNameFilter, lastNameFilter, roleFilter]);

    const handleModalClose = () => {
        filterBy({ searchFilter, lastNameFilter, firstNameFilter, emailFilter, roleFilter });
    };

    return (
        <Stack spacing={4}>
            <UsersTableFilters users={rows} />
            <UsersTable rows={rows} columns={columns} loading={loading} setOverlayData={setUserOverlayData} setSortModel={setSortModel} />
            {userOverlayData?.isOpen && userOverlayData?.userId && (
                <EditUser overlayData={userOverlayData} setOverlayData={setUserOverlayData} handleModalClose={handleModalClose} />
            )}
            <NewUserButton />
        </Stack>
    );
};

export default UsersPageWrapper;
