import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

import update from '../../../../../utils/update';
import { userRole } from '../../../../../utils/userRoles';

import UserOverlayTemplate from './UserOverlayTemplate';

const UserTemplateWrapper = ({
    isEdit,
    isDataSaved,
    setIsDataSaved,

    organizations,

    overlayData,
    setOverlayData,

    user,

    handleSubmit,
    handleModalClose,
}) => {
    const { t } = useTranslation;

    useEffect(() => {
        window.onbeforeunload = event => {
            if (!isDataSaved) {
                // This message wouldn't be showed in some browsers
                const message = t('common.promt-errors.changes-saved');
                event = event || window.event;

                // For IE and Firefox
                if (event) {
                    event.returnValue = message;
                }

                // For Safari
                return message;
            }

            return null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDataSaved]);

    const [model, setModel] = useState({
        firstName: '',
        name: '',
        email: '',
        // mobileNumber: "",
        roles: [userRole.auditResponsible],
        organizations: [],
    });

    useEffect(() => {
        const newModel = user || model;

        setModel({
            ...newModel,
        });

        if (isEdit && user) {
            validateInput('firstName', newModel.firstName);
            validateInput('name', newModel.name);
            validateInput('email', newModel.email);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const [valError, setValError] = useState({
        firstName: null,
        name: null,
        email: null,
    });

    let schema = {
        firstName: yup.object().shape({
            firstName: yup.string().required(),
        }),
        name: yup.object().shape({
            name: yup.string().required(),
        }),
        email: yup.object().shape({
            email: yup.string().required().email(),
        }),
        // mobileNumber: yup.object().shape({
        //     mobileNumber: yup.string().optional()
        // }),
        roles: yup.object().shape({
            roles: yup.array(),
        }),
        organizations: yup.object().shape({
            organizations: yup.array(),
        }),
    };

    const validateInput = (name, value) => {
        const validationModel = update.set(model, name, value);

        schema[name].isValid(validationModel).then(valid => {
            setValError(prev => {
                return update.set(prev, name, !valid);
            });
        });
    };

    const handleSave = useCallback(
        (path, value) => {
            handleSubmit({
                variables: {
                    user: {
                        _id: model._id,
                        [path]: value,
                    },
                },
            }).then(result => {
                if (result.data?.adminUpdateUser?.code === '200') {
                    setIsDataSaved(true);
                }
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [model]
    );

    const handleCreate = useCallback(() => {
        handleSubmit({
            variables: {
                user: {
                    ...model,
                },
            },
        }).then(result => {
            if (result.data?.createUser?.code === '200') {
                setIsDataSaved(true);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model]);

    const handleChange = (path, value) => {
        validateInput(path, value);

        setModel(prev => {
            const newModel = update.set(prev, path, value);
            return newModel;
        });

        setIsDataSaved(false);
    };

    const handleChangeWithoutDelay = (path, value) => {
        validateInput(path, value);

        setModel(prev => {
            const newModel = update.set(prev, path, value);

            if (isEdit && isAllFieldsValid(valError)) {
                handleSave(path, value);
            }

            return newModel;
        });

        setIsDataSaved(false);
    };

    const handleBlur = useCallback(
        (path, value) => {
            if (isEdit && !isDataSaved && isAllFieldsValid(valError)) {
                handleSave(path, value);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [model, valError, isEdit, isDataSaved]
    );

    const isAllFieldsValid = validationModel => {
        return validationModel.firstName === false && validationModel.name === false && validationModel.email === false;
    };

    return (
        <>
            {overlayData?.isOpen && (
                <UserOverlayTemplate
                    overlayData={overlayData}
                    setOverlayData={setOverlayData}
                    handleModalClose={handleModalClose}
                    isEdit={isEdit}
                    isDataSaved={isDataSaved}
                    organizations={organizations}
                    model={model}
                    valError={valError}
                    handleChange={handleChange}
                    handleChangeWithoutDelay={handleChangeWithoutDelay}
                    handleBlur={handleBlur}
                    isAllFieldsValid={isAllFieldsValid}
                    handleCreate={handleCreate}
                />
            )}
        </>
    );
};

export default UserTemplateWrapper;
