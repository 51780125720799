import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Grid, Typography } from '@mui/material';
import moment from 'moment';

import { useNcrTemplateContext } from '../../../../../../../common/contexts/ncrTemplate/NCRTemplateProvider';
import { notificationsSeverityByRequestStatus } from '../../../../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../../../../common/providers/notification/store/notification.context';
import { useUserSettingsContext } from '../../../../../../../common/providers/userSettings/store/userSettings.context';

import { useAtomicAddNewStepToNCR } from '../../hooks/useAtomicAddNewStepToNCR';

import { stepStatus } from '../../../../../../../common/enums/stepStatus';

import StepDueDate from '../inputs/StepDueDate';
import StepDurationInput from '../inputs/StepDurationInput';
import StepNameInput from '../inputs/StepNameInput';
import StepNumberInput from '../inputs/StepNumberInput';
import StepResponsibleDropdown from '../inputs/StepResponsibleDropdown';
import StepWorkplaceDropdown from '../inputs/StepWorkplaceDropdown';

const AddNewStep = ({ rework }) => {
    const { ncrId } = useParams();
    const { t } = useTranslation();

    const { setNCRReworkProcessStep } = useNcrTemplateContext();
    const { setNotification } = useNotificationContext();
    const { language } = useUserSettingsContext();

    const [handleAtomicUpdate, loading] = useAtomicAddNewStepToNCR();

    const [stepName, setStepName] = useState([]);
    const [stepNumber, setStepNumber] = useState('');
    const [stepResponsible, setStepResponsible] = useState([]);
    const [stepDuration, setStepDuration] = useState(null); // plannedDurations.processTime
    const [stepDueDate, setStepDueDate] = useState(null); // schedulingData.dueDate
    const [stepWorkplace, setStepWorkplace] = useState(null); // resolvedActualWorkplaces.workPlaceName

    const resetState = useCallback(() => {
        setStepName([]);
        setStepNumber('');
        setStepResponsible([]);
        setStepDuration(null);
        setStepDueDate(null);
        setStepWorkplace(null);
    }, [setStepName, setStepResponsible, setStepDuration, setStepDueDate, setStepWorkplace]);

    const handleNcrUpdate = useCallback(
        (reworkId, path, values, operation = 'add', childrenIds = undefined) => {
            if (ncrId && values) {
                handleAtomicUpdate({ ncrId, operation, path, values, childrenIds })
                    .then(response => {
                        setNotification({ severity: notificationsSeverityByRequestStatus[response.data.atomicNCR.success] });

                        const rework = response.data.atomicNCR.ncr.reworks.find(reworkItem => reworkItem._id === reworkId);

                        setNCRReworkProcessStep({ reworkId, value: rework.processRoutings });

                        resetState();
                    })
                    .catch(_ => {
                        setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                    });
            }
        },
        [handleAtomicUpdate, ncrId, setNCRReworkProcessStep, resetState, setNotification]
    );

    const handleAddNewStep = useCallback(() => {
        const [stepNameItem] = stepName;

        const isReworkHasProcessRoutings = !!rework?.processRoutings?.length;

        const processStepObject = {
            stepName: [
                {
                    language: language?.toUpperCase(),
                    value: stepNameItem?.value,
                },
            ],
            stepNumber,
            plannedDurations: {
                processTime: +stepDuration,
            },
            schedulingData: {
                dueDate: stepDueDate ? stepDueDate.toISOString() : null,
            },
            responsible: [
                {
                    responsibleID: stepResponsible[0]?.value,
                },
            ],
            stepStatus: stepStatus.OPEN,
            workplaces: [
                {
                    workPlaceName: {
                        _id: stepWorkplace?.value,
                        value: stepWorkplace?.title,
                        language: language?.toUpperCase(),
                    },
                },
            ],
        };

        const values = isReworkHasProcessRoutings
            ? {
                  processStep: processStepObject,
              }
            : {
                  processRouting: {
                      processSteps: processStepObject,
                  },
              };

        const createProcessStepPath = isReworkHasProcessRoutings
            ? 'reworks.$[reworkId].processRoutings.0.processSteps'
            : 'reworks.$[reworkId].processRoutings';

        handleNcrUpdate(rework._id, createProcessStepPath, values, 'add', {
            reworkId: rework._id,
        });
    }, [
        stepName,
        rework?.processRoutings?.length,
        rework._id,
        language,
        stepNumber,
        stepDuration,
        stepDueDate,
        stepResponsible,
        stepWorkplace?.value,
        stepWorkplace?.title,
        handleNcrUpdate,
    ]);

    return (
        <>
            <Typography variant="overline">Add Step</Typography>

            <Divider sx={{ marginBottom: '2em' }} />

            <Box sx={{ marginBottom: '2em' }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        <StepNumberInput value={stepNumber} handleUpdate={setStepNumber} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <StepNameInput value={stepName} handleUpdate={setStepName} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <StepResponsibleDropdown value={stepResponsible} handleUpdate={setStepResponsible} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <StepDueDate value={stepDueDate} handleUpdate={setStepDueDate} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <StepDurationInput value={stepDuration} handleUpdate={setStepDuration} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <StepWorkplaceDropdown value={stepWorkplace} handleUpdate={setStepWorkplace} />
                    </Grid>
                </Grid>
            </Box>
            <LoadingButton
                loading={loading}
                disabled={!stepNumber}
                loadingPosition="start"
                sx={{ cursor: 'pointer' }}
                onClick={handleAddNewStep}
                startIcon={<PlaylistAddRoundedIcon />}
                variant="contained"
                color="primary">
                <Typography variant="button" sx={{ cursor: 'pointer' }}>
                    {t('ncrInfo.immediate.addStep')}
                </Typography>
            </LoadingButton>
        </>
    );
};

export default AddNewStep;
