import React from 'react';

import { useTheme } from '@mui/material';

const ReportHomeScreenIcon = () => {
    const theme = useTheme();

    return (
        <svg width="125" height="126" viewBox="0 0 125 126" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M37.5 113H62.5C62.5 119.875 56.875 125.5 50 125.5C43.125 125.5 37.5 119.875 37.5 113ZM62.5 44.25C62.5 60.5625 72.9375 74.4375 87.5 79.625V94.25H93.75C97.1875 94.25 100 97.0625 100 100.5C100 103.938 97.1875 106.75 93.75 106.75H6.25C2.8125 106.75 0 103.938 0 100.5C0 97.0625 2.8125 94.25 6.25 94.25H12.5V50.5C12.5 33.0625 24.4375 18.375 40.625 14.25V9.875C40.625 4.6875 44.8125 0.5 50 0.5C55.1875 0.5 59.375 4.6875 59.375 9.875V14.25C63.8125 15.375 67.875 17.3125 71.5625 19.875C65.875 26.375 62.5 34.9375 62.5 44.25ZM118.75 38H106.25V25.5C106.25 22.0625 103.438 19.25 100 19.25C96.5625 19.25 93.75 22.0625 93.75 25.5V38H81.25C77.8125 38 75 40.8125 75 44.25C75 47.6875 77.8125 50.5 81.25 50.5H93.75V63C93.75 66.4375 96.5625 69.25 100 69.25C103.438 69.25 106.25 66.4375 106.25 63V50.5H118.75C122.188 50.5 125 47.6875 125 44.25C125 40.8125 122.188 38 118.75 38Z"
                fill={theme?.palette.mode === 'light' ? '#16ABA1' : '#52DBCE'}
            />
        </svg>
    );
};

export default ReportHomeScreenIcon;
