import { ncrInfoActions } from './NCRInfoActions';

export const ncrInfoInitialState = {
    ncrReworks: [],
};

export const ncrInfoReducer = (state, action) => {
    switch (action.type) {
        case ncrInfoActions.SET_NCR_REWORK_SECTION_FROM_OWN_TEMPLATE: {
            return {
                ...state,
                ncrReworks: action.payload,
            };
        }

        case ncrInfoActions.RESET_NCR_INFO_STATE:
            return ncrInfoInitialState;
        default:
            return ncrInfoInitialState;
    }
};
