import { gql } from '@apollo/client';

export const UPDATE_NCR = gql`
    mutation UpdateNCR($ncr: NCRInput!) {
        updateNCR(ncr: $ncr) {
            code
            success
            ncr {
                _id
                tags {
                    _id
                    name
                    colorCode
                    usedBy
                }
                status
                follower
                priority
            }
        }
    }
`;
