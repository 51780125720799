import { memo, useMemo } from 'react';

import UserDropdown from '../../UserDropdown';

const UpdateResponsibleDropdownComponent = ({ value, disabled, loading, handleUpdate }) => {
    const handleChange = option => {
        handleUpdate('responsible.0', {
            responsible: {
                responsibleID: option.value,
            },
        });
    };

    const mappedValue = useMemo(
        () =>
            !!value?.length
                ? {
                      avatar: value[0]?.profilePic?.thumbnail,
                      fullName: `${value[0]?.firstName} ${value[0]?.name}`,
                      value: value[0]?._id,
                  }
                : null,
        [value]
    );

    return <UserDropdown value={mappedValue} isDisabled={disabled} isLoading={loading} onChange={handleChange} />;
};

const UpdateResponsibleDropdown = memo(UpdateResponsibleDropdownComponent);

export default UpdateResponsibleDropdown;
