import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid, Typography } from '@mui/material';

import UpdateAffectedPiecesInput from '../../../../../../../../common/components/ncr/inputs/UpdateAffectedPiecesInput';
import UpdateTotalAmountInput from '../../../../../../../../common/components/ncr/inputs/UpdateTotalAmountInput';
import DiscardedPiecesInput from '../inputs/DiscardedPiecesInput';
import PiecesToRemakeInput from '../inputs/PiecesToRemakeInput';
import ShortDeliveryInput from '../inputs/ShortDeliveryInput';
import CommentInput from '../inputs/CommentInput';

const CaptureSection = ({ ncr, scrap, disabled, handleNcrUpdate, handleNcrScrapUpdate }) => {
    const { t } = useTranslation();

    return (
        <div style={{ marginBottom: '1.5em' }}>
            <Box marginTop="2em" marginBottom="0.25em">
                <Typography variant="overline" textTransform="uppercase">
                    {t('ncrInfo.immediate.capture')}
                </Typography>
            </Box>
            <Divider sx={{ marginBottom: '2em' }} />
            <Box sx={{ marginBottom: '2em' }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        <UpdateTotalAmountInput
                            value={ncr?.basicInformation?.quantityData}
                            disabled={true}
                            handleUpdate={handleNcrUpdate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <UpdateAffectedPiecesInput
                            value={ncr?.basicInformation?.quantityData}
                            disabled={true}
                            handleUpdate={handleNcrUpdate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <DiscardedPiecesInput
                            value={scrap?.discardedPieces}
                            scrapId={scrap._id}
                            disabled={disabled}
                            handleUpdate={handleNcrScrapUpdate}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box marginTop="2em" marginBottom="0.25em">
                <Typography variant="overline" textTransform="uppercase">
                    {t('ncrInfo.immediate.measures')}
                </Typography>
            </Box>
            <Divider sx={{ marginBottom: '2em' }} />

            <Box sx={{ marginBottom: '2em' }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        <ShortDeliveryInput
                            value={scrap.piecesToDeliver}
                            scrapId={scrap._id}
                            disabled={disabled}
                            handleUpdate={handleNcrScrapUpdate}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <PiecesToRemakeInput
                            value={scrap.piecesToRemake}
                            scrapId={scrap._id}
                            disabled={disabled}
                            handleUpdate={handleNcrScrapUpdate}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <CommentInput value={scrap.comment} scrapId={scrap._id} disabled={disabled} handleUpdate={handleNcrScrapUpdate} />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default CaptureSection;
