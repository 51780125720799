import React, { useMemo, useState } from 'react';

import UserDropdown from '../../../../../../../../../../common/components/UserDropdown';

const MeasureResponsibleDropdown = ({ value, isDisabled, isLoading, handleUpdate }) => {
    const [currentValue, setCurrentValue] = useState(null);

    const handleChange = data => {
        handleUpdate(data?.value);
        setCurrentValue(data);
    };

    const mappedValue = useMemo(
        () =>
            !!value
                ? {
                      avatar: value.profilePic?.thumbnail,
                      fullName: `${value?.firstName} ${value?.name}`,
                      value: value?._id,
                  }
                : null,
        [value]
    );

    return <UserDropdown value={mappedValue || currentValue} isDisabled={isDisabled} isLoading={isLoading} onChange={handleChange} />;
};

export default MeasureResponsibleDropdown;
