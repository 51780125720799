import { useMutation } from '@apollo/client';
import { REMOVE_SECTION_FROM_NCR } from '../../operations/mutations/removeSectionFromNCR';

export const useRemoveSectionFromNCR = () => {
    const [atomic, { loading }] = useMutation(REMOVE_SECTION_FROM_NCR);

    const handleRemoveSectionFromNCR = ({ ncrId, sectionId }) => {
        return atomic({
            variables: {
                ncrId,
                sectionId,
            },
            refetchQueries: ['GetSingleNCR'],
        });
    };

    return [handleRemoveSectionFromNCR, loading];
};
