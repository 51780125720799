import React from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { stepStatus } from '../../../../../../../common/enums/stepStatus';

const StepStatusDropdown = ({ value, isDisabled, isLoading, handleUpdate, ...props }) => {
    const { t } = useTranslation();

    const stepStatusOptions = [
        {
            title: t(`statuses.${stepStatus.OPEN}`),
            value: stepStatus.OPEN,
        },
        {
            title: t(`statuses.${stepStatus.IN_WORK}`),
            value: stepStatus.IN_WORK,
        },
        {
            title: t(`statuses.${stepStatus.DONE}`),
            value: stepStatus.DONE,
        },
        {
            title: t(`statuses.${stepStatus.DELAYED}`),
            value: stepStatus.DELAYED,
        },
        {
            title: t(`statuses.${stepStatus.WAITING}`),
            value: stepStatus.WAITING,
        },
    ];

    return (
        <Autocomplete
            {...props}
            options={stepStatusOptions}
            disableClearable
            getOptionLabel={option => option.title}
            value={value}
            fullWidth
            loading={isLoading}
            disabled={isLoading}
            renderInput={params => <TextField {...params} label={t('ncrInfo.immediate.table.status')} />}
            onChange={(_, data) => handleUpdate(data)}
        />
    );
};

export default StepStatusDropdown;
