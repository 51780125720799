import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import Accordion from '../accordion/Accordion';
import AccordionSummary from '../accordion/AccordionSummary';
import NcrBasicInfoForm from './NcrBasicInfoForm';

const NcrBasicInfoSectionComponent = ({ ncr }) => {
    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(true);

    const handleChange = (_event, newExpanded) => {
        setExpanded(newExpanded);
    };

    return (
        <Box style={{ borderRadius: 16 }}>
            <Accordion disableGutters elevation={3} sx={{ width: '100%', padding: '1.5em' }} expanded={expanded} onChange={handleChange}>
                <AccordionSummary>
                    <Typography variant="h6" noWrap component="div">
                        {t('ncrInfo.tabs.info')}
                    </Typography>
                </AccordionSummary>
                <MuiAccordionDetails sx={{ padding: '1em 0' }}>
                    <NcrBasicInfoForm ncr={ncr} />
                </MuiAccordionDetails>
            </Accordion>
        </Box>
    );
};

const NcrBasicInfoSection = memo(NcrBasicInfoSectionComponent);

export default NcrBasicInfoSection;
