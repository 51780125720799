import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import Accordion from '../../../../../../common/components/accordion/Accordion';
import AccordionSummary from '../../../../../../common/components/accordion/AccordionSummary';

import { useCollapse } from '../../../../../../common/hooks/useCollapse';

import AddCauseButton from './AddCauseButton';
import RootCauseList from './RootCauseList';

const RootCauseAnalysis = ({ ncr, loading }) => {
    const { t } = useTranslation();

    const [isCollapsed, collapse] = useCollapse(true);

    return (
        <Box style={{ borderRadius: 16 }}>
            <Accordion disableGutters elevation={3} sx={{ width: '100%', padding: '1.5em' }} expanded={isCollapsed} onChange={collapse}>
                <AccordionSummary>
                    <Typography variant="h6" noWrap component="div">
                        {t('ncrInfo.corrective.rootCauseAnalysis')}
                    </Typography>
                </AccordionSummary>
                <MuiAccordionDetails sx={{ padding: '1em 0' }}>
                    <RootCauseList ncr={ncr} loading={loading} />
                    <AddCauseButton ncr={ncr} loading={loading} />
                </MuiAccordionDetails>
            </Accordion>
        </Box>
    );
};

export default RootCauseAnalysis;
