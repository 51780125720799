import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '@mui/material';

import AutoSaveTextField from '../../../../common/components/AutoSaveTextField';

import { maxValueOfTotalAmount } from '../../../../common/constants/constants';
import { NcrTemplateContext } from '../../../../common/contexts/ncrTemplate/store/context';

const TotalAmountInputComponent = ({ value, disabled }) => {
    const { t } = useTranslation();

    const { setNCR } = useContext(NcrTemplateContext);

    const handleChange = event => {
        setNCR({
            value: maxValueOfTotalAmount < +event?.target?.value ? maxValueOfTotalAmount : +event?.target?.value,
            path: 'basicInformation.quantityData.total',
        });
    };

    return (
        <AutoSaveTextField
            onlyPositiveDigits
            value={value}
            disabled={disabled}
            type="number"
            fullWidth
            onBlur={handleChange}
            label={t('report.form.totalAmount')}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <span>Pcs</span>
                    </InputAdornment>
                ),
            }}
        />
    );
};

const TotalAmountInput = memo(TotalAmountInputComponent);

export default TotalAmountInput;
