import { useMemo, useEffect } from 'react';

import { debounce } from '@mui/material';
import { DEFAULT_DEBOUNCE_DELAY } from '../constants/constants';

export const useDebounce = (callback, delay = DEFAULT_DEBOUNCE_DELAY) => {
    const debouncedFn = useMemo(() => debounce(callback, delay), [delay, callback]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => debouncedFn.clear(), []);

    return debouncedFn;
};
