import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Grid, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';

import UserNameWithAvatar from '../../../../../../common/components/UserNameWithAvatar';

import { usePaginatedWorkplaces } from '../../../../../../common/components/ncr/hooks/usePaginatedWorkplacesOptions';
import { useUserAvatar } from '../../../../../../common/hooks/useUserAvatar';
import { useUserImages } from '../../../../../../common/hooks/useUserImages';
import { notificationsSeverityByRequestStatus } from '../../../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../../../common/providers/notification/store/notification.context';
import { useUserSettingsContext } from '../../../../../../common/providers/userSettings/store/userSettings.context';

import { useAtomicNCR } from '../../../../../inbox/hooks/useAtomicNCR';

import StepDueDate from '../components/inputs/StepDueDate';
import StepDurationInput from '../components/inputs/StepDurationInput';
import StepNumberInput from '../components/inputs/StepNumberInput';
import StepResponsibleDropdown from '../components/inputs/StepResponsibleDropdown';
import StepStatusDropdown from '../components/inputs/StepStatusDropdown';
import StepWorkplaceDropdown from '../components/inputs/StepWorkplaceDropdown';

export const useNcrReworkProcessStepsTableColumns = ({ disableDelete }) => {
    const { t } = useTranslation();

    const { ncrId } = useParams();

    const { language } = useUserSettingsContext();

    const [images] = useUserImages();
    const [avatar] = useUserAvatar();

    const { setNotification } = useNotificationContext();
    const [handleAtomicUpdate, loading] = useAtomicNCR();

    const [workplaceOptions] = usePaginatedWorkplaces();

    const [open, setOpen] = useState(false);
    const [stepData, setStepData] = useState({});

    const handleModalOpen = (stepID, rework) => {
        setStepData({
            stepID,
            rework,
        });

        setOpen(true);
    };

    const handleModalClose = () => {
        setOpen(false);
    };

    const handleRemoveStep = () => {
        if (ncrId && stepData?.stepID && stepData?.rework) {
            handleAtomicUpdate({
                ncrId,
                operation: 'remove',
                path: 'reworks.$[reworkId].processRoutings.$[processRoutingId].processSteps',
                values: {
                    processStep: {
                        _id: stepData?.stepID,
                    },
                },
                childrenIds: {
                    reworkId: stepData?.rework?._id,
                    processRoutingId: stepData?.rework.processRoutings[0]?._id,
                },
            })
                .then(result => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[result.data.atomicNCR.success] });
                })
                .catch(_ => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                });

            handleModalClose();
        }
    };

    const handleUpdateStep = (values, reworkId, processStepId, fieldName, cellApi) => {
        if (ncrId && values && reworkId && processStepId) {
            handleAtomicUpdate({
                ncrId,
                operation: 'update',
                path: 'reworks.$[reworkId].processRoutings.0.processSteps.$[processStepId]',
                values,
                childrenIds: {
                    reworkId,
                    processStepId,
                },
            })
                .then(result => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[result.data.atomicNCR.success] });
                })
                .catch(_ => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                });

            cellApi.stopCellEditMode({ id: processStepId, field: fieldName });
        }
    };

    let columns = [
        {
            field: 'stepNumber',
            headerName: t('ncrInfo.immediate.table.stepNumber'),
            editable: !disableDelete,
            valueGetter: params => params.row.stepNumber,
            renderEditCell: params => {
                const handleUpdate = value => {
                    handleUpdateStep(
                        {
                            processStep: {
                                stepNumber: value || null,
                            },
                        },
                        params.row?.rework?._id,
                        params?.row?.id,
                        'stepNumber',
                        params.api
                    );
                };

                return <StepNumberInput value={params.row.stepNumber} handleUpdate={handleUpdate} />;
            },
        },
        {
            field: 'stepName',
            headerName: t('ncrInfo.immediate.table.stepName'),
            editable: !disableDelete,
            width: 160,
            valueGetter: params => params.row.stepName || 'N/A',
            renderEditCell: params => {
                const handleUpdate = value => {
                    handleUpdateStep(
                        {
                            processStep: {
                                stepName: [
                                    {
                                        language: language?.toUpperCase(),
                                        value: value || null,
                                    },
                                ],
                            },
                        },
                        params.row?.rework?._id,
                        params?.row?.id,
                        'stepName',
                        params.api
                    );
                };

                return <StepNumberInput value={params.row.stepName} handleUpdate={handleUpdate} />;
            },
        },
        {
            field: 'workplaceName',
            headerName: t('ncrInfo.immediate.table.workplace'),
            editable: !disableDelete,
            renderEditCell: params => {
                const handleUpdate = data => {
                    handleUpdateStep(
                        {
                            processStep: {
                                workplaces: [
                                    {
                                        workPlaceName: {
                                            _id: data?.value,
                                            value: data?.title,
                                            language: language?.toUpperCase(),
                                        },
                                    },
                                ],
                            },
                        },
                        params.row?.rework?._id,
                        params?.row?.id,
                        'workplaceName',
                        params.api
                    );
                };

                const currentValue = workplaceOptions?.find(workplace => workplace?.value === params.row.workplaceName?._id);

                return <StepWorkplaceDropdown value={currentValue} handleUpdate={handleUpdate} />;
            },
            renderCell: params => {
                return <span>{params.row.workplaceName?.value || 'N/A'}</span>;
            },
        },
        {
            field: 'dueDate',
            headerName: t('ncrInfo.immediate.table.due'),
            editable: !disableDelete,
            type: 'date',
            renderEditCell: params => {
                const handleUpdate = value => {
                    handleUpdateStep(
                        {
                            processStep: {
                                schedulingData: {
                                    dueDate: value ? value.toISOString() : null,
                                },
                            },
                        },
                        params.row?.rework?._id,
                        params?.row?.id,
                        'dueDate',
                        params.api
                    );
                };

                return (
                    <StepDueDate
                        value={params?.row?.dueDate ? moment(params?.row?.dueDate).toISOString() : ''}
                        handleUpdate={handleUpdate}
                    />
                );
            },
        },
        {
            field: 'stepStatus',
            headerName: t('ncrInfo.immediate.table.stepStatus'),
            editable: !disableDelete,
            valueGetter: params => params.row.stepStatus,
            renderEditCell: params => {
                const handleUpdate = value => {
                    handleUpdateStep(
                        {
                            processStep: {
                                stepStatus: value?.value,
                            },
                        },
                        params.row?.rework?._id,
                        params?.row?.id,
                        'stepStatus',
                        params.api
                    );
                };

                const currentValue = {
                    title: t(`statuses.${params.row.stepStatus.toLowerCase()}`),
                    value: params.row.stepStatus.toLowerCase(),
                };

                return <StepStatusDropdown value={currentValue} handleUpdate={handleUpdate} />;
            },
        },
        {
            field: 'responsible',
            headerName: t('ncrInfo.immediate.table.responsible'),
            width: 140,
            editable: !disableDelete,
            renderEditCell: params => {
                const handleUpdate = event => {
                    handleUpdateStep(
                        {
                            processStep: {
                                responsible: [
                                    {
                                        responsibleID: event[0]?.value || null,
                                    },
                                ],
                            },
                        },
                        params.row?.rework?._id,
                        params?.row?.id,
                        'responsible',
                        params.api
                    );
                };

                return <StepResponsibleDropdown value={[params?.row?.responsible]} handleUpdate={handleUpdate} />;
            },
            renderCell: params => {
                if (!params.row.responsible) {
                    return 'N/A';
                }

                const avatarSrc = avatar.getUserAvatarByThumbnail(images, params.row.responsible.profilePic?.thumbnail);

                return (
                    <UserNameWithAvatar
                        avatarSrc={avatarSrc}
                        userName={`${params.formattedValue.firstName} ${params.formattedValue.name}`}
                    />
                );
            },
        },
        {
            field: 'processTime',
            headerName: t('ncrInfo.immediate.table.duration'),
            editable: !disableDelete,
            renderEditCell: params => {
                const handleUpdate = value => {
                    handleUpdateStep(
                        {
                            processStep: {
                                plannedDurations: {
                                    processTime: +value || null,
                                },
                            },
                        },
                        params.row?.rework?._id,
                        params?.row?.id,
                        'processTime',
                        params.api
                    );
                };

                return <StepDurationInput value={params?.row?.processTime} handleUpdate={handleUpdate} />;
            },
        },
    ];

    if (!disableDelete) {
        columns.push({
            field: 'deleteAction',
            headerName: '',
            maxWidth: 50,
            renderCell: params => {
                return (
                    <div>
                        <Dialog onClose={handleModalClose} open={open}>
                            <DialogTitle>{t('ncrInfo.immediate.removeStepText')}</DialogTitle>
                            <Grid container spacing={4} sx={{ marginBottom: '1em' }}>
                                <Grid item xs={6}>
                                    <Box display="flex" sx={{ justifyContent: 'end' }}>
                                        <Button variant="contained" color="primary" onClick={handleModalClose}>
                                            No
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="flex" sx={{ justifyContent: 'start' }}>
                                        <Button variant="contained" color="error" onClick={handleRemoveStep}>
                                            Yes
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Dialog>

                        <IconButton
                            onClick={() => {
                                handleModalOpen(params.row.id, params.row.rework);
                            }}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </div>
                );
            },
        });
    }

    return [columns, loading];
};
