import { Route, Routes } from 'react-router-dom';
import PermissionError from '../common/components/PermissionError';
import UnknownPage from '../common/components/UnknownPage';

import {
    inboxPageRoles,
    ncrIdOverviewPageRoles,
    ncrOverviewPageRoles,
    ncrUserRoles,
    reportPageRoles,
    tasksPageRoles,
    ticketsKanbanBoardPageRoles,
} from '../common/constants/userRoles';

import UserProfilePage from '../pages/admin/userProfile/UserProfilePage';
import HomeScreen from '../pages/home/HomeScreen';
import InboxPage from '../pages/inbox/InboxPage';
import MyTasksPage from '../pages/my-tasks/MyTasksPage';
import NcrInfoPage from '../pages/ncr-info/NcrInfoPage';
import NcrOverviewPage from '../pages/ncr-overview/NcrOverviewPage';
import ReportPage from '../pages/report/ReportPage';
import TicketsKanbanBoardPage from '../pages/tickets-kanban-board/TicketsKanbanBoardPage';
import AdminPage from './../pages/admin/AdminPage';
import ProtectedRoute from './ProtectedRoute';

const NcrRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<HomeScreen />} />

            <Route
                path="report"
                element={
                    <ProtectedRoute allowedRoles={reportPageRoles}>
                        <ReportPage />
                    </ProtectedRoute>
                }
            />

            <Route
                path="inbox"
                element={
                    <ProtectedRoute allowedRoles={inboxPageRoles}>
                        <InboxPage />
                    </ProtectedRoute>
                }
            />

            <Route
                path="ncr-overview"
                element={
                    <ProtectedRoute allowedRoles={ncrOverviewPageRoles}>
                        <NcrOverviewPage />
                    </ProtectedRoute>
                }
            />

            <Route
                path="ncr-overview/:ncrId"
                element={
                    <ProtectedRoute allowedRoles={ncrIdOverviewPageRoles}>
                        <NcrInfoPage />
                    </ProtectedRoute>
                }
            />

            <Route
                path="ncr-overview/:ncrId/:sectionType/:sectionId"
                element={
                    <ProtectedRoute allowedRoles={ncrIdOverviewPageRoles}>
                        <NcrInfoPage />
                    </ProtectedRoute>
                }
            />

            <Route
                path="my-tasks"
                element={
                    <ProtectedRoute allowedRoles={tasksPageRoles}>
                        <MyTasksPage />
                    </ProtectedRoute>
                }
            />

            <Route
                path="tickets-kanban-board"
                element={
                    <ProtectedRoute allowedRoles={ticketsKanbanBoardPageRoles}>
                        <TicketsKanbanBoardPage />
                    </ProtectedRoute>
                }
            />

            <Route
                path="admin/:activeTab"
                element={
                    <ProtectedRoute allowedRoles={[ncrUserRoles.admin]}>
                        <AdminPage />
                    </ProtectedRoute>
                }
            />
            <Route path="userProfile/:userId" element={<UserProfilePage />} />

            <Route path="403" element={<PermissionError />} />

            <Route path="*" element={<UnknownPage />} />
        </Routes>
    );
};

export default NcrRoutes;
