import React from 'react';

import { Button, Typography } from '@mui/material';

import { ncrStatus } from '../../enums/ncrStatus';

const color = {
    [ncrStatus.DONE]: {
        title: 'Approved',
        color: 'success',
    },
    [ncrStatus.REJECTED]: {
        title: 'Rejected',
        color: 'error',
    },
    [ncrStatus.IN_WORK]: {
        title: 'In Work',
        color: 'primary',
    },
    [ncrStatus.OPEN]: {
        title: 'Opened',
        color: 'info',
    },
    default: {
        title: 'N/A',
        color: 'info',
    },
};

const NcrSectionStatus = ({ status }) => {
    if (!status) {
        return null;
    }

    const colorValue = color[status]?.color || color.default.color;
    const statusValue = color[status]?.title || color.default.title;

    return (
        <Button variant="contained" color={colorValue}>
            <Typography variant="button">{statusValue}</Typography>
        </Button>
    );
};

export default NcrSectionStatus;
