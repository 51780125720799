import React from 'react';

import { styled } from '@mui/material/styles';

const StyledSpan = styled('span')({
    fontSize: '12px',
    width: '100%',
    padding: '2px',
    marginRight: '6px',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
});

const TagComponent = ({ text, backgroundColor, textColor }) => {
    return <StyledSpan sx={{ background: backgroundColor, color: textColor }}>{text}</StyledSpan>;
};

export default TagComponent;
