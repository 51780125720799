import { Container, Stack } from '@mui/material';

import NCRTemplateProvider from '../../common/contexts/ncrTemplate/NCRTemplateProvider';
import NCRInfoProvider from './store/NCRInfoContext';

import NcrInfoTabs from './components/tabs/NcrInfoTabs';

const NcrInfoPage = () => {
    return (
        <Container maxWidth="md" style={{ paddingBottom: '2em' }}>
            <NCRTemplateProvider>
                <NCRInfoProvider>
                    <Stack spacing={4}>
                        <NcrInfoTabs />
                    </Stack>
                </NCRInfoProvider>
            </NCRTemplateProvider>
        </Container>
    );
};

export default NcrInfoPage;
