import { useParams } from 'react-router-dom';

import { Box, Container, Paper } from '@mui/material';

import AdminPageTabs from './AdminPageTabs';
import UsersPageWrapper from './users/components/UsersPageWrapper';
import BinPageWrapper from './users/components/BinPageWrapper';

import UsersTableProvider from './users/store/usersTableProvider';

import { adminTabsEnum } from './constants';

const tabPanels = {
    [adminTabsEnum.USERS]: <UsersPageWrapper />,
    [adminTabsEnum.NCR_BIN]: <BinPageWrapper />,
};

const NcrOverviewPage = () => {
    const { activeTab } = useParams();

    return (
        <UsersTableProvider>
            <Container
                maxWidth="xl"
                style={{ paddingBottom: '4em', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Paper elevation={3} style={{ width: 'fit-content', padding: '0.5rem' }}>
                    <AdminPageTabs />
                </Paper>

                <Paper elevation={3}>
                    <Box width="100%" p="1.5em">
                        {tabPanels[activeTab]}
                    </Box>
                </Paper>
            </Container>
        </UsersTableProvider>
    );
};

export default NcrOverviewPage;
