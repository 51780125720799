import { useTranslation } from 'react-i18next';

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Button } from '@mui/material';

import { useNcrOverviewContext } from '../../store/NCROverviewProvider';

const NcrOverviewClearButton = () => {
    const { t } = useTranslation();
    const { resetNcrOverviewFilters } = useNcrOverviewContext();

    return (
        <Button variant="outlined" color="primary" startIcon={<ClearRoundedIcon />} onClick={resetNcrOverviewFilters}>
            {t('common.action-buttons.clear')}
        </Button>
    );
};

export default NcrOverviewClearButton;
