import React, { memo } from 'react';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 22,
        padding: 24,
        color: theme.palette.textColor.primary,

        '& .MuiSvgIcon-root': {
            fontSize: 52,
            marginRight: 20,
        },
    },
}));

const BrowserWarning = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <ErrorOutlineOutlinedIcon />
            {t('common.browser-warning')}
        </div>
    );
};

export default memo(BrowserWarning);
