import { useLazyQuery } from '@apollo/client';

import { GET_PAGINATED_CAPA_TICKET } from '../../../../../../common/gqlQueries/capaTickets';

export const useLazyGetCapaTicket = () => {
    const [getLazyCapaTickets, { data, loading, error, fetchMore }] = useLazyQuery(GET_PAGINATED_CAPA_TICKET);

    const handleLazyGetCapaTickets = ({ filter, skip, limit, sort }) => {
        return getLazyCapaTickets({
            fetchPolicy: 'cache-and-network',
            variables: {
                filter,
                skip,
                limit,
                sort,
            },
        });
    };

    return [handleLazyGetCapaTickets, { data, loading, error, fetchMore }];
};
