import { memo } from 'react';

import { Box, Paper, Stack, Typography } from '@mui/material';

import { useGetSingleNCR } from '../../hooks/useGetSingleNCR';

import { useInboxContext } from '../../store/inbox.context';

import NCRInboxPaperTabs from './components/NCRInboxPaperTabs';

const NCRInboxPaperComponent = ({ loading }) => {
    const { selectedNCRId } = useInboxContext();

    const [ncr, ncrLoading] = useGetSingleNCR(selectedNCRId);

    return (
        <Paper width="100%">
            <Box padding="1em">
                <Stack spacing={2} direction="column">
                    <Typography textAlign="center" variant="h6" minHeight={32}>
                        {ncr?.basicInformation?.externalReference}
                    </Typography>
                    <NCRInboxPaperTabs ncr={ncr} loading={loading || ncrLoading} />
                </Stack>
            </Box>
        </Paper>
    );
};

const NCRInboxPaper = memo(NCRInboxPaperComponent);

export default NCRInboxPaper;
