import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { stepMeasureStatusOptions } from '../../../../../../../../../../common/enums/stepStatus';

const MeasureStatusDropdown = ({ value, handleUpdate }) => {
    const { t } = useTranslation();

    const handleChange = data => {
        handleUpdate(data?.value);
    };

    const mappedValue = useMemo(() => stepMeasureStatusOptions?.find(status => status?.value === value), [value]);

    return (
        <Autocomplete
            value={mappedValue}
            options={stepMeasureStatusOptions}
            getOptionLabel={option => option.label}
            variant="outlined"
            fullWidth
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={params => <TextField {...params} label={t('ncrInfo.corrective.status')} />}
            onChange={(_, data) => handleChange(data)}
        />
    );
};

export default MeasureStatusDropdown;
