import React from 'react';

import { Box, Container, Paper, Stack } from '@mui/material';

import TicketsKanbanBoardContainer from './components/TicketsKanbanBoardContainer';
import TicketsKanbanBoardFilters from './components/TicketsKanbanBoardFilters';
import CapaTicketsProvider from './store/TicketsKanbanBoardProvider';

const TicketsKanbanBoardPage = () => {
    return (
        <CapaTicketsProvider>
            <Container maxWidth="lg" sx={{ minWidth: '1100px' }}>
                <Paper elevation={3}>
                    <Box width="100%" p="1.5em" overflow="auto">
                        <Stack spacing={4}>
                            <TicketsKanbanBoardFilters />
                            <TicketsKanbanBoardContainer />
                        </Stack>
                    </Box>
                </Paper>
            </Container>
        </CapaTicketsProvider>
    );
};

export default TicketsKanbanBoardPage;
