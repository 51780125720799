import { Box, Typography } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { useTranslation } from 'react-i18next';

import Accordion from '../../../../../../common/components/accordion/Accordion';
import AccordionSummary from '../../../../../../common/components/accordion/AccordionSummary';
import CostsTable from './CostsTable';

import { useCollapse } from '../../../../../../common/hooks/useCollapse';

const CostsSection = ({ ncr, loading }) => {
    const { t } = useTranslation();

    const [expanded, collapse] = useCollapse(true);

    return (
        <Box style={{ borderRadius: 16 }}>
            <Accordion disableGutters elevation={3} sx={{ width: '100%', padding: '1.5em' }} expanded={expanded} onChange={collapse}>
                <AccordionSummary>
                    <Typography variant="h6" noWrap component="div">
                        {t('ncrInfo.valuation.costs')}
                    </Typography>
                </AccordionSummary>
                <MuiAccordionDetails sx={{ padding: '1em 0' }}>
                    <CostsTable ncr={ncr} loading={loading} />
                </MuiAccordionDetails>
            </Accordion>
        </Box>
    );
};

export default CostsSection;
