import { memo } from 'react';

import Notification from '../Notification';

import { NotificationProvider } from '../store/notification.context';

const withNotificationMessageProvider = ComponentComposition => {
    return memo(props => {
        return (
            <NotificationProvider>
                <Notification />
                <ComponentComposition {...props} />
            </NotificationProvider>
        );
    });
};

export default withNotificationMessageProvider;
