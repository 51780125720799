import moment from 'moment';
import { sortBy } from 'lodash';
import JsBarcode from 'jsbarcode';

import { DDMMYYYY, DDMMYYYYWithHours } from '../../../../../../common/constants/dateFormatConstants';

import { getValueByLanguage } from '../../../../../../utils/common';

import i18n from '../../../../../../utils/i18n';

const { t } = i18n;

function textToBase64Barcode(text) {
    var canvas = document.createElement('canvas');
    JsBarcode(canvas, text, { format: 'CODE39', displayValue: false });
    return canvas.toDataURL('image/png');
}

const getReworkStepsHeader = (reference, ncrId, responsibleName, currentDate, description) => {
    return {
        columns: [
            {
                stack: [
                    {
                        layout: 'noBorders',
                        table: {
                            widths: ['*', 'auto'],
                            body: [
                                [
                                    {
                                        alignment: 'left',
                                        text: t('ncrInfo.immediate.reworkRequest'),
                                        style: 'header',
                                    },
                                    {
                                        alignment: 'right',
                                        text: `${t('common.inputs.reference')}: ${reference}      NCR ID: ${ncrId}`,
                                        style: 'header',
                                    },
                                ],
                                [
                                    {
                                        alignment: 'left',
                                        text: `${t('ncrInfo.immediate.table.responsible')}: ${responsibleName}`,
                                        style: 'headerSubdata',
                                    },
                                    {
                                        alignment: 'right',
                                        text: `${currentDate}`,
                                        style: 'headerSubdata',
                                    },
                                ],
                            ],
                        },
                        margin: [25, 20, 25, 0],
                    },
                    {
                        alignment: 'left',
                        text: `Description: ${description}`,
                        style: 'headerSubdata',
                        margin: [25, 0, 25, 0],
                    },
                ],
            },
        ],
    };
};

const getReworkStepsTable = (reworkSteps, language) => {
    const tableHeader = [
        {
            columns: [
                {
                    width: 100,
                    text: '',
                    style: 'tableHeader',
                },
                {
                    width: 100,
                    text: t('ncrInfo.immediate.table.stepNumber'),
                    style: 'tableHeader',
                },
                {
                    width: 200,
                    text: t('ncrInfo.immediate.table.stepName'),
                    style: 'tableHeader',
                },
                {
                    width: 100,
                    text: t('ncrInfo.immediate.table.workplace'),
                    style: 'tableHeader',
                },
                {
                    width: 80,
                    text: t('ncrInfo.immediate.table.due'),
                    style: 'tableHeader',
                },
                {
                    width: 70,
                    text: t('ncrInfo.immediate.table.stepStatus'),
                    style: 'tableHeader',
                },
                {
                    width: 100,
                    text: t('ncrInfo.immediate.table.responsible'),
                    style: 'tableHeader',
                },
                {
                    width: 60,
                    text: `${t('ncrInfo.immediate.table.duration')} (h)`,
                    style: 'tableHeader',
                },
            ],
            margin: [0, 15, 0, 0],
        },
        {
            canvas: [{ type: 'line', x1: 0, y1: 5, x2: 875 - 2 * 40, y2: 5, lineWidth: 1 }],
            margin: [0, 8, 0, 8],
        },
    ];

    let table = [...tableHeader];

    if (reworkSteps?.length > 0) {
        const sortedSteps = sortBy(reworkSteps, ['stepNumber']);

        sortedSteps.forEach(item => {
            table.push({
                columns: [
                    {
                        width: 100,
                        stack: [
                            {
                                image: item?.stepNumber ? textToBase64Barcode(item?.stepNumber) : '',
                                alignment: 'center',
                                width: 100,
                            },
                        ],
                    },
                    {
                        width: 100,
                        text: item?.stepNumber || 'N/A',
                        style: 'tableBody',
                    },
                    {
                        width: 200,
                        text: getValueByLanguage(item?.stepName, language) || 'N/A',
                        style: 'tableBody',
                    },
                    {
                        width: 100,
                        text: !!item?.workplaces[0]?.workPlaceName[0]?.value ? item?.workplaces[0]?.workPlaceName[0]?.value : 'N/A',
                        style: 'tableBody',
                    },
                    {
                        width: 80,
                        text: item?.schedulingData?.dueDate ? moment(item?.schedulingData?.dueDate).format(DDMMYYYY) : 'N/A',
                        style: 'tableBody',
                    },
                    {
                        width: 70,
                        text: item?.stepStatus?.toLowerCase() || 'N/A',
                        style: 'tableBody',
                    },
                    {
                        width: 100,
                        text: !!item.resolvedResponsible[0]
                            ? `${item.resolvedResponsible[0]?.firstName || ''} ${item.resolvedResponsible[0]?.name || ''}`
                            : 'N/A',
                        style: 'tableBody',
                    },
                    {
                        width: 60,
                        text: item?.plannedDurations?.processTime || 'N/A',
                        style: 'tableBody',
                    },
                ],
                margin: [0, 8, 0, 0],
            });
            table.push({
                canvas: [{ type: 'line', x1: 0, y1: 5, x2: 875 - 2 * 40, y2: 5, lineWidth: 0.1 }],
                margin: [0, 2, 0, 2],
            });
        });
    }

    return table;
};

const getReworkStepsStyles = () => {
    return {
        header: {
            fontSize: 12,
            bold: true,
        },
        headerSubdata: {
            fontSize: 10,
        },
        tableHeader: {
            fontSize: 10,
            bold: true,
        },
        tableBody: {
            fontSize: 10,
        },
    };
};

export const getReworkStepsPdfDefinition = (ncr, reworkId, language) => {
    const currentDate = moment().format(DDMMYYYYWithHours);

    const responsibleName = `${ncr?.resolvedResponsible[0]?.firstName} ${ncr?.resolvedResponsible[0]?.name}`;

    const currentRework = ncr?.reworks?.find(rework => rework?._id === reworkId);

    return {
        header: getReworkStepsHeader(
            ncr?.basicInformation?.externalReference,
            ncr?.name,
            responsibleName,
            currentDate,
            ncr?.basicInformation?.description
        ),
        content: getReworkStepsTable(currentRework?.processRoutings[0]?.processSteps, language),

        pageOrientation: 'landscape',
        pageMargins: [25, 70, 25, 40],

        styles: getReworkStepsStyles(),
    };
};
