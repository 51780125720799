import { BrowserRouter, Outlet } from 'react-router-dom';

import { ConfirmProvider } from 'material-ui-confirm';

import GeneralLayout from './common/components/layout/General';
import NcrRoutes from './routes/Routes';

import withApolloProvider from './common/providers/ApolloProvider';
import withAuthenticator from './common/providers/AuthenticatorProvider';
import withDateLocalizationProvider from './common/providers/DateLocalizationProvider';
import withNotificationMessageProvider from './common/providers/notification/hocs/NotificationMessageProvider';
import withThemeProvider from './common/providers/ThemeProvider';
import withTranslationProvider from './common/providers/TranslationProvider';
import withUserSettingsProvider from './common/providers/userSettings/UserSettingsProvider';

import { awsAuth } from './utils/awsAuth';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { ErrorBoundary } from './common/hocs/ErrorBoundary';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

awsAuth();

const App = () => {
    return (
        <BrowserRouter>
            <ErrorBoundary>
                <ConfirmProvider>
                    <GeneralLayout>
                        <NcrRoutes />
                        <Outlet />
                    </GeneralLayout>
                </ConfirmProvider>
            </ErrorBoundary>
        </BrowserRouter>
    );
};

export default withAuthenticator(
    withApolloProvider(
        withUserSettingsProvider(
            withTranslationProvider(withThemeProvider(withNotificationMessageProvider(withDateLocalizationProvider(App))))
        )
    )
);
