import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import Accordion from '../../../../../common/components/accordion/Accordion';
import AccordionSummary from '../../../../../common/components/accordion/AccordionSummary';
import AttachmentSection from '../../../../inbox/components/ncrInboxPaper/components/tabs/infoTab/components/AttachmentsSections';
import { ncrStatus } from '../../../../../common/enums/ncrStatus';

const NcrAttachmentsSection = ({ ncr }) => {
    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(true);

    const handleChange = (_event, newExpanded) => {
        setExpanded(newExpanded);
    };

    return (
        <Box style={{ borderRadius: 16 }}>
            <Accordion disableGutters elevation={3} sx={{ width: '100%', padding: '1.5em' }} expanded={expanded} onChange={handleChange}>
                <AccordionSummary>
                    <Typography variant="h6" noWrap component="div">
                        {t('ncrInfo.attachments')}
                    </Typography>
                </AccordionSummary>
                <MuiAccordionDetails sx={{ padding: '1em 0' }}>
                    <AttachmentSection mediaInputs={ncr?.basicInformation?.mediaInputs} disabled={ncr?.status === ncrStatus.DONE} />
                </MuiAccordionDetails>
            </Accordion>
        </Box>
    );
};

export default NcrAttachmentsSection;
