import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';

import UploadNewAttachment from '../../../../common/components/fileUploader/UploadNewAttachment';

import { allowedFileTypes } from '../../../../common/constants/fileUpload';

import { NcrTemplateContext } from '../../../../common/contexts/ncrTemplate/store/context';
import { UserSettingsContext } from '../../../../common/providers/userSettings/store/userSettings.context';

import { GET_FILES } from '../../../../common/gqlQueries/queries';

import { getFileName } from '../../../../common/utils/fileManagement';
import { useJwtToken } from '../../../../utils/awsAuth';
import { isArrayWithItems } from '../../../../utils/common';
import createMongoObjectId from '../../../../utils/mongo';

import { AWS_S3_BUCKET_NCR_MEDIA_FOLDER } from '../../../../configs/env';
import AttachmentItem from './AttachmentItem';

const CreateNCRAttachments = () => {
    const { t } = useTranslation();
    const token = useJwtToken();

    const { themeMode, language } = useContext(UserSettingsContext);
    const { updateNcrTemplateAttachments, ncr } = useContext(NcrTemplateContext);

    const accessRootFolder = `${AWS_S3_BUCKET_NCR_MEDIA_FOLDER}/${ncr?._id}/mediaInput`;

    const [newAttachments, setNewAttachments] = useState([]);

    const [mediaFiles, setMediaFiles] = useState();

    const handleAddAttachment = (uppy, imageUrl) => {
        const files = Object.entries(uppy).map(([_uppyId, file]) => {
            return {
                mediaName: file.name,
                mediaType: file.type,
                imageUrl: file.uploadURL,
            };
        });

        setNewAttachments(prev => {
            return [...prev, ...files];
        });
    };

    const handleRemoveAttachment = mediaId => _event => {
        setMediaFiles(prev => prev.filter(file => file._id !== mediaId));
        updateNcrTemplateAttachments(mediaFiles.filter(file => file._id !== mediaId));
    };

    const [fetchFiles] = useMutation(GET_FILES);

    useEffect(() => {
        fetchFiles({
            variables: {
                accessRootFolder,
            },
        }).then(images => {
            if (isArrayWithItems(newAttachments)) {
                const mediaFiles = newAttachments.map(mediaInput => {
                    return {
                        _id: createMongoObjectId(),
                        ...mediaInput,
                        ...images?.data?.createSignedGetURL?.find(file => mediaInput.mediaName === getFileName(file.url)),
                    };
                });

                setMediaFiles(mediaFiles);
                updateNcrTemplateAttachments(mediaFiles);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newAttachments]);

    return (
        <>
            <Box paddingBottom="1em">
                <UploadNewAttachment
                    useWebcam
                    label={t('report.attachments.attachments')}
                    language={language}
                    themeMode={themeMode}
                    token={token}
                    targetFolder={accessRootFolder}
                    addAttachments={handleAddAttachment}
                    allowedTypes={[...allowedFileTypes.attachments, ...allowedFileTypes.videos]}
                />
            </Box>
            <Box sx={{ marginBottom: '1em' }}>
                {isArrayWithItems(mediaFiles) &&
                    mediaFiles?.map(media => {
                        return <AttachmentItem key={media._id} handleRemoveAttachment={handleRemoveAttachment} media={media} />;
                    })}
            </Box>
        </>
    );
};

export default CreateNCRAttachments;
