import { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { useGetPaginatedFailureCategoriesOptions } from '../../../../common/components/ncr/hooks/useGetPaginatedFailureCategoriesOptions';
import { NcrTemplateContext } from '../../../../common/contexts/ncrTemplate/store/context';

const FailureCodeDropdownComponent = ({ value = null, disabled }) => {
    const { t } = useTranslation();

    const { setNCR } = useContext(NcrTemplateContext);

    const [failureCategoriesOptions, loadingFailureCategories] = useGetPaginatedFailureCategoriesOptions();

    const handleChange = data => {
        setNCR({ value: data?.name, path: 'basicInformation.failureCategory.name' });
        setNCR({ value: data?.failureMode, path: 'basicInformation.failureCategory.failureMode' });
    };

    const memoizedValue = useMemo(() => {
        return {
            name: value?.name || '',
            failureMode: value?.failureMode || '',
        };
    }, [value]);

    return (
        <Autocomplete
            value={memoizedValue?.name ? memoizedValue : null}
            disabled={disabled}
            loading={loadingFailureCategories}
            options={failureCategoriesOptions}
            groupBy={option => option.name}
            getOptionLabel={option => option.failureMode}
            isOptionEqualToValue={(option, value) => option.failureMode === value.failureMode}
            renderInput={params => <TextField {...params} label={t('report.form.failureCode')} />}
            onChange={(_, data) => handleChange(data)}
        />
    );
};
const FailureCodeDropdown = memo(FailureCodeDropdownComponent);

export default FailureCodeDropdown;
