import React, { useCallback } from 'react';
import SearchInput from '../../../../../common/components/SearchInput';
import { useUsersTableContext } from '../../store/usersTableProvider';

const UsersTableSearchInput = () => {
    const { searchFilter, setUsersTableSearchFilter } = useUsersTableContext();

    const memoizedChangeHandler = useCallback(
        event => {
            setUsersTableSearchFilter(event?.target?.value);
        },
        [setUsersTableSearchFilter]
    );

    return (
        <SearchInput
            fullWidth={true}
            value={searchFilter}
            onBlur={memoizedChangeHandler}
            onKeyPress={event => {
                event.key === 'Enter' && memoizedChangeHandler(event);
            }}
            onClear={() => setUsersTableSearchFilter('')}
        />
    );
};

export default UsersTableSearchInput;
