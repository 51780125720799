import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Contextmenu } from '@5thindustry/factory_portal.components.contextmenu';

import { Card, CardHeader, LinearProgress, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';

import { useCascadeUpdateManyNCRs } from '../../../../ncr-overview/hooks/useCascadeUpdateManyNCRs';
import { useInboxContext } from '../../../store/inbox.context';

import { DDMMyyyy } from '../../../../../common/constants/dateFormatConstants';

const NCRListItem = ({ ncr, selectedNCRId }) => {
    const { t } = useTranslation();

    const { setSelectedNCR } = useInboxContext();

    const [cascadeUpdate, updateLoading] = useCascadeUpdateManyNCRs();

    const handleCardClick = () => {
        setSelectedNCR(ncr._id);
    };

    const handleUpdateDeletedArchived = field => () => {
        cascadeUpdate([ncr._id], field, true).then(result => {
            if (result.data.cascadeUpdateManyNCRs.success) {
                setSelectedNCR(null);
            }
        });
    };

    return (
        <Card
            style={{ boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)', borderRadius: 0, cursor: 'pointer' }}
            sx={{
                backgroundColor: ncr._id === selectedNCRId ? 'rgba(25, 118, 210, 0.04)' : 'rgba(255, 255, 255, 0.002)',
            }}>
            <CardHeader
                action={
                    <Contextmenu
                        options={[
                            {
                                icon: <DeleteIcon />,
                                key: 'delete',
                                label: t('common.context-menu-options.delete'),
                                onClick: handleUpdateDeletedArchived('deleted'),
                            },
                            {
                                icon: <ArchiveIcon />,
                                key: 'archive',
                                label: t('common.context-menu-options.archive'),
                                onClick: handleUpdateDeletedArchived('archived'),
                            },
                        ]}
                    />
                }
                subheader={
                    <Typography
                        color="textColor.primary"
                        fontSize={14}
                        fontWeight={400}
                        letterSpacing="0.25px"
                        onClick={handleCardClick}>
                        {moment(ncr.ncrCreatedBy.createdAt).format(DDMMyyyy)} | {ncr.priority || 0}
                    </Typography>
                }
                title={
                    <Typography
                        color="textColor.primary"
                        fontSize={16}
                        fontWeight={400}
                        letterSpacing="0.15px"
                        onClick={handleCardClick}>
                        {ncr.basicInformation?.externalReference?.slice(0, 22) || 'N/A'}
                    </Typography>
                }
            />
            <LinearProgress color="primary" sx={{ visibility: updateLoading ? 'visible' : 'hidden' }} />
        </Card>
    );
};

export default NCRListItem;
