import { createContext, useContext } from 'react';

import { ncrTemplateInitialState } from './reducer';

export const NcrTemplateContext = createContext(ncrTemplateInitialState);

export const useNcrTemplateContext = () => {
    const context = useContext(NcrTemplateContext);

    if (context === undefined) {
        return ncrTemplateInitialState;
    }

    return context;
};
