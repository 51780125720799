import { useQuery } from '@apollo/client';

import { GET_ORGANIZATIONS } from '../../../common/gqlQueries/queries';
import { useUserSettingsContext } from '../../../common/providers/userSettings/store/userSettings.context';

export const useGetOrganizations = () => {
    const { userData } = useUserSettingsContext();

    const { data, loading, error } = useQuery(GET_ORGANIZATIONS);

    const organizations =
        data?.getOrganizations?.organizations?.filter(organization =>
            userData?.organizations?.some(org => org?.organizationID === organization?._id)
        ) ?? [];

    return { organizations, loading, error };
};
