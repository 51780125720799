import { gql } from '@apollo/client';

export const GET_SINGLE_NCR = gql`
    query GetSingleNCR($getSingleNcrId: ID!) {
        getSingleNCR(id: $getSingleNcrId) {
            code
            success
            ncr {
                _id
                name
                capaDetails {
                    _id
                    rootCause
                    impactDescription
                }
                follower
                priority
                tags {
                    _id
                    name
                    colorCode
                    usedBy
                }
                resolvedFollower {
                    _id
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                resolvedNcrCreatedBy {
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                resolvedResponsible {
                    _id
                    firstName
                    name
                    profilePic {
                        thumbnail
                    }
                }
                basicInformation {
                    externalReference
                    externalReferenceLocation
                    mediaInputs {
                        _id
                        mediaName
                        mediaLabel
                        mediaType
                        attachmentType
                    }
                    quantityData {
                        defected
                        total
                    }
                    failureCategory {
                        name
                        failureMode
                    }
                    description
                    customInputElements {
                        _id
                        customInputElementType
                        name
                        placeholder {
                            _id
                            language
                            value
                        }
                        label {
                            _id
                            language
                            value
                        }
                        choices {
                            value
                            label {
                                _id
                                language
                                value
                            }
                        }
                        required
                        readOnly
                        min
                        max
                        value
                        defaultValue
                        currencyCode
                    }
                }
            }
        }
    }
`;
