import React from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../../../../../../../common/components/AutoSaveTextField';

const RootCauseDescription = ({ capaDetail, handleUpdate, disabled }) => {
    const { t } = useTranslation();

    const handleChange = event => {
        handleUpdate('capaDetails.$[capaDetailsId]', {
            capaDetails: { impactDescription: event?.target?.value || '', rootCause: capaDetail?.rootCause },
        });
    };

    return (
        <AutoSaveTextField
            disabled={disabled}
            onBlur={handleChange}
            value={capaDetail?.impactDescription || ''}
            label={t('common.inputs.description')}
            rows={3}
            multiline
            fullWidth
        />
    );
};

export default RootCauseDescription;
