import { useTranslation } from 'react-i18next';

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Button } from '@mui/material';

import { useNcrMyTasksContext } from '../../store/NCRMyTasksProvider';

const MyTasksClearButton = () => {
    const { t } = useTranslation();
    const { resetNcrMyTasksFilters } = useNcrMyTasksContext();

    return (
        <Button variant="outlined" color="primary" startIcon={<ClearRoundedIcon />} onClick={resetNcrMyTasksFilters}>
            {t('common.action-buttons.clear')}
        </Button>
    );
};

export default MyTasksClearButton;
