import { useMemo, useState } from 'react';

import { useGetOrganizationData } from '../../../../common/hooks/useGetOrganizationData';
import { useGetUsersData } from './useGetUsersData';
import { useUsersTableColumns } from './useUsersTableColumns';

import { getFilteredNcrUserRoles } from '../../../../common/constants/userRoles';
import { tableColumnComparers } from '../../../../utils/sorting';
import { getTeamById } from '../../../../utils/userRoles';
import { isArrayWithItems } from './../../../../utils/common';

export const useUsersTableData = () => {
    const [data, loading, filterBy] = useGetUsersData();
    const [organizationData] = useGetOrganizationData();

    const [columns, userOverlayData, setUserOverlayData] = useUsersTableColumns();

    const [sortModel, setSortModel] = useState(
        {
            field: 'lastName',
            sort: 'desc',
        },
    );

    const onSortModelChange = (newModel) => {
        setSortModel(newModel[0])
    };

    const tableSort = array => {
        if (sortModel.field) {
            const column = columns.find(col => col.field === sortModel.field);
            const cmp = tableColumnComparers[column.columnType].cmp;

            return array.sort((a, b) => (sortModel.sort === 'desc' ? cmp(a, b, column.field) : -cmp(a, b, column.field)));
        }

        return array;
    };

    const rows = useMemo(() => {
        if (!isArrayWithItems(data)) {
            return [];
        }

        const tableData = data.map(user => {
            return {
                rowId: user._id,
                avatar: user.avatar?.thumbnail,
                lastName: user.name,
                firstName: user.firstName,
                email: user.email,
                roles: getFilteredNcrUserRoles(user.roles),
                teams: user.organizations
                    .map(organization => organization.teamsIDs.map(team => getTeamById(team, organizationData)).filter(team => team))
                    .flat(),
                blocked: user.disabled,
                contextMenu: user,
            };
        });

        return tableSort(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, organizationData, sortModel]);

    return [rows, columns, loading, filterBy, userOverlayData, setUserOverlayData, onSortModelChange];
};
