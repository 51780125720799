import { useCallback, useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';

import { GET_OVERVIEW_PAGINATED_NCRS } from '../operations/queries/getOverviewPaginatedNCRs';

import { paginatedNCRsQuery } from '../../inbox/cache';

import { ncrArchiveFilterValue } from '../../../common/enums/ncrArchivedOptions';
import { DATE_REQUEST_FORMAT, DEFAULT_TABLE_PAGE_SIZE } from '../../../common/constants/constants';
import { ncrStatus } from '../../../common/enums/ncrStatus';

export const useLazyGetOverviewPaginatedNCRs = () => {
    const [getSingleNCR, { data, loading }] = useLazyQuery(GET_OVERVIEW_PAGINATED_NCRS);

    const fetchData = useCallback(
        (page, searchFilter, statusFilter, from, to, failureCategoryFilter, archivedFilter, orderBy, order) => {
            const timestampFrom = !!from && dayjs(from).format(DATE_REQUEST_FORMAT);
            const timestampTo = !!to && dayjs(to).format(DATE_REQUEST_FORMAT);

            let dateRange = undefined;

            if (timestampFrom && timestampTo) {
                dateRange = {
                    $gte: timestampFrom,
                    $lte: timestampTo,
                };
            }

            if (!timestampFrom && timestampTo) {
                dateRange = {
                    $lte: timestampTo,
                };
            }

            if (timestampFrom && !timestampTo) {
                dateRange = {
                    $gte: timestampFrom,
                };
            }

            let sort = undefined;
            const sortOrder = order === 'asc' ? 1 : -1;

            if (orderBy === 'failureCategory') {
                sort = JSON.stringify({
                    'basicInformation.failureCategory.name': sortOrder,
                    'basicInformation.failureCategory.failureMode': sortOrder,
                });
            } else {
                sort = JSON.stringify({
                    [orderBy]: sortOrder,
                });
            }

            let filter = undefined;

            if (failureCategoryFilter) {
                filter = {
                    $and: [
                        {
                            'basicInformation.failureCategory.name': {
                                $regex: failureCategoryFilter?.name?.replaceAll(' ', '.*') || undefined,
                                $options: 'xi',
                            },
                        },
                        {
                            'basicInformation.failureCategory.failureMode': {
                                $regex: failureCategoryFilter?.failureMode?.replaceAll(' ', '.*') || undefined,
                                $options: 'xi',
                            },
                        },
                    ],
                };
            }

            const variables = {
                skip: DEFAULT_TABLE_PAGE_SIZE * page,
                limit: DEFAULT_TABLE_PAGE_SIZE,
                sort,
                filter: JSON.stringify({
                    ...ncrArchiveFilterValue[archivedFilter],
                    deleted: false,
                    status: statusFilter,
                    'ncrCreatedBy.createdAt': dateRange,
                    $and: [
                        { ...filter },
                        { status: { $nin: [ncrStatus.OPEN, ncrStatus.REJECTED] } },
                        {
                            $or: [
                                {
                                    name: {
                                        $regex: searchFilter?.replaceAll(' ', '.*'),
                                        $options: 'xi',
                                    },
                                },
                                {
                                    'basicInformation.externalReference': {
                                        $regex: searchFilter?.replaceAll(' ', '.*'),
                                        $options: 'xi',
                                    },
                                },
                            ],
                        },
                    ],
                }),
            };

            getSingleNCR({
                fetchPolicy: 'network-only',
                variables,
            }).then(() => {
                paginatedNCRsQuery({
                    query: GET_OVERVIEW_PAGINATED_NCRS,
                    variables,
                });
            });
        },
        [getSingleNCR]
    );

    const items = useMemo(() => data?.getPaginatedNCRs, [data?.getPaginatedNCRs]);

    return [items, loading, fetchData];
};
