import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../../../../../../../../common/components/AutoSaveTextField';

const RequestInputComponent = ({ handleUpdate, value, disabled }) => {
    const { t } = useTranslation();

    const handleBlur = event => {
        handleUpdate(`clearances.$[clearanceId]`, {
            clearance: { clearanceRequest: event.target.value },
        });
    };

    return (
        <AutoSaveTextField
            value={value}
            disabled={disabled}
            label={t('ncrInfo.immediate.requestSpecialReleasePlaceholder')}
            rows={2}
            onBlur={handleBlur}
            onKeyPress={event => {
                event.key === 'Enter' && handleBlur(event);
            }}
            multiline
            fullWidth
        />
    );
};

const RequestInput = memo(RequestInputComponent);

export default RequestInput;
