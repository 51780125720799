import { memo, useEffect, useState } from 'react';

export const withAutoSaveProvider = ComposedComponent => {
    return memo(({ value, onSave, onlyPositiveDigits, ...props }) => {
        const [newValue, setNewValue] = useState('');

        useEffect(() => {
            if (value !== newValue) {
                setNewValue(value);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value]);

        const handleChange = event => {
            const { value: nextValue } = event.target;

            if (onlyPositiveDigits) {
                const newValue = nextValue >= 0 ? nextValue : 0;

                setNewValue(newValue);

                if (onSave) {
                    onSave(newValue);
                }
            } else {
                setNewValue(nextValue);

                if (onSave) {
                    onSave(nextValue);
                }
            }
        };

        return <ComposedComponent {...props} value={newValue || ''} onChange={handleChange} />;
    });
};
