import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ErrorIcon from '@mui/icons-material/Error';
import { InputAdornment, TextField } from '@mui/material';
import { Auth } from 'aws-amplify';

import useQrCodeLogin from '../hooks/useQrCodeLogin';

import CompleteRegister from './CompleteRegister';
import LoginQRButton from './LoginQRButton';
import QrLoginModal from './QrLoginModal';

const SignIn = props => {
    const { t } = useTranslation();

    const [model, setModel] = useState({
        email: '',
        password: '',
        newPassword: '',
    });

    const [user, setUser] = useState(null);
    const [exception, setException] = useState(null);

    const getException = exception => {
        if (!exception) {
            return null;
        }

        if (exception.code === 'UserNotFoundException') {
            return {
                emailException: exception.message,
            };
        }

        if (exception.code === 'InvalidPasswordException') {
            return {
                passwordException: t(`authentication.validation.password.invalidFormat`),
            };
        }

        return {
            generalException: exception.message,
        };
    };

    const signIn = async model => {
        try {
            const email = model.email && model.email.trim();
            const password = model.password && model.password.trim();

            if (email && password) {
                const user = await Auth.signIn(email, password);

                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setUser(user);
                    setModel({
                        email: '',
                        password: '',
                        newPassword: '',
                    });
                } else {
                    props.onStateChange('signedIn', {});
                }

                setException(null);
            }
        } catch (error) {
            setException(error);
        }
    };

    const completeNewPassword = async (model, userModel) => {
        try {
            const newPassword = model.newPassword && model.newPassword.trim();

            if (userModel && newPassword) {
                await Auth.completeNewPassword(userModel, newPassword);
                props.onStateChange('signedIn', {});
                setException(null);
            }
        } catch (error) {
            setException(error);
        }
    };

    const handleValueChange = (path, event) => {
        const value = event.target?.value || '';

        setModel(prev => {
            return {
                ...prev,
                [path]: value,
            };
        });
    };

    const handleForgotPasswordClick = () => {
        props.onStateChange('forgotPassword', {});
    };

    const handleBackClick = () => {
        setUser(null);
        setModel({
            email: '',
            password: '',
            newPassword: '',
        });
        setException(null);
    };

    const handleSubmit = event => {
        if (event.charCode === 13) {
            if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                completeNewPassword(model, user);
            } else {
                signIn(model);
            }
        }
    };

    const isChallenge = user && user.challengeName === 'NEW_PASSWORD_REQUIRED';
    const exceptionMessage = getException(exception);

    const [isOpened, toggleModal, handleResult] = useQrCodeLogin(signIn);

    return (
        <div className="login-info-outer-container">
            <div className="login-info-inner-container" onKeyPress={handleSubmit}>
                {!isChallenge && (
                    <React.Fragment>
                        <div className={`input ${exceptionMessage ? 'error' : ''}`}>
                            <TextField
                                label={t(`authentication.signIn.email`)}
                                value={model.email || ''}
                                onChange={handleValueChange.bind({}, 'email')}
                            />
                            {exceptionMessage?.emailException && (
                                <div className="c-error">
                                    <ErrorIcon />
                                    {exceptionMessage.emailException}
                                </div>
                            )}
                        </div>
                        <div className={`input ${exceptionMessage?.generalException ? 'error' : ''}`}>
                            <TextField
                                label={t(`authentication.signIn.password`)}
                                type="password"
                                autoComplete="current-password"
                                value={model.password || ''}
                                onChange={handleValueChange.bind({}, 'password')}
                                InputProps={{
                                    endAdornment: model.email && model.email.trim() && model.password && model.password.trim() && (
                                        <InputAdornment position="end" className="login-button" onClick={signIn.bind({}, model)}>
                                            <ArrowForwardRoundedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        {exceptionMessage?.generalException && (
                            <div className="c-error">
                                <ErrorIcon />
                                {exceptionMessage.generalException}
                            </div>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em' }}>
                            <LoginQRButton handleClick={toggleModal} />
                            <QrLoginModal isOpen={isOpened} handleModalClose={toggleModal} handleResult={handleResult} />
                        </div>
                        <div className="forgot-password" onClick={handleForgotPasswordClick}>
                            {t(`authentication.signIn.forgotPassword`)}
                        </div>
                    </React.Fragment>
                )}
                {isChallenge && (
                    <CompleteRegister
                        user={user}
                        model={model}
                        exceptionMessage={exceptionMessage}
                        handleBackClick={handleBackClick}
                        handleValueChange={handleValueChange}
                        completeNewPassword={completeNewPassword}
                    />
                )}
            </div>
        </div>
    );
};

export default memo(SignIn);
