import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button } from '@mui/material';

import { ncrStatus } from '../../../../../../../../common/enums/ncrStatus';
import { notificationsSeverityByRequestStatus } from '../../../../../../../../common/providers/notification/constants';
import { NotificationContext } from '../../../../../../../../common/providers/notification/store/notification.context';

import { useUpdateNCR } from '../../../../../../hooks/useUpdateNCR';

import { InboxContext } from '../../../../../../store/inbox.context';

import { deleteNCRFromCache } from '../../../../utils';

const NoNCRButton = () => {
    const { t } = useTranslation();

    const { selectedNCRId } = useContext(InboxContext);
    const { setNotification } = useContext(NotificationContext);

    const [updateNCR] = useUpdateNCR(deleteNCRFromCache);

    const handleClick = () => {
        updateNCR(selectedNCRId, { status: ncrStatus.REJECTED }).then(response => {
            setNotification({ severity: notificationsSeverityByRequestStatus[response.data.updateNCR.success] });
        });
    };

    return (
        <Button onClick={handleClick} startIcon={<CheckCircleOutlineIcon />} color="success" variant="contained">
            {t('inbox.infoForm.actionsButton.noNCR')}
        </Button>
    );
};

export default NoNCRButton;
