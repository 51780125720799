import React, { memo, useMemo } from 'react';

import { styled } from '@mui/material/styles';
import { groupBy } from 'lodash';
import moment from 'moment';

import ActivityCard from '../ActivityCard';

const StyledRootDiv = styled('div')(({ theme }) => ({
    borderRadius: 16,
    maxHeight: 'calc(100vh - 380px)',
    overflowY: 'auto',

    padding: '2em',
}));

const StyledWrapperDiv = styled('div')(({ theme }) => ({
    marginBottom: '2em',
}));

const StyledDataTextSpan = styled('span')(({ theme }) => ({
    textAlign: 'center',
}));

const StyledDataDividerDiv = styled('div')(({ theme }) => ({
    width: '100%',
    textAlign: 'center',
    borderBottom: `1px #CED4DB solid`,
    lineHeight: '0.1em',
    margin: '10px 0 20px',

    '& span': {
        padding: '0 10px',
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        fontWeight: '400',
        fontSize: 14,
    },
}));

const ActivityActivitiesTabComponent = ({ allActivities, images }) => {
    const gropedActivities = useMemo(
        () =>
            allActivities &&
            groupBy(
                allActivities?.filter(activity => activity?.activityObject?.objectType !== 'comment'),
                activity => moment(activity.createdAt).format('DD MMMM yyyy')
            ),
        [allActivities]
    );

    return (
        <StyledRootDiv>
            {gropedActivities &&
                Object.keys(gropedActivities)?.map(key => {
                    return (
                        <StyledWrapperDiv key={key}>
                            <StyledDataDividerDiv>
                                <StyledDataTextSpan>{key}</StyledDataTextSpan>
                            </StyledDataDividerDiv>

                            {gropedActivities[key]?.map(activity => (
                                <ActivityCard key={key + activity?._id} images={images} {...activity} />
                            ))}
                        </StyledWrapperDiv>
                    );
                })}
        </StyledRootDiv>
    );
};

const ActivityActivitiesTab = memo(ActivityActivitiesTabComponent);

export default ActivityActivitiesTab;
