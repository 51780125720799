import { memo } from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

import NcrBasicInfoSection from './../../../../../common/components/ncr/NcrBasicInfoSection';
import NcrActivitiesSection from './NcrActivitiesSection';
import NcrAttachmentsSection from './NcrAttachmentsSection';

const NcrInfoTabComponent = ({ ncr, loading }) => {
    return (
        <>
            <Backdrop style={{ marginTop: 0 }} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <NcrBasicInfoSection ncr={ncr} />
            <NcrAttachmentsSection ncr={ncr} />
            <NcrActivitiesSection ncr={ncr} />
        </>
    );
};

const NcrInfoTab = memo(NcrInfoTabComponent);

export default NcrInfoTab;
