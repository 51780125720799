import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogTitle, IconButton, Typography } from '@mui/material';

import UserOverlayData from './UserOverlayData';

const UserOverlayTemplate = ({
    overlayData,
    setOverlayData,

    isEdit,
    isAllFieldsValid,

    organizations,
    model,
    valError,

    handleChange,
    handleChangeWithoutDelay,
    handleBlur,

    handleCreate,
    handleModalClose,
}) => {
    const handleOverlayModalClose = () => {
        setOverlayData({
            isOpen: false,
            userId: null,
        });

        handleModalClose();
    };

    const { t } = useTranslation();

    return (
        <Dialog maxWidth="md" onClose={handleOverlayModalClose} open={overlayData.isOpen}>
            <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} variant="h5">
                {t(`adminPage.modal.${isEdit ? 'edit' : 'create'}`)}

                <IconButton
                    aria-label="close"
                    onClick={handleOverlayModalClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Box sx={{ padding: '2em' }}>
                <UserOverlayData
                    model={model}
                    organizations={organizations}
                    isEdit={isEdit}
                    handleChange={handleChange}
                    handleChangeWithoutDelay={handleChangeWithoutDelay}
                    handleBlur={handleBlur}
                    valError={valError}
                />
            </Box>
            <DialogActions sx={{ display: 'flex', direction: 'row', justifyContent: 'center', paddingBottom: '2em' }}>
                <Button sx={{ cursor: 'pointer' }} onClick={handleOverlayModalClose} variant="outlined" color="primary">
                    <Typography variant="button" sx={{ cursor: 'pointer' }}>
                        {t('adminPage.modal.close')}
                    </Typography>
                </Button>
                {!isEdit && (
                    <Button disabled={!isAllFieldsValid(valError)} onClick={handleCreate} color="primary" variant="contained">
                        {t('adminPage.modal.create')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default UserOverlayTemplate;
