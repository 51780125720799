import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import CollapseRow from '../../../../../../../../common/components/CollapseRow';

import { useCollapse } from '../../../../../../../../common/hooks/useCollapse';

import MeasuresContent from './MeasuresContent';

const MeasuresView = ({ ncrId, capaDetailId, userImages }) => {
    const { t } = useTranslation();

    const [isCollapsed, collapse] = useCollapse(true);

    return (
        <Box sx={{ margin: '32px 0px' }}>
            <CollapseRow title={t('ncrInfo.corrective.measures').toUpperCase()} isExpanded={isCollapsed} collapse={collapse} />

            {isCollapsed && <MeasuresContent ncrId={ncrId} capaDetailId={capaDetailId} userImages={userImages} />}
        </Box>
    );
};

export default MeasuresView;
