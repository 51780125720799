import { gql } from '@apollo/client';

export const SEARCH_PAGINATED_WORKFLOW_STEPS_BY_NCR = gql`
    query SearchPaginatedWorkflowStepsByNCR($filter: String, $limit: Int, $skip: Int, $sort: String) {
        searchPaginatedWorkflowStepsByNCR(filter: $filter, limit: $limit, skip: $skip, sort: $sort) {
            code
            success
            message
            workflowSteps {
                _id
                status
                name {
                    language
                    value
                }
                description {
                    language
                    value
                }
                responsible {
                    responsibleID
                }
                resolvedResponsible {
                    _id
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                resolvedNCR {
                    name
                    basicInformation {
                        externalReference
                    }
                }
                organizations {
                    organizationID
                    teamsIDs
                }
                dueDate
                status
                ncrID
                workflowID
                ncrSectionID
                ncrSectionType
            }
            metadata {
                total
                skip
                limit
                hasPrev
                hasNext
            }
        }
    }
`;
