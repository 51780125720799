import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ContextMenu } from '@5thindustry/factory_portal.components.contextmenu/dist/contextmenu';
import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { Avatar, Chip, IconButton, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';

import { DELETE_USER, GET_USERS, UPDATE_USER } from '../../../../common/gqlQueries/queries';

import { useUserAvatar } from '../../../../common/hooks/useUserAvatar';
import { useUserImages } from '../../../../common/hooks/useUserImages';
import { useUserOverlayData } from './useUserOverlayData';

import { notificationsSeverityByRequestStatus } from '../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../common/providers/notification/store/notification.context';

import { isArrayWithItems, stringAvatar } from '../../../../utils/common';
import i18n from '../../../../utils/i18n';
import update from '../../../../utils/update';
import { tableColumnTypes } from '../../../../utils/sorting';

export const useUsersTableColumns = () => {
    const navigate = useNavigate();
    const confirm = useConfirm();
    const { t } = useTranslation();
    const { setNotification } = useNotificationContext();

    const [images] = useUserImages();
    const [avatar] = useUserAvatar();

    const [userOverlayData, setUserOverlayData] = useUserOverlayData();

    const handleUserClick = userId => {
        setUserOverlayData({
            isOpen: true,
            userId: userId,
        });
    };

    const [blockUser] = useMutation(UPDATE_USER, {
        update(cache, { data }) {
            const { getUsers } = cache.readQuery({
                query: GET_USERS,
            });
            const userIndex = getUsers.findIndex(item => item._id === data.adminUpdateUser?.user?._id);
            const newData = update(getUsers, {
                [userIndex]: { $set: data.adminUpdateUser?.user },
            });
            cache.writeQuery({
                query: GET_USERS,
                data: {
                    getUsers: newData,
                },
            });
        },
        onCompleted: data => {
            if (data.adminUpdateUser?.code !== '200') {
                setNotification({ severity: notificationsSeverityByRequestStatus[data.adminUpdateUser?.success] });
            }
        },
        onError: _ => {
            setNotification({ severity: notificationsSeverityByRequestStatus[false] });
        },
    });

    const handleBlockUser = (userId, blocked) => {
        confirm({
            titleProps: { variant: 'body1', sx: { textAlign: 'center', marginBottom: '2em' } },
            title: (
                <Typography variant="h5">
                    {blocked
                        ? t('adminPage.blockConfirmation.titleUnblock')
                        : t('adminPage.blockConfirmation.titleBlock')}
                </Typography>
            ),
            contentProps: { sx: { textAlign: 'center' } },
            content: (
                <Typography variant="body1" sx={{ marginBottom: '1em' }}>
                    {blocked
                        ? t('adminPage.blockConfirmation.contentUnblock')
                        : t('adminPage.blockConfirmation.contentBlock')}
                </Typography>
            ),
            dialogActionsProps: { sx: { justifyContent: 'center', paddingBotom: '2em' } },
            confirmationText: blocked
                ? t('adminPage.blockConfirmation.confirmButtonUnblock')
                : t('adminPage.blockConfirmation.confirmButtonBlock'),
            confirmationButtonProps: { variant: 'contained', color: 'error', sx: { cursor: 'pointer' } },
            cancellationText: t('adminPage.blockConfirmation.cancelButton'),
            cancellationButtonProps: { variant: 'outlined', color: 'primary', sx: { cursor: 'pointer' } },
        })
            .then(() => {
                blockUser({
                    variables: {
                        user: {
                            _id: userId,
                            disabled: !blocked,
                        },
                    },
                });
            })
            .catch(_ => {});
    };

    const [deleteUser] = useMutation(DELETE_USER, {
        update(cache, { data }) {
            const { getUsers } = cache.readQuery({
                query: GET_USERS,
            });
            cache.writeQuery({
                query: GET_USERS,
                data: {
                    getUsers: getUsers.filter(user => user._id !== data.deleteUser?.user?._id),
                },
            });
        },
        onCompleted: data => {
            if (data.deleteUser?.code !== '200') {
                setNotification({ severity: notificationsSeverityByRequestStatus[data.deleteUser?.success] });
            }
        },
        onError: _ => {
            setNotification({ severity: notificationsSeverityByRequestStatus[false] });
        },
    });

    const handleDeleteUser = userId => {
        confirm({
            titleProps: { variant: 'body1', sx: { textAlign: 'center', marginBottom: '2em' } },
            title: <Typography variant="h5">{t('adminPage.deleteConfirmation.title')}</Typography>,
            contentProps: { sx: { textAlign: 'center' } },
            content: (
                <Typography variant="body1" sx={{ marginBottom: '1em' }}>
                    {t('adminPage.deleteConfirmation.content')}
                </Typography>
            ),
            dialogActionsProps: { sx: { justifyContent: 'center', marginBotom: '2em' } },
            confirmationText: t('adminPage.deleteConfirmation.deleteButton'),
            confirmationButtonProps: { variant: 'contained', color: 'error', sx: { cursor: 'pointer' } },
            cancellationText: t('adminPage.deleteConfirmation.cancelButton'),
            cancellationButtonProps: { variant: 'outlined', color: 'primary', sx: { cursor: 'pointer' } },
        })
            .then(() => {
                deleteUser({
                    variables: {
                        userID: userId,
                    },
                });
            })
            .catch(_ => {});
    };

    const columns = useMemo(() => {
        return [
            {
                field: 'avatar',
                width: 80,
                headerName: '',
                sortable: false,
                renderCell: params => {
                    const avatarSrc = avatar.getUserAvatarByThumbnail(images, params?.row?.avatar);
                    const userName = `${params.row.firstName} ${params.row.lastName}`;

                    if (!avatarSrc) {
                        return <Avatar alt={userName} {...stringAvatar(userName)} />;
                    }

                    return <Avatar alt={userName} src={avatarSrc} />;
                },
            },
            {
                field: 'lastName',
                headerName: t('adminPage.usersTable.lastName'),
                columnType: tableColumnTypes.string,
                width: 120,
                valueGetter: params => params.row.lastName,
            },
            {
                field: 'firstName',
                headerName: t('adminPage.usersTable.firstName'),
                columnType: tableColumnTypes.string,
                width: 120,
                valueGetter: params => params.row.firstName,
            },
            {
                field: 'email',
                headerName: t('adminPage.usersTable.email'),
                columnType: tableColumnTypes.string,
                width: 320,
                valueGetter: params => params.row.email,
            },
            {
                field: 'roles',
                width: 300,
                headerName: t('adminPage.usersTable.roles'),
                columnType: tableColumnTypes.array,
                renderCell: params => {
                    if (!isArrayWithItems(params.row.roles)) {
                        return null;
                    }

                    const roles = params?.row?.roles.join(', ');

                    return <span>{roles}</span>;
                },
            },
            {
                field: 'teams',
                width: 300,
                headerName: t('adminPage.usersTable.teams'),
                columnType: tableColumnTypes.array,
                renderCell: params => {
                    if (!isArrayWithItems(params.row.teams)) {
                        return null;
                    }

                    return params.row.teams.map(team => {
                        return <Chip key={team.name} label={team.name} style={{ margin: '0.25em' }} />;
                    });
                },
            },
            {
                field: 'blocked',
                width: 80,
                columnType: tableColumnTypes.string,
                headerName: t('adminPage.usersTable.blocked'),
                valueGetter: params => params.row.blocked.toString(),
            },
            {
                field: 'contextMenu',
                width: 80,
                sortable: false,
                headerName: '',
                valueGetter: params => params.row,
                renderCell: params => {
                    let user = params?.row;

                    return (
                        <IconButton
                            disableRipple
                            disableTouchRipple
                            onClick={event => {
                                event.ignoreRowClick = true;
                            }}>
                            <ContextMenu
                                options={[
                                    {
                                        key: `overview-${user?.rowId}`,
                                        icon: <VisibilityRoundedIcon />,
                                        label: i18n.t('adminPage.contextMenu.overview'),
                                        onClick: () => {
                                            navigate(`/userProfile/${user?.rowId}`);
                                        },
                                    },
                                    {
                                        key: `edit-${user?.rowId}`,
                                        icon: <EditIcon />,
                                        label: i18n.t('adminPage.contextMenu.edit'),
                                        onClick: () => {
                                            handleUserClick(user.rowId);
                                        },
                                    },
                                    {
                                        key: user.blocked ? `unblock-${user?.rowId}` : `block-${user?.rowId}`,
                                        icon: user.blocked ? <LockOpenRoundedIcon /> : <LockRoundedIcon />,
                                        label: user.blocked
                                            ? i18n.t('adminPage.contextMenu.unblock')
                                            : i18n.t('adminPage.contextMenu.block'),
                                        onClick: () => {
                                            handleBlockUser(user.rowId, user.blocked);
                                        },
                                    },
                                    {
                                        key: `delete-${user?.rowId}`,
                                        icon: <DeleteIcon />,
                                        label: i18n.t('adminPage.contextMenu.delete'),
                                        onClick: () => {
                                            handleDeleteUser(user.rowId);
                                        },
                                    },
                                ]}
                            />
                        </IconButton>
                    );
                },
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, images, avatar]);

    return [columns, userOverlayData, setUserOverlayData];
};
