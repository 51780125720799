import React from 'react';

import { useTheme } from '@mui/material';

const MyTasksHomeScreenIcon = () => {
    const theme = useTheme();

    return (
        <svg width="100" height="126" viewBox="0 0 100 126" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M96.3125 34.3125L66.125 4.125C63.8125 1.8125 60.625 0.5 57.3125 0.5H12.5C5.625 0.5 0.0625014 6.125 0.0625014 13L0 113C0 119.875 5.5625 125.5 12.4375 125.5H87.5C94.375 125.5 100 119.875 100 113V43.1875C100 39.875 98.6875 36.6875 96.3125 34.3125ZM38.9375 96.0625L25.6875 82.8125C23.25 80.375 23.25 76.4375 25.6875 74C28.125 71.5625 32.0625 71.5625 34.5 74L43.3125 82.8125L65.4375 60.6875C67.875 58.25 71.8125 58.25 74.25 60.6875C76.6875 63.125 76.6875 67.0625 74.25 69.5L47.75 96C45.375 98.5 41.375 98.5 38.9375 96.0625ZM62.5 44.25C59.0625 44.25 56.25 41.4375 56.25 38V9.875L90.625 44.25H62.5Z"
                fill={theme?.palette.mode === 'light' ? '#16ABA1' : '#52DBCE'}
            />
        </svg>
    );
};

export default MyTasksHomeScreenIcon;
