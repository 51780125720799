import { Stack } from '@mui/material';

import MyTaskMyTaskFilter from './MyTaskMyTaskFilter';
import MyTasksClearButton from './MyTasksClearButton';
import MyTasksSearchInput from './MyTasksSearchInput';
import MyTasksDateRangeFilter from './MyTasksDateRangeFilter';
import TeamsFilter from './TeamsFilter';

const MyTasksFilters = () => {
    return (
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
            <MyTasksDateRangeFilter />
            <MyTasksSearchInput />
            <TeamsFilter />
            <MyTaskMyTaskFilter />
            <MyTasksClearButton />
        </Stack>
    );
};

export default MyTasksFilters;
