import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';

const NoInboxData = () => {
    const { t } = useTranslation();

    return (
        <Stack alignItems="center" justifyContent="center" height="calc(100vh - 160px)">
            <Typography variant="body1">{t('inbox.noData.title')}</Typography>
            <Stack direction="row" spacing={1}>
                <Typography variant="body1">{t('inbox.noData.subHeader')}</Typography>
                <Link to="/report">
                    <Typography variant="body1">{t('inbox.noData.linkTitle')}</Typography>
                </Link>
            </Stack>
        </Stack>
    );
};

export default NoInboxData;
