import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../../../../common/components/AutoSaveTextField';
import { NcrTemplateContext } from '../../../../common/contexts/ncrTemplate/store/context';

const DescriptionInputComponent = ({ value, disabled }) => {
    const { t } = useTranslation();

    const { setNCR } = useContext(NcrTemplateContext);

    const handleChange = event => {
        setNCR({ value: event?.target?.value, path: 'basicInformation.description' });
    };

    return (
        <AutoSaveTextField
            value={value}
            disabled={disabled}
            onBlur={handleChange}
            label={t('report.form.description')}
            rows={2}
            multiline
            fullWidth
        />
    );
};

const DescriptionInput = memo(DescriptionInputComponent);

export default DescriptionInput;
