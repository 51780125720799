import { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';

import { AWS_S3_BUCKET_EHS_USER_FOLDER } from '../../configs/env';
import { GET_FILES } from '../gqlQueries/queries';

export const useUserImages = () => {
    const [images, setImages] = useState([]);

    const [fetchUserImagesData, { loading }] = useMutation(GET_FILES);

    useEffect(() => {
        fetchUserImagesData({
            variables: {
                accessRootFolder: `${AWS_S3_BUCKET_EHS_USER_FOLDER}/`,
            },
        }).then(images => {
            setImages(images.data.createSignedGetURL);
        });
    }, [fetchUserImagesData]);

    return [images, loading];
};
