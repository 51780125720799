import { Box, Collapse, Stack, useMediaQuery, useTheme } from '@mui/material';

import NcrOverviewClearButton from './NcrOverviewClearButton';
import NcrOverviewDateRangeFilter from './NcrOverviewDateRangeFilter';
import NcrOverviewFailureCategoryFilter from './NcrOverviewFailureCategoryFilter';
import NcrOverviewSearchInput from './NcrOverviewSearchInput';
import NcrOverviewStatusFilter from './NcrOverviewStatusFilter';
import NcrOverviewFiltersButton from './NcrOverviewFiltersButton';
import NcrOverviewArchivedFilter from './NcrOverviewArchivedFilter';

import { useNcrOverviewContext } from '../../store/NCROverviewProvider';

const NcrOverviewFilters = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const { filtersOpened } = useNcrOverviewContext();

    return (
        <>
            <Stack direction={{ sm: 'column', md: 'row' }} spacing={2} justifyContent="space-between">
                <Box sx={{ flexGrow: 1, width: '100%' }}>
                    <NcrOverviewSearchInput />
                </Box>
                <NcrOverviewFailureCategoryFilter />
                <Box sx={{ width: matches ? '280px' : '100%' }}>
                    <NcrOverviewStatusFilter />
                </Box>
                <NcrOverviewFiltersButton />
            </Stack>

            <Collapse in={filtersOpened}>
                <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={2}>
                    <NcrOverviewDateRangeFilter />
                    <Box sx={{ width: matches ? '230px' : '100%' }}>
                        <NcrOverviewArchivedFilter />
                    </Box>
                    <NcrOverviewClearButton />
                </Stack>
            </Collapse>
        </>
    );
};

export default NcrOverviewFilters;
