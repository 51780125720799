import React, { useMemo } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { measureIncidentTypeOptions } from '../../../../../../../../constants';

const MeasureIncidentTypeDropdown = ({ value, handleUpdate }) => {
    const handleChange = data => {
        handleUpdate(data?.value);
    };

    const mappedValue = useMemo(() => measureIncidentTypeOptions?.find(incidentType => incidentType?.value === value), [value]);

    return (
        <Autocomplete
            value={mappedValue}
            options={measureIncidentTypeOptions}
            getOptionLabel={option => option.label}
            variant="outlined"
            isOptionEqualToValue={(option, value) => option.value === value.value}
            fullWidth
            renderInput={params => <TextField {...params} label={'Incident Type'} />}
            onChange={(_, data) => handleChange(data)}
        />
    );
};

export default MeasureIncidentTypeDropdown;
