import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../../../../../../../common/components/AutoSaveTextField';

import { useUserSettingsContext } from '../../../../../../../common/providers/userSettings/store/userSettings.context';

import { getValueByLanguage } from '../../../../../../../utils/common';

const StepNameInputComponent = ({ value, disabled, handleUpdate }) => {
    const { t } = useTranslation();

    const { language } = useUserSettingsContext();

    const handleBlur = event => {
        handleUpdate([
            {
                language,
                value: event.target.value,
            },
        ]);
    };

    const stepName = useMemo(() => getValueByLanguage(value, language), [value, language]);

    return (
        <AutoSaveTextField
            value={stepName}
            disabled={disabled}
            label={t('ncrInfo.immediate.table.stepName')}
            onBlur={handleBlur}
            onKeyPress={event => {
                event.key === 'Enter' && handleBlur(event);
            }}
            fullWidth
        />
    );
};

const StepNameInput = memo(StepNameInputComponent);

export default StepNameInput;
