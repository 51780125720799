import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { useGetPaginatedFailureCategoriesOptions } from './ncr/hooks/useGetPaginatedFailureCategoriesOptions';

const FailureCategoryDropdown = ({ onChange, ...props }) => {
    const { t } = useTranslation();

    const [failureCategoriesOptions, loadingFailureCategories] = useGetPaginatedFailureCategoriesOptions();

    return (
        <Autocomplete
            {...props}
            loading={loadingFailureCategories}
            options={failureCategoriesOptions}
            groupBy={option => option.name}
            getOptionLabel={option => option.failureMode}
            renderInput={params => <TextField {...params} fullWidth label={t('report.form.failureCode')} />}
            onChange={(_, data) => onChange(data)}
        />
    );
};

export default FailureCategoryDropdown;
