import React from 'react';

import { useTheme } from '@mui/material';

const InboxHomeScreenIcon = () => {
    const theme = useTheme();

    return (
        <svg width="114" height="114" viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M100.75 0.75H13.25C6.375 0.75 0.75 6.375 0.75 13.25V100.75C0.75 107.625 6.375 113.25 13.25 113.25H100.75C107.625 113.25 113.25 107.625 113.25 100.75V13.25C113.25 6.375 107.625 0.75 100.75 0.75ZM100.75 13.25V69.5H78.5C76.25 69.5 74.25 70.6875 73.125 72.625C69.875 78.25 63.9375 82 57 82C50.0625 82 44.125 78.25 40.875 72.625C39.75 70.6875 37.6875 69.5 35.5 69.5H13.25V13.25H100.75Z"
                fill={theme?.palette.mode === 'light' ? '#16ABA1' : '#52DBCE'}
            />
        </svg>
    );
};

export default InboxHomeScreenIcon;
