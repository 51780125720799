import { useEffect } from 'react';

import { LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import TableNoDataOverlay from '../../../../../common/components/table/TableNoDataOverlay';

import { useNcrOverviewContext } from '../../../../ncr-overview/store/NCROverviewProvider';

import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../common/constants/constants';
import { useNcrBinTableData } from './hooks/useNcrBinTableData';

const BinPage = () => {
    const { page, sortBy, order, setNcrOverviewTablePage, setNcrOverviewTableSorting } = useNcrOverviewContext();

    const [rows, columns, loading, total, fetchData] = useNcrBinTableData();

    useEffect(() => {
        fetchData(page, sortBy, order);
    }, [fetchData, page, sortBy, order]);

    const handleTableSort = model => {
        const [item] = model;

        setNcrOverviewTableSorting({ sortBy: item.field, order: item.sort });
    };

    return (
        <DataGrid
            sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 600,
                },
                '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                    display: 'none',
                },
            }}
            rows={rows}
            columns={columns}
            components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: TableNoDataOverlay,
            }}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            hideFooterSelectedRowCount
            getRowId={row => row.rowId}
            loading={loading}
            pagination
            page={page}
            rowCount={total}
            pageSize={DEFAULT_TABLE_PAGE_SIZE}
            rowsPerPageOptions={[10]}
            paginationMode="server"
            sortingOrder={['asc', 'desc']}
            sortingMode="server"
            onPageChange={setNcrOverviewTablePage}
            onSortModelChange={handleTableSort}
        />
    );
};

export default BinPage;
