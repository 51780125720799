import React from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { ncrUserRolesArray } from '../../../../../common/constants/userRoles';
import { isArrayWithItems } from '../../../../../utils/common';
import i18n from '../../../../../utils/i18n';

import { useUsersTableContext } from '../../store/usersTableProvider';

const getUsersTableRoleOptions = users => {
    if (!isArrayWithItems(users)) {
        return [];
    }

    return ncrUserRolesArray
        .map(role => {
            return {
                title: i18n.t(`roles.${role}`),
                value: role,
            };
        })
        .sort((a, b) => a?.value?.localeCompare(b?.value));
};

const UsersTableRoleFilter = ({ users, value }) => {
    const { t } = useTranslation();
    const { setUsersTableFilter } = useUsersTableContext();

    const options = getUsersTableRoleOptions(users);

    return (
        <Autocomplete
            value={value}
            fullWidth
            multiple
            limitTags={1}
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            options={options}
            getOptionLabel={option => option.title}
            renderInput={params => <TextField {...params} label={t('adminPage.usersTable.role')} />}
            onChange={(_, data) => {
                setUsersTableFilter({ key: 'roleFilter', value: data });
            }}
        />
    );
};

export default UsersTableRoleFilter;
