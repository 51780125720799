import { useMutation } from '@apollo/client';

import { CREATE_CAPA_TICKET } from '../../../../../../common/gqlQueries/capaTickets';

export const useCreateCapaTicket = () => {
    const [createCapaTicket, { data, loading: loadingCreateCapaTicket, error }] = useMutation(CREATE_CAPA_TICKET);

    const handleCreateCapaTicket = capaTicket => {
        return createCapaTicket({
            variables: {
                capaTicket,
            },
            refetchQueries: ['GetCapaTickets'],
        });
    };

    return [handleCreateCapaTicket, { data, loadingCreateCapaTicket, error }];
};
