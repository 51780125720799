// LIBS
import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

//UTILS
import { getAwsToken } from './awsAuth';
import { GATEWAY_URL } from '../configs/env';
import { getMainDefinition } from '@apollo/client/utilities';

const cache = new InMemoryCache();
const httpLink = createUploadLink({
    uri: GATEWAY_URL,
});

const authLink = setContext(async (_, { headers }) => {
    const token = await getAwsToken();
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const wsLink = new GraphQLWsLink(
    createClient({
        url: GATEWAY_URL.replace('http', 'ws'),
        options: {
            reconnect: true,
            lazy: true,
            connectionParams: async () => {
                const token = await getAwsToken();
                // return the headers to the context so websocketLink can read them
                return {
                    Authorization: token ? `Bearer ${token}` : '',
                };
            },
        },
    })
);

const link = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

const client = new ApolloClient({
    link: authLink.concat(link),
    cache,
});

export default client;
