import { useContext } from 'react';

import { Alert, Snackbar } from '@mui/material';

import { NotificationContext } from './store/notification.context';

import { DEFAULT_AUTO_HIDE_DURATION } from './constants';

const Notification = () => {
    const { isOpen, severity, message, clearNotification } = useContext(NotificationContext);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        clearNotification();
    };

    if (!severity && !isOpen) {
        return null;
    }

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={DEFAULT_AUTO_HIDE_DURATION}
            onClose={handleClose}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
            <Alert elevation={2} variant="filled" onClose={handleClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Notification;
