import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip, Typography } from '@mui/material';

import UserNameWithAvatar from '../../../common/components/UserNameWithAvatar';
import DescriptionColumn from '../components/ncr-table/DescriptionColumn';

import { useUserAvatar } from '../../../common/hooks/useUserAvatar';
import { useUserImages } from '../../../common/hooks/useUserImages';
import { isArrayWithItems } from '../../../utils/common';

export const useMyTasksTableColumns = () => {
    const { t } = useTranslation();

    const [images] = useUserImages();
    const [avatar] = useUserAvatar();

    const columns = useMemo(() => {
        return [
            {
                field: 'description',
                headerName: 'Task',
                width: 220,
                renderCell: params => {
                    return <DescriptionColumn value={params.row.description} />;
                },
            },
            {
                field: 'name',
                headerName: 'NCR Name',
                valueGetter: params => params.row.name,
                width: 220,
                renderCell: params => {
                    const name = params.row.name;

                    if (!name) {
                        return 'N/A';
                    }

                    return (
                        <Tooltip title={name} placement="top">
                            <Typography variant="body2" noWrap>
                                {params.row.name}
                            </Typography>
                        </Tooltip>
                    );
                },
            },
            {
                field: 'externalReference',
                headerName: 'External Reference',
                flex: 1,
                valueGetter: params => params.row.externalReference,
            },
            {
                field: 'dueDate',
                headerName: 'Due Date',
                type: 'date',
                width: 180,
            },
            {
                field: 'responsible',
                minWidth: 200,
                flex: 1,
                headerName: t('ncrOverview.responsible'),
                renderCell: params => {
                    if (!params.row.responsible) {
                        return null;
                    }

                    const avatarSrc = avatar.getUserAvatarByThumbnail(images, params?.row?.responsible?.profilePic?.thumbnail);

                    return (
                        <UserNameWithAvatar
                            avatarSrc={avatarSrc}
                            userName={`${params.formattedValue.firstName} ${params.formattedValue.name}`}
                        />
                    );
                },
            },
            {
                field: 'teams',
                headerName: 'Teams',
                minWidth: 350,
                renderCell: params => {
                    if (!params.row?.teams) {
                        return 'N/A';
                    }
                    const teams = params.row.teams.flat(1);
                    return <span style={{ whiteSpace: 'break-spaces' }}>{teams?.filter(team => team).join(', ')}</span>;
                },
            },
        ];
    }, [t, images, avatar]);

    return [columns];
};
