import React from 'react';

import { Chip, LinearProgress, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import TableNoDataOverlay from '../../../../../../common/components/table/TableNoDataOverlay';

import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../../common/constants/constants';

import { useNcrCostsTableData } from '../hooks/useNcrCostsTableData';

const CostsTable = ({ ncr, loading }) => {
    const [rows, columns, total] = useNcrCostsTableData({ ncr });

    return (
        <>
            <DataGrid
                sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600,
                    },
                }}
                rows={rows || []}
                columns={columns || []}
                components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: TableNoDataOverlay,
                }}
                loading={loading}
                getRowId={row => row.id}
                autoHeight
                disableColumnMenu
                disableSelectionOnClick
                hideFooterSelectedRowCount
                pageSize={DEFAULT_TABLE_PAGE_SIZE}
            />
            <br />
            <Stack direction="row" justifyContent="flex-end">
                <Chip label={total} />
            </Stack>
        </>
    );
};

export default CostsTable;
