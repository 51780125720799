import React from 'react';

import { Stack } from '@mui/material';

import TicketKanbanBoardClearButton from './inputs/TicketKanbanBoardClearButton';
import TicketKanbanBoardIncidentTypeDropdown from './inputs/TicketKanbanBoardIncidentTypeDropdown';
import TicketKanbanBoardPeriodDatePicker from './inputs/TicketKanbanBoardPeriodDatePicker';
import TicketKanbanBoardResponsibleDropdown from './inputs/TicketKanbanBoardResponsibleDropdown';
import TicketKanbanBoardSearchInput from './inputs/TicketKanbanBoardSearchInput';

const TicketsKanbanBoardFilters = () => {
    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TicketKanbanBoardIncidentTypeDropdown />
            <TicketKanbanBoardResponsibleDropdown />
            <TicketKanbanBoardPeriodDatePicker />
            <TicketKanbanBoardSearchInput />
            <TicketKanbanBoardClearButton />
        </Stack>
    );
};

export default TicketsKanbanBoardFilters;
