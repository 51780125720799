export const GATEWAY_URL =
    process.env.REACT_APP_GATEWAY_URL || 'https://dev-gateway.livelytree-1e9a0eed.westeurope.azurecontainerapps.io/graphql';

export const AWS_S3_BUCKET_EHS_USER_FOLDER = process.env.REACT_APP_AWS_S3_BUCKET_EHS_USER_FOLDER || `users`;

export const AWS_S3_BUCKET_NCR_MEDIA_FOLDER = process.env.AWS_S3_BUCKET_NCR_MEDIA_FOLDER || `ncr/medias`;

export const AWS_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_IDENTITY_POOL_ID || 'eu-central-1:9a86f7e5-3bde-4a74-a6b7-56857725f6d5';
export const AWS_REGION = process.env.REACT_APP_AWS_REGION || 'eu-central-1';
export const AWS_USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID || 'eu-central-1_pWwoBGFLS';
export const AWS_USER_POOL_WEB_CLIENT = process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT || '1iu11hgjgr2p1scfa45ph57hp0';
export const AWS_OAUTH_DOMAIN = process.env.REACT_APP_AWS_OAUTH_DOMAIN || '5i-dev.auth.eu-central-1.amazoncognito.com';

export const AWS_AMPLIFY_DEFAULT_LANGUAGE = process.env.REACT_APP_AWS_AMPLIFY_DEFAULT_LANGUAGE || 'de';
