import React from 'react';

import ScrapView from './ScrapView';

const ScrapViewList = ({ ncr, loading }) => {
    if (!ncr) {
        return null;
    }

    return (
        <>
            {ncr?.scraps?.map(scrap => {
                return <ScrapView key={scrap._id} scrap={scrap} ncr={ncr} loading={loading} />;
            })}
        </>
    );
};

export default ScrapViewList;
