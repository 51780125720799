import { get } from "lodash";
import moment from "moment";

export const tableColumnTypes = {
    chevron: 'chevron',
    selectCheckbox: 'selectCheckbox',
    action: 'action',
    number: 'number',
    string: 'string',
    date: 'date',
    array: 'array',
    image: 'image',
};

export const tableColumnComparers = {
    [tableColumnTypes.number]: {
        cmp: (obj1, obj2, key) => {
            const val1 = get(obj1, key);
            const val2 = get(obj2, key);
            return val2 - val1;
        },
    },
    [tableColumnTypes.string]: {
        cmp: (obj1, obj2, key) => {
            const val1 = get(obj1, key);
            const val2 = get(obj2, key);

            if (!val1) {
                return 1;
            }

            if (!val2) {
                return -1;
            }

            return val1.localeCompare(val2);
        },
    },
    [tableColumnTypes.date]: {
        cmp: (obj1, obj2, key) => {
            const val1 = get(obj1, key);
            const val2 = get(obj2, key);

            if (!val1) {
                return 1;
            }

            if (!val2) {
                return -1;
            }

            return moment(val2).unix() - moment(val1).unix();
        },
    },
    [tableColumnTypes.array]: {
        cmp: (obj1, obj2, key) => {
            const val1 = get(obj1, key);
            const val2 = get(obj2, key);
            return val2.length - val1.length;
        },
    },
};
