import { useTranslation } from 'react-i18next';

import { ContextMenu } from '@5thindustry/factory_portal.components.contextmenu/dist/contextmenu';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { Card, CardHeader, CardMedia, Dialog, IconButton, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';

import { allowedFileTypes } from '../../../../common/constants/fileUpload';
import { getFileExtension } from '../../../../common/utils/fileManagement';

import { useModal } from '../../../../common/hooks/useModal';

const AttachmentItem = ({ media, handleRemoveAttachment, disabled }) => {
    const { t } = useTranslation();
    const confirm = useConfirm();

    const [open, toggleModal] = useModal(false);

    const handleDownloadAttachment = (fileName, fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDeleteAttachment = () => {
        confirm({
            titleProps: { variant: 'body1', sx: { textAlign: 'center', marginBottom: '2em' } },
            title: <Typography variant="h5">{t('confirmationModal.title')}</Typography>,
            contentProps: { sx: { textAlign: 'center' } },
            content: (
                <Typography variant="body1" sx={{ marginBottom: '1em' }}>
                    {t('confirmationModal.description')}
                </Typography>
            ),
            dialogActionsProps: { sx: { justifyContent: 'center' } },
            confirmationText: t('confirmationModal.confirmButton'),
            confirmationButtonProps: { variant: 'contained', color: 'error', sx: { cursor: 'pointer' } },
            cancellationText: t('confirmationModal.cancelButton'),
            cancellationButtonProps: { variant: 'outlined', color: 'primary', sx: { cursor: 'pointer' } },
        })
            .then(() => {
                handleRemoveAttachment(media._id)();
            })
            .catch(() => {});
    };

    const isImage = allowedFileTypes.images.some(ext => getFileExtension(ext) === getFileExtension(media.mediaName).toLowerCase());
    const isVideo = allowedFileTypes.videos.some(ext => getFileExtension(ext) === getFileExtension(media.mediaName).toLowerCase());

    const handleAttachmentClick = _event => {
        if (isImage || isVideo) {
            toggleModal();
        } else {
            handleDownloadAttachment(media.mediaName, media.img);
        }
    };

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    margin: '1em',
                }}>
                <CardMedia
                    sx={{
                        width: 150,
                        cursor: 'pointer',
                    }}
                    image={media.img}
                    alt={media.mediaName}
                    onClick={handleAttachmentClick}
                    children={
                        <>
                            {!isImage ? (
                                <DescriptionRoundedIcon sx={{ color: '#8D90AF', width: '96px', cursor: 'pointer', height: '113px' }} />
                            ) : (
                                <img
                                    src={media.img}
                                    alt={media.mediaName}
                                    style={{ width: 'auto', maxWidth: '100%', objectFit: 'contain' }}
                                />
                            )}
                        </>
                    }
                />
                <CardHeader
                    sx={{
                        width: '100%',

                        '& .MuiCardHeader-title': {
                            overflowWrap: 'break-word',
                            maxWidth: '100%',
                            cursor: 'pointer',
                        },
                    }}
                    action={
                        <ContextMenu
                            options={[
                                {
                                    icon: <FileDownloadRoundedIcon />,
                                    key: 'download',
                                    label: t('common.context-menu-options.download'),
                                    onClick: () => handleDownloadAttachment(media.mediaName, media.img),
                                },
                                {
                                    icon: <DeleteRoundedIcon />,
                                    key: 'delete',
                                    disabled: disabled,
                                    label: t('common.context-menu-options.delete'),
                                    onClick: handleDeleteAttachment,
                                },
                            ]}
                        />
                    }
                    title={media.mediaName}
                    titleTypographyProps={{
                        onClick: handleAttachmentClick,
                    }}
                    subheader={media.mediaType}
                />
            </Card>

            <Dialog onClose={toggleModal} open={open}>
                <IconButton
                    aria-label="close"
                    onClick={toggleModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                        zIndex: 10000,
                    }}>
                    <CloseRoundedIcon />
                </IconButton>
                {isImage && media.img && (
                    <img src={media.img} alt={media.mediaName} style={{ width: 'auto', maxWidth: '100%', objectFit: 'contain' }} />
                )}
                {isVideo && media.img && (
                    <video controls style={{ width: 'auto', maxWidth: '100%', objectFit: 'contain' }}>
                        <source src={media.img} />
                    </video>
                )}
            </Dialog>
        </>
    );
};

export default AttachmentItem;
