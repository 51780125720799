import React from 'react';

import AutoSaveTextField from '../../../../common/components/AutoSaveTextField';

const NcrInput = ({ label, disabled, ...props }) => {
    const handleInputChange = event => {
        if (props.value !== event.target.value) {
            props.onBlur(event) || props.onKeyPress(event) || props.onChange(event);
        }
    };

    return (
        <AutoSaveTextField
            label={label}
            disabled={disabled}
            onBlur={handleInputChange}
            onKeyPress={handleInputChange}
            fullWidth
            {...props}
        />
    );
};

export default NcrInput;
