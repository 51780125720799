import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

const AccordionSummary = styled(props => <MuiAccordionSummary expandIcon={<ArrowDropDownCircleIcon />} {...props} />)(({ theme }) => ({
    border: 0,
    flexDirection: 'row-reverse',
    padding: theme.spacing(0),
    '& .MuiAccordionSummary-content': {
        margin: theme.spacing(0, 0, 0, 2),
    },
}));

export default AccordionSummary;
