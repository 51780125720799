import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '@mui/material';

import { maxValueOfTotalAmount } from '../../../constants/constants';

import AutoSaveTextField from '../../AutoSaveTextField';

const UpdateTotalAmountInputComponent = ({ value, disabled, handleUpdate }) => {
    const { t } = useTranslation();

    const handleBlur = event => {
        handleUpdate('basicInformation', {
            basicInformation: {
                quantityData: {
                    total: maxValueOfTotalAmount < +event.target.value ? maxValueOfTotalAmount : +event.target.value,
                    defected: value?.defected,
                },
            },
        });
    };

    return (
        <AutoSaveTextField
            onlyPositiveDigits
            value={value?.total}
            disabled={disabled}
            type="number"
            fullWidth
            onBlur={handleBlur}
            onKeyPress={event => {
                event.key === 'Enter' && handleBlur(event);
            }}
            label={t('report.form.totalAmount')}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <span>Pcs</span>
                    </InputAdornment>
                ),
            }}
        />
    );
};

const UpdateTotalAmountInput = memo(UpdateTotalAmountInputComponent);

export default UpdateTotalAmountInput;
