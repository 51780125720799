import { Container } from '@mui/system';

import InboxPageContent from './components/InboxPageContent';

import { InboxProvider } from './store/inbox.context';

const InboxPage = () => {
    return (
        <InboxProvider>
            <Container>
                <InboxPageContent />
            </Container>
        </InboxProvider>
    );
};

export default InboxPage;
