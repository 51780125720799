import i18n from '../../utils/i18n';

const passwordValidation = {
    minLength: 8,
    minNumbers: 1,
    minSpecialCharacters: 1,
    minLowerCaseCharacters: 1,
    minUpperCaseCharacters: 1,
};

const digitRegex = /\d/;
const specialCharacterRegex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
const lowercaseRegex = /^(?=.*[a-z])/;
const uppercaseRegex = /^(?=.*[A-Z])/;

export const hasNumber = password => {
    return digitRegex.test(password);
};

export const hasSpecialCharacter = password => {
    return specialCharacterRegex.test(password);
};

export const hasLowercaseCharacter = password => {
    return lowercaseRegex.test(password);
};

export const hasUppercaseCharacter = password => {
    return uppercaseRegex.test(password);
};

export const isPasswordLengthValid = password => {
    return password && password.length >= passwordValidation.minLength;
};

export const isPasswordValid = password => {
    return (
        isPasswordLengthValid(password) &&
        hasNumber(password) &&
        hasSpecialCharacter(password) &&
        hasLowercaseCharacter(password) &&
        hasUppercaseCharacter(password)
    );
};

export const getValidationMessages = () => [
    {
        label: i18n.t('authentication.validation.password.minLength', { number: passwordValidation.minLength }),
        isValid: password => isPasswordLengthValid(password),
    },
    {
        label: i18n.t('authentication.validation.password.lowerCaseCharacters', { number: passwordValidation.minLowerCaseCharacters }),
        isValid: password => hasLowercaseCharacter(password),
    },
    {
        label: i18n.t('authentication.validation.password.upperCaseCharacters', { number: passwordValidation.minUpperCaseCharacters }),
        isValid: password => hasUppercaseCharacter(password),
    },
    {
        label: i18n.t('authentication.validation.password.digitCharacters', { number: passwordValidation.minNumbers }),
        isValid: password => hasNumber(password),
    },
    {
        label: i18n.t('authentication.validation.password.specialCharacters', { number: passwordValidation.minSpecialCharacters }),
        isValid: password => hasSpecialCharacter(password),
    },
];
