import { useCallback, useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';

import { DATE_REQUEST_FORMAT, DEFAULT_TABLE_PAGE_SIZE } from '../../../common/constants/constants';
import { ncrStatus } from '../../../common/enums/ncrStatus';
import { SEARCH_PAGINATED_WORKFLOW_STEPS_BY_NCR } from '../operations/queries/searchPaginatedWorkflowStepsByNCR';
import { isArrayWithItems } from '../../../utils/common';

export const useLazySearchPaginatedWorkflowStepsByNCR = userId => {
    const [getSingleNCR, { data, loading }] = useLazyQuery(SEARCH_PAGINATED_WORKFLOW_STEPS_BY_NCR);

    const fetchData = useCallback(
        (page, searchFilter, from, to, orderBy, order, myTask, teams) => {
            const timestampFrom = !!from && dayjs(from).format(DATE_REQUEST_FORMAT);
            const timestampTo = !!to && dayjs(to).format(DATE_REQUEST_FORMAT);

            let dateRange = undefined;

            if (timestampFrom && timestampTo) {
                dateRange = {
                    $gte: timestampFrom,
                    $lte: timestampTo,
                };
            }

            if (!timestampFrom && timestampTo) {
                dateRange = {
                    $lte: timestampTo,
                };
            }

            if (timestampFrom && !timestampTo) {
                dateRange = {
                    $gte: timestampFrom,
                };
            }

            const sortOrder = order === 'asc' ? 1 : -1;

            const sort = JSON.stringify({
                [orderBy]: sortOrder,
            });

            const responsibleFilter = myTask ? { 'responsible.responsibleID': userId } : {};

            const teamsFilter = isArrayWithItems(teams) ? { 'organizations.teamsIDs': { $in: teams?.map(team => team.value) } } : [];

            getSingleNCR({
                fetchPolicy: 'network-only',
                variables: {
                    skip: DEFAULT_TABLE_PAGE_SIZE * page,
                    limit: DEFAULT_TABLE_PAGE_SIZE,
                    sort,
                    filter: JSON.stringify({
                        ...responsibleFilter,
                        ...teamsFilter,
                        deleted: false,
                        status: ncrStatus.IN_WORK,
                        dueDate: dateRange,
                        $and: [
                            {
                                $or: [
                                    {
                                        'name.0.value': {
                                            $regex: searchFilter?.replaceAll(' ', '.*'),
                                            $options: 'xi',
                                        },
                                    },
                                    {
                                        'description.0.value': {
                                            $regex: searchFilter?.replaceAll(' ', '.*'),
                                            $options: 'xi',
                                        },
                                    },
                                    {
                                        'resolvedNCR.name': {
                                            $regex: searchFilter?.replaceAll(' ', '.*'),
                                            $options: 'xi',
                                        },
                                    },
                                    {
                                        'resolvedNCR.basicInformation.externalReference': {
                                            $regex: searchFilter?.replaceAll(' ', '.*'),
                                            $options: 'xi',
                                        },
                                    },
                                ],
                            },
                        ],
                    }),
                },
            });
        },
        [getSingleNCR, userId]
    );

    const items = useMemo(() => data?.searchPaginatedWorkflowStepsByNCR, [data?.searchPaginatedWorkflowStepsByNCR]);

    return [items, loading, fetchData];
};
