import { useCallback } from 'react';

import SearchInput from '../../../../common/components/SearchInput';

import { useNcrOverviewContext } from '../../store/NCROverviewProvider';

const NcrOverviewSearchInput = () => {
    const { searchFilter, setNcrOverviewSearchFilter } = useNcrOverviewContext();

    const memoizedChangeHandler = useCallback(
        event => {
            setNcrOverviewSearchFilter(event?.target?.value);
        },
        [setNcrOverviewSearchFilter]
    );

    return (
        <SearchInput
            fullWidth
            value={searchFilter}
            onKeyPress={event => {
                event.key === 'Enter' && memoizedChangeHandler(event);
            }}
            onBlur={memoizedChangeHandler}
            onClear={() => setNcrOverviewSearchFilter('')}
        />
    );
};

export default NcrOverviewSearchInput;
