export const inboxActions = {
    SET_SELECTED_NCR: 'SET_SELECTED_NCR',
    SET_INBOX_NO_DATA: 'SET_INBOX_NO_DATA',
};

export const setSelectedNCR = payload => ({
    type: inboxActions.SET_SELECTED_NCR,
    payload,
});

export const setInboxNoData = payload => ({
    type: inboxActions.SET_INBOX_NO_DATA,
    payload,
});
