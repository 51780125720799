import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import TableNoDataOverlay from '../../../../common/components/table/TableNoDataOverlay';

import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../common/constants/constants';

import { useUserSettingsContext } from '../../../../common/providers/userSettings/store/userSettings.context';
import { useNcrMyTasksContext } from '../../store/NCRMyTasksProvider';

import { useMyTasksTableData } from '../../hooks/useMyTasksTableData';

const MyTasksTable = () => {
    const navigate = useNavigate();

    const { page, sortBy, order, searchFilter, dateRangeFilter, myTask, setNcrMyTasksTablePage, setNcrMyTasksTableSorting, teams } =
        useNcrMyTasksContext();

    const { userData } = useUserSettingsContext();

    const [rows, columns, loading, total, fetchData] = useMyTasksTableData(userData?._id);

    useEffect(() => {
        const { from, to } = dateRangeFilter;

        fetchData(page, searchFilter, from, to, sortBy, order, myTask, teams);
    }, [fetchData, page, dateRangeFilter, searchFilter, sortBy, order, myTask, teams]);

    const handleTableSort = model => {
        const [item] = model;

        setNcrMyTasksTableSorting({ sortBy: item.field, order: item.sort });
    };

    const handleRowClick = params => {
        const { row } = params;

        if (row?.ncrId && row?.ncrSectionID && row?.ncrSectionType) {
            const path = generatePath('/ncr-overview/:ncrId/:sectionType/:sectionId', {
                ncrId: row?.ncrId,
                sectionId: row?.ncrSectionID,
                sectionType: row?.ncrSectionType,
            });

            navigate(path);
        }
    };

    return (
        <DataGrid
            sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 600,
                },
            }}
            rows={rows}
            columns={columns}
            components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: TableNoDataOverlay,
            }}
            onRowClick={handleRowClick}
            getRowClassName={params => {
                const { row } = params;

                if (row?.ncrId && row?.ncrSectionID && row?.ncrSectionType) {
                    return 'cursor-pointer';
                }

                return 'cursor-not-allowed';
            }}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            hideFooterSelectedRowCount
            getRowId={row => row.rowId}
            loading={loading}
            pagination
            page={page}
            rowCount={total}
            pageSize={DEFAULT_TABLE_PAGE_SIZE}
            rowsPerPageOptions={[10]}
            paginationMode="server"
            sortingOrder={['asc', 'desc']}
            sortingMode="server"
            onPageChange={setNcrMyTasksTablePage}
            onSortModelChange={handleTableSort}
        />
    );
};

export default MyTasksTable;
