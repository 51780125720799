import React, { useMemo, useReducer } from 'react';

import {
    resetTicketsKanbanBoardFilters,
    setActiveCapaTicket,
    setTicketsKanbanBoardDateRange,
    setTicketsKanbanBoardIncidentType,
    setTicketsKanbanBoardResponsible,
    setTicketsKanbanBoardSearch,
} from './TicketsKanbanBoardActions';

import { TicketKanbanBoardContext } from './TicketsKanbanBoardContext';

import { ticketsKanbanBoardInitialState, ticketsKanbanBoardReducer } from './TicketsKanbanBoardReducer';

const CapaTicketsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ticketsKanbanBoardReducer, ticketsKanbanBoardInitialState);

    const ticketKanbanBoardContextValue = useMemo(() => {
        return {
            searchFilter: state.searchFilter,
            dateRangeFilter: state.dateRangeFilter,
            responsibleFilter: state.responsibleFilter,
            incidentTypeFilter: state.incidentTypeFilter,
            activeCapaTicket: state.activeCapaTicket,

            setActiveCapaTicket: filter => dispatch(setActiveCapaTicket(filter)),
            setTicketsKanbanBoardIncidentType: filter => dispatch(setTicketsKanbanBoardIncidentType(filter)),
            setTicketsKanbanBoardResponsible: filter => dispatch(setTicketsKanbanBoardResponsible(filter)),
            setTicketsKanbanBoardDateRange: filter => dispatch(setTicketsKanbanBoardDateRange(filter)),
            setTicketsKanbanBoardSearch: filter => dispatch(setTicketsKanbanBoardSearch(filter)),
            resetTicketsKanbanBoardFilters: () => dispatch(resetTicketsKanbanBoardFilters()),
        };
    }, [state]);

    return <TicketKanbanBoardContext.Provider value={ticketKanbanBoardContextValue}>{children}</TicketKanbanBoardContext.Provider>;
};

export default CapaTicketsProvider;
