import { memo, useContext, useMemo } from 'react';

import UserDropdown from '../../../../common/components/UserDropdown';

import { NcrTemplateContext } from '../../../../common/contexts/ncrTemplate/store/context';

const ResponsibleDropdownComponent = ({ value, isDisabled, isLoading }) => {
    const { setNCRResponsible } = useContext(NcrTemplateContext);

    const handleChange = data => {
        setNCRResponsible(data);
    };

    const mappedValue = useMemo(() => (!!value?.length ? value[0] : null), [value]);

    return <UserDropdown value={mappedValue} isDisabled={isDisabled} isLoading={isLoading} onChange={handleChange} />;
};

const ResponsibleDropdown = memo(ResponsibleDropdownComponent);

export default ResponsibleDropdown;
