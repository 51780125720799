import { gql } from '@apollo/client';

export const GET_SINGLE_NCR = gql`
    query GetSingleNCR($getSingleNcrId: ID!) {
        getSingleNCR(id: $getSingleNcrId) {
            code
            success
            ncr {
                _id
                name
                status
                capaDetails {
                    _id
                    rootCause
                    impactDescription
                }
                follower
                priority
                finalComment
                impactDescription
                tags {
                    _id
                    name
                    colorCode
                    usedBy
                }
                resolvedFollower {
                    _id
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                resolvedNcrCreatedBy {
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                responsible {
                    responsibleID
                }
                resolvedResponsible {
                    _id
                    firstName
                    name
                    profilePic {
                        thumbnail
                    }
                }
                resolvedActivities {
                    activityActor {
                        actorType
                        actorID
                        externalActor
                    }
                    activityVerb
                    activityObject {
                        objectType
                        value
                        prevValue
                        valueObject {
                            priority
                            status
                            action
                            name
                        }
                        atMentions
                    }
                    activityTarget {
                        targetType
                        foreignID
                    }
                    createdAt
                    deleted
                    archived
                    organizations {
                        organizationID
                        teamsIDs
                    }
                    _id
                }
                basicInformation {
                    externalReference
                    externalReferenceLocation
                    mediaInputs {
                        _id
                        mediaName
                        mediaLabel
                        mediaType
                        attachmentType
                    }
                    quantityData {
                        defected
                        total
                    }
                    failureCategory {
                        name
                        failureMode
                    }
                    description
                    customInputElements {
                        _id
                        customInputElementType
                        name
                        placeholder {
                            _id
                            language
                            value
                        }
                        label {
                            _id
                            language
                            value
                        }
                        choices {
                            value
                            label {
                                _id
                                language
                                value
                            }
                        }
                        required
                        readOnly
                        min
                        max
                        value
                        defaultValue
                        currencyCode
                    }
                }
                reworks {
                    _id
                    hasConnector
                    processRoutings {
                        _id
                        processSteps {
                            _id
                            stepName {
                                value
                                language
                            }
                            stepNumber
                            stepStatus
                            responsible {
                                responsibleID
                            }
                            resolvedResponsible {
                                _id
                                name
                                firstName
                                profilePic {
                                    thumbnail
                                }
                            }
                            plannedDurations {
                                processTime
                            }
                            resolvedActualWorkplaces {
                                _id
                                workPlaceName {
                                    _id
                                    language
                                    value
                                }
                            }
                            workplaces {
                                _id
                                workplaceType
                                workPlaceName {
                                    _id
                                    language
                                    value
                                }
                            }
                            actualWorkplaces {
                                workplaceType
                                workPlaceName {
                                    _id
                                    language
                                    value
                                }
                            }
                            schedulingData {
                                dueDate
                            }
                        }
                    }
                    responsible {
                        responsibleID
                    }
                    resolvedResponsible {
                        name
                        firstName
                        _id
                    }
                    status
                    costAssignments {
                        value
                        currency
                        costCenter
                    }
                    workflowsIDs
                    resolvedWorkflows {
                        _id
                        name {
                            _id
                            language
                            value
                        }
                        workflowType
                        ncrID
                        resolvedWorkflowSteps {
                            _id
                            name {
                                _id
                                language
                                value
                            }
                            status
                            ncrID
                            workflowID
                            workflowStepTemplateID
                            resolvedResponsible {
                                _id
                                firstName
                                name
                                profilePic {
                                    thumbnail
                                }
                            }
                            availableActions {
                                _id
                                label {
                                    _id
                                    language
                                    value
                                }
                                caption {
                                    _id
                                    language
                                    value
                                }
                                trigger
                            }
                            dueDate
                        }
                    }
                }
                scraps {
                    _id
                    piecesToDeliver
                    piecesToRemake
                    discardedPieces
                    piecesInStock
                    comment
                    customInputElements {
                        _id
                        name
                        required
                        defaultValue
                        value
                        customInputElementType
                        choices {
                            value
                            label {
                                language
                                value
                            }
                        }
                        readOnly
                        label {
                            _id
                            language
                            value
                            formattedValue
                        }
                    }
                    responsible {
                        responsibleID
                    }
                    resolvedResponsible {
                        name
                        firstName
                        profilePic {
                            thumbnail
                        }
                    }
                    status
                    costAssignments {
                        value
                        currency
                        costCenter
                    }
                    resolvedWorkflows {
                        _id
                        name {
                            _id
                            language
                            value
                        }
                        ncrID
                        resolvedWorkflowSteps {
                            _id
                            name {
                                _id
                                language
                                value
                            }
                            resolvedResponsible {
                                _id
                                firstName
                                name
                                profilePic {
                                    thumbnail
                                }
                            }
                            status
                            dueDate
                            ncrID
                            availableActions {
                                _id
                                label {
                                    _id
                                    language
                                    value
                                }
                                caption {
                                    _id
                                    language
                                    value
                                }
                                trigger
                            }
                        }
                    }
                }
                clearances {
                    _id
                    comments
                    clearanceRequest
                    clearanceResponse
                    responsible {
                        responsibleID
                    }
                    resolvedResponsible {
                        name
                        firstName
                        email
                        _id
                    }
                    status
                    costAssignments {
                        value
                        currency
                        costCenter
                    }
                    approvalStatus
                    customInputElements {
                        _id
                        name
                        required
                        defaultValue
                        value
                        customInputElementType
                        label {
                            _id
                            language
                            value
                            formattedValue
                        }
                    }
                    workflowsIDs
                    resolvedWorkflows {
                        _id
                        name {
                            _id
                            language
                            value
                            formattedValue
                        }
                        ncrID
                        workflowType
                        resolvedWorkflowSteps {
                            _id
                            status
                            dueDate
                            workflowID
                            ncrID
                            workflowStepTemplateID
                            resolvedResponsible {
                                _id
                                firstName
                                name
                                profilePic {
                                    thumbnail
                                }
                            }
                            name {
                                _id
                                language
                                value
                                formattedValue
                            }
                            availableActions {
                                _id
                                trigger
                                caption {
                                    formattedValue
                                    value
                                    language
                                    _id
                                }
                                label {
                                    formattedValue
                                    value
                                    language
                                    _id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
