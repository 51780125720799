import { ncrTemplateActions } from './actions';

import update from 'immutability-helper';

import createMongoObjectId from '../../../../utils/mongo';

update.extend('$popById', (value, object) => {
    const obj = JSON.parse(JSON.stringify(object));

    for (var i = 0; i < obj.length; i++) {
        if (obj[i] && obj[i].id === value) {
            obj.splice(i, 1);
            break;
        }
    }
    return obj;
});

update.extend('$auto', (value, object) => (object ? update(object, value) : update({}, value)));

update.set = (obj, path, value) => {
    const pathMap = {};
    let pathPointer = pathMap;

    const pathArray = path.split('.');

    pathArray.forEach((item, idx) => {
        if (idx === pathArray.length - 1) {
            pathPointer['$auto'] = { [item]: { $set: value } };
        } else {
            const temp = {};
            pathPointer['$auto'] = { [item]: temp };
            pathPointer = temp;
        }
    });

    return update(obj, pathMap);
};

export const ncrTemplateInitialState = {
    ncr: null,
    defaultNcr: null,
};

export const ncrTemplateReducer = (state, action) => {
    switch (action.type) {
        case ncrTemplateActions.SET_NCR: {
            const newState = update.set(state.ncr, action.payload.path, action.payload.value);

            return {
                ...state,
                ncr: { ...newState, _id: state?.ncr?._id },
            };
        }

        case ncrTemplateActions.SET_NCR_RESPONSIBLE: {
            return {
                ...state,
                ncr: {
                    ...state.ncr,
                    responsible: {
                        responsibleID: action?.payload?.value,
                    },
                    resolvedResponsible: [action.payload],
                },
            };
        }

        case ncrTemplateActions.SET_NCR_CUSTOM_FIELDS: {
            return {
                ...state,
                ncr: {
                    ...state.ncr,
                    basicInformation: {
                        ...state?.ncr?.basicInformation,
                        customInputElements: state?.ncr?.basicInformation?.customInputElements?.map(item => {
                            if (item._id !== action.payload.id) {
                                return item;
                            }

                            return {
                                ...item,
                                value: action.payload.value,
                            };
                        }),
                    },
                },
            };
        }

        case ncrTemplateActions.SET_NCR_TEMPLATE:
            return {
                ...state,
                ncr: { ...action.payload, _id: createMongoObjectId() },
                defaultNcr: action.payload,
            };

        case ncrTemplateActions.SET_NCR_TEMPLATE_ATTACHMENTS:
            return {
                ...state,
                ncr: {
                    ...state.ncr,
                    basicInformation: {
                        ...state.ncr.basicInformation,
                        mediaInputs: action.payload,
                    },
                },
            };

        case ncrTemplateActions.SET_NCR_REWORK_PROCESS_STEP:
            return {
                ...state,
                ncr: {
                    ...state.ncr,
                    reworks: state?.ncr?.reworks?.map(rework => {
                        if (rework._id !== action.payload.reworkId) {
                            return rework;
                        }

                        return {
                            ...rework,
                            processRoutings: action.payload.value,
                        };
                    }),
                },
            };

        case ncrTemplateActions.UPDATE_NCR_REWORK_WORKFLOW_STEPS:
            return {
                ...state,
                ncr: {
                    ...state.ncr,
                    reworks: state.ncr.reworks.map(rework => {
                        if (rework._id !== action.payload.sectionId) {
                            return rework;
                        }

                        return {
                            ...rework,
                            resolvedWorkflows: rework.resolvedWorkflows.map(workflow => {
                                if (workflow._id !== action.payload.workflowId) {
                                    return workflow;
                                }

                                return {
                                    ...workflow,
                                    resolvedWorkflowSteps: workflow.resolvedWorkflowSteps.map(step => {
                                        if (step._id === action.payload.value.updateCurrentStep.workflowStep._id) {
                                            return {
                                                ...step,
                                                ...action.payload.value.updateCurrentStep.workflowStep,
                                            };
                                        }

                                        if (step._id === action.payload.value.updateNextStep?.workflowStep._id) {
                                            return {
                                                ...step,
                                                ...action.payload.value.updateNextStep?.workflowStep,
                                            };
                                        }

                                        return step;
                                    }),
                                };
                            }),
                        };
                    }),
                    scraps: state.ncr.scraps.map(scrap => {
                        if (scrap._id !== action.payload.sectionId) {
                            return scrap;
                        }

                        return {
                            ...scrap,
                            resolvedWorkflows: scrap.resolvedWorkflows.map(workflow => {
                                if (workflow._id !== action.payload.workflowId) {
                                    return workflow;
                                }

                                return {
                                    ...workflow,
                                    resolvedWorkflowSteps: workflow.resolvedWorkflowSteps.map(step => {
                                        if (step._id === action.payload.value.updateCurrentStep.workflowStep._id) {
                                            return {
                                                ...step,
                                                ...action.payload.value.updateCurrentStep.workflowStep,
                                            };
                                        }

                                        if (step._id === action.payload.value.updateNextStep?.workflowStep._id) {
                                            return {
                                                ...step,
                                                ...action.payload.value.updateNextStep?.workflowStep,
                                            };
                                        }

                                        return step;
                                    }),
                                };
                            }),
                        };
                    }),
                    clearances: state.ncr.clearances.map(clearance => {
                        if (clearance._id !== action.payload.sectionId) {
                            return clearance;
                        }

                        return {
                            ...clearance,
                            resolvedWorkflows: clearance.resolvedWorkflows.map(workflow => {
                                if (workflow._id !== action.payload.workflowId) {
                                    return workflow;
                                }

                                return {
                                    ...workflow,
                                    resolvedWorkflowSteps: workflow.resolvedWorkflowSteps.map(step => {
                                        if (step._id === action.payload.value.updateCurrentStep.workflowStep._id) {
                                            return {
                                                ...step,
                                                ...action.payload.value.updateCurrentStep?.workflowStep,
                                            };
                                        }

                                        if (step._id === action.payload.value.updateNextStep?.workflowStep._id) {
                                            return {
                                                ...step,
                                                ...action.payload.value.updateNextStep.workflowStep,
                                            };
                                        }

                                        return step;
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };

        case ncrTemplateActions.UPDATE_NCR_REWORK_WORKFLOW_STATUS:
            return {
                ...state,
                ncr: {
                    ...state.ncr,
                    reworks: state.ncr.reworks.map(rework => {
                        if (rework._id !== action.payload.sectionId) {
                            return rework;
                        }

                        return {
                            ...rework,
                            status: action.payload.value,
                        };
                    }),
                    scraps: state.ncr.scraps.map(scrap => {
                        if (scrap._id !== action.payload.sectionId) {
                            return scrap;
                        }

                        return {
                            ...scrap,
                            status: action.payload.value,
                        };
                    }),
                    clearances: state.ncr.clearances.map(clearance => {
                        if (clearance._id !== action.payload.sectionId) {
                            return clearance;
                        }

                        return {
                            ...clearance,
                            status: action.payload.value,
                        };
                    }),
                },
            };
        case ncrTemplateActions.UPDATE_NCR_IMMEDIATE_MEASURE_SECTION_WORKFLOW_RESPONSIBLE:
            return {
                ...state,
                ncr: {
                    ...state.ncr,
                    reworks: state.ncr.reworks.map(rework => {
                        if (rework._id !== action.payload.sectionId) {
                            return rework;
                        }

                        const [workflow, restWorkflows] = rework?.resolvedWorkflows;

                        return {
                            ...rework,
                            resolvedWorkflows: [
                                {
                                    ...workflow,
                                    resolvedWorkflowSteps: workflow.resolvedWorkflowSteps.map(workflowStep => {
                                        if (workflowStep._id !== action.payload.workflowStepId) {
                                            return workflowStep;
                                        }

                                        return {
                                            ...workflowStep,
                                            resolvedResponsible: action.payload.resolvedResponsible,
                                        };
                                    }),
                                },
                                ...[restWorkflows || []],
                            ],
                        };
                    }),
                    clearances: state.ncr.clearances.map(clearance => {
                        if (clearance._id !== action.payload.sectionId) {
                            return clearance;
                        }
                        const [workflow, restWorkflows] = clearance?.resolvedWorkflows;

                        return {
                            ...clearance,
                            resolvedWorkflows: [
                                {
                                    ...workflow,
                                    resolvedWorkflowSteps: workflow.resolvedWorkflowSteps.map(workflowStep => {
                                        if (workflowStep._id !== action.payload.workflowStepId) {
                                            return workflowStep;
                                        }

                                        return {
                                            ...workflowStep,
                                            resolvedResponsible: action.payload.resolvedResponsible,
                                        };
                                    }),
                                },
                                ...[restWorkflows || []],
                            ],
                        };
                    }),
                    scraps: state.ncr.scraps.map(scrap => {
                        if (scrap._id !== action.payload.sectionId) {
                            return scrap;
                        }

                        const [workflow, restWorkflows] = scrap?.resolvedWorkflows;

                        return {
                            ...scrap,
                            resolvedWorkflows: [
                                {
                                    ...workflow,
                                    resolvedWorkflowSteps: workflow.resolvedWorkflowSteps.map(workflowStep => {
                                        if (workflowStep._id !== action.payload.workflowStepId) {
                                            return workflowStep;
                                        }

                                        return {
                                            ...workflowStep,
                                            resolvedResponsible: action.payload.resolvedResponsible,
                                        };
                                    }),
                                },
                                ...[restWorkflows || []],
                            ],
                        };
                    }),
                },
            };

        case ncrTemplateActions.RESET_NCR:
            return {
                ...state,
                ncr: { ...state.defaultNcr, _id: createMongoObjectId() },
            };

        default:
            return state;
    }
};
