import React from 'react';
import { useTranslation } from 'react-i18next';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { notificationsSeverityByRequestStatus } from '../../../../providers/notification/constants';
import { useNotificationContext } from '../../../../providers/notification/store/notification.context';

import { ncrStatus } from '../../../../enums/ncrStatus';

import { useSharedAtomicNCR } from '../../hooks/useSharedAtomicNCR';
import { useUpdateWorkflowStepInApprovalMode } from '../hooks/useUpdateWorkflowStepInApprovalMode';

const ApprovalModal = ({
    ncrId,
    sectionId,
    isOpened,
    toggleModal,
    workflowStepId,
    nextWorkflowStepId,
    workflowId,
    handleNcrUpdate,
    path,
    childrenIds,
    type,
}) => {
    const { t } = useTranslation();

    const { setNotification } = useNotificationContext();

    const [handleUpdateWorkflowStepInApprovalMode, loading] = useUpdateWorkflowStepInApprovalMode();
    const [handleAtomicUpdate] = useSharedAtomicNCR();

    const handleApprovalAction = () => {
        handleUpdateWorkflowStepInApprovalMode({ currentWorkflowStepId: workflowStepId })
            .then(response => {
                if (response?.data?.updateCurrentStep?.success) {
                    handleAtomicUpdate({
                        ncrId,
                        operation: 'update',
                        path,
                        values: { [type]: { status: ncrStatus.DONE } },
                        childrenIds,
                    })
                        .then(result => {
                            setNotification({ severity: notificationsSeverityByRequestStatus[result.data.atomicNCR.success] });
                            toggleModal();
                        })
                        .catch(_ => {
                            setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                        });
                }
            })
            .catch(_ => {
                setNotification({ severity: notificationsSeverityByRequestStatus[false] });
            });
    };

    return (
        <Dialog fullWidth maxWidth="sm" onClose={toggleModal} open={isOpened}>
            <DialogTitle sx={{ textAlign: 'center', marginBottom: '2em' }} variant="body1">
                <Typography variant="h5">{t('ncrInfo.immediate.actions.approval.title')}</Typography>
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'center' }}>
                <Typography variant="body1" sx={{ marginBottom: '1em' }}>
                    {t('ncrInfo.immediate.actions.approval.description')}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', direction: 'row', justifyContent: 'center', paddingBottom: '2em' }}>
                <Button sx={{ cursor: 'pointer' }} onClick={toggleModal} variant="outlined" color="primary">
                    <Typography variant="button" sx={{ cursor: 'pointer' }}>
                        {t('ncrInfo.immediate.actions.approval.backButton')}
                    </Typography>
                </Button>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleApprovalAction}
                    startIcon={<TaskAltIcon />}
                    variant="contained"
                    color="success">
                    <Typography variant="button" sx={{ cursor: 'pointer' }}>
                        {t('ncrInfo.immediate.actions.approval.submitButton')}
                    </Typography>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ApprovalModal;
