import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import ActivityTab from './tabs/activityTab/ActivityTab';
import CreateNCRButton from './tabs/infoTab/components/CreateNCRButton';
import NoNCRButton from './tabs/infoTab/components/NoNCRButton';
import InfoTab from './tabs/infoTab/InfoTab';

import { NCRInboxPaperTabsIndex } from '../constants';

const tabContent = {
    [NCRInboxPaperTabsIndex.INFO_TAB]: {
        render: ({ ncr, loading }) => (
            <Box
                sx={{
                    height: 'calc(100vh - 334px)',
                    overflowY: 'auto',
                    paddingRight: '1em',
                }}>
                <InfoTab ncr={ncr} loading={loading} />
            </Box>
        ),
    },
    [NCRInboxPaperTabsIndex.ACTIVITY_TAB]: {
        render: () => (
            <Box
                sx={{
                    height: 'calc(100vh - 282px)',
                    overflowY: 'hidden',
                    paddingRight: '1em',
                }}>
                <ActivityTab />
            </Box>
        ),
    },
};

const NCRInboxPaperTabs = ({ ncr, loading }) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [activeTabIndex, setActiveTabIndex] = useState(NCRInboxPaperTabsIndex.INFO_TAB);

    const handleTabIndexChange = (_event, newTabIndex) => {
        setActiveTabIndex(newTabIndex);
    };

    return (
        <>
            <Box>
                <Tabs value={activeTabIndex} onChange={handleTabIndexChange}>
                    <Tab label={t('inbox.tabs.info')} tabIndex={NCRInboxPaperTabsIndex.INFO_TAB} />
                    <Tab label={t('inbox.tabs.activities')} tabIndex={NCRInboxPaperTabsIndex.ACTIVITY_TAB} />
                </Tabs>
            </Box>
            {tabContent[activeTabIndex]?.render({ ncr, loading })}
            {activeTabIndex === NCRInboxPaperTabsIndex.INFO_TAB && (
                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Box display="flex" sx={{ justifyContent: 'end' }}>
                                <NoNCRButton />
                            </Box>
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: mobile ? '16px' : '32px' }}>
                            <Box display="flex" sx={{ justifyContent: 'start' }}>
                                <CreateNCRButton />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default memo(NCRInboxPaperTabs);
