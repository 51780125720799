import { useMutation } from '@apollo/client';
import { ATOMIC_NCR } from '../operations/mutations/atomicNCR';

export const useSharedAtomicNCR = () => {
    const [atomic, { loading }] = useMutation(ATOMIC_NCR);

    const handleAtomic = ({ ncrId, operation, path, values, childrenIds }) => {
        return atomic({
            variables: {
                atomics: [
                    {
                        ncrId,
                        operation,
                        path,
                        values,
                        childrenIds,
                    },
                ],
            },
        });
    };

    return [handleAtomic, loading];
};
