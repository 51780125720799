import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ClearanceCustomInputs from './ClearanceCustomInputs';
import CommentsInput from './inputs/CommentsInput';
import StatementInput from './inputs/StatementInput';

const useStyles = makeStyles(theme => ({
    inputWrapper: {
        marginBottom: '1.5em',
    },
}));

const SpecialReleaseSectionComponent = ({ handleUpdate, clearance, disabled }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div>
            <Box marginTop="2em" marginBottom="0.25em">
                <Typography variant="overline" textTransform="uppercase">
                    {t('ncrInfo.immediate.specialReleaseStatement')}
                </Typography>
            </Box>
            <Divider sx={{ marginBottom: '1.5em' }} />

            <div className={classes.inputWrapper}>
                <StatementInput value={clearance?.clearanceResponse} handleUpdate={handleUpdate} disabled={disabled} />
            </div>

            <div className={classes.inputWrapper}>
                <ClearanceCustomInputs
                    customInputElements={clearance?.customInputElements}
                    handleUpdate={handleUpdate}
                    disabled={disabled}
                />
            </div>

            <div className={classes.inputWrapper}>
                <CommentsInput value={clearance?.comments} handleUpdate={handleUpdate} disabled={disabled} />
            </div>
        </div>
    );
};

const SpecialReleaseSection = memo(SpecialReleaseSectionComponent);

export default SpecialReleaseSection;
