import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { GET_PAGINATED_TAGS } from '../operations/queries/getPaginatedTags';

export const useGetTagsOptions = () => {
    const { data, loading } = useQuery(GET_PAGINATED_TAGS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            filter: JSON.stringify({
                usedBy: 'ncr',
            }),
        },
    });

    const tagsOptions = useMemo(
        () => data?.getPaginateTags?.tag?.map(tag => ({ label: tag.name, value: tag._id, colorCode: tag?.colorCode, usedBy: 'ncr' })) || [],
        [data?.getPaginateTags?.tag]
    );

    return [tagsOptions, loading];
};
