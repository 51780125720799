import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardActions, CardContent, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import InboxHomeScreenIcon from '../../common/components/icons/InboxHomeScreenIcon';
import MyTasksHomeScreenIcon from '../../common/components/icons/MyTasksHomeScreenIcon';
import OverviewHomeScreenIcon from '../../common/components/icons/OverviewHomeScreenIcon';
import ReportHomeScreenIcon from '../../common/components/icons/ReportHomeScreenIcon';
import MenuLink from '../../common/components/MenuLink';

import {
    inboxPageRoles,
    ncrOverviewPageRoles,
    reportPageRoles,
    tasksPageRoles,
    ticketsKanbanBoardPageRoles,
} from '../../common/constants/userRoles';
import { useUserSettingsContext } from '../../common/providers/userSettings/store/userSettings.context';

import { useCurrentUserBySub } from '../../utils/awsAuth';

const HomeScreenItem = styled(Card)(({ theme }) => ({
    height: '100%',
    padding: theme.spacing(2),
}));

const HomeScreen = () => {
    const { t } = useTranslation();

    const { data } = useCurrentUserBySub();

    const { userData } = useUserSettingsContext();

    const isAllowedRoport = userData && userData.roles?.some(userRole => reportPageRoles?.some(role => role === userRole));
    const isAllowedCapaTickets = userData && userData.roles?.some(userRole => ticketsKanbanBoardPageRoles?.some(role => role === userRole));

    return (
        <Container maxWidth="lg" sx={{ padding: '8em' }}>
            <Grid container spacing={4}>
                {data?.roles?.some(userRole => reportPageRoles?.some(role => role === userRole)) && (
                    <Grid item xs={12} sm={6}>
                        <HomeScreenItem>
                            <CardContent sx={{ padding: 0, height: '135px' }}>
                                <Grid item xs={12} sm container>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Grid item xs>
                                            <Typography fontSize="12px" color="primary.main" gutterBottom>
                                                {t('home-page.incidentCapture.report')}
                                            </Typography>
                                            <Typography paddingBottom="0.5em" variant="h5" color="text.primary">
                                                {t('home-page.incidentCapture.title')}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {t('home-page.incidentCapture.description')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <ReportHomeScreenIcon />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions padding="0">
                                <MenuLink to="/report">
                                    <Button size="small">{t('home-page.incidentCapture.capture')}</Button>
                                </MenuLink>
                            </CardActions>
                        </HomeScreenItem>
                    </Grid>
                )}
                {data?.roles?.some(userRole => inboxPageRoles?.some(role => role === userRole)) && (
                    <Grid item xs={12} sm={6}>
                        <HomeScreenItem>
                            <CardContent sx={{ padding: 0, height: '135px' }}>
                                <Grid item xs={12} sm container>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Grid item xs>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                {t('home-page.newMessages.inbox')}
                                            </Typography>
                                            <Typography sx={{ paddingBottom: '0.5em' }} variant="h5" color="text.primary">
                                                {t('home-page.newMessages.title')}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {t('home-page.newMessages.description')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <InboxHomeScreenIcon />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions sx={{ padding: 0 }}>
                                <MenuLink to="/inbox">
                                    <Button size="small">{t('home-page.newMessages.toSue')}</Button>
                                </MenuLink>
                            </CardActions>
                        </HomeScreenItem>
                    </Grid>
                )}
                {data?.roles?.some(userRole => ncrOverviewPageRoles?.some(role => role === userRole)) && (
                    <Grid item xs={12} sm={6}>
                        <HomeScreenItem>
                            <CardContent sx={{ padding: 0, height: '135px' }}>
                                <Grid item xs={12} sm container>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Grid item xs>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                {t('home-page.ncrOverview.overview')}
                                            </Typography>
                                            <Typography sx={{ paddingBottom: '0.5em' }} variant="h5" color="text.primary">
                                                {t('home-page.ncrOverview.title')}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {t('home-page.ncrOverview.description')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <OverviewHomeScreenIcon />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions sx={{ padding: 0 }}>
                                <MenuLink to="/ncr-overview">
                                    <Button>{t('home-page.ncrOverview.toSue')}</Button>
                                </MenuLink>
                                {isAllowedRoport && <Button>{t('home-page.ncrOverview.createNCR')}</Button>}
                            </CardActions>
                        </HomeScreenItem>
                    </Grid>
                )}
                {data?.roles?.some(userRole => tasksPageRoles?.some(role => role === userRole)) && (
                    <Grid item xs={12} sm={6}>
                        <HomeScreenItem>
                            <CardContent sx={{ padding: 0, height: '135px' }}>
                                <Grid item xs={12} sm container>
                                    <Grid item xs container direction="column" spacing={2}>
                                        <Grid item xs>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                {t('home-page.myTasks.overview')}
                                            </Typography>
                                            <Typography sx={{ paddingBottom: '0.5em' }} variant="h5" color="text.primary">
                                                {t('home-page.myTasks.title')}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {t('home-page.myTasks.description')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <MyTasksHomeScreenIcon />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions sx={{ padding: 0 }}>
                                <MenuLink to="/my-tasks">
                                    <Button>{t('home-page.myTasks.toSue')}</Button>
                                </MenuLink>
                                {isAllowedCapaTickets && (
                                    <MenuLink to="/tickets-kanban-board">
                                        <Button>{t('home-page.myTasks.capa')}</Button>
                                    </MenuLink>
                                )}
                            </CardActions>
                        </HomeScreenItem>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default HomeScreen;
