import { useCallback } from 'react';

import SearchInput from '../../../../common/components/SearchInput';
import { useNcrMyTasksContext } from '../../store/NCRMyTasksProvider';

const MyTasksSearchInput = () => {
    const { searchFilter, setNcrMyTasksSearchFilter } = useNcrMyTasksContext();

    const memoizedChangeHandler = useCallback(
        event => {
            setNcrMyTasksSearchFilter(event?.target?.value);
        },
        [setNcrMyTasksSearchFilter]
    );

    return (
        <SearchInput
            fullWidth={false}
            value={searchFilter}
            sx={{ minWidth: 324 }}
            onKeyPress={event => {
                event.key === 'Enter' && memoizedChangeHandler(event);
            }}
            onBlur={memoizedChangeHandler}
            onClear={() => setNcrMyTasksSearchFilter('')}
        />
    );
};

export default MyTasksSearchInput;
