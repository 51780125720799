import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import TableNoDataOverlay from '../../../../common/components/table/TableNoDataOverlay';

import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../common/constants/constants';

import { useNcrOverviewTableData } from '../../hooks/useNcrOverviewTableData';

import { useNcrOverviewContext } from '../../store/NCROverviewProvider';

const NcrOverviewTable = () => {
    const navigate = useNavigate();

    const {
        page,
        sortBy,
        order,
        searchFilter,
        statusFilter,
        dateRangeFilter,
        archivedFilter,
        failureCategoryFilter,
        setNcrOverviewTablePage,
        setNcrOverviewTableSorting,
    } = useNcrOverviewContext();

    const [rows, columns, loading, total, fetchData] = useNcrOverviewTableData();

    useEffect(() => {
        const { from, to } = dateRangeFilter;

        fetchData(page, searchFilter, statusFilter, from, to, failureCategoryFilter, archivedFilter, sortBy, order);
    }, [
        fetchData,
        page,
        statusFilter,
        dateRangeFilter,
        failureCategoryFilter,
        searchFilter,
        archivedFilter,
        sortBy,
        order,
    ]);

    const handleTableSort = model => {
        const [item] = model;

        setNcrOverviewTableSorting({ sortBy: item.field, order: item.sort });
    };

    const handleRowClick = (params, event) => {
        if (!event?.ignoreRowClick) {
            navigate(`/ncr-overview/${params.id}`);
        }
    };

    return (
        <DataGrid
            sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 600,
                },
                '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                    display: 'none',
                },
            }}
            rows={rows}
            columns={columns}
            components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: TableNoDataOverlay,
            }}
            onRowClick={handleRowClick}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            hideFooterSelectedRowCount
            getRowId={row => row.rowId}
            loading={loading}
            pagination
            page={page}
            rowCount={total}
            pageSize={DEFAULT_TABLE_PAGE_SIZE}
            rowsPerPageOptions={[10]}
            paginationMode="server"
            sortingOrder={['asc', 'desc']}
            sortingMode="server"
            onPageChange={setNcrOverviewTablePage}
            onSortModelChange={handleTableSort}
        />
    );
};

export default NcrOverviewTable;
