import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import { Box, Collapse, Divider, Grid, Typography } from '@mui/material';

import { useAtomicNCR } from '../../../pages/inbox/hooks/useAtomicNCR';
import { useUpdateNCR } from '../../../pages/inbox/hooks/useUpdateNCR';
import { useCollapse } from '../../hooks/useCollapse';

import NcrCheckbox from '../../../pages/report/components/customFields/NcrCheckbox';
import NcrInput from '../../../pages/report/components/customFields/NcrInput';
import NcrSelect from '../../../pages/report/components/customFields/NcrSelect';
import CreatedByInput from './../../../pages/report/components/inputs/CreatedByInput';
import UpdateAffectedPiecesInput from './inputs/UpdateAffectedPiecesInput';
import UpdateDescriptionInput from './inputs/UpdateDescriptionInput';
import UpdateFailureCodeDropdown from './inputs/UpdateFailureCodeDropdown';
import UpdateFollowerDropdown from './inputs/UpdateFollowerDropdown';
import UpdatePriorityDropdown from './inputs/UpdatePriorityDropdown';
import UpdateReferenceInput from './inputs/UpdateReferenceInput';
import UpdateReferenceLocationInput from './inputs/UpdateReferenceLocationInput';
import UpdateResponsibleDropdown from './inputs/UpdateResponsibleDropdown';
import UpdateTagsDropdown from './inputs/UpdateTagsDropdown';
import UpdateTotalAmountInput from './inputs/UpdateTotalAmountInput';

import { useNotificationContext } from '../../providers/notification/store/notification.context';
import { useUserSettingsContext } from '../../providers/userSettings/store/userSettings.context';

import { getValueByLanguage } from '../../../utils/common';
import { customInputElementType } from '../../enums/customInputElementType';
import { notificationsSeverityByRequestStatus } from '../../providers/notification/constants';
import { ncrStatus } from '../../enums/ncrStatus';

export const ncrInfoFields = language => {
    return {
        [customInputElementType.SELECT]: props => {
            const label = getValueByLanguage(props.label, language);

            const options = props.choices.map(option => {
                return {
                    label: getValueByLanguage(option.label, language),
                    value: option.value,
                };
            });

            const currentValue = props.value ? options?.find(option => option?.value === props.value) : null;

            const handleChange = newValue => {
                props.handleUpdate(
                    'basicInformation.customInputElements.$[customInputElementId]',
                    {
                        customInputElement: { value: newValue || '' },
                    },
                    'update',
                    {
                        customInputElementId: props.customInputElementId,
                    }
                );
            };

            return (
                <NcrSelect
                    disabled={props.readOnly || props.disabled}
                    value={currentValue}
                    loading={props.loading}
                    options={options}
                    label={label}
                    labelKey={'label'}
                    valueKey={'value'}
                    handleChange={handleChange}
                />
            );
        },

        [customInputElementType.TEXT]: props => {
            const handleBlur = event => {
                props.handleUpdate(
                    'basicInformation.customInputElements.$[customInputElementId]',
                    {
                        customInputElement: { value: event.target.value },
                    },
                    'update',
                    {
                        customInputElementId: props.customInputElementId,
                    }
                );
            };

            const label = getValueByLanguage(props.label, language);

            return (
                <NcrInput
                    disabled={props.readOnly || props.disabled}
                    value={props.value}
                    onBlur={handleBlur}
                    onKeyPress={event => {
                        event.key === 'Enter' && handleBlur(event);
                    }}
                    label={label}
                />
            );
        },

        [customInputElementType.NUMBER]: props => {
            const handleBlur = event => {
                props.handleUpdate(
                    'basicInformation.customInputElements.$[customInputElementId]',
                    {
                        customInputElement: { value: event.target.value },
                    },
                    'update',
                    {
                        customInputElementId: props.customInputElementId,
                    }
                );
            };

            const label = getValueByLanguage(props.label, language);

            return (
                <NcrInput
                    disabled={props.readOnly || props.disabled}
                    value={props.value}
                    onBlur={handleBlur}
                    onKeyPress={event => {
                        event.key === 'Enter' && handleBlur(event);
                    }}
                    type="number"
                    label={label}
                />
            );
        },

        [customInputElementType.CHECKBOXES]: props => {
            const [choices] = props.choices;
            const { label } = choices;
            const title = getValueByLanguage(label, language);

            const handleChange = event => {
                props.handleUpdate(
                    'basicInformation.customInputElements.$[customInputElementId]',
                    {
                        customInputElement: { value: event.target.value, _id: props.customInputElementId },
                    },
                    'update',
                    {
                        customInputElementId: props.customInputElementId,
                    }
                );
            };

            return (
                <NcrCheckbox disabled={props.readOnly || props.disabled} value={props.value} label={title} handleChange={handleChange} />
            );
        },
    };
};

const NcrBasicInfoForm = ({ ncr, loading }) => {
    const { t } = useTranslation();

    const { language } = useUserSettingsContext();
    const { setNotification } = useNotificationContext();

    const [isCollapsed, collapse] = useCollapse(false);

    const [handleAtomicUpdate, ncrAtomicLoading] = useAtomicNCR();
    const [updateNCR, ncrUpdateLoading] = useUpdateNCR();

    const isLoading = loading || ncrAtomicLoading || ncrUpdateLoading;
    const ncrId = ncr?._id;

    const handleAtomicNcrUpdate = (path, values, operation = 'update', childrenIds = undefined) => {
        if (ncrId && values) {
            handleAtomicUpdate({ ncrId, operation, path, values, childrenIds })
                .then(result => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[result.data.atomicNCR.success] });
                })
                .catch(_ => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                });
        }
    };

    const handleNcrUpdate = value => {
        updateNCR(ncrId, value)
            .then(response => {
                setNotification({ severity: notificationsSeverityByRequestStatus[response.data.updateNCR.success] });
            })
            .catch(_ => {
                setNotification({ severity: notificationsSeverityByRequestStatus[false] });
            });
    };

    const disabled = isLoading || ncr?.status === ncrStatus.DONE;

    return (
        <div>
            <Box marginBottom="0.25em">
                <Typography variant="overline">{t('ncrInfo.basicInformation')}</Typography>
            </Box>
            <Divider
                sx={{
                    marginBottom: '2em',
                }}
            />
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                    <UpdateReferenceInput
                        value={ncr?.basicInformation?.externalReference}
                        disabled={disabled}
                        handleUpdate={handleAtomicNcrUpdate}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <UpdateReferenceLocationInput
                        value={ncr?.basicInformation?.externalReferenceLocation}
                        disabled={disabled}
                        handleUpdate={handleAtomicNcrUpdate}
                    />
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                    <UpdateAffectedPiecesInput
                        value={ncr?.basicInformation?.quantityData}
                        disabled={disabled}
                        handleUpdate={handleAtomicNcrUpdate}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <UpdateTotalAmountInput
                        value={ncr?.basicInformation?.quantityData}
                        disabled={disabled}
                        handleUpdate={handleAtomicNcrUpdate}
                    />
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                    <UpdateFailureCodeDropdown
                        value={{
                            name: ncr?.basicInformation?.failureCategory?.name || '',
                            failureMode: ncr?.basicInformation?.failureCategory?.failureMode || '',
                        }}
                        disabled={disabled}
                        loading={isLoading}
                        handleUpdate={handleAtomicNcrUpdate}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <UpdateTagsDropdown value={ncr?.tags} disabled={disabled} loading={isLoading} handleUpdate={handleNcrUpdate} />
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                    <UpdatePriorityDropdown disabled={disabled} loading={isLoading} handleUpdate={handleNcrUpdate} value={ncr?.priority} />
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <UpdateDescriptionInput
                        value={ncr?.basicInformation?.description}
                        disabled={disabled}
                        handleUpdate={handleAtomicNcrUpdate}
                    />
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                    <CreatedByInput value={ncr?.resolvedNcrCreatedBy} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <UpdateResponsibleDropdown
                        value={ncr?.resolvedResponsible}
                        disabled={disabled}
                        loading={isLoading}
                        handleUpdate={handleAtomicNcrUpdate}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <UpdateFollowerDropdown disabled={disabled} value={ncr?.follower} handleUpdate={handleNcrUpdate} />
                </Grid>
            </Grid>
            <br />

            <Box paddingBottom="1em">
                <LoadingButton
                    variant="text"
                    loadingPosition="start"
                    loading={isLoading}
                    onClick={collapse}
                    startIcon={isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                    {isCollapsed ? t('common.expand-button.showLess') : t('common.expand-button.showMore')}
                </LoadingButton>
            </Box>
            <Collapse in={isCollapsed}>
                <Box marginBottom="0.25em">
                    <Typography variant="overline">{t('report.customFields')}</Typography>
                </Box>

                <Divider sx={{ marginBottom: '2em' }} />
                <Grid container spacing={4}>
                    {!!ncr?.basicInformation &&
                        ncr?.basicInformation?.customInputElements.map((field, index) => {
                            const item = ncrInfoFields(language)[field.customInputElementType];

                            return (
                                <Fragment key={field._id}>
                                    {item && (
                                        <Grid item xs={12} sm={6}>
                                            {item &&
                                                item({
                                                    ...field,
                                                    disabled,
                                                    handleUpdate: handleAtomicNcrUpdate,
                                                    customInputElementId: field._id,
                                                    customInputElementIndex: index,
                                                })}
                                        </Grid>
                                    )}
                                </Fragment>
                            );
                        })}
                </Grid>
            </Collapse>
        </div>
    );
};

export default NcrBasicInfoForm;
