export const ticketsKanbanBoardActions = {
    SET_TICKETS_KANBAN_BOARD_INCIDENT_TYPE: 'SET_TICKETS_KANBAN_BOARD_INCIDENT_TYPE',
    SET_TICKETS_KANBAN_BOARD_RESPONSIBLE: 'SET_TICKETS_KANBAN_BOARD_RESPONSIBLE',
    SET_TICKETS_KANBAN_BOARD_DATE_RANGE: 'SET_TICKETS_KANBAN_BOARD_DATE_RANGE',
    SET_TICKETS_KANBAN_BOARD_SEARCH: 'SET_TICKETS_KANBAN_BOARD_SEARCH',
    SET_ACTIVE_CAPA_TICKET: 'SET_ACTIVE_CAPA_TICKET',
    RESET_TICKETS_KANBAN_BOARD_FILTERS: 'RESET_TICKETS_KANBAN_BOARD_FILTERS',
};

export const setTicketsKanbanBoardIncidentType = payload => {
    return {
        type: ticketsKanbanBoardActions.SET_TICKETS_KANBAN_BOARD_INCIDENT_TYPE,
        payload,
    };
};

export const setActiveCapaTicket = payload => {
    return {
        type: ticketsKanbanBoardActions.SET_ACTIVE_CAPA_TICKET,
        payload,
    };
};

export const setTicketsKanbanBoardResponsible = payload => {
    return {
        type: ticketsKanbanBoardActions.SET_TICKETS_KANBAN_BOARD_RESPONSIBLE,
        payload,
    };
};

export const setTicketsKanbanBoardDateRange = payload => {
    return {
        type: ticketsKanbanBoardActions.SET_TICKETS_KANBAN_BOARD_DATE_RANGE,
        payload,
    };
};

export const setTicketsKanbanBoardSearch = payload => {
    return {
        type: ticketsKanbanBoardActions.SET_TICKETS_KANBAN_BOARD_SEARCH,
        payload,
    };
};

export const resetTicketsKanbanBoardFilters = () => {
    return {
        type: ticketsKanbanBoardActions.RESET_TICKETS_KANBAN_BOARD_FILTERS,
    };
};
