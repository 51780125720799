export const ncrOverviewActions = {
    SET_NCR_OVERVIEW_SEARCH_FILTER: 'SET_NCR_OVERVIEW_SEARCH_FILTER',
    SET_NCR_OVERVIEW_STATUS_FILTER: 'SET_NCR_OVERVIEW_STATUS_FILTER',
    SET_NCR_OVERVIEW_DATE_RANGE_FILTER: 'SET_NCR_OVERVIEW_DATE_RANGE_FILTER',
    SET_NCR_OVERVIEW_FAILURE_CATEGORY_FILTER: 'SET_NCR_OVERVIEW_FAILURE_CATEGORY_FILTER',
    RESET_NCR_OVERVIEW_FILTERS: 'RESET_NCR_OVERVIEW_FILTERS',
    SET_NCR_OVERVIEW_TABLE_PAGE: 'SET_NCR_OVERVIEW_TABLE_PAGE',
    SET_NCR_OVERVIEW_TABLE_SORTING: 'SET_NCR_OVERVIEW_TABLE_SORTING',
    SET_NCR_OVERVIEW_SELECTED_TEAMS: 'SET_NCR_OVERVIEW_SELECTED_TEAMS',
    TOGGLE_NCR_OVERVIEW_FILTERS: 'TOGGLE_NCR_OVERVIEW_FILTERS',
    SET_NCR_OVERVIEW_ARCHIVED_FILTER: 'SET_NCR_OVERVIEW_ARCHIVED_FILTER',
};

export const setNcrOverviewSearchFilter = payload => {
    return {
        type: ncrOverviewActions.SET_NCR_OVERVIEW_SEARCH_FILTER,
        payload,
    };
};

export const setNcrOverviewStatusFilter = payload => {
    return {
        type: ncrOverviewActions.SET_NCR_OVERVIEW_STATUS_FILTER,
        payload,
    };
};

export const setNcrOverviewDateRangeFilter = payload => {
    return {
        type: ncrOverviewActions.SET_NCR_OVERVIEW_DATE_RANGE_FILTER,
        payload,
    };
};

export const setNcrOverviewFailureCategoryFilter = payload => {
    return {
        type: ncrOverviewActions.SET_NCR_OVERVIEW_FAILURE_CATEGORY_FILTER,
        payload,
    };
};

export const resetNcrOverviewFilters = () => {
    return {
        type: ncrOverviewActions.RESET_NCR_OVERVIEW_FILTERS,
    };
};

export const toggleNcrOverviewFilters = () => {
    return {
        type: ncrOverviewActions.TOGGLE_NCR_OVERVIEW_FILTERS,
    };
};

export const setNcrOverviewTablePage = payload => {
    return {
        type: ncrOverviewActions.SET_NCR_OVERVIEW_TABLE_PAGE,
        payload,
    };
};

export const setNcrOverviewTableSorting = payload => {
    return {
        type: ncrOverviewActions.SET_NCR_OVERVIEW_TABLE_SORTING,
        payload,
    };
};

export const setNrcOverviewSelectedTeams = payload => {
    return {
        type: ncrOverviewActions.SET_NCR_OVERVIEW_SELECTED_TEAMS,
        payload,
    };
};

export const setNcrOverviewArchivedFilter = payload => {
    return {
        type: ncrOverviewActions.SET_NCR_OVERVIEW_ARCHIVED_FILTER,
        payload,
    };
};
