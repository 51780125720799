import React from 'react';

import { Box } from '@mui/material';

import CollapseRow from '../../../../../../../common/components/CollapseRow';

import { useCollapse } from '../../../../../../../common/hooks/useCollapse';

import RootCauseContent from './RootCauseContent';

const RootCauseView = ({ capaDetail, ncrId, loading, userImages }) => {
    const [isCollapsed, collapse] = useCollapse(false);

    return (
        <Box sx={{ marginBottom: '32px' }}>
            <CollapseRow title={capaDetail?.rootCause?.toUpperCase()} isExpanded={isCollapsed} collapse={collapse} />

            {isCollapsed && (
                <Box sx={{ margin: '16px 16px 0px 32px' }}>
                    <RootCauseContent capaDetail={capaDetail} ncrId={ncrId} userImages={userImages} />
                </Box>
            )}
        </Box>
    );
};

export default RootCauseView;
