import { useTranslation } from 'react-i18next';

import { Autocomplete, Grid, TextField } from '@mui/material';

import userDataProvider from './UserDataProvider';

const UserOverlayData = ({
    model,

    roleOptions,
    organizationOptions,
    getTeamOptions,

    isDisableActionForTeamAdmin,
    disableEmailInput,

    handleUserRoleChange,
    handleOrganizationChange,
    handleTeamChange,

    handleChange,
    handleBlur,
    valError,
}) => {
    const { t } = useTranslation();

    const userRoleValues = model?.roles?.length ? roleOptions?.filter(user => model?.roles?.indexOf(user?.value) !== -1) : [];
    const userOrganizationsValues = model?.organizations?.length
        ? organizationOptions?.filter(user => {
              return model?.organizations?.find(organization => organization.organizationID === user?.value);
          })
        : [];

    const teamsOptions = getTeamOptions();

    const userTeamsValues = model?.organizations.map(opt => opt.teamsIDs).flat()?.length
        ? teamsOptions?.filter(user => {
              return model?.organizations
                  .map(opt => opt.teamsIDs)
                  .flat()
                  ?.find(teamId => {
                      return teamId === user?.value;
                  });
          })
        : [];

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={model.firstName || ''}
                    type="text"
                    label={t('adminPage.usersTable.firstName')}
                    error={valError.firstName}
                    required
                    onChange={event => {
                        handleChange('firstName', event.target.value);
                    }}
                    onBlur={handleBlur.bind({}, 'firstName', model.firstName)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={model.name || ''}
                    type="text"
                    label={t('adminPage.usersTable.lastName')}
                    error={valError.name}
                    required
                    onChange={event => {
                        handleChange('name', event.target.value);
                    }}
                    onBlur={handleBlur.bind({}, 'name', model.name)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={model.email || ''}
                    type="text"
                    label={t('adminPage.usersTable.email')}
                    error={valError.email}
                    required
                    disabled={disableEmailInput}
                    onChange={event => {
                        handleChange('email', event.target.value);
                    }}
                    onBlur={handleBlur.bind({}, 'email', model.email)}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    value={userRoleValues}
                    options={roleOptions}
                    fullWidth
                    multiple
                    limitTags={3}
                    isOptionEqualToValue={(option, value) => option.value === value?.value}
                    getOptionLabel={option => option.title}
                    renderInput={params => <TextField {...params} label={t('adminPage.usersTable.roles')} />}
                    onChange={handleUserRoleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    value={userOrganizationsValues}
                    options={organizationOptions}
                    disabled={isDisableActionForTeamAdmin}
                    fullWidth
                    multiple
                    isOptionEqualToValue={(option, value) => option.value === value?.value}
                    getOptionLabel={option => option.title}
                    renderInput={params => <TextField {...params} label={t('adminPage.usersTable.organizations')} />}
                    onChange={handleOrganizationChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    value={userTeamsValues}
                    options={getTeamOptions()}
                    disabled={isDisableActionForTeamAdmin}
                    fullWidth
                    multiple
                    isOptionEqualToValue={(option, value) => option.value === value?.value}
                    getOptionLabel={option => option.title}
                    renderInput={params => <TextField {...params} label={t('adminPage.usersTable.teams')} />}
                    onChange={handleTeamChange}
                />
            </Grid>
        </Grid>
    );
};

export default userDataProvider(UserOverlayData);
