import { useCallback } from 'react';

import { notificationsSeverityByRequestStatus } from '../../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../../common/providers/notification/store/notification.context';

import { useUpdateNCR } from '../../../../inbox/hooks/useUpdateNCR';

import NcrInfoAccordion from '../ncr-immediate/components/shared/NcrInfoAccordion';
import CommentsSection from './components/CommentsSection';
import CostsSection from './components/CostsSection';
import ProofOfEffectivenessSection from './components/ProofOfEffectivenessSection';

const NcrValuationTab = ({ ncr, loading }) => {
    const { setNotification } = useNotificationContext();

    const [updateNCR, ncrUpdateLoading] = useUpdateNCR();

    const isLoading = loading || ncrUpdateLoading;
    const ncrId = ncr?._id;

    const handleNcrUpdate = useCallback(
        value => {
            updateNCR(ncrId, value)
                .then(response => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[response.data.updateNCR.success] });
                })
                .catch(_ => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ncr?._id]
    );
    return (
        <>
            <NcrInfoAccordion ncr={ncr} />
            <CostsSection ncr={ncr} loading={isLoading} />
            <ProofOfEffectivenessSection ncr={ncr} loading={isLoading} handleUpdate={handleNcrUpdate} />
            <CommentsSection ncr={ncr} loading={isLoading} handleUpdate={handleNcrUpdate} />
        </>
    );
};

export default NcrValuationTab;
