import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Tab, Tabs } from '@mui/material';

import { adminTabsEnum, adminTabsEnumIndexes } from './constants';
import { useTranslation } from 'react-i18next';

const tabs = [adminTabsEnum.USERS, adminTabsEnum.NCR_BIN];

const AdminPageTabs = () => {
    const navigate = useNavigate();
    const { activeTab } = useParams();

    const { t } = useTranslation();

    const setActiveTab = (_, index) => navigate(`/admin/${tabs[index]}`);

    // TODO add translations
    return (
        <Tabs
            value={adminTabsEnumIndexes[activeTab]}
            onChange={setActiveTab}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary">
            <Tab label={t('adminPage.tabs.users')} tabIndex={0} />,
            <Tab label={t('adminPage.tabs.ncrBin')} tabIndex={1} />
        </Tabs>
    );
};

export default AdminPageTabs;
