import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { CREATE_USER, GET_ORGANIZATIONS, GET_USERS } from '../../../../../common/gqlQueries/queries';

import { notificationsSeverityByRequestStatus } from '../../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../../common/providers/notification/store/notification.context';

import update from '../../../../../utils/update';

import UserTemplateWrapper from '../templates/UserTemplateWrapper';

const CreateUser = ({ overlayData, setOverlayData }) => {
    const { setNotification } = useNotificationContext();

    const { data: organizationData } = useQuery(GET_ORGANIZATIONS, { fetchPolicy: 'cache-and-network' });

    const [isDataSaved, setIsDataSaved] = useState(true);

    const [createUser] = useMutation(CREATE_USER, {
        update(cache, { data }) {
            if (overlayData?.isOpen) {
                const { getUsers } = cache.readQuery({
                    query: GET_USERS,
                });

                const newData = update(getUsers, {
                    $push: [data.createUser?.user],
                });

                cache.writeQuery({
                    query: GET_USERS,
                    data: {
                        getUsers: newData,
                    },
                });
            }
        },
        onCompleted(mutationResponse) {
            if (mutationResponse && mutationResponse.createUser.code === '200') {
                setIsDataSaved(true);

                setOverlayData({
                    isOpen: false,
                });

                setNotification({ severity: notificationsSeverityByRequestStatus[mutationResponse.createUser.success] });
            }
        },
        onError: ({ networkError }) => {
            setNotification({
                message: JSON.parse(networkError?.result?.errors[0]?.extensions?.data)?.error?.id || null,
                severity: notificationsSeverityByRequestStatus[false],
            });
        },
    });

    return (
        <UserTemplateWrapper
            isDataSaved={isDataSaved}
            setIsDataSaved={setIsDataSaved}
            organizations={organizationData?.getOrganizations?.organizations || []}
            overlayData={overlayData}
            setOverlayData={setOverlayData}
            handleSubmit={createUser}
        />
    );
};

export default CreateUser;
