import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DEFAULT_DATE_FORMAT } from '../../../../../../../common/constants/constants';

const StepDueDateComponent = ({ value, handleUpdate }) => {
    const { t } = useTranslation();

    return (
        <DatePicker
            label={t('ncrInfo.immediate.table.due')}
            inputFormat={DEFAULT_DATE_FORMAT}
            value={value}
            onChange={handleUpdate}
            renderInput={params => <TextField {...params} fullWidth />}
        />
    );
};

const StepDueDate = memo(StepDueDateComponent);

export default StepDueDate;
