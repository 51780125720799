import { createContext, useContext, useReducer } from 'react';

import { setInboxNoData, setSelectedNCR } from './inbox.actions';
import { inboxInitialState, inboxReducer } from './inbox.reducer';

export const InboxContext = createContext();

export const InboxProvider = ({ children }) => {
    const [state, dispatch] = useReducer(inboxReducer, inboxInitialState);

    const value = {
        selectedNCRId: state.selectedNCRId,
        noInboxData: state.noInboxData,
        setSelectedNCR: payload => dispatch(setSelectedNCR(payload)),
        setInboxNoData: payload => dispatch(setInboxNoData(payload)),
    };

    return <InboxContext.Provider value={value}>{children}</InboxContext.Provider>;
};

export const useInboxContext = () => {
    const context = useContext(InboxContext);

    if (context === undefined) {
        return inboxInitialState;
    }

    return context;
};
