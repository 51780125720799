import { useTranslation } from 'react-i18next';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { LoadingButton } from '@mui/lab';
import { Box, Paper } from '@mui/material';

import InfoTab from './ncrInboxPaper/components/tabs/infoTab/InfoTab';
import NoNCRButton from './ncrInboxPaper/components/tabs/infoTab/components/NoNCRButton';
import CreateNCRButton from './ncrInboxPaper/components/tabs/infoTab/components/CreateNCRButton';

import { useGetSingleNCR } from '../hooks/useGetSingleNCR';

import { useInboxContext } from '../store/inbox.context';

const InboxPageMobileContent = () => {
    const { t } = useTranslation();

    const { selectedNCRId, setSelectedNCR } = useInboxContext();

    const [ncr, ncrLoading] = useGetSingleNCR(selectedNCRId);

    return (
        <Paper elevation={3} style={{ padding: '1em' }}>
            <Box paddingBottom="1em">
                <LoadingButton
                    variant="text"
                    loadingPosition="start"
                    onClick={() => setSelectedNCR(null)}
                    startIcon={<ArrowBackRoundedIcon />}>
                    {t('common.action-buttons.back')}
                </LoadingButton>
            </Box>
            <InfoTab ncr={ncr} loading={ncrLoading} />
            <Box display="flex" justifyContent="space-evenly">
                <NoNCRButton />

                <CreateNCRButton />
            </Box>
        </Paper>
    );
};

export default InboxPageMobileContent;
