export const customInputElementType = {
    SELECT: 'select',
    TEXT: 'text',
    RICHTEXT: 'richtext',
    RADIOS: 'radios',
    CHECKBOXES: 'checkboxes',
    FILE: 'file',
    DATE: 'date',
    NUMBER: 'number',
};
