import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

const CreateMeasureButton = ({ handleOpenModal }) => {
    const { t } = useTranslation();

    return (
        <Button variant="outlined" color="primary" onClick={handleOpenModal}>
            {t('ncrInfo.corrective.createMeasure')}
        </Button>
    );
};

export default CreateMeasureButton;
