import { gql } from '@apollo/client';

export const GET_PAGINATED_NCRS = gql`
    query GetPaginatedNCRs($skip: Int, $limit: Int, $filter: String, $sort: String) {
        getPaginatedNCRs(skip: $skip, limit: $limit, filter: $filter, sort: $sort) {
            metadata {
                total
                skip
                limit
                hasNext
            }
            ncrs {
                _id
                name
                archived
                deleted
                ncrCreatedBy {
                    userID
                    createdAt
                }
                basicInformation {
                    description
                    externalReference
                }
                resolvedNcrCreatedBy {
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                priority
            }
        }
    }
`;
