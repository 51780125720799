import { gql } from '@apollo/client';

export const CREATE_NCR_FROM_NCR_TEMPLATE = gql`
    mutation CreateNCRFromNCRTemplate($ncrTemplateId: ID!, $ncr: NCRInput) {
        createNCRFromNCRTemplate(ncrTemplateId: $ncrTemplateId, ncr: $ncr) {
            code
            success
            message
            ncr {
                _id
                responsible {
                    responsibleID
                }
                resolvedResponsible {
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
            }
        }
    }
`;
