import FailureCategoryDropdown from '../../../../common/components/FailureCategoryDropdown';

import { useNcrOverviewContext } from '../../store/NCROverviewProvider';

const NcrOverviewFailureCategoryFilter = () => {
    const { failureCategoryFilter, setNcrOverviewFailureCategoryFilter } = useNcrOverviewContext();

    return <FailureCategoryDropdown sx={{ minWidth: 240 }} value={failureCategoryFilter} onChange={setNcrOverviewFailureCategoryFilter} />;
};

export default NcrOverviewFailureCategoryFilter;
