import i18n from '../../utils/i18n';

export const ncrStatus = {
    OPEN: 'open',
    IN_WORK: 'inWork',
    DONE: 'done',
    REJECTED: 'rejected',
};

export const getNcrStatusOptions = () => [
    {
        title: i18n.t('statuses.inWork'),
        value: ncrStatus.IN_WORK,
    },
    {
        title: i18n.t('statuses.done'),
        value: ncrStatus.DONE,
    },
];
