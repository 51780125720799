import { Navigate, Outlet } from 'react-router-dom';
import { useUserSettingsContext } from '../common/providers/userSettings/store/userSettings.context';

const ProtectedRoute = ({ allowedRoles, redirectPath = '/403', children }) => {
    const { userData } = useUserSettingsContext();

    if (!userData.roles) {
        return <div />;
    }

    const isAllowed = userData && userData.roles?.some(userRole => allowedRoles?.some(role => role === userRole));

    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;
