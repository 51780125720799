import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';

import { Backdrop, Box, CircularProgress, Container } from '@mui/material';

import { GET_SINGLE_FILE, GET_SINGLE_USER } from '../../../common/gqlQueries/queries';

import UserProfileTemplate from './UserProfileTemplate';

const UserProfilePage = () => {
    const params = useParams();

    const { userId } = params;

    const [user, setUser] = useState(null);

    const { data: userData, loading: loadingUser } = useQuery(GET_SINGLE_USER, {
        variables: { userID: userId },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (userData) {
            setUser(userData.getSingleUser?.user);
        }
    }, [userData]);

    const [fetchUserImage, { data: userImageData, loading: loadingUserImage }] = useMutation(GET_SINGLE_FILE);

    useEffect(() => {
        if (user && user.avatar?.thumbnail) {
            const imageName = user.avatar?.thumbnail;

            fetchUserImage({
                variables: {
                    accessRootFolder: `users/${user._id}`,
                    file: imageName,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.avatar?.thumbnail]);

    const isLoading = Boolean(loadingUser || loadingUserImage);

    return (
        <Box sx={{ height: 'calc(100vh - 68px)' }}>
            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container maxWidth="md">
                <UserProfileTemplate
                    user={userData?.getSingleUser?.user}
                    userImage={userImageData?.createSingleSignedGetURL?.signedRequest}
                />
            </Container>
        </Box>
    );
};

export default UserProfilePage;
