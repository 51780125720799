import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../../../../../../../../common/components/AutoSaveTextField';

const StatementInputComponent = ({ value, disabled, handleUpdate }) => {
    const { t } = useTranslation();

    const handleBlur = event => {
        handleUpdate(`clearances.$[clearanceId]`, {
            clearance: { clearanceResponse: event.target.value },
        });
    };

    return (
        <AutoSaveTextField
            value={value}
            disabled={disabled}
            label={t('ncrInfo.immediate.specialReleaseStatementPlaceholder')}
            rows={3}
            onBlur={handleBlur}
            onKeyPress={event => {
                event.key === 'Enter' && handleBlur(event);
            }}
            multiline
            fullWidth
        />
    );
};

const StatementInput = memo(StatementInputComponent);

export default StatementInput;
