import { useCallback } from 'react';

import { isArrayWithItems } from './../../utils/common';
import { getFileName, getThumbFileName } from './../utils/fileManagement';

export const useUserAvatar = () => {
    const getUserAvatarByModel = useCallback((images, userModel) => {
        if (!userModel || !isArrayWithItems(images)) {
            return null;
        }

        const userAvatar =
            !!userModel && userModel.profilePic && images
                ? images.find(img => getFileName(img.url) === getThumbFileName(userModel.profilePic.thumbnail))
                : null;

        const userOriginalAvatar =
            userModel && userModel.profilePic && images && !userAvatar
                ? images.find(img => getFileName(img.url) === userModel.profilePic.thumbnail)
                : null;

        return {
            _id: userModel?._id,
            avatar: userAvatar?.img || userOriginalAvatar?.img,
            fullName: userModel ? `${userModel.firstName || ''} ${userModel.name || ''}` : null,
            value: userModel?._id,
        };
    }, []);

    const getUserAvatarByThumbnail = useCallback((images, thumbnail) => {
        if (!isArrayWithItems(images)) {
            return null;
        }

        const userAvatar = thumbnail ? images.find(img => getFileName(img.url) === getThumbFileName(thumbnail)) : null;

        return userAvatar?.img;
    }, []);

    return [{ getUserAvatarByModel, getUserAvatarByThumbnail }];
};
