import { ncrArchiveStatus } from '../../../common/enums/ncrArchivedOptions';
import { ncrStatus } from '../../../common/enums/ncrStatus';
import { ncrOverviewActions } from './NCROverviewActions';

export const ncrOverviewInitialState = {
    filtersOpened: false,
    searchFilter: '',
    statusFilter: ncrStatus.IN_WORK,
    archivedFilter: ncrArchiveStatus.ACTIVE,
    dateRangeFilter: {
        from: null,
        to: null,
    },
    failureCategoryFilter: null,
    pagination: {
        page: 0,
        sortBy: 'ncrCreatedBy.createdAt',
        order: 'desc',
    },
};

export const ncrOverviewReducer = (state, action) => {
    switch (action.type) {
        case ncrOverviewActions.SET_NCR_OVERVIEW_SEARCH_FILTER: {
            return {
                ...state,
                searchFilter: action.payload,
                pagination: {
                    ...state.pagination,
                    page: 0,
                },
            };
        }
        case ncrOverviewActions.SET_NCR_OVERVIEW_STATUS_FILTER: {
            return {
                ...state,
                statusFilter: action.payload,
            };
        }
        case ncrOverviewActions.SET_NCR_OVERVIEW_DATE_RANGE_FILTER: {
            return {
                ...state,
                dateRangeFilter: action.payload,
            };
        }
        case ncrOverviewActions.SET_NCR_OVERVIEW_FAILURE_CATEGORY_FILTER: {
            return {
                ...state,
                failureCategoryFilter: action.payload,
            };
        }
        case ncrOverviewActions.SET_NCR_OVERVIEW_SELECTED_TEAMS: {
            return {
                ...state,
                selectedTeams: action.payload,
            };
        }
        case ncrOverviewActions.RESET_NCR_OVERVIEW_FILTERS: {
            return {
                ...ncrOverviewInitialState,
                filtersOpened: state.filtersOpened,
            };
        }
        case ncrOverviewActions.TOGGLE_NCR_OVERVIEW_FILTERS: {
            return {
                ...state,
                filtersOpened: !state.filtersOpened,
            };
        }
        case ncrOverviewActions.SET_NCR_OVERVIEW_TABLE_PAGE: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.payload,
                },
            };
        }
        case ncrOverviewActions.SET_NCR_OVERVIEW_TABLE_SORTING: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    sortBy: action.payload.sortBy,
                    order: action.payload.order,
                },
            };
        }
        case ncrOverviewActions.SET_NCR_OVERVIEW_ARCHIVED_FILTER: {
            return {
                ...state,
                archivedFilter: action.payload,
            };
        }
        default:
            return ncrOverviewInitialState;
    }
};
