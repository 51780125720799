import omit, { isArray, isPlainObject } from 'lodash';

export const isArrayWithItems = arr => {
    if (!arr) {
        return false;
    }

    return Array.isArray(arr) && arr.length ? true : false;
};

export const getValueByLanguage = (array, language) => {
    if (isArrayWithItems(array)) {
        const result =
            array?.find(x => x?.language?.toLowerCase() === language?.toLowerCase())?.value || array?.find(x => !!x?.value)?.value || '';

        return result;
    }

    return '';
};

export const filterObject = (obj, key) => {
    if (!obj) {
        return obj;
    }

    for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;

        if (typeof obj[i] === 'object') {
            filterObject(obj[i], key);
        } else if (i === key) {
            omit(obj[key], [key]);
        }
    }

    return obj;
};

export default function cloneWithoutTypename(obj) {
    if (!isPlainObject(obj)) {
        return obj;
    }

    let result = {};

    for (let key in obj) {
        if (key === '__typename') {
            continue;
        }

        let value = obj[key];

        if (isPlainObject(value)) {
            result[key] = cloneWithoutTypename(value);
        } else if (isArray(value)) {
            result[key] = value.map(cloneWithoutTypename);
        } else {
            result[key] = value;
        }
    }

    return result;
}

export const stringAvatar = name => {
    return {
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
};
