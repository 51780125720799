import { useEffect, useState } from 'react';
import { useModal } from './useModal';

const useQrCodeLogin = signIn => {
    const [isOpened, toggleModal] = useModal(false);
    const [qrModel, setQrModel] = useState({
        email: '',
        password: '',
    });

    useEffect(() => {
        if (isOpened && qrModel?.email && qrModel?.password) {
            toggleModal();

            signIn(qrModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpened, qrModel]);

    const handleResult = result => {
        if (!!result) {
            try {
                const data = JSON.parse(result);

                setQrModel(prev => {
                    return {
                        ...prev,
                        ...data,
                    };
                });
            } catch (error) {}
        }
    };

    return [isOpened, toggleModal, handleResult];
};

export default useQrCodeLogin;
