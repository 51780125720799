import { useMutation } from '@apollo/client';
import { UPDATE_WORKFLOW_STEP } from './../../../gqlQueries/updateWorkflowStep';

export const useUpdateWorkflowStepResponsible = () => {
    const [updateWorkflowStep, { data, loading }] = useMutation(UPDATE_WORKFLOW_STEP);

    const updateResponsible = (workflowStepId, responsibleID) => {
        return updateWorkflowStep({
            variables: {
                workflowStep: {
                    _id: workflowStepId,
                    responsible: [
                        {
                            responsibleID,
                        },
                    ],
                },
            },
        });
    };

    return [updateResponsible, data, loading];
};
