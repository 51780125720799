import { useTranslation } from 'react-i18next';

import MenuLink from '../../../common/components/MenuLink';
import FloatingActionButtonPortal from '../../../common/components/portals/FloatingActionButton';

import { reportPageRoles } from '../../../common/constants/userRoles';

import { useUserSettingsContext } from '../../../common/providers/userSettings/store/userSettings.context';

const NewNcrButton = () => {
    const { t } = useTranslation();

    const { userData } = useUserSettingsContext();

    const isAllowedRoport = userData && userData.roles?.some(userRole => reportPageRoles?.some(role => role === userRole));

    if (!isAllowedRoport) {
        return null;
    }

    return <FloatingActionButtonPortal label={<MenuLink to="/report">{t('ncrOverview.newNcr')}</MenuLink>} />;
};

export default NewNcrButton;
