import { Box, Container, Paper, Stack } from '@mui/material';

import MyTasksFilters from './components/filters/MyTasksFilters';
import MyTasksTable from './components/ncr-table/MyTasksTable';

import NCRMyTasksProvider from './store/NCRMyTasksProvider';

const MyTasksPage = () => {
    return (
        <NCRMyTasksProvider>
            <Container maxWidth="xl">
                <Paper elevation={3}>
                    <Box width={'100%'} p={'1.5em'}>
                        <Stack spacing={4}>
                            <MyTasksFilters />
                            <MyTasksTable />
                        </Stack>
                    </Box>
                </Paper>
            </Container>
        </NCRMyTasksProvider>
    );
};

export default MyTasksPage;
