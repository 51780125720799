import { gql } from '@apollo/client';

export const GET_PAGINATED_TAGS = gql`
    query Tag($filter: String, $limit: Int) {
        getPaginateTags(filter: $filter, limit: $limit) {
            tag {
                _id
                name
                colorCode
                usedBy
            }
        }
    }
`;
