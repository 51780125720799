import { useState } from 'react';

export const useUserOverlayData = () => {
    const [userOverlayData, setUserOverlayData] = useState({
        isOpen: false,
        userId: null,
    });

    return [userOverlayData, setUserOverlayData];
};
