import { useMemo } from 'react';

import moment from 'moment';

import { useLazyGetDeletedNCRs } from './useLazyGetDeletedNCRs';
import { useNcrBinTableColumns } from './useNcrBinTableColumns';
import createMongoObjectId from '../../../../../../utils/mongo';
import { DEFAULT_DATE_FORMAT } from '../../../../../../common/constants/constants';

export const useNcrBinTableData = () => {
    const [items, loading, fetchData] = useLazyGetDeletedNCRs();
    const [columns] = useNcrBinTableColumns();

    const rows = useMemo(() => {
        if (!items) {
            return [];
        }

        return (
            items?.ncrs?.map(item => {
                return {
                    rowId: item._id,
                    externalReference: item.basicInformation?.externalReference || 'N/A',
                    name: item.name || createMongoObjectId(),
                    failureCategory: `${item.basicInformation?.failureCategory?.name || 'N/A'} | ${
                        item.basicInformation?.failureCategory?.failureMode || 'N/A'
                    }`,
                    'ncrModifiedBy.modifiedAt': moment(item.ncrModifiedBy?.modifiedAt).format(DEFAULT_DATE_FORMAT),
                    'ncrCreatedBy.createdAt': moment(item.ncrCreatedBy?.createdAt).format(DEFAULT_DATE_FORMAT),
                    responsible: !!item?.resolvedResponsible?.length ? item?.resolvedResponsible[0] : null,
                    status: item.status,
                    contextMenu: item,
                };
            }) || []
        );
    }, [items]);

    const total = useMemo(() => {
        if (!items) {
            return 0;
        }

        return items.metadata.total;
    }, [items]);

    return [rows, columns, loading, total, fetchData];
};
