import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { ncrInfoInitialState, ncrInfoReducer } from './NCRInfoReducer';

export const NCRInfoContext = createContext(ncrInfoInitialState);

const NCRInfoProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ncrInfoReducer, ncrInfoInitialState);

    const setNcrReworks = useCallback(ncrReworks => {
        dispatch(setNcrReworks(ncrReworks));
    }, []);

    const ncrInfoContextValue = useMemo(() => {
        return {
            ncrReworks: state.ncrReworks,
            setNcrReworks: ncrReworks => setNcrReworks(ncrReworks),
        };
    }, [state.ncrReworks, setNcrReworks]);

    return <NCRInfoContext.Provider value={ncrInfoContextValue}>{children}</NCRInfoContext.Provider>;
};

export const useNCRInfoContext = () => {
    return useContext(NCRInfoContext);
};

export default NCRInfoProvider;
