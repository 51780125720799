import { gql } from '@apollo/client';

export const CASCADE_UPDATE_MANY_NCRS = gql`
    mutation CascadeUpdateManyNCRs($ncrIds: [ID!], $updatedFields: NCRCascadeUpdateInput) {
        cascadeUpdateManyNCRs(ncrIds: $ncrIds, updatedFields: $updatedFields) {
            code
            success
            ncrs {
                _id
                name
                archived
                deleted
                ncrCreatedBy {
                    userID
                    createdAt
                }
                basicInformation {
                    description
                    externalReference
                }
                resolvedNcrCreatedBy {
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                priority
            }
        }
    }
`;
