import React, { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid, Typography } from '@mui/material';

import { customInputElementType } from '../../../../../../../../common/enums/customInputElementType';
import { getValueByLanguage } from '../../../../../../../../utils/common';

import { useUserSettingsContext } from '../../../../../../../../common/providers/userSettings/store/userSettings.context';

import NcrCheckbox from '../../../../../../../report/components/customFields/NcrCheckbox';
import NcrInput from '../../../../../../../report/components/customFields/NcrInput';
import NcrSelect from '../../../../../../../report/components/customFields/NcrSelect';

const fields = language => {
    return {
        [customInputElementType.SELECT]: props => {
            const label = getValueByLanguage(props.label, language);

            const options = props.choices.map(option => {
                return {
                    label: getValueByLanguage(option.label, language),
                    value: option.value,
                };
            });

            const currentValue = props.value ? options?.find(option => option?.value === props.value) : null;

            const handleChange = newValue => {
                props.handleUpdate(
                    'scraps.$[scrapId].customInputElements.$[customInputElementId]',
                    {
                        customInputElement: { value: newValue || '' },
                    },
                    'update',
                    {
                        scrapId: props.scrapId,
                        customInputElementId: props.customInputElementId,
                    }
                );
            };

            return (
                <NcrSelect
                    disabled={props.disabled || props.readOnly}
                    value={currentValue}
                    loading={props.loading}
                    options={options}
                    label={label}
                    labelKey="label"
                    valueKey="value"
                    handleChange={handleChange}
                />
            );
        },

        [customInputElementType.TEXT]: props => {
            const handleBlur = event => {
                props.handleUpdate(
                    'scraps.$[scrapId].customInputElements.$[customInputElementId]',
                    {
                        customInputElement: { value: event.target.value },
                    },
                    'update',
                    {
                        scrapId: props.scrapId,
                        customInputElementId: props.customInputElementId,
                    }
                );
            };

            const label = getValueByLanguage(props.label, language);

            return (
                <NcrInput
                    disabled={props.disabled || props.readOnly}
                    value={props.value}
                    onBlur={handleBlur}
                    onKeyPress={event => {
                        event.key === 'Enter' && handleBlur(event);
                    }}
                    label={label}
                />
            );
        },

        [customInputElementType.NUMBER]: props => {
            const handleBlur = event => {
                props.handleUpdate(
                  'scraps.$[scrapId].customInputElements.$[customInputElementId]',
                  {
                      customInputElement: { value: event.target.value },
                  },
                  'update',
                  {
                      scrapId: props.scrapId,
                      customInputElementId: props.customInputElementId,
                  }
                );
            };

            const label = getValueByLanguage(props.label, language);

            return (
              <NcrInput
                disabled={props.readOnly}
                value={props.value}
                onBlur={handleBlur}
                onKeyPress={event => {
                    event.key === 'Enter' && handleBlur(event);
                }}
                type="number"
                label={label}
              />
            );
        },

        [customInputElementType.CHECKBOXES]: props => {
            const [choices] = props.choices;
            const { label } = choices;
            const title = getValueByLanguage(label, language);

            const handleChange = event => {
                props.handleUpdate(
                    'scraps.$[scrapId].customInputElements.$[customInputElementId]',
                    {
                        customInputElement: {
                            value: event.target.checked ? 'on' : 'off',
                            _id: props.customInputElementId,
                        },
                    },
                    'update',
                    {
                        scrapId: props.scrapId,
                        customInputElementId: props.customInputElementId,
                    }
                );
            };

            return (
                <NcrCheckbox disabled={props.disabled || props.readOnly} value={props.value} label={title} handleChange={handleChange} />
            );
        },
    };
};

const ScrapCustomFieldsSectionComponent = ({ scrap, disabled, handleUpdate }) => {
    const { t } = useTranslation();

    const { language } = useUserSettingsContext();

    return (
        <>
            <Box marginBottom="0.25em">
                <Typography variant="overline">{t('ncrInfo.furtherInformation')}</Typography>
            </Box>
            <Divider sx={{ marginBottom: '2em' }} />
            <Grid container spacing={4}>
                {!!scrap?.customInputElements &&
                    scrap?.customInputElements.map((field, index) => {
                        const item = fields(language)[field.customInputElementType];

                        return (
                            <Fragment key={field._id}>
                                {item && (
                                    <Grid item xs={12} sm={6}>
                                        {item &&
                                            item({
                                                ...field,
                                                handleUpdate,
                                                customInputElementId: field._id,
                                                customInputElementIndex: index,
                                                scrapId: scrap._id,
                                                disabled,
                                            })}
                                    </Grid>
                                )}
                            </Fragment>
                        );
                    })}
            </Grid>
        </>
    );
};

const ScrapCustomFieldsSection = memo(ScrapCustomFieldsSectionComponent);

export default ScrapCustomFieldsSection;
