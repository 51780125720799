import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { useGetPaginatedFailureCategoriesOptions } from '../hooks/useGetPaginatedFailureCategoriesOptions';

const UpdateFailureCodeDropdownComponent = ({ value = null, disabled, loading, handleUpdate }) => {
    const { t } = useTranslation();

    const [failureCategoriesOptions, loadingFailureCategories] = useGetPaginatedFailureCategoriesOptions();

    const handleChange = newValue => {
        let failureCategory = null;

        if (newValue && newValue.failureMode !== value.failureMode) {
            failureCategory = {
                name: newValue.name,
                failureMode: newValue.failureMode,
            };
        }

        handleUpdate('basicInformation', {
            basicInformation: {
                failureCategory,
            },
        });
    };

    const memoizedValue = useMemo(() => {
        return {
            name: value?.name || '',
            failureMode: value?.failureMode || '',
        };
    }, [value]);

    return (
        <Autocomplete
            value={memoizedValue?.name ? memoizedValue : null}
            disabled={disabled}
            loading={loadingFailureCategories || loading}
            options={failureCategoriesOptions}
            groupBy={option => option.name}
            getOptionLabel={option => option.failureMode}
            renderInput={params => <TextField {...params} label={t('report.form.failureCode')} />}
            onChange={(_, data) => handleChange(data)}
        />
    );
};
const UpdateFailureCodeDropdown = memo(UpdateFailureCodeDropdownComponent);

export default UpdateFailureCodeDropdown;
