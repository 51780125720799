import { useTranslation } from 'react-i18next';

import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ReplayIcon from '@mui/icons-material/Replay';
import { LoadingButton } from '@mui/lab';

import { useNotificationContext } from '../../../common/providers/notification/store/notification.context';
import ConfirmationModal from '../../../common/components/ConfirmationModal';

import { useUpdateNCR } from '../../inbox/hooks/useUpdateNCR';
import { useModal } from '../../../common/hooks/useModal';

import { notificationsSeverityByRequestStatus } from '../../../common/providers/notification/constants';
import { ncrStatus } from '../../../common/enums/ncrStatus';

const FinishNCRButton = ({ ncrId, status }) => {
    const { t } = useTranslation();

    const [isOpen, toggleModal] = useModal();

    const { setNotification } = useNotificationContext();

    const [updateNCR, ncrUpdateLoading] = useUpdateNCR();
    const handleFinishButtonClick = () => {
        updateNCR(ncrId, { status: status === ncrStatus.DONE ? ncrStatus.OPEN : ncrStatus.DONE })
            .then(response => {
                setNotification({ severity: notificationsSeverityByRequestStatus[response.data.updateNCR.success] });
            })
            .catch(_ => {
                setNotification({ severity: notificationsSeverityByRequestStatus[false] });
            })
            .finally(() => {
                toggleModal();
            });
    };

    const buttonLabel = status === ncrStatus.DONE ? t('ncrInfo.valuation.renew') : t('ncrInfo.valuation.finish');

    return (
        <>
            <LoadingButton
                variant="contained"
                color="primary"
                style={{ cursor: 'pointer' }}
                loadingPosition="start"
                loading={ncrUpdateLoading}
                onClick={toggleModal}
                startIcon={status === ncrStatus.DONE ? <ReplayIcon /> : <DoneRoundedIcon />}>
                {status === ncrStatus.DONE ? t('ncrInfo.valuation.renew') : t('ncrInfo.valuation.finish')}
            </LoadingButton>
            <ConfirmationModal
                isOpen={isOpen}
                handleModalClose={toggleModal}
                disabledAction={ncrUpdateLoading}
                handleConfirm={handleFinishButtonClick}
                title={t('ncrInfo.valuation.finishTitle', { action: buttonLabel.toLowerCase() })}
            />
        </>
    );
};

export default FinishNCRButton;
