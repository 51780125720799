export const ncrMyTasksActions = {
    SET_NCR_MY_TASKS_SEARCH_FILTER: 'SET_NCR_MY_TASKS_SEARCH_FILTER',
    SET_NCR_MY_TASKS_DATE_RANGE_FILTER: 'SET_NCR_MY_TASKS_DATE_RANGE_FILTER',
    SET_NCR_MY_TASKS_FAILURE_CATEGORY_FILTER: 'SET_NCR_MY_TASKS_FAILURE_CATEGORY_FILTER',
    RESET_NCR_MY_TASKS_FILTERS: 'RESET_NCR_MY_TASKS_FILTERS',
    SET_NCR_MY_TASKS_TABLE_PAGE: 'SET_NCR_MY_TASKS_TABLE_PAGE',
    SET_NCR_MY_TASKS_TABLE_SORTING: 'SET_NCR_MY_TASKS_TABLE_SORTING',
    SET_NCT_MY_TASKS_MY_TASK: 'SET_NCT_MY_TASKS_MY_TASK',
    SET_NCR_MY_TASK_SELECTED_TEAMS: 'SET_NCR_MY_TASK_SELECTED_TEAMS',
};

export const setNcrMyTasksSearchFilter = payload => {
    return {
        type: ncrMyTasksActions.SET_NCR_MY_TASKS_SEARCH_FILTER,
        payload,
    };
};

export const setNcrMyTasksDateRangeFilter = payload => {
    return {
        type: ncrMyTasksActions.SET_NCR_MY_TASKS_DATE_RANGE_FILTER,
        payload,
    };
};

export const setNcrMyTasksFailureCategoryFilter = payload => {
    return {
        type: ncrMyTasksActions.SET_NCR_MY_TASKS_FAILURE_CATEGORY_FILTER,
        payload,
    };
};

export const resetNcrMyTasksFilters = () => {
    return {
        type: ncrMyTasksActions.RESET_NCR_MY_TASKS_FILTERS,
    };
};

export const setNcrMyTasksTablePage = payload => {
    return {
        type: ncrMyTasksActions.SET_NCR_MY_TASKS_TABLE_PAGE,
        payload,
    };
};

export const setNcrMyTasksTableSorting = payload => {
    return {
        type: ncrMyTasksActions.SET_NCR_MY_TASKS_TABLE_SORTING,
        payload,
    };
};

export const setNcrMyTasksMyTask = payload => {
    return {
        type: ncrMyTasksActions.SET_NCT_MY_TASKS_MY_TASK,
        payload,
    };
};

export const setNrcMyTaskSelectedTeams = payload => {
    return {
        type: ncrMyTasksActions.SET_NCR_MY_TASK_SELECTED_TEAMS,
        payload,
    };
};
