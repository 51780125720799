import germanLocale from '@uppy/locales/lib/de_DE';
import englishLocale from '@uppy/locales/lib/en_US';
import { AWS_AMPLIFY_DEFAULT_LANGUAGE } from '../../../configs/env';
import { languageCode } from '../../enums/language';

export const uppyLocale = {
    [languageCode.ENGLISH]: englishLocale,
    [languageCode.GERMANY]: germanLocale,
};

export const getUppyLocale = currentUserLanguage => {
    const language = currentUserLanguage || AWS_AMPLIFY_DEFAULT_LANGUAGE;
    return uppyLocale[language];
};
