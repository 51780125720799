import React, { useCallback } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Box, Grid, LinearProgress, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import Accordion from '../../../../../../../common/components/accordion/Accordion';
import AccordionSummary from '../../../../../../../common/components/accordion/AccordionSummary';
import NcrCostSection from '../../../../../../../common/components/ncr/NcrCostSection';
import NcrSectionStatus from '../../../../../../../common/components/ncr/NcrSectionStatus';
import ResponsibleWorkflowStepInput from '../../../../../../../common/components/ncr/ResponsibleWorkflowStepInput';
import WorkflowStepAvailableActions from '../../../../../../../common/components/ncr/workflowStepAction/WorkflowStepAvailableActions';
import CaptureSection from './capture/CaptureSection';
import ScrapCustomFieldsSection from './customFields/ScrapCustomFieldsSection';

import { ncrSectionType } from '../../../../../../../common/enums/ncrSectionType';
import { ncrStatus } from '../../../../../../../common/enums/ncrStatus';
import { getValueByLanguage } from '../../../../../../../utils/common';

import { useSharedAtomicNCR } from '../../../../../../../common/components/ncr/hooks/useSharedAtomicNCR';
import { useCollapse } from '../../../../../../../common/hooks/useCollapse';

import { notificationsSeverityByRequestStatus } from '../../../../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../../../../common/providers/notification/store/notification.context';
import { useUserSettingsContext } from '../../../../../../../common/providers/userSettings/store/userSettings.context';

const ScrapView = ({ ncr, scrap, loading }) => {
    const navigate = useNavigate();
    const { ncrId, sectionType, sectionId } = useParams();

    const isSectionCollapsed = sectionType === ncrSectionType.SCRAP && scrap._id === sectionId;

    const [isCollapsed, collapse] = useCollapse(isSectionCollapsed);

    const { language } = useUserSettingsContext();
    const { setNotification } = useNotificationContext();

    const [handleAtomicUpdate] = useSharedAtomicNCR();

    const handleAccordionCollapseChange = () => {
        const path = generatePath('/ncr-overview/:ncrId/:sectionType/:sectionId', {
            ncrId,
            sectionId: scrap._id,
            sectionType: ncrSectionType.SCRAP,
        });

        collapse();
        navigate(path);
    };

    const handleNcrUpdate = useCallback(
        (path, values, operation = 'update', childrenIds) => {
            if (ncrId && values) {
                handleAtomicUpdate({ ncrId, operation, path, values, childrenIds })
                    .then(result => {
                        setNotification({ severity: notificationsSeverityByRequestStatus[result.data.atomicNCR.success] });
                    })
                    .catch(_ => {
                        setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                    });
            }
        },
        [ncrId, handleAtomicUpdate, setNotification]
    );

    const handleNcrScrapUpdate = useCallback(
        (path, values, operation = 'update', childIds) => {
            const childrenIds = childIds ? { ...childIds } : { scrapId: scrap._id };

            if (ncrId && values) {
                handleAtomicUpdate({ ncrId, operation, path, values, childrenIds })
                    .then(result => {
                        setNotification({ severity: notificationsSeverityByRequestStatus[result.data.atomicNCR.success] });
                    })
                    .catch(_ => {
                        setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                    });
            }
        },
        [scrap._id, ncrId, handleAtomicUpdate, setNotification]
    );

    const scrapStatusDone = [ncrStatus.DONE, ncrStatus.REJECTED].includes(scrap.status) || loading;
    const disableScrapSection = scrapStatusDone || ncr.status === ncrStatus.DONE;

    if (!scrap) {
        return null;
    }

    const [resolvedWorkflow] = scrap.resolvedWorkflows;
    const workflowName = getValueByLanguage(resolvedWorkflow?.name, language);

    const doneStepIndex = resolvedWorkflow?.resolvedWorkflowSteps?.findLastIndex(step => step.status === ncrStatus.DONE);
    const activeStep = doneStepIndex === -1 ? 0 : doneStepIndex + 1;
    const resolvedWorkflowStep = resolvedWorkflow?.resolvedWorkflowSteps[activeStep];
    const nextWorkflowStepId = resolvedWorkflow?.resolvedWorkflowSteps[activeStep + 1]?._id;

    return (
        <Box style={{ borderRadius: 16 }}>
            <Accordion
                disableGutters
                elevation={3}
                sx={{ width: '100%', padding: '1.5em' }}
                expanded={isCollapsed}
                onChange={handleAccordionCollapseChange}>
                <AccordionSummary>
                    <Typography variant="h6" noWrap component="div">
                        {workflowName}
                    </Typography>
                    <Box marginLeft="auto">
                        <NcrSectionStatus status={scrap.status} />
                    </Box>

                    {loading && <LinearProgress />}
                </AccordionSummary>
                <MuiAccordionDetails sx={{ padding: '1em 0' }}>
                    <Stack spacing={4} marginBottom="2em">
                        <Grid width="100%" container spacing={6}>
                            <Grid item sm={12} md={5}>
                                <ResponsibleWorkflowStepInput
                                    sectionId={scrap._id}
                                    isDisabled={disableScrapSection}
                                    value={resolvedWorkflowStep?.resolvedResponsible}
                                    workflowStepId={resolvedWorkflowStep?._id}
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Box height="100%">
                                    <Stepper activeStep={activeStep} sx={{ alignItems: 'center', height: '100%' }}>
                                        {resolvedWorkflow?.resolvedWorkflowSteps.map(workflowStep => (
                                            <Step key={workflowStep._id} completed={workflowStep.status === ncrStatus.DONE}>
                                                <StepLabel sx={{ textTransform: 'capitalize' }}>
                                                    {getValueByLanguage(workflowStep.name, language)}
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                    <CaptureSection
                        ncr={ncr}
                        scrap={scrap}
                        handleNcrScrapUpdate={handleNcrScrapUpdate}
                        handleNcrUpdate={handleNcrUpdate}
                        disabled={disableScrapSection}
                    />
                    <ScrapCustomFieldsSection scrap={scrap} disabled={disableScrapSection} handleUpdate={handleNcrScrapUpdate} />
                    <NcrCostSection
                        data={scrap}
                        handleUpdate={handleNcrScrapUpdate}
                        path={'scraps.$[scrapId]'}
                        disabled={disableScrapSection}
                    />
                    <WorkflowStepAvailableActions
                        ncrId={ncrId}
                        sectionId={scrap._id}
                        resolvedWorkflowStep={resolvedWorkflowStep}
                        nextWorkflowStepId={nextWorkflowStepId}
                        workflowId={resolvedWorkflow?._id}
                        handleNcrUpdate={handleNcrScrapUpdate}
                        path={'scraps.$[scrapId]'}
                        childrenIds={{ scrapId: scrap._id }}
                        type="scrap"
                        disabled={disableScrapSection}
                    />
                </MuiAccordionDetails>
            </Accordion>
        </Box>
    );
};

export default ScrapView;
