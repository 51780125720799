import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { InputAdornment, TextField } from '@mui/material';

import { useCurrentUserBySub } from '../../../../utils/awsAuth';

const CreatedByInput = ({ value }) => {
    const { t } = useTranslation();

    const user = useCurrentUserBySub();

    const memoizedValue = useMemo(() => {
        return value ? `${value?.firstName || ''} ${value?.name || ''}` : `${user?.data?.name || ''} ${user?.data?.firstName || ''}`;
    }, [value, user]);

    return (
        <TextField
            variant="outlined"
            color="primary"
            fullWidth
            disabled
            value={memoizedValue}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <AccountCircleIcon />
                    </InputAdornment>
                ),
            }}
            label={t('report.form.createdBy')}
        />
    );
};

export default CreatedByInput;
