import { useTranslation } from 'react-i18next';
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from 'react-virtualized';

import ListRoundedIcon from '@mui/icons-material/ListRounded';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import { Box, Card, CardContent, CardHeader, Collapse, LinearProgress, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { useInboxContext } from '../../store/inbox.context';

import InboxReportsSortDropdown from './components/InboxReportsSortDropdown';
import NCRListItem from './components/NCRListItem';

const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 81,
});

const NCRList = ({ checked, onCollapse, items, metadata, loading, loadMoreRows, sortBy, setSortBy }) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const { selectedNCRId, setSelectedNCR } = useInboxContext();

    const rowRenderer = ({ index, key, parent, style, ref }) => {
        const ncr = items[items?.length - 1 - index];

        return (
            <CellMeasurer ref={ref} key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                <div style={style}>
                    <NCRListItem ncr={ncr} selectedNCRId={selectedNCRId} />
                </div>
            </CellMeasurer>
        );
    };

    const isRowLoaded = ({ index }) => {
        return !!items[index] || loading;
    };

    const handleSortByUpdate = value => {
        setSortBy(value);
        setSelectedNCR(null);
    };

    return (
        <Collapse
            orientation="horizontal"
            in={checked}
            collapsedSize={52}
            sx={{
                '.MuiCollapse-horizontal': {
                    width: checked ? '100%' : 'auto',
                    maxWidth: mobile ? '100%' : 314,
                },
            }}>
            {!loading && !checked && (
                <Card style={{ width: 52, textAlign: 'center' }}>
                    <Box
                        style={{
                            bgcolor: 'background.paper',
                            height: 'calc(100vh - 160px)',
                            overflow: 'auto',
                            cursor: 'pointer',
                            paddingTop: '1em',
                        }}>
                        <div onClick={onCollapse}>
                            <ListRoundedIcon />
                        </div>
                    </Box>
                </Card>
            )}
            {checked && (
                <Card>
                    <CardHeader
                        style={{ boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)' }}
                        action={
                            !mobile && (
                                <UnfoldLessRoundedIcon
                                    onClick={onCollapse}
                                    style={{
                                        transform: 'rotate(90deg)',
                                        cursor: 'pointer',
                                    }}
                                />
                            )
                        }
                        title={
                            <>
                                <Typography variant="subtitle1" fontWeight="500" marginBottom="1em">
                                    {t('inbox.reportsList.title')}
                                </Typography>
                                <InboxReportsSortDropdown value={sortBy} disabled={loading} handleUpdate={handleSortByUpdate} />
                            </>
                        }
                    />
                    <CardContent style={{ padding: 0 }}>
                        <LinearProgress color="primary" sx={{ visibility: loading ? 'visible' : 'hidden' }} />
                        <Box
                            style={{
                                bgcolor: 'background.paper',
                                height: 'calc(100vh - 274px)',
                                overflow: 'auto',
                            }}>
                            <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={metadata?.total || 0}>
                                {({ onRowsRendered, registerChild }) => (
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                height={height}
                                                onRowsRendered={onRowsRendered}
                                                ref={registerChild}
                                                rowCount={items?.length || 0}
                                                rowHeight={cache.rowHeight}
                                                remoteRowCount={metadata?.total || 0}
                                                deferredMeasurementCache={cache}
                                                rowRenderer={rowRenderer}
                                                width={width}
                                            />
                                        )}
                                    </AutoSizer>
                                )}
                            </InfiniteLoader>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Collapse>
    );
};

export default NCRList;
