import { themeMode } from '@5thindustry/factory_portal.theme.theme-provider/dist/theme-provider';

import { languageCode } from '../../../enums/language';

import { userSettingsActions } from './userSettings.actions';

const localStorage = window.localStorage.getItem('userData');
const userData = JSON.parse(localStorage);

export const userSettingsInitialState = {
    userData: { ...userData },
    themeMode: userData?.userSettings.theme || themeMode.light,
    language: userData?.userSettings.language || languageCode.ENGLISH,
};

export const userSettingsReducer = (state, action) => {
    switch (action.type) {
        case userSettingsActions.SET_THEME_MODE:
            return {
                ...state,
                themeMode: action.payload,
            };
        case userSettingsActions.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };

        case userSettingsActions.SET_USER_DATA:
            return {
                ...state,
                userData: { ...action.payload },
                themeMode: action.payload.userSettings.theme,
                language: action.payload.userSettings.language,
            };

        default:
            return state;
    }
};
