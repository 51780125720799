import React, { useCallback } from 'react';

import { notificationsSeverityByRequestStatus } from '../../../../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../../../../common/providers/notification/store/notification.context';

import { useAtomicNCR } from '../../../../../../inbox/hooks/useAtomicNCR';

import MeasuresView from './measures/MeasuresView';
import RootCauseDescription from './RootCauseDescription';

const RootCauseContent = ({ capaDetail, ncrId, userImages }) => {
    const [handleAtomicUpdate] = useAtomicNCR();

    const { setNotification } = useNotificationContext();

    const handleNcrUpdate = useCallback(
        (path, values, operation = 'update', childIds = {}) => {
            const childrenIds = { capaDetailsId: capaDetail?._id, ...childIds };

            if (ncrId && values) {
                handleAtomicUpdate({ ncrId, operation, path, values, childrenIds })
                    .then(result => {
                        setNotification({ severity: notificationsSeverityByRequestStatus[result.data.atomicNCR.success] });
                    })
                    .catch(_ => {
                        setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                    });
            }
        },
        [ncrId, capaDetail?._id, handleAtomicUpdate, setNotification]
    );

    return (
        <>
            <RootCauseDescription capaDetail={capaDetail} handleUpdate={handleNcrUpdate} />
            <MeasuresView ncrId={ncrId} capaDetailId={capaDetail?._id} userImages={userImages} />
        </>
    );
};

export default RootCauseContent;
