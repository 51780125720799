import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Dialog, DialogTitle, Grid } from '@mui/material';

import { BarcodeReader } from '../utils/barcodeReaderClass';
import CameraModeButton from './CameraModeButton';

const BarcodeReaderModal = ({ isOpen, handleModalClose, handleResult }) => {
    const { t } = useTranslation();

    const [isFrontCamera, setIsFrontCamera] = useState(false);

    /**
     * onTransitionEndHandler is handling of the div with id="scanner-container" logic
     */
    const transitionEndHandler = () => {
        if (isOpen) {
            BarcodeReader.init(isFrontCamera);
            BarcodeReader.onDetected(handleResult);
        }
    };

    /**
     * closeModalHandler destroys the Quagga instance related to an element and closes the modal
     */
    const closeModalHandler = () => {
        BarcodeReader.offDetected();
        handleModalClose();
    };

    const handleCameraModeChange = () => {
        setIsFrontCamera(!isFrontCamera);
    };

    return (
        <Dialog open={isOpen} maxWidth="sm" onTransitionEnd={transitionEndHandler} onClose={closeModalHandler}>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={7}>
                    <DialogTitle sx={{ paddingBottom: '0px' }}>{t('common.barcode')}</DialogTitle>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box sx={{ padding: '16px 24px' }}>
                        <CameraModeButton onClick={handleCameraModeChange} />
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '0px !important', overflow: 'hidden' }}>
                    <div id="scanner-container" style={{ height: 360, width: 600, overflow: 'hidden' }} />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default BarcodeReaderModal;
