export const MAX_NUMBER_OF_MEDIA = 100;

export const allowedFileTypes = {
    images: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
    documents: ['.doc', '.docx', '.xls', '.xlsx', '.pdf'],
    attachments: ['image/*', '.jpg', '.jpeg', '.png', '.gif', '.doc', '.docx', '.xls', '.xlsx', '.pdf'],
    videos: ['video/*', '.mp4', '.avi', '.mov', '.wmv', '.flv', '.3gp', '.mkv', '.webm'],
};

export const webcamModes = {
    picture: ['picture'],
    media: ['video-audio', 'picture'],
};
