import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '../../../../../../common/constants/constants';
import { useUserSettingsContext } from '../../../../../../common/providers/userSettings/store/userSettings.context';

import { getValueByLanguage } from '../../../../../../utils/common';

import { useNcrReworkProcessStepsTableColumns } from './useNcrReworkProcessStepsTableColumns';

export const useNcrReworkProcessStepsTableData = ({ reworkId, ncr, disableDelete }) => {
    const [columns, loading] = useNcrReworkProcessStepsTableColumns({ disableDelete });
    const { language } = useUserSettingsContext();

    const { t } = useTranslation();

    let rows = [];

    if (ncr) {
        const rework = ncr.reworks.find(rework => rework._id === reworkId);

        if (rework) {
            rows =
                rework.processRoutings[0]?.processSteps?.map(item => {
                    return {
                        id: item._id,
                        stepStatus: t(`statuses.${item.stepStatus}`),
                        stepName: getValueByLanguage(item.stepName, language),
                        stepNumber: item.stepNumber,
                        responsible: item.resolvedResponsible[0],
                        processTime: item.plannedDurations?.processTime || '',
                        workplaceName: !!item?.workplaces?.length ? item?.workplaces[0]?.workPlaceName[0] : null,
                        dueDate: item.schedulingData?.dueDate ? moment(item.schedulingData?.dueDate).format(DEFAULT_DATE_FORMAT) : 'N/A',
                        rework,
                    };
                }) || [];
        }
    }

    return [rows, columns, loading];
};
