import { useMemo } from 'react';

import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '../../../common/constants/constants';

import createMongoObjectId from '../../../utils/mongo';

import { useLazyGetOverviewPaginatedNCRs } from './useLazyGetOverviewPaginatedNCRs';
import { useNcrOverviewTableColumns } from './useNcrOverviewTableColumns';

export const useNcrOverviewTableData = () => {
    const [items, loading, fetchData] = useLazyGetOverviewPaginatedNCRs();
    const [columns] = useNcrOverviewTableColumns();

    const rows = useMemo(() => {
        if (!items) {
            return [];
        }

        return (
            items?.ncrs?.map(item => {
                return {
                    rowId: item._id,
                    externalReference: item.basicInformation?.externalReference || 'N/A',
                    name: item.name || createMongoObjectId(),
                    failureCategory: `${item.basicInformation?.failureCategory?.name || 'N/A'} | ${
                        item.basicInformation?.failureCategory?.failureMode || 'N/A'
                    }`,
                    'ncrModifiedBy.modifiedAt': moment(item.ncrModifiedBy?.modifiedAt).format(DEFAULT_DATE_FORMAT),
                    'ncrCreatedBy.createdAt': moment(item.ncrCreatedBy?.createdAt).format(DEFAULT_DATE_FORMAT),
                    responsible: !!item?.resolvedResponsible?.length ? item?.resolvedResponsible[0] : null,
                    status: item.status,
                    archived: item.archived,
                    contextMenu: item,
                };
            }) || []
        );
    }, [items]);

    const total = useMemo(() => {
        if (!items) {
            return 0;
        }

        return items.metadata.total;
    }, [items]);

    return [rows, columns, loading, total, fetchData];
};
