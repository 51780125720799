import React, { useMemo } from 'react';

import AwsS3 from '@uppy/aws-s3';
import Compressor from '@uppy/compressor';
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import Webcam from '@uppy/webcam';

import { GATEWAY_URL } from '../../../configs/env';
import createMongoObjectId from '../../../utils/mongo';
import { allowedFileTypes, MAX_NUMBER_OF_MEDIA, webcamModes } from '../../constants/fileUpload';
import { getUppyLocale } from './locale';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import './dashboardStyle.css';
import '@uppy/webcam/dist/style.css';

const FileUploader = ({
    isOpened,
    toggleModal,
    webcamConfig,
    facingMode,
    language,
    themeMode,
    maxNumberOfFiles,
    allowedTypes,
    token,
    allowUniqueName,
    allowUniqueNameWithName,
    targetFolder,
    useWebcam,
    compressBeforeUpload,

    addAttachments,
    fetchImages,
    handleSetTileData,
    handleSaveAttachments,
}) => {
    const webcamOptions = useMemo(() => {
        return {
            modes: webcamConfig || webcamModes.picture,
            facingMode: facingMode || 'environment',
            mirror: false,
        };
    }, [webcamConfig, facingMode]);

    const uppy = useMemo(() => {
        const uppyInstance = new Uppy({
            id: 'uppy-dashboard',
            locale: getUppyLocale(language),
            restrictions: {
                maxNumberOfFiles: maxNumberOfFiles || MAX_NUMBER_OF_MEDIA,
                allowedFileTypes: allowedTypes || allowedFileTypes.images,
            },
        });

        uppyInstance
            .use(AwsS3, {
                getUploadParameters(file) {
                    // Send a request to our Gateway endpoint to get SAS.
                    return fetch(GATEWAY_URL, {
                        method: 'post',
                        // Send and receive JSON.
                        headers: {
                            accept: 'application/json',
                            'content-type': 'application/json',
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            query: `mutation{createSignedPutURL(filename: "${
                                (allowUniqueName && `${createMongoObjectId()}.${file.extension}`) ||
                                (allowUniqueNameWithName && `${createMongoObjectId()}.${file.name}`) ||
                                file.name
                            }" filetype:"${file.type}" targetFolder:"${targetFolder}")
                            {
                                signedRequest
                                url
                            }}`,
                        }),
                    })
                        .then(response => {
                            // Parse the JSON response.
                            return response.json();
                        })
                        .then(data => {
                            // Return an object in the correct shape.
                            return {
                                method: 'PUT',
                                url: data.data.createSignedPutURL.signedRequest,
                                fields: [],
                            };
                        })
                        .catch(e => console.error(e));
                },
            })
            .on('complete', () => {
                if (addAttachments) {
                    const files = uppy.getState().files;
                    addAttachments(files);
                }

                uppy.reset();
                toggleModal(false);
            });

        if (allowedTypes === allowedFileTypes.images || useWebcam) {
            uppyInstance.use(Webcam, {
                ...webcamOptions,
            });
        }

        if (compressBeforeUpload) {
            uppyInstance.use(Compressor);
        }

        return uppyInstance;
    }, [
        language,
        token,
        addAttachments,
        targetFolder,
        maxNumberOfFiles,
        allowUniqueName,
        allowUniqueNameWithName,
        allowedTypes,
        webcamOptions,
        useWebcam,
        compressBeforeUpload,
        toggleModal,
    ]);

    React.useEffect(() => {
        return () => uppy.close({ reason: 'unmount' });
    }, [uppy]);

    return <DashboardModal uppy={uppy} open={isOpened} onRequestClose={() => toggleModal(false)} plugins={['Webcam']} theme={themeMode} />;
};
export default FileUploader;
