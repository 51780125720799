import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeMode } from '@5thindustry/factory_portal.theme.theme-provider/dist/theme-provider';

export const StyledReworkButtonPaper = styled(Paper)(({ theme }) => ({
    width: '228px',
    height: '192px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background:
        theme.palette.mode === themeMode.light
            ? 'linear-gradient(0deg, rgba(0, 100, 149, 0.05), rgba(0, 100, 149, 0.05)), linear-gradient(0deg, rgba(117, 119, 122, 0.02), rgba(117, 119, 122, 0.02)), #FCFCFF'
            : '#2c2c2c',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
}));

export const StyledScrapButtonPaper = styled(Paper)(({ theme }) => ({
    width: '228px',
    height: '192px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background:
        theme.palette.mode === themeMode.light
            ? 'linear-gradient(0deg, rgba(0, 100, 149, 0.05), rgba(0, 100, 149, 0.05)), linear-gradient(0deg, rgba(117, 119, 122, 0.02), rgba(117, 119, 122, 0.02)), #FCFCFF'
            : '#2c2c2c',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
}));

export const StyledClearanceButtonPaper = styled(Paper)(({ theme }) => ({
    width: '228px',
    height: '192px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background:
        theme.palette.mode === themeMode.light
            ? 'linear-gradient(0deg, rgba(0, 100, 149, 0.05), rgba(0, 100, 149, 0.05)), linear-gradient(0deg, rgba(117, 119, 122, 0.02), rgba(117, 119, 122, 0.02)), #FCFCFF'
            : '#2c2c2c',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
}));
