import React, { Fragment, memo, useContext } from 'react';

import { Grid } from '@mui/material';

import { customInputElementType } from '../../../../../../../common/enums/customInputElementType';
import { UserSettingsContext } from '../../../../../../../common/providers/userSettings/store/userSettings.context';

import { getValueByLanguage } from '../../../../../../../utils/common';

import NcrCheckbox from '../../../../../../report/components/customFields/NcrCheckbox';
import NcrInput from '../../../../../../report/components/customFields/NcrInput';
import NcrSelect from "../../../../../../report/components/customFields/NcrSelect";

const fields = language => {
    return {
        [customInputElementType.TEXT]: props => {
            const handleBlur = event => {
                props.handleUpdate(
                    'clearances.$[clearanceId].customInputElements.$[customInputElementId]',
                    {
                        customInputElement: { value: event.target.value },
                    },
                    'update',
                    {
                        customInputElementId: props.customInputElementId,
                    }
                );
            };

            const label = getValueByLanguage(props.label, language);

            return (
                <NcrInput
                    disabled={props.disabled}
                    value={props.value}
                    onBlur={handleBlur}
                    onKeyPress={event => {
                        event.key === 'Enter' && handleBlur(event);
                    }}
                    label={label}
                />
            );
        },

      [customInputElementType.NUMBER]: props => {
        const handleBlur = event => {
          props.handleUpdate(
            'clearances.$[clearanceId].customInputElements.$[customInputElementId]',
            {
              customInputElement: { value: event.target.value },
            },
            'update',
            {
              customInputElementId: props.customInputElementId,
            }
          );
        };

        const label = getValueByLanguage(props.label, language);

        return (
          <NcrInput
            disabled={props.readOnly}
            value={props.value}
            onBlur={handleBlur}
            onKeyPress={event => {
              event.key === 'Enter' && handleBlur(event);
            }}
            type="number"
            label={label}
          />
        );
      },

      [customInputElementType.SELECT]: props => {
        const label = getValueByLanguage(props.label, language);

        const options = props.choices.map(option => {
          return {
            label: getValueByLanguage(option.label, language),
            value: option.value,
          };
        });

        const currentValue = props.value ? options?.find(option => option?.value === props.value) : null;

        const handleChange = newValue => {
          props.handleUpdate(
            'clearances.$[clearanceId].customInputElements.$[customInputElementId]',
            {
              customInputElement: { value: newValue },
            },
            'update',
            {
              customInputElementId: props.customInputElementId,
            }
          );
        };

        return (
          <NcrSelect
            disabled={props.disabled || props.readOnly}
            value={currentValue}
            loading={props.loading}
            options={options}
            label={label}
            labelKey="label"
            valueKey="value"
            handleChange={handleChange}
          />
        );
      },

        [customInputElementType.CHECKBOXES]: props => {
            const title = getValueByLanguage(props?.label, language);

            const handleChange = event => {
                props.handleUpdate(
                    'clearances.$[clearanceId].customInputElements.$[customInputElementId]',
                    {
                        customInputElement: { value: event.target.checked.toString() },
                    },
                    'update',
                    {
                        customInputElementId: props.customInputElementId,
                    }
                );
            };

            return (
                <NcrCheckbox
                    value={JSON.parse(props?.value?.toLowerCase() || "false")}
                    label={title}
                    handleChange={handleChange}
                    disabled={props.disabled}
                />
            );
        },
    };
};

const ClearanceCustomInputsComponents = ({ customInputElements, handleUpdate, disabled }) => {
    const { language } = useContext(UserSettingsContext);

    return (
        !!customInputElements?.length && (
            <Grid width="100%" container spacing={6}>
                {customInputElements?.map((field, index) => {
                    const item = fields(language)[field.customInputElementType];

                    return (
                        <Fragment key={field?._id}>
                            {item && (
                                <Grid item xs={12} sm={5}>
                                    {item &&
                                        item({
                                            ...field,
                                            handleUpdate: handleUpdate,
                                            customInputElementId: field._id,
                                            customInputElementIndex: index,
                                            disabled,
                                        })}
                                </Grid>
                            )}
                        </Fragment>
                    );
                })}
            </Grid>
        )
    );
};

const ClearanceCustomInputs = memo(ClearanceCustomInputsComponents);

export default ClearanceCustomInputs;
