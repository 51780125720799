export const ncrInfoActions = {
    RESET_NCR_INFO_STATE: 'RESET_NCR_INFO_STATE',
    SET_NCR_REWORK_SECTION_FROM_OWN_TEMPLATE: 'SET_NCR_REWORK_SECTION_FROM_OWN_TEMPLATE',
};

export const resetNcrInfoState = () => ({
    type: ncrInfoActions.RESET_NCR_INFO_STATE,
});

export const setNcrReworks = ncrReworks => ({
    type: ncrInfoActions.SET_NCR_REWORK_SECTION_FROM_OWN_TEMPLATE,
    payload: ncrReworks,
});

export default ncrInfoActions;
