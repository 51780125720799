import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IconButton, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { ContextMenu } from '@5thindustry/factory_portal.components.contextmenu/dist/contextmenu';

import UserNameWithAvatar from '../../../../../../common/components/UserNameWithAvatar';
import NcrStatusLabel from '../../../../../../common/components/ncr/NcrStatusLabel';
import useCascadeDeleteManyNCRs from './useCascadeDeleteManyNCRs';

import { useCascadeUpdateManyNCRs } from '../../../../../ncr-overview/hooks/useCascadeUpdateManyNCRs';
import { useUserAvatar } from '../../../../../../common/hooks/useUserAvatar';
import { useUserImages } from '../../../../../../common/hooks/useUserImages';

export const useNcrBinTableColumns = () => {
    const { t } = useTranslation();

    const confirm = useConfirm();

    const [images] = useUserImages();
    const [avatar] = useUserAvatar();

    const [cascadeUpdate] = useCascadeUpdateManyNCRs();
    const [cascadeDelete] = useCascadeDeleteManyNCRs();

    const handleUpdateDeletedArchived = useCallback(
        (_id, field) => () => cascadeUpdate([_id], field, false),
        [cascadeUpdate]
    );

    const handleDeletePermanently = useCallback(
        _id => () => {
            confirm({
                titleProps: { variant: 'body1', sx: { textAlign: 'center', marginBottom: '2em' } },
                title: <Typography variant="h5">{t('adminPage.ncrBin.deleteConfirmation.title')}</Typography>,
                contentProps: { sx: { textAlign: 'center' } },
                content: (
                    <Typography variant="body1" sx={{ marginBottom: '1em' }}>
                        {t('adminPage.ncrBin.deleteConfirmation.content')}
                    </Typography>
                ),
                dialogActionsProps: { sx: { justifyContent: 'center', marginBotom: '2em' } },
                confirmationText: t('adminPage.ncrBin.deleteConfirmation.deleteButton'),
                confirmationButtonProps: { variant: 'contained', color: 'error', sx: { cursor: 'pointer' } },
                cancellationText: t('adminPage.ncrBin.deleteConfirmation.cancelButton'),
                cancellationButtonProps: { variant: 'outlined', color: 'primary', sx: { cursor: 'pointer' } },
            })
                .then(() => cascadeDelete([_id]))
                .catch(_ => {});
        },
        [cascadeDelete, confirm, t]
    );

    const columns = useMemo(() => {
        return [
            {
                field: 'name',
                headerName: t('ncrOverview.orderId'),
                width: 140,
                renderCell: params => {
                    return (
                        <Link
                            style={{
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                            to={`/ncr-overview/${params.id}`}>
                            {params.value}
                        </Link>
                    );
                },
            },
            {
                field: 'externalReference',
                headerName: t('ncrOverview.externalReference'),
                width: 260,
                valueGetter: params => params.row.externalReference,
            },
            {
                field: 'failureCategory',
                headerName: t('ncrOverview.failureCategory'),
                width: 280,
                valueGetter: params => params.row.failureCategory,
            },
            { field: 'ncrModifiedBy.modifiedAt', headerName: t('ncrOverview.modifiedAt'), type: 'date', width: 120 },
            {
                field: 'ncrCreatedBy.createdAt',
                headerName: t('ncrOverview.createdAt'),
                type: 'date',
                width: 120,
            },
            {
                field: 'responsible',
                minWidth: 220,
                headerName: t('ncrOverview.responsible'),
                renderCell: params => {
                    if (!params.row.responsible) {
                        return null;
                    }

                    const avatarSrc = avatar.getUserAvatarByThumbnail(
                        images,
                        params?.row?.responsible?.profilePic?.thumbnail
                    );

                    return (
                        <UserNameWithAvatar
                            avatarSrc={avatarSrc}
                            userName={`${params.formattedValue.firstName} ${params.formattedValue.name}`}
                        />
                    );
                },
            },
            {
                field: 'status',
                headerName: t('ncrOverview.status'),
                width: 160,
                valueGetter: params => params.row.status,
                renderCell: params => {
                    return <NcrStatusLabel value={params?.row?.status} />;
                },
            },
            {
                field: 'contextMenu',
                width: 80,
                sortable: false,
                headerName: '',
                valueGetter: params => params.row,
                renderCell: params => {
                    let ncr = params?.row;

                    return (
                        <IconButton
                            disableRipple
                            disableTouchRipple
                            onClick={event => {
                                event.ignoreRowClick = true;
                            }}>
                            <ContextMenu
                                options={[
                                    {
                                        icon: <RestoreFromTrashIcon />,
                                        key: `restore-${ncr?.rowId}`,
                                        label: t('common.context-menu-options.restore'),
                                        onClick: handleUpdateDeletedArchived(ncr?.rowId, 'deleted'),
                                    },
                                    {
                                        icon: <DeleteForeverIcon />,
                                        key: `delete-permanently-${ncr?.rowId}`,
                                        label: t('common.context-menu-options.deletePermanently'),
                                        onClick: handleDeletePermanently(ncr?.rowId),
                                    },
                                ]}
                            />
                        </IconButton>
                    );
                },
            },
        ];
    }, [t, images, avatar, handleUpdateDeletedArchived, handleDeletePermanently]);

    return [columns];
};
