import React from 'react';
import { useTranslation } from 'react-i18next';

import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';

const LoadMoreButton = ({ isDisable, onClick, isLoading }) => {
    const { t } = useTranslation();

    return (
        <LoadingButton
            loading={isLoading}
            disabled={isDisable}
            loadingPosition="end"
            sx={{ cursor: 'pointer' }}
            onClick={onClick}
            endIcon={<RefreshIcon />}
            variant="outlined"
            color="primary">
            <Typography variant="button" sx={{ cursor: 'pointer' }}>
                {t('common.action-buttons.loadMore')}
            </Typography>
        </LoadingButton>
    );
};

export default LoadMoreButton;
