import { gql } from '@apollo/client';

const CREATE_CAPA_TICKET = gql`
    mutation CreateCapaTicket($capaTicket: CapaTicketInput) {
        createCapaTicket(capaTicket: $capaTicket) {
            code
            success
            capaTicket {
                ticketType
                action
                description
                status
                dueDate
                deleted
                images
                _id
                responsible {
                    responsibleID
                }
                resolvedResponsible {
                    firstName
                    name
                    roles
                    profilePic {
                        thumbnail
                    }
                }
                tags {
                    name
                    colorCode
                    _id
                }
                incidentType {
                    description
                    name
                    colorCode
                }
                externalReference {
                    NCRCapaDetailId
                    NCRId
                }
            }
        }
    }
`;

const GET_CAPA_TICKET = gql`
    query GetCapaTickets($capaTicket: CapaTicketInput, $filter: String) {
        getCapaTickets(capaTicket: $capaTicket, filter: $filter) {
            ticketType
            action
            description
            status
            deleted
            dueDate
            images
            _id
            responsible {
                responsibleID
            }
            resolvedResponsible {
                firstName
                name
                roles
                profilePic {
                    thumbnail
                }
            }
            tags {
                name
                colorCode
                _id
            }
            incidentType {
                description
                name
                colorCode
            }
            externalReference {
                NCRCapaDetailId
                NCRId
            }
        }
    }
`;

const GET_PAGINATED_CAPA_TICKET = gql`
    query GetPaginatedCapaTickets($filter: String, $limit: Int, $skip: Int, $sort: String) {
        getPaginatedCapaTickets(filter: $filter, limit: $limit, skip: $skip, sort: $sort) {
            code
            success
            message
            metadata {
                total
                skip
                limit
                hasPrev
                hasNext
            }
            capaTickets {
                ticketType
                action
                description
                status
                dueDate
                deleted
                images
                _id
                responsible {
                    responsibleID
                }
                resolvedResponsible {
                    firstName
                    name
                    roles
                    profilePic {
                        thumbnail
                    }
                }
                tags {
                    name
                    colorCode
                    _id
                }
                incidentType {
                    description
                    name
                    colorCode
                }
                externalReference {
                    NCRCapaDetailId
                    NCRId
                }
                ticketModifiedBy {
                    modifiedAt
                }
                ticketCreatedBy {
                    createdAt
                }
            }
        }
    }
`;

const UPDATE_CAPA_TICKET = gql`
    mutation UpdateCapaTicket($capaTicket: CapaTicketInput) {
        updateCapaTicket(capaTicket: $capaTicket) {
            code
            success
            message
            capaTicket {
                ticketType
                action
                description
                status
                dueDate
                images
                deleted
                _id
                responsible {
                    responsibleID
                }
                resolvedResponsible {
                    firstName
                    name
                    roles
                    profilePic {
                        thumbnail
                    }
                }
                tags {
                    name
                    colorCode
                    _id
                }
                incidentType {
                    description
                    name
                    colorCode
                }
                externalReference {
                    NCRCapaDetailId
                    NCRId
                }
                ticketModifiedBy {
                    modifiedAt
                }
                ticketCreatedBy {
                    createdAt
                }
            }
        }
    }
`;

export { CREATE_CAPA_TICKET, GET_CAPA_TICKET, GET_PAGINATED_CAPA_TICKET, UPDATE_CAPA_TICKET };
