import React, { useMemo } from 'react';

import { themeMode } from '@5thindustry/factory_portal.theme.theme-provider/dist/theme-provider';

import { Avatar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';

import { getFilteredNcrUserRoles } from '../../../../../../common/constants/userRoles';
import { useGetUsersOptions } from '../../../../../../common/hooks/useGetUsersOptions';
import { useUserAvatar } from '../../../../../../common/hooks/useUserAvatar';

import { useCurrentUserBySub } from '../../../../../../utils/awsAuth';

const StyledDataRowTitleDiv = styled('div')(({ theme }) => ({
    display: 'flex',

    justifyContent: 'space-between',

    '& p': {
        textAlign: 'end',
        fontSize: '12px',
    },
    '& div': {
        minWidth: '170px',
    },

    marginBottom: '8px',
}));

const StyledDataRowTitleRightDiv = styled('div')(({ theme }) => ({
    display: 'flex',

    justifyContent: 'space-between',

    '& p': {
        textAlign: 'start',
        fontSize: '12px',
    },
    '& div': {
        minWidth: '170px',
        textAlign: 'end',
    },

    marginBottom: '8px',
}));

const StyledDataRowDiv = styled('div')(({ theme }) => ({
    display: 'flex',
}));

const StyledDataRowRightDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    marginRight: '0.7em',
}));

const StyledSubTextTypography = styled(Typography)(({ theme }) => ({
    color: '#64676c',
    fontSize: '12px !important',
    fontWeight: 500,
}));

const StyledActionMessageTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.mode === themeMode.light ? '#000' : '#fff',
}));

const StyledRootDiv = styled('div')(({ theme }) => ({
    border: `1px #CED4DB solid`,
    borderRadius: 16,

    padding: '1em',
    marginBottom: '1em',

    maxWidth: '70%',
}));

const StyledRootRightDiv = styled('div')(({ theme }) => ({
    border: `1px #CED4DB solid`,
    borderRadius: 16,

    padding: '1em',
    marginBottom: '1em',
    marginLeft: 'auto',

    maxWidth: '70%',
}));

const ChatCard = ({ activityActor, createdAt, activityObject, images }) => {
    const user = useCurrentUserBySub();

    const [userOptions] = useGetUsersOptions();

    const [avatar] = useUserAvatar();

    const actor = useMemo(
        () => userOptions && userOptions?.find(user => user?.value === activityActor?.actorID),
        [activityActor, userOptions]
    );

    const avatarSrc = avatar?.getUserAvatarByThumbnail(images, actor?.avatar);

    return user?.data?._id !== actor?.value ? (
        <StyledRootDiv>
            <StyledDataRowTitleDiv>
                <div>{actor?.fullName}</div>
                <StyledSubTextTypography>{`${getFilteredNcrUserRoles(actor?.roles)?.join(', ')}, ${moment(createdAt).format(
                    'HH:mm'
                )}`}</StyledSubTextTypography>
            </StyledDataRowTitleDiv>

            <StyledDataRowDiv>
                <StyledAvatar src={avatarSrc}>{actor ? actor?.fullName : ''}</StyledAvatar>
                <StyledActionMessageTypography>{activityObject?.value}</StyledActionMessageTypography>
            </StyledDataRowDiv>
        </StyledRootDiv>
    ) : (
        <StyledRootRightDiv>
            <StyledDataRowTitleRightDiv>
                <StyledSubTextTypography>{`${getFilteredNcrUserRoles(actor?.roles)?.join(', ')}, ${moment(createdAt).format(
                    'HH:mm'
                )}`}</StyledSubTextTypography>

                <div>{actor?.fullName}</div>
            </StyledDataRowTitleRightDiv>

            <StyledDataRowRightDiv>
                <StyledActionMessageTypography>{activityObject?.value}</StyledActionMessageTypography>
                <StyledAvatar src={avatarSrc}>{actor ? actor?.fullName : ''}</StyledAvatar>
            </StyledDataRowRightDiv>
        </StyledRootRightDiv>
    );
};

export default ChatCard;
