import { useMemo } from 'react';

import { useMutation } from '@apollo/client';

import { GET_FILES } from '../gqlQueries/queries';

export const useFiles = accessRootFolder => {
    const [fetchUserImagesData, { data, loading }] = useMutation(GET_FILES);

    const handleFetch = () => {
        return fetchUserImagesData({
            variables: {
                accessRootFolder,
            },
        });
    };

    const files = useMemo(() => data?.createSignedGetURL, [data?.createSignedGetURL]);

    return [handleFetch, files, loading];
};
