import { useCallback, useContext, useMemo, useReducer } from 'react';

import {
    resetNcr,
    setNCR,
    setNCRCustomFields,
    setNCRTemplate,
    updateNCRTemplateAttachments,
    setNCRResponsible,
    setNCRReworkProcessStep,
    updateNCRSectionWorkflowSteps,
    updateNCRSectionWorkflowStatus,
    updateNcrImmediateMeasureSectionWorkflowResponsible,
} from './store/actions';
import { NcrTemplateContext } from './store/context';
import { ncrTemplateInitialState, ncrTemplateReducer } from './store/reducer';

const NCRTemplateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ncrTemplateReducer, ncrTemplateInitialState);

    const memoizedSetNCRTemplate = useCallback(ncr => dispatch(setNCRTemplate(ncr)), [dispatch]);

    const memoizedSetNCRReworkProcessStep = useCallback(
        ({ reworkId, value }) => dispatch(setNCRReworkProcessStep({ reworkId, value })),
        [dispatch]
    );

    const memoizedUpdateNCRSectionWorkflowSteps = useCallback(
        ({ sectionId, workflowId, value }) => {
            dispatch(updateNCRSectionWorkflowSteps({ sectionId, workflowId, value }));
        },
        [dispatch]
    );

    const memoizedUpdateNCRSectionWorkflowStatus = useCallback(
        ({ sectionId, workflowId, value }) => {
            dispatch(updateNCRSectionWorkflowStatus({ sectionId, value }));
        },
        [dispatch]
    );

    const ncrTemplateContextValue = useMemo(() => {
        return {
            ncr: state.ncr,
            resetNCR: () => dispatch(resetNcr()),
            setNCR: ({ value, path }) => dispatch(setNCR({ value, path })),
            setNCRResponsible: value => dispatch(setNCRResponsible(value)),
            setNCRCustomFields: ({ value, id }) => dispatch(setNCRCustomFields({ value, id })),
            setNCRTemplate: memoizedSetNCRTemplate,
            setNCRReworkProcessStep: memoizedSetNCRReworkProcessStep,
            updateNCRSectionWorkflowSteps: memoizedUpdateNCRSectionWorkflowSteps,
            updateNCRSectionWorkflowStatus: memoizedUpdateNCRSectionWorkflowStatus,
            updateNcrTemplateAttachments: attachments => dispatch(updateNCRTemplateAttachments(attachments)),
            updateNcrImmediateMeasureSectionWorkflowResponsible: payload =>
                dispatch(updateNcrImmediateMeasureSectionWorkflowResponsible(payload)),
        };
    }, [
        state.ncr,
        memoizedSetNCRTemplate,
        memoizedSetNCRReworkProcessStep,
        memoizedUpdateNCRSectionWorkflowSteps,
        memoizedUpdateNCRSectionWorkflowStatus,
        dispatch,
    ]);

    return <NcrTemplateContext.Provider value={ncrTemplateContextValue}>{children}</NcrTemplateContext.Provider>;
};

export const useNcrTemplateContext = () => {
    return useContext(NcrTemplateContext);
};

export default NCRTemplateProvider;
