import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { notificationsSeverityByRequestStatus } from '../../../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../../../common/providers/notification/store/notification.context';

import { useAtomicNCR } from '../../../../../inbox/hooks/useAtomicNCR';

import { ncrStatus } from '../../../../../../common/enums/ncrStatus';

const AddCauseButton = ({ ncr }) => {
    const { t } = useTranslation();

    const [handleAtomicUpdate] = useAtomicNCR();

    const { setNotification } = useNotificationContext();

    const [disableButton, setDisableButton] = useState(false);

    const handleCreateCause = () => {
        if (ncr?._id && ncr?.capaDetails) {
            setDisableButton(true);

            handleAtomicUpdate({
                ncrId: ncr?._id,
                operation: 'add',
                path: 'capaDetails',
                values: {
                    capaDetails: {
                        rootCause: `Root cause ${ncr?.capaDetails?.length + 1}`,
                        impactDescription: '',
                    },
                },
                childIds: {},
            })
                .then(result => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[result.data.atomicNCR.success] });

                    setDisableButton(false);
                })
                .catch(_ => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[false] });

                    setDisableButton(false);
                });
        }
    };

    return (
        <Button variant="contained" color="primary" onClick={handleCreateCause} disabled={disableButton || ncr.status === ncrStatus.DONE}>
            {t('ncrInfo.corrective.addCause')}
        </Button>
    );
};

export default AddCauseButton;
