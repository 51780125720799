import { useTranslation } from 'react-i18next';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box } from '@mui/material';

const PermissionError = () => {
    const { t } = useTranslation();

    return (
        <Box sx={{ padding: 24, textAlign: 'center' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 24,
                    padding: '24px 0px',
                    '& .MuiSvgIcon-root': {
                        fontSize: 52,
                    },
                }}>
                <ErrorOutlineIcon />
                <Box>{t('common.permission-error.title')}</Box>
            </Box>
            <Box sx={{ fontSize: 20 }}>{t('common.permission-error.description')}</Box>
        </Box>
    );
};

export default PermissionError;
