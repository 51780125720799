import React from 'react';

import { ContextMenu } from '@5thindustry/factory_portal.components.contextmenu/dist/contextmenu';
import { themeMode } from '@5thindustry/factory_portal.theme.theme-provider/dist/theme-provider';
import { Avatar, Box, Card, CardContent, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { truncateString } from '../utils/arrayHelper';

import TagComponent from './TagComponent';

const StyledCard = styled(Card)(({ theme }) => ({
    width: '100%',
    height: 120,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    background: theme.palette.mode === themeMode.light ? '#FCFCFF' : '#2c2c2c',
}));

const DEFAULT_TITLE_LENGTH = 15;
const DEFAULT_SUBTITLE_LENGTH = 13;

const TicketCard = ({ tags, subtitle, title, avatarSrc, contextMenuOptions }) => {
    return (
        <StyledCard>
            <Box sx={{ margin: '8px 0px 0px 10px' }}>
                <Box sx={{ minHeight: '24px' }}>
                    {tags?.map((tag, index) => (
                        <TagComponent key={index} text={tag?.name} backgroundColor={tag?.colorCode} textColor={tag?.textColor || '#fff'} />
                    ))}

                    {contextMenuOptions && (
                        <IconButton aria-label="settings" sx={{ float: 'right', marginRight: '4px', padding: 0 }}>
                            <ContextMenu options={contextMenuOptions} />
                        </IconButton>
                    )}
                </Box>
            </Box>
            <CardContent sx={{ paddingTop: '8px', textOverflow: 'ellipsis' }}>
                {truncateString(title, DEFAULT_TITLE_LENGTH)}
                <Box
                    sx={{
                        marginTop: '8px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <Box
                        sx={{
                            opacity: 0.6,
                            fontSize: '14px',
                        }}>
                        {truncateString(subtitle, DEFAULT_SUBTITLE_LENGTH)}
                    </Box>
                    <Avatar src={avatarSrc} />
                </Box>
            </CardContent>
        </StyledCard>
    );
};

export default TicketCard;
