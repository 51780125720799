import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';

import AutoSaveTextField from '../../../../../../../common/components/AutoSaveTextField';

const StepDurationInputComponent = ({ value, disabled, handleUpdate }) => {
    const { t } = useTranslation();

    return (
        <AutoSaveTextField
            value={value}
            disabled={disabled}
            InputProps={{
                endAdornment: <WatchLaterRoundedIcon color="action" />,
            }}
            label={t('ncrInfo.immediate.table.duration')}
            onChange={handleUpdate}
            fullWidth
        />
    );
};

const StepDurationInput = memo(StepDurationInputComponent);

export default StepDurationInput;
