import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';

import AutoSaveTextField from '../AutoSaveTextField';

const CostInputComponent = ({ costAssignment, disabled, path, operation, handleUpdate }) => {
    const { t } = useTranslation();

    const onBlurChange = event => {
        const newValue = +event?.target?.value;

        if ((costAssignment?.value || null) !== newValue) {
            handleUpdate(
                operation === 'update' ? `${path}.costAssignments.0` : `${path}.costAssignments`,
                {
                    costAssignment: {
                        value: parseFloat(newValue),
                        currency: 'EUR',
                        costCenter: costAssignment?.costCenter,
                    },
                },
                operation
            );
        }
    };

    return (
        <AutoSaveTextField
            disabled={disabled}
            type="number"
            InputProps={{
                endAdornment: <EuroSymbolIcon />,
                inputProps: { min: 0, max: Number.MAX_SAFE_INTEGER },
            }}
            value={costAssignment?.value}
            label={t('ncrInfo.immediate.cost')}
            onBlur={onBlurChange}
            onKeyPress={event => {
                event.key === 'Enter' && onBlurChange(event);
            }}
            fullWidth
        />
    );
};

const CostInput = memo(CostInputComponent);

export default CostInput;
