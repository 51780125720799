import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';

import { useNotificationContext } from '../../../../../../common/providers/notification/store/notification.context';

import { CASCADE_DELETE_MANY_NCRS } from '../operations/cascadeDeleteManyNCRs';

import {
    notificationsSeverity,
    notificationsSeverityByRequestStatus,
} from '../../../../../../common/providers/notification/constants';

const useCascadeDeleteManyNCRs = () => {
    const { setNotification } = useNotificationContext();

    const { t } = useTranslation();

    const [cascadeDelete, { loading }] = useMutation(CASCADE_DELETE_MANY_NCRS, {
        onCompleted: data => {
            setNotification({
                severity: notificationsSeverityByRequestStatus[data.cascadeDeleteManyNCRs.success],
                message: t('adminPage.ncrBin.message.success-message'),
            });
        },
        onError: error => {
            setNotification({
                severity: notificationsSeverity.ERROR,
                message: t('adminPage.ncrBin.message.error-message'),
            });
        },
        update: (cache, { data: { cascadeDeleteManyNCRs } }) => {
            const { ncrs } = cascadeDeleteManyNCRs;

            ncrs.forEach(ncr => {
                cache.evict({ id: cache.identify({ __typename: 'NCR', _id: ncr._id }) });
            });
        },
    });

    const handleCascadeUpdate = ncrIds => cascadeDelete({ variables: { ncrIds } });

    return [handleCascadeUpdate, loading];
};

export default useCascadeDeleteManyNCRs;
