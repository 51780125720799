import { useMutation } from '@apollo/client';
import { ncrStatus } from '../../../../enums/ncrStatus';
import { UPDATE_WORKFLOW_STEP_IN_TRANSITION_MODE } from '../../operations/mutations/updateWorkflowStepInTransitionMode';

export const useUpdateWorkflowStepInTransitionMode = () => {
    const [atomic, { loading }] = useMutation(UPDATE_WORKFLOW_STEP_IN_TRANSITION_MODE);

    const handleUpdateWorkflowStepInTransitionMode = ({ currentWorkflowStepId, nextWorkflowStepId, responsibleId, dueDate }) => {
        return atomic({
            variables: {
                currentWorkflowStepId: {
                    _id: currentWorkflowStepId,
                    status: ncrStatus.DONE,
                },
                nextWorkflowStepId: {
                    _id: nextWorkflowStepId,
                    status: ncrStatus.IN_WORK,
                    responsible: {
                        responsibleID: responsibleId,
                    },
                    dueDate,
                },
            },
            refetchQueries: ['GetSingleNCR'],
        });
    };

    return [handleUpdateWorkflowStepInTransitionMode, loading];
};
