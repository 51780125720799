export const userSettingsActions = {
    SET_THEME_MODE: 'SET_THEME_MODE',
    SET_LANGUAGE: 'SET_LANGUAGE',
    SET_USER_DATA: 'SET_USER_DATA',
};

export const setThemeMode = payload => {
    return {
        type: userSettingsActions.SET_THEME_MODE,
        payload,
    };
};

export const setLanguage = payload => {
    return {
        type: userSettingsActions.SET_LANGUAGE,
        payload,
    };
};

export const setUserSettings = payload => {
    return {
        type: userSettingsActions.SET_USER_DATA,
        payload,
    };
};
