import React from 'react';

import { Avatar, Box, Chip, Typography } from '@mui/material';

import { userRoleBadges } from '../../../utils/userRoles';

const UserProfileTemplate = ({ user, userImage }) => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
            <Box overflow="hidden">
                <Box margin="2em">
                    <Avatar sx={{ margin: '0 auto', width: 156, height: 156 }} alt={user?.name} src={userImage} variant="rounded" />
                </Box>
                {user && (
                    <Box textAlign="center">
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                            {React.Children.toArray(
                                user.roles.map(x => {
                                    return <Chip sx={{ marginRight: '1em', marginBottom: '1em' }} label={userRoleBadges[x]?.label} />;
                                })
                            )}
                        </Box>
                        <Typography variant="h3" gutterBottom>{`${user.firstName} ${user.name}`}</Typography>
                        <Typography variant="h5" gutterBottom>
                            {user.email}
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            {user.mobileNumber}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default UserProfileTemplate;
