import { memo, useContext } from 'react';

import { ThemeProvider } from '@5thindustry/factory_portal.theme.theme-provider';

import { CssBaseline } from '@mui/material';

import { UserSettingsContext } from './userSettings/store/userSettings.context';

const withThemeProvider = ComposedComponent => {
    return memo(props => {
        const { themeMode } = useContext(UserSettingsContext);

        return (
            <ThemeProvider mode={themeMode}>
                <CssBaseline enableColorScheme />
                <ComposedComponent {...props} />
            </ThemeProvider>
        );
    });
};

export default withThemeProvider;
