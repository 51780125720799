import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '@mui/material';

import AutoSaveTextField from '../../AutoSaveTextField';

const UpdateAffectedPiecesInputComponent = ({ value, disabled, handleUpdate }) => {
    const { t } = useTranslation();

    const handleBlur = event => {
        handleUpdate('basicInformation', {
            basicInformation: {
                quantityData: {
                    defected: +event.target.value,
                    total: value?.total,
                },
            },
        });
    };

    return (
        <AutoSaveTextField
            onlyPositiveDigits
            value={value?.defected}
            disabled={disabled}
            type="number"
            onBlur={handleBlur}
            onKeyPress={event => {
                event.key === 'Enter' && handleBlur(event);
            }}
            label={t('report.form.affectedPieces')}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <span>Stk</span>
                    </InputAdornment>
                ),
            }}
            fullWidth
        />
    );
};

const UpdateAffectedPiecesInput = memo(UpdateAffectedPiecesInputComponent);

export default UpdateAffectedPiecesInput;
