import React from 'react';

import ClearanceView from './ClearanceView';

const ClearanceViewList = ({ ncr, loading }) => {
    if (!ncr) {
        return null;
    }

    return (
        <>
            {ncr?.clearances?.map(clearance => {
                return <ClearanceView key={clearance._id} clearance={clearance} loading={loading} status={ncr?.status} />;
            })}
        </>
    );
};

export default ClearanceViewList;
