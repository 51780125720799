import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import LoadingButton from '@mui/lab/LoadingButton';

import { inboxPageRoles } from '../../../../common/constants/userRoles';
import { NcrTemplateContext } from '../../../../common/contexts/ncrTemplate/store/context';
import { notificationsSeverityByRequestStatus } from '../../../../common/providers/notification/constants';
import { NotificationContext } from '../../../../common/providers/notification/store/notification.context';
import { useUserSettingsContext } from '../../../../common/providers/userSettings/store/userSettings.context';

import cloneWithoutTypename from '../../../../utils/common';

import { useCreateNCRFromNCRTemplate } from './../../hooks/useCreateNCRFromNCRTemplate';

const CreateNCRButton = ({ ncrTemplateId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { setNotification } = useContext(NotificationContext);
    const { ncr } = useContext(NcrTemplateContext);
    const { userData } = useUserSettingsContext();

    const isAllowedInboxPage = userData && userData.roles?.some(userRole => inboxPageRoles?.some(role => role === userRole));

    const [createNCRFromNCRTemplate, { loadingCreateNCRFromNCRTemplate }] = useCreateNCRFromNCRTemplate();

    const handleClick = () => {
        if (ncr) {
            const clonedData = cloneWithoutTypename(ncr);

            const model = {
                _id: clonedData._id,
                follower: clonedData.follower,
                priority: clonedData.priority,
                tags: clonedData.tags?.map(tag => {
                    return {
                        _id: tag.value,
                        name: tag.label,
                        colorCode: tag.colorCode,
                        usedBy: tag.usedBy,
                    };
                }),
                responsible: clonedData.responsible,
                basicInformation: {
                    ...clonedData.basicInformation,
                    customInputElements: clonedData.basicInformation.customInputElements?.map(item => {
                        const { value } = item;

                        return { value };
                    }),
                    mediaInputs: clonedData.basicInformation.mediaInputs?.map(item => {
                        const { _id, mediaName } = item;

                        return { _id, mediaName };
                    }),
                },
            };

            createNCRFromNCRTemplate({
                variables: {
                    ncrTemplateId,
                    ncr: model,
                },
            })
                .then(response => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[response.data.createNCRFromNCRTemplate.success] });

                    navigate(isAllowedInboxPage ? '/inbox' : '/');
                })
                .catch(_error => {
                    setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                });
        }
    };

    return (
        <LoadingButton
            disabled={!ncr?.basicInformation?.externalReference}
            variant="contained"
            color="primary"
            onClick={handleClick}
            startIcon={<ReportProblemRoundedIcon />}
            loadingPosition="start"
            loading={loadingCreateNCRFromNCRTemplate}>
            {t('report.button.report')}
        </LoadingButton>
    );
};

export default CreateNCRButton;
