import { Autocomplete, TextField } from '@mui/material';

const NcrSelect = ({ value, loading, options, label, labelKey, valueKey, groupBy, disabled, handleChange }) => {
    return (
        <Autocomplete
            value={value}
            loading={loading}
            disabled={disabled || loading}
            options={options}
            groupBy={option => (groupBy ? option[groupBy] : null)}
            getOptionLabel={option => (labelKey ? option[labelKey] : option.title)}
            renderInput={params => <TextField {...params} label={label} />}
            onChange={(_, data) => handleChange(valueKey && data ? data[valueKey] : data?.value)}
        />
    );
};

export default NcrSelect;
