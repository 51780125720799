import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../../../../../../../common/components/AutoSaveTextField';

const StepNumberInputComponent = ({ value, disabled, handleUpdate }) => {
    const { t } = useTranslation();

    const handleChange = event => {
        handleUpdate(event?.target?.value);
    };

    return (
        <AutoSaveTextField
            value={value}
            disabled={disabled}
            label={t('ncrInfo.immediate.table.stepNumber')}
            onBlur={handleChange}
            fullWidth
            error={!value}
            required
        />
    );
};

const StepNumberInput = memo(StepNumberInputComponent);

export default StepNumberInput;
