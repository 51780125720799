import { useEffect, useMemo, useState } from 'react';

import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { useCollapse } from '../../../common/hooks/useCollapse';
import { useGetPaginatedNCRs } from '../hooks/useGetPaginatedNCRs';

import { inboxDefaultPageSize } from '../constants';
import { inboxReportsSortOptions } from './ncrList/components/InboxReportsSortDropdown';

import { useInboxContext } from '../store/inbox.context';

import InboxPageMobileContent from './InboxPageMobileContent';
import NCRInboxPaper from './ncrInboxPaper/NCRInboxPaper';
import NCRList from './ncrList/NCRList';
import NoInboxData from './NoInboxData';

const InboxPageContent = () => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { selectedNCRId, setSelectedNCR } = useInboxContext();

    const [checked, setChecked] = useCollapse(true);

    const handleCollapseChange = () => {
        setChecked();
    };

    const [sortBy, setSortBy] = useState(inboxReportsSortOptions[0].value);
    const [noInboxData, setNoInboxData] = useState(false);

    const sort = useMemo(() => JSON.stringify({ [sortBy]: 1 }), [sortBy]);

    const [metadata, loading, fetchMore, items] = useGetPaginatedNCRs({ sort });

    useEffect(() => {
        if (items?.length && (!selectedNCRId || !items?.find(item => item._id === selectedNCRId))) {
            setSelectedNCR(items[items?.length - 1]._id);
        }
        if (!loading && !items?.length) {
            setNoInboxData(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    const loadMoreRows = ({ startIndex }) => {
        if (metadata.hasNext) {
            fetchMore({
                variables: { skip: startIndex, limit: inboxDefaultPageSize, sort: JSON.stringify({ [sortBy]: 1 }) },
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    return {
                        ...fetchMoreResult,
                        getPaginatedNCRs: {
                            ncrs: [...prev.getPaginatedNCRs.ncrs, ...fetchMoreResult.getPaginatedNCRs.ncrs],
                            metadata: fetchMoreResult.getPaginatedNCRs.metadata,
                        },
                    };
                },
            });
        }
    };

    if (noInboxData) {
        return <NoInboxData />;
    }

    if (mobile) {
        return (
            <Grid width="100%" container spacing={mobile ? 0 : 4}>
                {!selectedNCRId && (
                    <Grid item xs={12} sm={checked ? 4 : 1}>
                        <NCRList
                            checked={checked}
                            onCollapse={handleCollapseChange}
                            items={items}
                            metadata={metadata}
                            loading={loading}
                            loadMoreRows={loadMoreRows}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        />
                    </Grid>
                )}

                {selectedNCRId && <InboxPageMobileContent />}
            </Grid>
        );
    }

    return (
        <Grid width="100%" container spacing={mobile ? 0 : 4}>
            <Grid item xs={12} sm={checked ? 4 : 1}>
                <NCRList
                    checked={checked}
                    onCollapse={handleCollapseChange}
                    items={items}
                    metadata={metadata}
                    loading={loading}
                    loadMoreRows={loadMoreRows}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                />
            </Grid>

            <Grid item xs={12} sm={checked ? 8 : 11}>
                <NCRInboxPaper loading={loading} />
            </Grid>
        </Grid>
    );
};

export default InboxPageContent;
