import dayjs from 'dayjs';

import { DATE_REQUEST_FORMAT } from '../../common/constants/constants';
import { GET_PAGINATED_CAPA_TICKET } from '../../common/gqlQueries/capaTickets';

import client from '../../utils/apolloClient';

export const DEFAULT_CAPA_TICKET_FETCH_SIZE = 20;
export const DEFAULT_CAPA_TICKET_LOAD_MORE_SIZE = 10;

export const getCapaTicketsDateRangeFilter = dateRangeFilter => {
    const { from, to } = dateRangeFilter;

    const timestampFrom = !!from && dayjs(from).format(DATE_REQUEST_FORMAT);
    const timestampTo = !!to && dayjs(to).format(DATE_REQUEST_FORMAT);

    let dateRange = undefined;

    if (timestampFrom && timestampTo) {
        dateRange = {
            $gte: timestampFrom,
            $lte: timestampTo,
        };
    }

    if (!timestampFrom && timestampTo) {
        dateRange = {
            $lte: timestampTo,
        };
    }

    if (timestampFrom && !timestampTo) {
        dateRange = {
            $gte: timestampFrom,
        };
    }

    return dateRange;
};

export const getCapaTicketsSearchFilter = searchFilter => {
    return {
        $or: [
            {
                action: {
                    $regex: searchFilter?.replaceAll(' ', '.*'),
                    $options: 'xi',
                },
            },
            {
                description: {
                    $regex: searchFilter?.replaceAll(' ', '.*'),
                    $options: 'xi',
                },
            },
        ],
    };
};

export const getCapaTicketVariables = (
    status,
    dateRangeFilter = null,
    incidentTypeFilter = null,
    searchFilter = '',
    responsibleFilter = null
) => {
    let filtersList = [
        {
            status,
        },
        {
            deleted: false,
        },
    ];

    if (dateRangeFilter) {
        filtersList.push({
            dueDate: getCapaTicketsDateRangeFilter(dateRangeFilter),
        });
    }

    if (incidentTypeFilter) {
        filtersList.push({
            'incidentType.name': incidentTypeFilter,
        });
    }

    if (responsibleFilter) {
        filtersList.push({
            'responsible.responsibleID': responsibleFilter?.value,
        });
    }

    if (searchFilter) {
        filtersList.push(getCapaTicketsSearchFilter(searchFilter));
    }

    return {
        filter: JSON.stringify({
            $and: filtersList,
        }),
        skip: 0,
        limit: DEFAULT_CAPA_TICKET_FETCH_SIZE,
        sort: JSON.stringify({
            'ticketCreatedBy.CreatedAt': 'desc',
        }),
    };
};

const { cache } = client;

export const removeCapaTicketFromCache = (ticketId, status, searchFilter, dateRangeFilter, incidentTypeFilter, responsibleFilter) => {
    const data = cache.readQuery({
        query: GET_PAGINATED_CAPA_TICKET,
        fetchPolicy: 'cache-and-network',
        variables: getCapaTicketVariables(status, dateRangeFilter, incidentTypeFilter, searchFilter, responsibleFilter),
    });

    const updatedData = {
        ...data,
        getPaginatedCapaTickets: {
            ...data.getPaginatedCapaTickets,
            capaTickets: data.getPaginatedCapaTickets?.capaTickets?.filter(ticket => ticket._id !== ticketId),
        },
    };

    cache.writeQuery({
        query: GET_PAGINATED_CAPA_TICKET,
        fetchPolicy: 'cache-and-network',
        variables: getCapaTicketVariables(status, dateRangeFilter, incidentTypeFilter, searchFilter, responsibleFilter),
        data: updatedData,
    });
};

export const updateTicketStatusInCache = (capaTicket, oldStatus, filter) => {
    //  ------------ Remove ticket from old list ------------

    const oldData = cache.readQuery({
        query: GET_PAGINATED_CAPA_TICKET,
        fetchPolicy: 'cache-and-network',
        variables: getCapaTicketVariables(
            oldStatus,
            filter?.dateRangeFilter,
            filter?.incidentTypeFilter,
            filter?.searchFilter,
            filter?.responsibleFilter
        ),
    });

    let currentTicket = oldData?.getPaginatedCapaTickets?.capaTickets?.find(ticket => ticket?._id === capaTicket?._id);

    const updatedOldData = {
        ...oldData,
        getPaginatedCapaTickets: {
            ...oldData.getPaginatedCapaTickets,
            capaTickets: oldData.getPaginatedCapaTickets?.capaTickets?.filter(ticket => ticket._id !== currentTicket?._id),
        },
    };

    cache.writeQuery({
        query: GET_PAGINATED_CAPA_TICKET,
        fetchPolicy: 'cache-and-network',
        variables: getCapaTicketVariables(
            oldStatus,
            filter?.dateRangeFilter,
            filter?.incidentTypeFilter,
            filter?.searchFilter,
            filter?.responsibleFilter
        ),
        data: updatedOldData,
    });

    //  ------------------------------------------------

    //  ------------ Add ticket to new list ------------

    const newData = cache.readQuery({
        query: GET_PAGINATED_CAPA_TICKET,
        fetchPolicy: 'cache-and-network',
        variables: getCapaTicketVariables(
            capaTicket?.status,
            filter?.dateRangeFilter,
            filter?.incidentTypeFilter,
            filter?.searchFilter,
            filter?.responsibleFilter
        ),
    });

    const updatedNewData = {
        ...oldData,
        getPaginatedCapaTickets: {
            ...oldData.getPaginatedCapaTickets,
            capaTickets: [...newData.getPaginatedCapaTickets.capaTickets, { ...currentTicket, status: capaTicket?.status }],
        },
    };

    cache.writeQuery({
        query: GET_PAGINATED_CAPA_TICKET,
        fetchPolicy: 'cache-and-network',
        variables: getCapaTicketVariables(
            capaTicket?.status,
            filter?.dateRangeFilter,
            filter?.incidentTypeFilter,
            filter?.searchFilter,
            filter?.responsibleFilter
        ),
        data: updatedNewData,
    });

    //  ------------------------------------------------
};
