import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

const priorityOptions = [
    {
        label: '1',
        value: 1,
    },
    {
        label: '2',
        value: 2,
    },
    {
        label: '3',
        value: 3,
    },
    {
        label: '4',
        value: 4,
    },
    {
        label: '5',
        value: 5,
    },
    {
        label: '6',
        value: 6,
    },
    {
        label: '7',
        value: 7,
    },
    {
        label: '8',
        value: 8,
    },
    {
        label: '9',
        value: 9,
    },
    {
        label: '10',
        value: 10,
    },
];

export const priorityAutocompleteValue = {
    1: {
        label: '1',
        value: 1,
    },
    2: {
        label: '2',
        value: 2,
    },
    3: {
        label: '3',
        value: 3,
    },
    4: {
        label: '4',
        value: 4,
    },
    5: {
        label: '5',
        value: 5,
    },
    6: {
        label: '6',
        value: 6,
    },
    7: {
        label: '7',
        value: 7,
    },
    8: {
        label: '8',
        value: 8,
    },
    9: {
        label: '9',
        value: 9,
    },
    10: {
        label: '10',
        value: 10,
    },
};

const UpdatePriorityDropdownComponent = ({ value, disabled, loading, handleUpdate }) => {
    const { t } = useTranslation();

    const handleChange = newValue => {
        handleUpdate({
            priority: +newValue,
        });
    };

    const memoizedValue = useMemo(() => {
        return value ? priorityAutocompleteValue[value] : null;
    }, [value]);

    return (
        <Autocomplete
            value={memoizedValue}
            disabled={disabled}
            loading={loading}
            fullWidth
            options={priorityOptions}
            getOptionLabel={option => option.label}
            renderInput={params => <TextField {...params} label={t('report.form.priority')} />}
            onChange={(_, data) => handleChange(data?.value)}
        />
    );
};

const UpdatePriorityDropdown = memo(UpdatePriorityDropdownComponent);

export default UpdatePriorityDropdown;
