import React from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../../../../../../../../../../common/components/AutoSaveTextField';

const MeasureDescriptionInput = ({ value, handleUpdate, disabled }) => {
    const { t } = useTranslation();

    const handleChange = event => {
        if (value !== event?.target?.value) {
            handleUpdate(event?.target?.value);
        }
    };

    return (
        <AutoSaveTextField
            disabled={disabled}
            onBlur={handleChange}
            value={value || ''}
            label={t('common.inputs.description')}
            rows={4}
            multiline
            fullWidth
        />
    );
};

export default MeasureDescriptionInput;
