import { useTranslation } from 'react-i18next';

import QrCodeIcon from '@mui/icons-material/QrCode';
import { Button } from '@mui/material';

const LoginQRButton = ({ handleClick }) => {
    const { t } = useTranslation();

    return (
        <Button
            sx={{ background: '#fff', '&:hover': { opacity: 0.8, background: '#fff' } }}
            variant="outlined"
            onClick={handleClick}
            startIcon={<QrCodeIcon />}>
            {t('authentication.signIn.qrLogin')}
        </Button>
    );
};

export default LoginQRButton;
