import { Box, Collapse, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { useUsersTableContext } from '../../store/usersTableProvider';

import UsersTableClearButton from './UsersTableClearButton';
import UsersTableEmailFilter from './UsersTableEmailFilter';
import UsersTableFiltersButton from './UsersTableFiltersButton';
import UsersTableFirstNameFilter from './UsersTableFirstNameFilter';
import UsersTableLastNameFilter from './UsersTableLastNameFilter';
import UsersTableRoleFilter from './UsersTableRoleFilter';
import UsersTableSearchInput from './UsersTableSearchInput';

const UsersTableFilters = ({ users }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const { filtersOpened, lastNameFilter, firstNameFilter, emailFilter, roleFilter } = useUsersTableContext();

    return (
        <>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Box sx={{ flexGrow: 1, width: '100%' }}>
                    <UsersTableSearchInput />
                </Box>
                <UsersTableFiltersButton />
            </Stack>
            <Collapse in={filtersOpened}>
                <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={2}>
                    <Box sx={{ width: matches ? '180px' : '100%' }}>
                        <UsersTableFirstNameFilter users={users} value={firstNameFilter} />
                    </Box>
                    <Box sx={{ width: matches ? '180px' : '100%' }}>
                        <UsersTableLastNameFilter users={users} value={lastNameFilter} />
                    </Box>
                    <Box sx={{ width: matches ? '380px' : '100%' }}>
                        <UsersTableEmailFilter users={users} value={emailFilter} />
                    </Box>
                    <Box sx={{ width: matches ? '280px' : '100%' }}>
                        <UsersTableRoleFilter users={users} value={roleFilter} />
                    </Box>
                    <UsersTableClearButton />
                </Stack>
            </Collapse>
        </>
    );
};

export default UsersTableFilters;
