import React, { useCallback, useContext } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Box, Grid, LinearProgress, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import Accordion from '../../../../../../../common/components/accordion/Accordion';
import AccordionSummary from '../../../../../../../common/components/accordion/AccordionSummary';
import NcrCostSection from '../../../../../../../common/components/ncr/NcrCostSection';
import NcrSectionStatus from '../../../../../../../common/components/ncr/NcrSectionStatus';
import ResponsibleWorkflowStepInput from '../../../../../../../common/components/ncr/ResponsibleWorkflowStepInput';
import WorkflowStepAvailableActions from '../../../../../../../common/components/ncr/workflowStepAction/WorkflowStepAvailableActions';
import RequestSpecialSection from './RequestSpecialSection';
import SpecialReleaseSection from './SpecialReleaseSection';

import { useCollapse } from '../../../../../../../common/hooks/useCollapse';
import { useAtomicNCR } from '../../../../../../inbox/hooks/useAtomicNCR';

import { notificationsSeverityByRequestStatus } from '../../../../../../../common/providers/notification/constants';
import { NotificationContext } from '../../../../../../../common/providers/notification/store/notification.context';
import { useUserSettingsContext } from '../../../../../../../common/providers/userSettings/store/userSettings.context';

import { ncrSectionType } from '../../../../../../../common/enums/ncrSectionType';
import { ncrStatus } from '../../../../../../../common/enums/ncrStatus';
import { getValueByLanguage } from '../../../../../../../utils/common';

const ClearanceView = ({ clearance, status, loading }) => {
    const navigate = useNavigate();
    const { ncrId, sectionType, sectionId } = useParams();

    const isSectionCollapsed = sectionType === ncrSectionType.CLEARANCE && clearance._id === sectionId;

    const { language } = useUserSettingsContext();
    const { setNotification } = useContext(NotificationContext);

    const [handleAtomicUpdate] = useAtomicNCR();
    const [isCollapsed, collapse] = useCollapse(isSectionCollapsed);

    const [resolvedWorkflow] = clearance?.resolvedWorkflows;
    const workflowName = getValueByLanguage(resolvedWorkflow?.name, language);
    const doneStepIndex = resolvedWorkflow?.resolvedWorkflowSteps?.findLastIndex(step => step?.status === ncrStatus.DONE);
    const activeStep = doneStepIndex === -1 ? 0 : doneStepIndex + 1;
    const resolvedWorkflowStep = resolvedWorkflow?.resolvedWorkflowSteps[activeStep];
    const nextWorkflowStepId = resolvedWorkflow?.resolvedWorkflowSteps[activeStep + 1]?._id;

    const handleAccordionCollapseChange = () => {
        const path = generatePath('/ncr-overview/:ncrId/:sectionType/:sectionId', {
            ncrId,
            sectionId: clearance._id,
            sectionType: ncrSectionType.CLEARANCE,
        });

        collapse();
        navigate(path);
    };

    const handleNcrUpdate = useCallback(
        (path, values, operation = 'update', childIds = {}) => {
            const childrenIds = { clearanceId: clearance?._id, ...childIds };

            if (ncrId && values) {
                handleAtomicUpdate({ ncrId, operation, path, values, childrenIds })
                    .then(result => {
                        setNotification({ severity: notificationsSeverityByRequestStatus[result.data.atomicNCR.success] });
                    })
                    .catch(_ => {
                        setNotification({ severity: notificationsSeverityByRequestStatus[false] });
                    });
            }
        },
        [ncrId, handleAtomicUpdate, setNotification, clearance?._id]
    );

    const clearanceStatusDone = [ncrStatus.DONE, ncrStatus.REJECTED].includes(clearance.status) || loading;
    const isSectionDisabled = clearanceStatusDone || status === ncrStatus.DONE;
    const isFirstWorkflowStepDone = clearance?.resolvedWorkflows[0]?.resolvedWorkflowSteps[0]?.status === ncrStatus.DONE;

    return (
        <Box style={{ borderRadius: 16 }}>
            <Accordion
                disableGutters
                elevation={3}
                sx={{ width: '100%', padding: '1.5em' }}
                expanded={isCollapsed}
                onChange={handleAccordionCollapseChange}>
                <AccordionSummary>
                    <Typography variant="h6" noWrap component="div">
                        {workflowName}
                    </Typography>
                    <Box marginLeft="auto">
                        <NcrSectionStatus status={clearance.status} />
                    </Box>

                    {loading && <LinearProgress />}
                </AccordionSummary>
                <MuiAccordionDetails sx={{ padding: '1em 0' }}>
                    <Stack spacing={4}>
                        <Grid width="100%" container spacing={6}>
                            <Grid item sm={12} md={5}>
                                <ResponsibleWorkflowStepInput
                                    sectionId={clearance._id}
                                    isDisabled={isSectionDisabled}
                                    value={resolvedWorkflowStep?.resolvedResponsible}
                                    workflowStepId={resolvedWorkflowStep?._id}
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Box height="100%">
                                    <Stepper activeStep={activeStep} sx={{ alignItems: 'center', height: '100%' }}>
                                        {clearance?.resolvedWorkflows[0]?.resolvedWorkflowSteps
                                            ?.filter(item => item)
                                            ?.map(workflowStep => (
                                                <Step key={workflowStep._id} completed={workflowStep.status === ncrStatus.DONE}>
                                                    <StepLabel sx={{ textTransform: 'capitalize' }}>
                                                        {getValueByLanguage(workflowStep.name, language)}
                                                    </StepLabel>
                                                </Step>
                                            ))}
                                    </Stepper>
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                    <RequestSpecialSection
                        clearanceRequest={clearance?.clearanceRequest}
                        handleUpdate={handleNcrUpdate}
                        disabled={isSectionDisabled}
                    />
                    {isFirstWorkflowStepDone && (
                        <SpecialReleaseSection clearance={clearance} handleUpdate={handleNcrUpdate} disabled={isSectionDisabled} />
                    )}
                    {isFirstWorkflowStepDone && (
                        <NcrCostSection
                            data={clearance}
                            handleUpdate={handleNcrUpdate}
                            path={'clearances.$[clearanceId]'}
                            disabled={isSectionDisabled}
                        />
                    )}
                    {!isSectionDisabled && (
                        <Box sx={{ marginTop: '2em' }}>
                            <WorkflowStepAvailableActions
                                ncrId={ncrId}
                                sectionId={clearance._id}
                                resolvedWorkflowStep={resolvedWorkflowStep}
                                nextWorkflowStepId={nextWorkflowStepId}
                                workflowId={resolvedWorkflow?._id}
                                handleNcrUpdate={handleNcrUpdate}
                                path={'clearances.$[clearanceId]'}
                                childrenIds={{ clearanceId: clearance._id }}
                                type="clearance"
                                disabled={isSectionDisabled}
                            />
                        </Box>
                    )}
                </MuiAccordionDetails>
            </Accordion>
        </Box>
    );
};

export default ClearanceView;
