import { gql } from '@apollo/client';

export const GET_NCR_TEMPLATES = gql`
    query GetNCRTemplates {
        getNCRTemplates {
            _id
            basicInformation {
                customInputElements {
                    _id
                    customInputElementType
                    readOnly
                    name
                    placeholder {
                        _id
                        language
                        value
                    }
                    label {
                        _id
                        language
                        value
                    }
                    choices {
                        value
                        label {
                            _id
                            language
                            value
                        }
                    }
                    required
                    max
                    min
                    value
                    defaultValue
                    currencyCode
                    caption {
                        _id
                        language
                        value
                    }
                    file {
                        _id
                        mediaName
                        mediaType
                        mediaDescription
                    }
                }
            }
            tags {
                _id
                name
                usedBy
            }
            priority
        }
    }
`;
