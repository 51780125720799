import { useMutation } from '@apollo/client';

import { ERP_UPDATE } from '../operations/mutations/erpUpdate';

export const useErpUpdate = () => {
    const [erpUpdate, { loading: erpUpdateLoading, error }] = useMutation(ERP_UPDATE);

    const handleErpUpdate = ({ ncrId, sectionType, sectionId }) => {
        return erpUpdate({
            variables: {
                pullData: {
                    ncrId,
                    sectionType,
                    sectionId,
                },
            },
            refetchQueries: ['GetSingleNCR'],
        });
    };

    return [handleErpUpdate, { erpUpdateLoading, error }];
};
