import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { getNcrStatusOptions } from '../../../../common/enums/ncrStatus';

import { useNcrOverviewContext } from '../../store/NCROverviewProvider';

const NcrOverviewStatusFilterComponent = () => {
    const { t } = useTranslation();
    const { statusFilter, setNcrOverviewStatusFilter } = useNcrOverviewContext();

    const ncrStatusOptions = getNcrStatusOptions();

    const memoizedValue = statusFilter ? ncrStatusOptions.find(status => status.value === statusFilter) : null;

    const handleChange = (_, data) => {
        if (data === null) {
            setNcrOverviewStatusFilter(undefined);
            return;
        }

        setNcrOverviewStatusFilter(data.value);
    };

    return (
        <Autocomplete
            value={memoizedValue}
            fullWidth
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={ncrStatusOptions}
            getOptionLabel={option => option.title}
            renderInput={params => <TextField {...params} label={t('ncrOverview.status')} />}
            onChange={handleChange}
        />
    );
};

const NcrOverviewStatusFilter = memo(NcrOverviewStatusFilterComponent);

export default NcrOverviewStatusFilter;
