import React from 'react';

import { useTheme } from '@mui/material';

const OverviewHomeScreenIcon = () => {
    const theme = useTheme();

    return (
        <svg width="114" height="88" viewBox="0 0 114 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 56.5H19.5C22.9375 56.5 25.75 53.6875 25.75 50.25V37.75C25.75 34.3125 22.9375 31.5 19.5 31.5H7C3.5625 31.5 0.75 34.3125 0.75 37.75V50.25C0.75 53.6875 3.5625 56.5 7 56.5ZM7 87.75H19.5C22.9375 87.75 25.75 84.9375 25.75 81.5V69C25.75 65.5625 22.9375 62.75 19.5 62.75H7C3.5625 62.75 0.75 65.5625 0.75 69V81.5C0.75 84.9375 3.5625 87.75 7 87.75ZM7 25.25H19.5C22.9375 25.25 25.75 22.4375 25.75 19V6.5C25.75 3.0625 22.9375 0.25 19.5 0.25H7C3.5625 0.25 0.75 3.0625 0.75 6.5V19C0.75 22.4375 3.5625 25.25 7 25.25ZM38.25 56.5H107C110.438 56.5 113.25 53.6875 113.25 50.25V37.75C113.25 34.3125 110.438 31.5 107 31.5H38.25C34.8125 31.5 32 34.3125 32 37.75V50.25C32 53.6875 34.8125 56.5 38.25 56.5ZM38.25 87.75H107C110.438 87.75 113.25 84.9375 113.25 81.5V69C113.25 65.5625 110.438 62.75 107 62.75H38.25C34.8125 62.75 32 65.5625 32 69V81.5C32 84.9375 34.8125 87.75 38.25 87.75ZM32 6.5V19C32 22.4375 34.8125 25.25 38.25 25.25H107C110.438 25.25 113.25 22.4375 113.25 19V6.5C113.25 3.0625 110.438 0.25 107 0.25H38.25C34.8125 0.25 32 3.0625 32 6.5Z"
                fill={theme?.palette.mode === 'light' ? '#16ABA1' : '#52DBCE'}
            />
        </svg>
    );
};

export default OverviewHomeScreenIcon;
