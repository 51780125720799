import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Box, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { last } from 'lodash';

import ReworkIcon from '../../../../../common/components/icons/ReworkIcon';
import ScrapIcon from '../../../../../common/components/icons/ScrapIcon';
import SpecialReleaseIcon from '../../../../../common/components/icons/SpecialReleaseIcon';
import ClearanceViewList from './components/clearance/ClearanceViewList';
import ReworkViewList from './components/rework/ReworkViewList';
import ScrapViewList from './components/scrap/ScrapViewList';
import NcrInfoAccordion from './components/shared/NcrInfoAccordion';
import { StyledClearanceButtonPaper, StyledReworkButtonPaper, StyledScrapButtonPaper } from './NcrCreateImmediateButtons';

import { ncrSectionType } from '../../../../../common/enums/ncrSectionType';

import { useCreateNCRFromOwnTemplate } from './hooks/useCreateNCRFromOwnTemplate';
import { ncrStatus } from '../../../../../common/enums/ncrStatus';

const { REWORK, SCRAP, CLEARANCE } = ncrSectionType;

const MeasureSectionPaper = styled(Paper)(({ theme }) => ({
    marginBottom: '4em',

    '&.disabled': {
        cursor: 'not-allowed',
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
    },
}));

const NcrImmediateTab = ({ ncr, loading }) => {
    const { t } = useTranslation();
    const { ncrId } = useParams();
    const navigate = useNavigate();

    const [addNCRSectionFromOwnTemplate, { loadingCreateNCRFromNCRTemplate }] = useCreateNCRFromOwnTemplate();

    const openReworkView = useCallback(() => {
        const section = { ncrId, sectionType: REWORK };

        addNCRSectionFromOwnTemplate({
            variables: {
                section,
            },
        }).then(response => {
            if (response.data.addNCRSectionFromOwnTemplate.success) {
                const rework = last(response.data.addNCRSectionFromOwnTemplate.ncr.reworks);
                const resolvedWorkflowStep = rework?.resolvedWorkflows[0].resolvedWorkflowSteps[0];

                const { ncrSectionID, ncrSectionType } = resolvedWorkflowStep;

                if (ncrId && ncrSectionID && ncrSectionType) {
                    const path = generatePath('/ncr-overview/:ncrId/:sectionType/:sectionId', {
                        ncrId,
                        sectionId: ncrSectionID,
                        sectionType: ncrSectionType,
                    });

                    navigate(path);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ncrId, addNCRSectionFromOwnTemplate]);

    const openScrapView = useCallback(() => {
        const section = { ncrId, sectionType: SCRAP };

        addNCRSectionFromOwnTemplate({
            variables: {
                section,
            },
        }).then(response => {
            if (response.data.addNCRSectionFromOwnTemplate.success) {
                const rework = last(response.data.addNCRSectionFromOwnTemplate.ncr.scraps);
                const resolvedWorkflowStep = rework?.resolvedWorkflows[0].resolvedWorkflowSteps[0];

                const { ncrSectionID, ncrSectionType } = resolvedWorkflowStep;

                if (ncrId && ncrSectionID && ncrSectionType) {
                    const path = generatePath('/ncr-overview/:ncrId/:sectionType/:sectionId', {
                        ncrId,
                        sectionId: ncrSectionID,
                        sectionType: ncrSectionType,
                    });

                    navigate(path);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ncrId, addNCRSectionFromOwnTemplate]);

    const openClearanceView = useCallback(() => {
        const section = { ncrId, sectionType: CLEARANCE };

        addNCRSectionFromOwnTemplate({
            variables: {
                section,
            },
        }).then(response => {
            if (response.data.addNCRSectionFromOwnTemplate.success) {
                const rework = last(response.data.addNCRSectionFromOwnTemplate.ncr.clearances);
                const resolvedWorkflowStep = rework?.resolvedWorkflows[0].resolvedWorkflowSteps[0];

                const { ncrSectionID, ncrSectionType } = resolvedWorkflowStep;

                if (ncrId && ncrSectionID && ncrSectionType) {
                    const path = generatePath('/ncr-overview/:ncrId/:sectionType/:sectionId', {
                        ncrId,
                        sectionId: ncrSectionID,
                        sectionType: ncrSectionType,
                    });

                    navigate(path);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ncrId, addNCRSectionFromOwnTemplate]);

    const disableActions = loading || loadingCreateNCRFromNCRTemplate || ncr?.status === ncrStatus.DONE;

    return (
        <>
            <NcrInfoAccordion ncr={ncr} />
            {loading || loadingCreateNCRFromNCRTemplate ? (
                <Skeleton variant="rectangular" height={180} />
            ) : (
                <>
                    <ReworkViewList ncr={ncr} loading={loading} />

                    <ScrapViewList ncr={ncr} loading={loading} />

                    <ClearanceViewList ncr={ncr} loading={loading} />
                </>
            )}
            <MeasureSectionPaper className={disableActions ? 'disabled' : ''} elevation={3}>
                <Box width={'100%'} p={'1.5em'}>
                    <Typography textAlign="center" marginBottom="2em" variant="h5">
                        {t('ncrInfo.immediate.title')}
                    </Typography>
                    <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="center" spacing={4}>
                        <StyledReworkButtonPaper onClick={openReworkView} elevation={3}>
                            <ReworkIcon />
                            <Typography variant="body1">{t('ncrInfo.immediate.rework')}</Typography>
                        </StyledReworkButtonPaper>
                        <StyledScrapButtonPaper onClick={openScrapView} elevation={3}>
                            <ScrapIcon />
                            <Typography variant="body1">{t('ncrInfo.immediate.scrapAndRework')}</Typography>
                        </StyledScrapButtonPaper>
                        <StyledClearanceButtonPaper onClick={openClearanceView} elevation={3}>
                            <SpecialReleaseIcon />
                            <Typography variant="body1">{t('ncrInfo.immediate.specialRelease')}</Typography>
                        </StyledClearanceButtonPaper>
                    </Stack>
                </Box>
            </MeasureSectionPaper>
        </>
    );
};

export default NcrImmediateTab;
