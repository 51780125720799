import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { GET_SINGLE_NCR } from '../operations/queries/getSingleNCR';

export const useGetSingleNCR = selectedNCRId => {
    const { data, loading } = useQuery(GET_SINGLE_NCR, {
        skip: !selectedNCRId,
        fetchPolicy: 'cache-first',
        variables: { getSingleNcrId: selectedNCRId },
    });

    const ncr = useMemo(() => data?.getSingleNCR?.ncr, [data?.getSingleNCR?.ncr]);

    return [ncr, loading];
};
