import { gql } from '@apollo/client';

export const CASCADE_DELETE_MANY_NCRS = gql`
    mutation CascadeDeleteManyNCRs($ncrIds: [ID!]) {
        cascadeDeleteManyNCRs(ncrIds: $ncrIds) {
            code
            success
            ncrs {
                _id
                name
                archived
                deleted
                ncrCreatedBy {
                    userID
                    createdAt
                }
                basicInformation {
                    description
                    externalReference
                }
                resolvedNcrCreatedBy {
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                priority
            }
        }
    }
`;
