import React from 'react';

import ReworkView from './ReworkView';

const ReworkViewList = ({ ncr, loading }) => {
    if (!ncr) {
        return null;
    }

    return (
        <>
            {ncr?.reworks?.map((rework, index) => {
                return <ReworkView key={rework._id} rework={rework} index={index} loading={loading} ncr={ncr} />;
            })}
        </>
    );
};

export default ReworkViewList;
