import QrReader from 'modern-react-qr-reader';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

const QrLoginModal = ({ isOpen, handleModalClose, handleResult }) => {
    const { t } = useTranslation();

    return (
        <Dialog onClose={handleModalClose} open={isOpen} maxWidth="sm">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <DialogTitle sx={{ paddingBottom: '0px' }}>{t('authentication.signIn.provideQr')}</DialogTitle>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '0px !important', overflow: 'hidden' }}>
                    <QrReader delay={500} onScan={handleResult} facingMode={'environment'} />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default QrLoginModal;
