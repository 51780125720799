import { useTranslation } from 'react-i18next';

import { useUserOverlayData } from '../../hooks/useUserOverlayData';

import FloatingActionButtonPortal from '../../../../../common/components/portals/FloatingActionButton';
import CreateUser from './CreateUser';

const NewUserButton = () => {
    const { t } = useTranslation();

    const [userOverlayData, setUserOverlayData] = useUserOverlayData();

    const handleCreateClick = () => {
        setUserOverlayData({
            isOpen: true,
        });
    };

    return (
        <FloatingActionButtonPortal label={t('adminPage.bottomNavigation.addButton')} onClick={handleCreateClick}>
            {userOverlayData?.isOpen && !userOverlayData?.userId && (
                <CreateUser overlayData={userOverlayData} setOverlayData={setUserOverlayData} />
            )}
        </FloatingActionButtonPortal>
    );
};

export default NewUserButton;
