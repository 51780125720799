import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import dayjs from 'dayjs';

import { useNcrOverviewContext } from '../../store/NCROverviewProvider';

const NcrOverviewDateRangeFilter = () => {
    const { t } = useTranslation();
    const { dateRangeFilter, setNcrOverviewDateRangeFilter } = useNcrOverviewContext();

    return (
        <>
            <DatePicker
                label={t('common.dateRange.from')}
                value={dateRangeFilter.from}
                onChange={newValue => {
                    setNcrOverviewDateRangeFilter({
                        from: newValue ? dayjs(newValue).startOf('date') : null,
                        to: dateRangeFilter?.to && dayjs(newValue).endOf('day'),
                    });
                }}
                renderInput={params => <TextField {...params} />}
                renderDay={(day, _value, DayComponentProps) => {
                    const isDisabled = dayjs(day).isSameOrAfter(dateRangeFilter.to);

                    return <PickersDay {...DayComponentProps} disabled={isDisabled} />;
                }}
            />
            <DatePicker
                label={t('common.dateRange.to')}
                value={dateRangeFilter.to}
                onChange={newValue => {
                    setNcrOverviewDateRangeFilter({
                        from: dateRangeFilter?.from && dayjs(dateRangeFilter.from).startOf('day'),
                        to: newValue ? dayjs(newValue).endOf('day') : null,
                    });
                }}
                renderInput={params => <TextField {...params} />}
                renderDay={(day, _value, DayComponentProps) => {
                    const isDisabled = dayjs(day).isSameOrBefore(dateRangeFilter.from);

                    return <PickersDay {...DayComponentProps} disabled={isDisabled} />;
                }}
            />
        </>
    );
};

export default NcrOverviewDateRangeFilter;
