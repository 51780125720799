import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Box, TextField } from '@mui/material';

import { useGetUsersOptions } from '../hooks/useGetUsersOptions';
import { useUserAvatar } from '../hooks/useUserAvatar';
import { useUserImages } from '../hooks/useUserImages';

import UserNameWithAvatar from './UserNameWithAvatar';

const FollowerMultipleDropdownComponent = ({ value, isDisabled, isLoading, onChange, ...props }) => {
    const { t } = useTranslation();

    const [userOptions, getUserOptionsLoading] = useGetUsersOptions();
    const [images, getUserImages] = useUserImages();

    const [avatar] = useUserAvatar();

    const mappedValues = useMemo(() => {
        return value?.length ? userOptions?.filter(user => value?.indexOf(user?.value) !== -1) : [];
    }, [value, userOptions]);

    const isDisableAutocomplete = isDisabled || isLoading || getUserOptionsLoading || getUserImages;
    const isLoadingAutocomplete = isLoading || getUserOptionsLoading || getUserImages;

    return (
        <Autocomplete
            {...props}
            disableCloseOnSelect
            loading={isLoadingAutocomplete}
            disabled={isDisableAutocomplete}
            options={userOptions}
            getOptionLabel={option => option.fullName}
            value={mappedValues}
            limitTags={1}
            multiple
            variant="outlined"
            fullWidth
            renderOption={(props, option) => {
                const avatarSrc = avatar.getUserAvatarByThumbnail(images, option.avatar);

                return (
                    <Box component="li" sx={{ cursor: 'pointer' }} {...props}>
                        <UserNameWithAvatar avatarSrc={avatarSrc} userName={option.fullName} />
                    </Box>
                );
            }}
            renderInput={params => <TextField {...params} label={t('report.form.follower')} />}
            onChange={(_, data) => onChange(data)}
        />
    );
};

const FollowerMultipleDropdown = memo(FollowerMultipleDropdownComponent);

export default FollowerMultipleDropdown;
