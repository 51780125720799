import { Autocomplete, TextField } from '@mui/material';

import { useGetTeamsOptions } from '../../hooks/useGetTeamsOptions';
import { useNcrMyTasksContext } from '../../store/NCRMyTasksProvider';

const TeamsFilter = () => {
    const { teams, setNrcMyTasksSelectedTeams } = useNcrMyTasksContext();

    const ncrStatusOptions = useGetTeamsOptions();

    return (
        <Autocomplete
            value={teams}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={ncrStatusOptions}
            multiple
            sx={{ minWidth: 240 }}
            getOptionLabel={option => option.title}
            renderInput={params => <TextField {...params} label={'Teams'} />}
            onChange={(_, data) => setNrcMyTasksSelectedTeams(data)}
        />
    );
};

export default TeamsFilter;
