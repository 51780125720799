import CapaTicketsProvider from '../../../../tickets-kanban-board/store/TicketsKanbanBoardProvider';
import NcrInfoAccordion from '../ncr-immediate/components/shared/NcrInfoAccordion';
import RootCauseAnalysis from './components/RootCauseAnalysis';

const NcrCorrectiveTab = ({ ncr, loading }) => {
    return (
        <CapaTicketsProvider>
            <NcrInfoAccordion ncr={ncr} />
            <RootCauseAnalysis ncr={ncr} loading={loading} />
        </CapaTicketsProvider>
    );
};

export default NcrCorrectiveTab;
