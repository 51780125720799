import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '@mui/material';

import { NcrTemplateContext } from '../../../../common/contexts/ncrTemplate/store/context';
import AutoSaveTextField from '../../../../common/components/AutoSaveTextField';

const AffectedPiecesInputComponent = ({ value, disabled }) => {
    const { t } = useTranslation();

    const { setNCR } = useContext(NcrTemplateContext);

    const handleChange = event => {
        setNCR({ value: +event?.target?.value, path: 'basicInformation.quantityData.defected' });
    };

    return (
        <AutoSaveTextField
            onlyPositiveDigits
            value={value}
            disabled={disabled}
            type="number"
            onBlur={handleChange}
            label={t('report.form.affectedPieces')}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <span>Stk</span>
                    </InputAdornment>
                ),
            }}
            fullWidth
        />
    );
};

const AffectedPiecesInput = memo(AffectedPiecesInputComponent);

export default AffectedPiecesInput;
