import { LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import TableNoDataOverlay from '../../../../../common/components/table/TableNoDataOverlay';

const UsersTable = ({ rows, columns, loading, setOverlayData, setSortModel }) => {
    const handleRowClick = (params, event) => {
        const { row } = params;

        if (!event?.ignoreRowClick) {
            setOverlayData({
                isOpen: true,
                userId: row?.rowId,
            });
        }
    };

    return (
        <DataGrid
            sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 600,
                },
                '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-cell': {
                    flexWrap: 'wrap',
                    padding: '0.25em',
                },
            }}
            rows={rows}
            columns={columns}
            components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: TableNoDataOverlay,
            }}
            onRowClick={handleRowClick}
            getRowHeight={() => 'auto'}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            hideFooterSelectedRowCount
            getRowId={row => row.rowId}
            sortingOrder={['asc', 'desc']}
            sortingMode="client"
            onSortModelChange={newSortModel => setSortModel(newSortModel)}
            loading={loading}
        />
    );
};

export default UsersTable;
