import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { themeMode } from '@5thindustry/factory_portal.theme.theme-provider/dist/theme-provider';

import { Avatar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';

import { getFilteredNcrUserRoles } from '../../../../../../common/constants/userRoles';
import { useGetUsersOptions } from '../../../../../../common/hooks/useGetUsersOptions';
import { useUserAvatar } from '../../../../../../common/hooks/useUserAvatar';

const StyledRootDiv = styled('div')(({ theme }) => ({
    border: `1px #CED4DB solid`,
    borderRadius: 16,

    padding: '1em',
    marginBottom: '1em',

    display: 'flex',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    marginRight: '0.7em',
}));

const StyledSubTextTypography = styled(Typography)(({ theme }) => ({
    color: '#64676c',
    fontSize: '12px !important',
    fontWeight: 500,
}));

const StyledActionMessageTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.mode === themeMode.light ? '#000' : '#fff',
}));

const messageContent = {
    newNCR: ({ actor, t }) => {
        return `${actor?.fullName} ${t('activities.actions.created')}`;
    },
    basicInformation_description: ({ actor, activityObject, t }) => {
        return `${actor?.fullName} ${t('activities.actions.changed', {
            field: t('activities.fields.description'),
        })} "${activityObject.value}"`;
    },
    basicInformation_externalReference: ({ actor, activityObject, t }) => {
        return `${actor?.fullName} ${t('activities.actions.changed', {
            field: t('activities.fields.reference'),
        })} "${activityObject.value}"`;
    },
    status: ({ actor, activityObject, t }) => {
        return `${actor?.fullName} ${t('activities.actions.changed', {
            field: t('activities.fields.status'),
        })} "${activityObject.value}"`;
    },
    follower: ({ actor, activityObject, t }) => {
        return `${actor?.fullName} ${t('activities.actions.changed', {
            field: t('activities.fields.follower'),
        })} "${activityObject.value}"`;
    },

    priority: ({ actor, activityObject, t }) => {
        return `${actor?.fullName} ${t('activities.actions.changed', {
            field: t('activities.fields.priority'),
        })} "${activityObject.value}"`;
    },
    basicInformation_quantityData: ({ actor, activityObject, t }) => {
        return `${actor?.fullName} ${t('activities.actions.changed', {
            field: t('activities.fields.quantityData'),
        })} "${activityObject.value}"`;
    },
    basicInformation_failureCategory: ({ actor, activityObject, t }) => {
        return `${actor?.fullName} ${t('activities.actions.changed', {
            field: t('activities.fields.failureCategory'),
        })} "${activityObject.value}"`;
    },

    default: ({ actor, activityObject, t, objectType }) => {
        return `${actor?.fullName} ${t('activities.actions.changed', {
            field: objectType,
        })} "${activityObject.value}"`;
    },
};

const ActivityCard = ({ activityActor, createdAt, activityObject, activityVerb, images }) => {
    const { t } = useTranslation();

    const [userOptions] = useGetUsersOptions();

    const [avatar] = useUserAvatar();

    const actor = useMemo(
        () => userOptions && userOptions?.find(user => user?.value === activityActor?.actorID),
        [activityActor, userOptions]
    );

    const avatarSrc = avatar?.getUserAvatarByThumbnail(images, actor?.avatar);

    return (
        <StyledRootDiv>
            <StyledAvatar src={avatarSrc}>{actor ? actor?.fullName : ''}</StyledAvatar>
            <div>
                <StyledActionMessageTypography>
                    {(messageContent[activityObject?.objectType] || messageContent.default)({
                        actor,
                        activityObject,
                        activityVerb,
                        t,
                        objectType: activityObject?.objectType,
                    })}
                </StyledActionMessageTypography>
                <StyledSubTextTypography>{`${getFilteredNcrUserRoles(actor?.roles)?.join(', ')}, ${moment(createdAt).format(
                    'HH:mm'
                )}`}</StyledSubTextTypography>
            </div>
        </StyledRootDiv>
    );
};

export default ActivityCard;
