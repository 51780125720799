import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@mui/material';
import { DatePicker, PickersDay } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { useTicketKanbanBoardContext } from '../../store/TicketsKanbanBoardContext';

const TicketKanbanBoardPeriodDatePicker = () => {
    const { t } = useTranslation();
    const { dateRangeFilter, setTicketsKanbanBoardDateRange } = useTicketKanbanBoardContext();

    return (
        <>
            <DatePicker
                label={t('common.dateRange.from')}
                value={dateRangeFilter.from}
                onChange={newValue => {
                    setTicketsKanbanBoardDateRange({
                        from: newValue ? dayjs(newValue).startOf('date') : null,
                        to: dateRangeFilter?.to && dayjs(newValue).endOf('day'),
                    });
                }}
                renderInput={params => <TextField {...params} />}
                renderDay={(day, _value, DayComponentProps) => {
                    const isDisabled = dayjs(day).isSameOrAfter(dateRangeFilter.to);

                    return <PickersDay {...DayComponentProps} disabled={isDisabled} />;
                }}
            />
            <DatePicker
                label={t('common.dateRange.to')}
                value={dateRangeFilter.to}
                onChange={newValue => {
                    setTicketsKanbanBoardDateRange({
                        from: dateRangeFilter?.from && dayjs(dateRangeFilter.from).startOf('day'),
                        to: newValue ? dayjs(newValue).endOf('day') : null,
                    });
                }}
                renderInput={params => <TextField {...params} />}
                renderDay={(day, _value, DayComponentProps) => {
                    const isDisabled = dayjs(day).isSameOrBefore(dateRangeFilter.from);

                    return <PickersDay {...DayComponentProps} disabled={isDisabled} />;
                }}
            />
        </>
    );
};

export default TicketKanbanBoardPeriodDatePicker;
