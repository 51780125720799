import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { GET_NCR_TEMPLATES } from '../operations/queries/getNCRTemplates';

export const useGetNCRTemplates = () => {
    const { data, loading: loadingNCRTemplates } = useQuery(GET_NCR_TEMPLATES, {
        fetchPolicy: 'cache-and-network',
    });

    const [ncrTemplate] = useMemo(() => data?.getNCRTemplates ?? [], [data?.getNCRTemplates]);

    return [ncrTemplate, loadingNCRTemplates];
};
