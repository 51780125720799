import React, { memo } from 'react';
import { ApolloProvider } from '@apollo/client';

import client from '../../utils/apolloClient';

// Run application with connected Appolo Provider
const withApolloProvider = ComposedComponent => {
    class hoc extends React.Component {
        render() {
            return (
                <ApolloProvider client={client}>
                    <ComposedComponent {...this.props} />
                </ApolloProvider>
            );
        }
    }

    return memo(hoc);
};

export default withApolloProvider;
