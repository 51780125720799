import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { GET_SINGLE_NCR } from '../components/ncr/operations/queries/getSingleNCR';

const useGetSingleNCRFromNetwork = selectedNCRId => {
    const { data, loading } = useQuery(GET_SINGLE_NCR, {
        skip: !selectedNCRId,
        fetchPolicy: 'network-only',
        variables: { getSingleNcrId: selectedNCRId },
    });

    const ncr = useMemo(() => data?.getSingleNCR?.ncr, [data?.getSingleNCR?.ncr]);

    return [ncr, loading];
};

export default useGetSingleNCRFromNetwork;
