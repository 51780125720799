import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';

import { isArrayWithItems } from '../../../../../../utils/common';

import { useNcrCostsTableColumns } from './useNcrCostsTableColumns';

export const useNcrCostsTableData = ({ ncr }) => {
    const { t } = useTranslation();

    const [columns] = useNcrCostsTableColumns();

    if (!ncr) {
        return [];
    }

    const reworkCosts =
        ncr.reworks
            ?.map(rework => {
                if (!isArrayWithItems(rework?.costAssignments)) {
                    return [];
                }

                return rework?.costAssignments.map(cost => {
                    return {
                        id: rework._id,
                        measure: t('ncrInfo.immediate.rework'),
                        costCenter: cost.costCenter,
                        costValue: `${getSymbolFromCurrency(cost.currency)}${cost.value}`,
                        value: cost.value || 0,
                        currency: getSymbolFromCurrency(cost.currency),
                    };
                });
            })
            .flat() || [];

    const scrapCosts =
        ncr.scraps
            ?.map(scrap => {
                if (!isArrayWithItems(scrap?.costAssignments)) {
                    return [];
                }

                return scrap?.costAssignments.map(cost => {
                    return {
                        id: scrap._id,
                        measure: t('ncrInfo.immediate.scrapAndRework'),
                        costCenter: cost.costCenter,
                        costValue: `${getSymbolFromCurrency(cost.currency)}${cost.value}`,
                        value: cost.value || 0,
                        currency: getSymbolFromCurrency(cost.currency),
                    };
                });
            })
            .flat() || [];

    const specialReleaseCosts =
        ncr.clearances
            ?.map(clearance => {
                if (!isArrayWithItems(clearance?.costAssignments)) {
                    return [];
                }

                return clearance?.costAssignments.map(cost => {
                    return {
                        id: clearance._id,
                        measure: t('ncrInfo.immediate.specialRelease'),
                        costCenter: cost.costCenter,
                        costValue: `${getSymbolFromCurrency(cost.currency)}${cost.value}`,
                        value: cost.value || 0,
                        currency: getSymbolFromCurrency(cost.currency),
                    };
                });
            })
            .flat() || [];

    const rows = [...reworkCosts, ...scrapCosts, ...specialReleaseCosts].filter(item => item.value !== 0 && item.costCenter);

    const totalValue = rows.reduce((accumulator, object) => accumulator + object.value, 0);
    const total = `${t('ncrInfo.valuation.table.total')}: ${totalValue}`;

    return [rows, columns, total];
};
