import { useEffect, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useUserSettingsContext } from './userSettings/store/userSettings.context';

import 'dayjs/locale/de';

const withDateLocalizationProvider = ComposedComponent => {
    return props => {
        const { language } = useUserSettingsContext();

        const [locale, setLocale] = useState(language);

        useEffect(() => {
            setLocale(language);
        }, [language]);

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <ComposedComponent {...props} />
            </LocalizationProvider>
        );
    };
};

export default withDateLocalizationProvider;
