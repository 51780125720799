import { useTranslation } from 'react-i18next';

import { styled, Switch, Typography } from '@mui/material';

import { useNcrMyTasksContext } from '../../store/NCRMyTasksProvider';

const MyTaskFilterRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 9,

    '& .MuiSwitch-root': {
        marginRight: -12,
    },
    padding: '8px',
    height: 56,
    justifyContent: 'center',
    border: `1px solid ${theme.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)'}`,
    borderRadius: 8,
}));

const MyTaskMyTaskFilter = () => {
    const { t } = useTranslation();

    const { myTask, setNcrMyTasksMyTask } = useNcrMyTasksContext();

    return (
        <MyTaskFilterRoot>
            <Switch checked={myTask} onChange={() => setNcrMyTasksMyTask(!myTask)} color="primary" />
            <Typography>{t('common.action-buttons.myTask')}</Typography>
        </MyTaskFilterRoot>
    );
};

export default MyTaskMyTaskFilter;
