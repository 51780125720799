import { inboxActions } from './inbox.actions';

export const inboxInitialState = {
    selectedNCRId: null,
    noInboxData: false,
};

export const inboxReducer = (state, { type, payload }) => {
    switch (type) {
        case inboxActions.SET_SELECTED_NCR: {
            return {
                ...state,
                selectedNCRId: payload,
            };
        }
        case inboxActions.SET_INBOX_NO_DATA: {
            return {
                ...state,
                noInboxData: payload,
            };
        }
        default: {
            return state;
        }
    }
};
