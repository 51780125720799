import { memo } from 'react';
import { useDebounce } from '../hooks/useDebounce';

export const withDebounce = ComposedComponent => {
    return memo(({ onChange, ...props }) => {
        const debouncedSave = useDebounce(nextValue => onChange(nextValue));

        return <ComposedComponent {...props} onSave={debouncedSave} />;
    });
};
