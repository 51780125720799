import { useMutation } from '@apollo/client';

import { UPDATE_CAPA_TICKET } from '../../../../../../common/gqlQueries/capaTickets';

export const useUpdateStatusCapaTicket = () => {
    const [updateStatusCapaTicket, { loading }] = useMutation(UPDATE_CAPA_TICKET);

    const handleUpdateStatusCapaTicket = capaTicket => {
        return updateStatusCapaTicket({
            variables: {
                capaTicket,
            },
        });
    };

    return [handleUpdateStatusCapaTicket, { loading }];
};
