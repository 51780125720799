import { notificationActions } from './notification.actions';

import { notificationMessageBySeverity } from '../constants';

export const notificationInitialState = {
    isOpen: false,
    severity: '',
    message: '',
};

export const notificationReducer = (state, { payload = {}, type }) => {
    switch (type) {
        case notificationActions.SET_NOTIFICATION: {
            const { severity, message } = payload;

            return {
                ...state,
                isOpen: true,
                severity,
                message: message || notificationMessageBySeverity[severity],
            };
        }

        case notificationActions.CLEAR_NOTIFICATION: {
            return notificationInitialState;
        }

        default:
            return state;
    }
};
