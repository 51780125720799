import React, { useEffect } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { stepStatus } from '../../../../common/enums/stepStatus';
import { notificationsSeverityByRequestStatus } from '../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../common/providers/notification/store/notification.context';
import { findTileElement } from '../../../../common/utils/fileManagement';

import createMongoObjectId from '../../../../utils/mongo';

import { useLazyGetCapaTicket } from '../../../ncr-info/components/tabs/ncr-corrective/hooks/useLazyGetCapaTicket';

import { DEFAULT_CAPA_TICKET_LOAD_MORE_SIZE, getCapaTicketVariables } from '../../constants';

import { StyledDroppableBox } from '../TicketKanbanBoardStyledElements';

import { useTicketKanbanBoardContext } from '../../store/TicketsKanbanBoardContext';

import CapaTicketWrapper from './CapaTicketWrapper';
import LoadMoreButton from './LoadMoreButton';

const InWorkColumn = ({ userImages }) => {
    const { t } = useTranslation();

    const { setNotification } = useNotificationContext();

    const [handleLazyGetCapaTickets, { data, loading, fetchMore }] = useLazyGetCapaTicket();

    const { searchFilter, dateRangeFilter, incidentTypeFilter, responsibleFilter } = useTicketKanbanBoardContext();

    useEffect(() => {
        handleLazyGetCapaTickets(
            getCapaTicketVariables(stepStatus.IN_WORK, dateRangeFilter, incidentTypeFilter, searchFilter, responsibleFilter)
        ).catch(() => {
            setNotification({ severity: notificationsSeverityByRequestStatus[false] });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilter, dateRangeFilter, incidentTypeFilter, responsibleFilter]);

    const loadMoreTickets = () => {
        if (data?.getPaginatedCapaTickets?.metadata?.hasNext) {
            fetchMore({
                variables: { skip: data?.getPaginatedCapaTickets?.capaTickets?.length, limit: DEFAULT_CAPA_TICKET_LOAD_MORE_SIZE },
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    return {
                        ...fetchMoreResult,
                        getPaginatedCapaTickets: {
                            ...fetchMoreResult.getPaginatedCapaTickets,
                            capaTickets: [
                                ...prev.getPaginatedCapaTickets.capaTickets,
                                ...fetchMoreResult.getPaginatedCapaTickets.capaTickets,
                            ],
                        },
                    };
                },
            });
        }
    };

    const columnId = createMongoObjectId();

    return (
        <StyledDroppableBox>
            <Typography sx={{ fontSize: '20px', marginBottom: '10px' }}>{t('statuses.inWork')}</Typography>
            <Box sx={{ overflow: 'auto', height: 'calc(100vh - 370px)', paddingRight: '6px' }}>
                <Box sx={{ minHeight: '92%', marginBottom: '8px' }}>
                    <Droppable droppableId={`${columnId}_inWork`} key={columnId}>
                        {(provided, snapshot) => {
                            return (
                                <div {...provided.droppableProps} ref={provided.innerRef} style={{ minHeight: '400px' }}>
                                    {data?.getPaginatedCapaTickets?.capaTickets?.map((ticket, index) => {
                                        return (
                                            <Draggable key={ticket?._id} draggableId={ticket?._id} index={index}>
                                                {(provided, _snapshot) => {
                                                    const userImage = findTileElement(
                                                        ticket?.resolvedResponsible?.profilePic?.thumbnail,
                                                        userImages
                                                    );

                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}>
                                                            <Box sx={{ marginBottom: '12px ' }}>
                                                                <CapaTicketWrapper ticket={ticket} avatarSrc={userImage?.img} />
                                                            </Box>
                                                            {provided.placeholder}
                                                        </div>
                                                    );
                                                }}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            );
                        }}
                    </Droppable>
                </Box>

                <Box sx={{ textAlign: 'center' }}>
                    <LoadMoreButton
                        isDisable={!data?.getPaginatedCapaTickets?.metadata?.hasNext}
                        onClick={loadMoreTickets}
                        isLoading={loading}
                    />
                </Box>
            </Box>
        </StyledDroppableBox>
    );
};

export default InWorkColumn;
