import React, { memo, useEffect, useState } from 'react';

import { notificationsSeverityByRequestStatus } from '../../providers/notification/constants';
import { useNotificationContext } from '../../providers/notification/store/notification.context';

import UserDropdown from '../UserDropdown';

import { useUpdateWorkflowStepResponsible } from './hooks/useUpdateWorkflowStepResponsible';

const ResponsibleWorkflowStepInputComponent = ({ workflowStepId, isDisabled, value, sectionId }) => {
    const { setNotification } = useNotificationContext();

    const [updateResponsible] = useUpdateWorkflowStepResponsible();

    const [currentValue, setCurrentValue] = useState(null);

    useEffect(
        () =>
            setCurrentValue(
                !!value?.length
                    ? {
                          avatar: value[0]?.profilePic?.thumbnail,
                          fullName: `${value[0]?.firstName} ${value[0]?.name}`,
                          value: value[0]?._id,
                      }
                    : null
            ),
        [value]
    );

    const handleResponsibleChange = data => {
        updateResponsible(workflowStepId, data?.value)
            .then(() => {
                setNotification({ severity: notificationsSeverityByRequestStatus[true] });
            })
            .catch(() => {
                setNotification({ severity: notificationsSeverityByRequestStatus[false] });
            });

        setCurrentValue(data);
    };

    return <UserDropdown value={currentValue} onChange={handleResponsibleChange} isDisabled={isDisabled} />;
};

const ResponsibleWorkflowStepInput = memo(ResponsibleWorkflowStepInputComponent);

export default ResponsibleWorkflowStepInput;
