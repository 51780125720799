import { useMutation } from '@apollo/client';
import { CASCADE_UPDATE_MANY_NCRS } from '../operations/mutations/cascadeUpdateManyNCRs';
import { paginatedNCRsQuery } from '../../inbox/cache';
import { useNotificationContext } from '../../../common/providers/notification/store/notification.context';
import { notificationsSeverityByRequestStatus } from '../../../common/providers/notification/constants';

export const useCascadeUpdateManyNCRs = () => {
    const { setNotification } = useNotificationContext();

    const [cascadeUpdate, { loading }] = useMutation(CASCADE_UPDATE_MANY_NCRS, {
        onCompleted: data => {
            setNotification({
                severity: notificationsSeverityByRequestStatus[data.cascadeUpdateManyNCRs.success],
            });
        },
        update: (cache, { data: { cascadeUpdateManyNCRs } }) => {
            const { ncrs } = cascadeUpdateManyNCRs;

            ncrs.forEach(ncr => {
                cache.evict({ id: cache.identify({ __typename: 'NCR', _id: ncr._id }) });
            });

            const data = cache.readQuery({
                ...paginatedNCRsQuery(),
                fetchPolicy: 'network-only',
            });

            if (data) {
                cache.writeQuery({
                    ...paginatedNCRsQuery(),
                    fetchPolicy: 'network-only',
                    data: {
                        ...data,
                        getPaginatedNCRs: {
                            ...data.getPaginatedNCRs,
                            metadata: {
                                ...data.getPaginatedNCRs.metadata,
                                total: data.getPaginatedNCRs.metadata.total - ncrs.length,
                            },
                        },
                    },
                });
            }
        },
    });

    const handleCascadeUpdate = (ncrIds, field, value) => {
        return cascadeUpdate({
            variables: {
                ncrIds,
                updatedFields: {
                    [field]: value,
                },
            },
        });
    };

    // TODO add cache update

    return [handleCascadeUpdate, loading];
};
