import React from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { measureIncidentTypeOptions } from '../../../ncr-info/constants';
import { useTicketKanbanBoardContext } from '../../store/TicketsKanbanBoardContext';

const TicketKanbanBoardIncidentTypeDropdown = () => {
    const { t } = useTranslation();

    const { incidentTypeFilter, setTicketsKanbanBoardIncidentType } = useTicketKanbanBoardContext();

    const handleChange = data => {
        setTicketsKanbanBoardIncidentType(data?.value);
    };

    return (
        <Autocomplete
            sx={{ minWidth: 200 }}
            value={incidentTypeFilter}
            options={measureIncidentTypeOptions}
            renderInput={params => <TextField {...params} fullWidth label={t('capaTicketsKanbanBoard.incidentType')} />}
            onChange={(_, data) => handleChange(data)}
        />
    );
};

export default TicketKanbanBoardIncidentTypeDropdown;
