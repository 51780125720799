import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import AutoSaveTextField from '../../../../../../../../common/components/AutoSaveTextField';

const CommentInputComponent = ({ value, scrapId, disabled, handleUpdate }) => {
    const { t } = useTranslation();

    const handleBlur = event => {
        const newValue = event.target.value;

        if (value !== newValue) {
            handleUpdate(
                `scraps.$[scrapId]`,
                {
                    scrap: { comment: newValue },
                },
                'update',
                { scrapId }
            );
        }
    };

    return (
        <AutoSaveTextField
            value={value}
            disabled={disabled}
            label={t('ncrInfo.immediate.comment')}
            onBlur={handleBlur}
            onKeyPress={event => {
                event.key === 'Enter' && handleBlur(event);
            }}
            fullWidth
        />
    );
};

const CommentInput = memo(CommentInputComponent);

export default CommentInput;
