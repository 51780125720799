import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { GET_PAGINATED_NCRS } from '../operations/queries/getPaginatedNCRs';

import { paginatedNCRsQuery } from '../cache';

import { ncrStatus } from '../../../common/enums/ncrStatus';
import { inboxDefaultPageSize } from '../constants';

const defaultVariables = {
    limit: inboxDefaultPageSize,
    filter: JSON.stringify({ status: ncrStatus.OPEN }),
    sort: JSON.stringify({
        'ncrCreatedBy.createdAt': 1,
    }),
};

export const useGetPaginatedNCRs = ({ limit, filter, sort }) => {
    const variables = {
        limit: limit || defaultVariables.limit,
        filter: filter || JSON.stringify({ status: ncrStatus.OPEN, archived: false, deleted: false }),
        sort:
            sort ||
            JSON.stringify({
                'ncrCreatedBy.createdAt': 1,
            }),
    };

    const { data, loading, fetchMore } = useQuery(GET_PAGINATED_NCRS, {
        fetchPolicy: 'cache-and-network',
        variables,
        onCompleted: () =>
            paginatedNCRsQuery({
                query: GET_PAGINATED_NCRS,
                variables,
            }),
    });

    const result = useMemo(() => data?.getPaginatedNCRs, [data?.getPaginatedNCRs]);

    return [result?.metadata, loading, fetchMore, result?.ncrs];
};
