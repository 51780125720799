import { memo, useMemo } from 'react';
import { t } from 'i18next';

import { MenuItem, TextField } from '@mui/material';

export const inboxReportsSortOptions = [
    {
        label: t('inbox.reportsList.latest'),
        value: 'ncrCreatedBy.createdAt',
    },
    {
        label: t('inbox.reportsList.prio'),
        value: 'priority',
    },
    {
        label: t('inbox.reportsList.lastEdit'),
        value: 'ncrModifiedBy.modifiedAt',
    },
];

export const renderValue = value => {
    const result = inboxReportsSortOptions.find(item => item.value === value);

    return result.label || '';
};

const InboxReportsSortDropdownComponent = ({ value, disabled, handleUpdate }) => {
    const handleChange = event => {
        handleUpdate(event.target.value);
    };

    const memoizedValue = useMemo(() => {
        return value || inboxReportsSortOptions[0].value;
    }, [value]);

    return (
        <TextField
            select
            fullWidth
            label={t('common.action-buttons.sortBy')}
            value={memoizedValue}
            disabled={disabled}
            onChange={handleChange}>
            {inboxReportsSortOptions.map(item => {
                return (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

const InboxReportsSortDropdown = memo(InboxReportsSortDropdownComponent);

export default InboxReportsSortDropdown;
