import { useTranslation } from 'react-i18next';

import CachedIcon from '@mui/icons-material/Cached';
import { Button } from '@mui/material';

const CameraModeButton = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <Button sx={{ background: '#fff', width: '100%' }} variant="outlined" onClick={onClick} startIcon={<CachedIcon />}>
            {t('common.cameraMode')}
        </Button>
    );
};

export default CameraModeButton;
