import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { getValueByLanguage } from '../../../../utils/common';

import { GET_PAGINATED_WORKPLACES } from '../../../gqlQueries/getPaginatedWorkplaces';

import { useUserSettingsContext } from '../../../providers/userSettings/store/userSettings.context';

export const usePaginatedWorkplaces = () => {
    const { data: workplaces, loading } = useQuery(GET_PAGINATED_WORKPLACES, {
        fetchPolicy: 'cache-first',
    });

    const { language } = useUserSettingsContext();

    const workplaceOptions = useMemo(
        () =>
            workplaces?.getPaginateWorkplaces.workplaces.map(workplace => ({
                title: getValueByLanguage(workplace.workPlaceName, language),
                value: workplace._id,
            })),
        [workplaces, language]
    );

    return [workplaceOptions, loading];
};
