import { useMutation } from '@apollo/client';
import { ATOMIC_NCR } from '../../pages/inbox/operations/mutations/atomicNCR';

export const useAttachmentsAtomicNCR = () => {
    const [atomic, { loading }] = useMutation(ATOMIC_NCR);

    const handleAtomic = ({ ncrId, operation, path, values, childrenIds }) => {
        return atomic({
            variables: {
                atomics: values?.map(value => {
                    return {
                        ncrId,
                        operation,
                        path,
                        values: value,
                        childrenIds,
                    };
                }),
            },
        });
    };

    return [handleAtomic, loading];
};
