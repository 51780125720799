export const notificationActions = {
    SET_NOTIFICATION: 'NOTIFICATION/SET_NOTIFICATION',
    CLEAR_NOTIFICATION: 'NOTIFICATION/CLEAR_NOTIFICATION',
};

export const setNotification = payload => ({
    type: notificationActions.SET_NOTIFICATION,
    payload,
});

export const clearNotification = () => ({
    type: notificationActions.CLEAR_NOTIFICATION,
});
