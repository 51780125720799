import { themeMode } from '@5thindustry/factory_portal.theme.theme-provider/dist/theme-provider';
import { Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
    fontWeight: 500,
    fontSize: '14px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.mode === themeMode.light ? theme.palette.background.paper : '#242424',
    '&.Mui-selected': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '4px',
    },
    '&.Mui-focusVisible': {
        backgroundColor: theme.palette.primary.contrastText,
    },
}));

export default StyledTab;
