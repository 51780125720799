export const getFileName = url => {
    if (!url) {
        return '';
    }

    return url?.split('?')[0]?.split('/')?.slice(-1)[0];
};

export const getFileExtension = fileName => {
    return fileName.split('.').pop();
};

export const getThumbFileName = fileName => {
    if (fileName) {
        return `thumb-${fileName}`;
    }
    return null;
};

export const findTileElement = (image, tileData) => {
    const thumbnailImage = tileData ? tileData.find(item => getFileName(item.url) === getThumbFileName(image)) : null;
    const originalImage = tileData ? tileData.find(item => getFileName(item.url) === image) : null;

    return thumbnailImage || originalImage;
};
