import { Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTabs = styled(props => (
    <Tabs
        {...props}
        value={props.value}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        width: 0,
    },
});

export default StyledTabs;
