import React, { useMemo } from 'react';

import { setLanguage, setThemeMode, setUserSettings } from './store/userSettings.actions';
import { UserSettingsContext } from './store/userSettings.context';
import { userSettingsInitialState, userSettingsReducer } from './store/userSettings.reducer';

const withUserSettingsProvider = ComposedComponent => {
    return props => {
        const [state, dispatch] = React.useReducer(userSettingsReducer, userSettingsInitialState);

        const memoizedValue = useMemo(() => {
            return {
                themeMode: state.themeMode,
                language: state.language,
                userData: state.userData,
                setThemeMode: mode => dispatch(setThemeMode(mode)),
                setLanguage: language => dispatch(setLanguage(language)),
                setUserSettings: userData => dispatch(setUserSettings(userData)),
            };
        }, [state.themeMode, state.language, state.userData]);

        return (
            <UserSettingsContext.Provider value={memoizedValue}>
                <ComposedComponent {...props} />
            </UserSettingsContext.Provider>
        );
    };
};

export default withUserSettingsProvider;
