import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { useMutation } from '@apollo/client';
import AddAlertRoundedIcon from '@mui/icons-material/AddAlertRounded';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import FolderIcon from '@mui/icons-material/Folder';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InboxRoundedIcon from '@mui/icons-material/InboxRounded';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import {
    AppBar,
    Avatar,
    Backdrop,
    Chip,
    CircularProgress,
    IconButton,
    LinearProgress,
    ListItemAvatar,
    styled,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar,
    Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { makeStyles } from '@mui/styles';

import { signOut, useCurrentUserBySub } from '../../../utils/awsAuth';
import { stringAvatar } from '../../../utils/common';
import { userRole } from '../../../utils/userRoles';

import {
    getFilteredNcrUserRoles,
    inboxPageRoles,
    ncrOverviewPageRoles,
    reportPageRoles,
    tasksPageRoles,
    ticketsKanbanBoardPageRoles,
} from '../../constants/userRoles';
import { daysCountWeeklyNotification, weeklyOverdueTicketsMailValue } from '../../constants/userSettingsConstants';

import { SAVE_USER_SETTINGS } from '../../gqlQueries/queries';

import { useUserAvatar } from '../../hooks/useUserAvatar';
import { useUserImages } from '../../hooks/useUserImages';

import { dark, light, useUserSettingsContext } from '../../providers/userSettings/store/userSettings.context';

import MenuLink from '../MenuLink';

window &&
    window.addEventListener(
        'beforeunload',
        function (e) {
            localStorage.removeItem('pageTitle');
        },
        false
    );

const useStyles = makeStyles({
    drawerPaper: {
        marginTop: '68px',
        boxShadow:
            '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
        borderRadius: '0px 16px 16px 0px',
    },
    userSettingsDrawer: {
        marginTop: '68px',
        boxShadow:
            '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
        borderRadius: '16px 0 0 16px',
    },
});

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const GeneralLayout = ({ children }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    let { pathname } = useLocation();

    const { themeMode, language, setUserSettings } = useUserSettingsContext();

    const { data, loading } = useCurrentUserBySub();

    const [pageTitle, setPageTitle] = useState();

    useEffect(() => {
        localStorage.setItem('pageTitle', JSON.stringify(''));
        window && window.dispatchEvent(new Event('setPageTitle'));
    }, [pathname]);

    useEffect(() => {
        window &&
            window.addEventListener('setPageTitle', () => {
                const title = JSON.parse(localStorage.getItem('pageTitle'));

                setPageTitle(title);
            });
    }, []);

    useEffect(() => {
        if (data) {
            setUserSettings(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const [open, setOpen] = React.useState(false);
    const [userSettingsOpen, setUserSettingsOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
        setUserSettingsOpen(false);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleUserSettingsDrawerOpen = () => {
        setUserSettingsOpen(true);
        setOpen(false);
    };

    const handleUserSettingsDrawerClose = () => {
        setUserSettingsOpen(false);
    };

    const [avatar] = useUserAvatar();

    const [images] = useUserImages();
    const avatarSrc = avatar.getUserAvatarByThumbnail(images, data?.profilePic?.thumbnail);
    const userName = `${data?.firstName} ${data?.name}`;

    const [updateUser, { loading: userUpdateLoading }] = useMutation(SAVE_USER_SETTINGS, {
        onCompleted: data => {
            if (data?.updateUser?.user) {
                setUserSettings(data?.updateUser?.user);
            }
        },
    });

    const handlePageChange = path => () => {
        navigate(path);
    };

    const handleThemeChange = (_event, value) => {
        updateUser({
            variables: {
                user: {
                    _id: data._id,
                    userSettings: {
                        language,
                        theme: value,
                    },
                },
            },
        });
    };

    const handleLanguageChange = (_event, value) => {
        updateUser({
            variables: {
                user: {
                    _id: data._id,
                    userSettings: {
                        language: value,
                        theme: themeMode,
                    },
                },
            },
        });
    };

    const handleDailyTicketOverviewChange = value => {
        updateUser({
            variables: {
                user: {
                    _id: data._id,
                    notifications: {
                        email: data.notifications.email,
                        dailyTicketOverview: value,
                        overdueTicketsMail: {
                            active: data?.notifications.overdueTicketsMail.active,
                            weekdays: data?.notifications.overdueTicketsMail.weekdays,
                        },
                    },
                },
            },
        });
    };

    const handleEmailChange = value => {
        updateUser({
            variables: {
                user: {
                    _id: data._id,
                    notifications: {
                        email: value,
                        dailyTicketOverview: data.notifications.dailyTicketOverview,
                        overdueTicketsMail: {
                            active: data?.notifications.overdueTicketsMail.active,
                            weekdays: data?.notifications.overdueTicketsMail.weekdays,
                        },
                    },
                },
            },
        });
    };

    const handleWeeklyReportChange = value => {
        updateUser({
            variables: {
                user: {
                    _id: data._id,
                    notifications: {
                        email: data.notifications.email,
                        dailyTicketOverview: data.notifications.dailyTicketOverview,
                        overdueTicketsMail: value,
                    },
                },
            },
        });
    };

    if (loading) {
        return (
            <Backdrop
                style={{ marginTop: 0 }}
                sx={{ color: '#fff', zIndex: theme => theme.zIndex.appBar + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <Box sx={{ maxHeight: '100vh' }}>
            <AppBar sx={{ zIndex: 10001 }} position="fixed" open={open} color="primary" enableColorOnDark>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2 }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        {pageTitle ? `${pageTitle}` : `${t('menu.appName')}`}
                    </Typography>
                    <Avatar
                        src={avatarSrc}
                        sx={{ cursor: 'pointer' }}
                        onClick={userSettingsOpen ? handleUserSettingsDrawerClose : handleUserSettingsDrawerOpen}
                    />
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor={'left'}
                open={open}
                onClose={handleDrawerClose}
                onOpen={handleDrawerOpen}>
                <Box sx={{ width: '350px' }} onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handlePageChange('/')}>
                                <ListItemIcon>
                                    <HomeRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary={<MenuLink to="/">{t('menu.home')}</MenuLink>} />
                            </ListItemButton>
                        </ListItem>
                        {data?.roles?.some(userRole => reportPageRoles?.some(role => role === userRole)) && (
                            <ListItem disablePadding>
                                <ListItemButton onClick={handlePageChange('/report')}>
                                    <ListItemIcon>
                                        <AddAlertRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<MenuLink to="/report">{t('menu.report')}</MenuLink>} />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {data?.roles?.some(userRole => tasksPageRoles?.some(role => role === userRole)) && (
                            <ListItem disablePadding>
                                <ListItemButton onClick={handlePageChange('/my-tasks')}>
                                    <ListItemIcon>
                                        <TaskRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<MenuLink to="/my-tasks">{t('menu.myTasks')}</MenuLink>} />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {data?.roles?.some(userRole => inboxPageRoles?.some(role => role === userRole)) && (
                            <ListItem disablePadding>
                                <ListItemButton onClick={handlePageChange('/inbox')}>
                                    <ListItemIcon>
                                        <InboxRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<MenuLink to="/inbox">{t('menu.inbox')}</MenuLink>} />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {data?.roles?.some(userRole => ncrOverviewPageRoles?.some(role => role === userRole)) && (
                            <ListItem disablePadding>
                                <ListItemButton onClick={handlePageChange('/ncr-overview')}>
                                    <ListItemIcon>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<MenuLink to="/ncr-overview">{t('menu.activeNcr')}</MenuLink>}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {data?.roles?.some(userRole =>
                            ticketsKanbanBoardPageRoles?.some(role => role === userRole)
                        ) && (
                            <ListItem disablePadding>
                                <ListItemButton onClick={handlePageChange('/tickets-kanban-board')}>
                                    <ListItemIcon>
                                        <ViewColumnIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        component={'span'}
                                        primary={
                                            <MenuLink to="/tickets-kanban-board">
                                                {t('menu.ticketsKanbanBoard')}
                                            </MenuLink>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                    <Divider />
                    <List>
                        {data?.roles?.includes(userRole.admin) && (
                            <ListItem disablePadding>
                                <ListItemButton onClick={handlePageChange('/admin/users')}>
                                    <ListItemIcon>
                                        <SecurityRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<MenuLink to="/admin/users">{t('menu.admin')}</MenuLink>} />
                                </ListItemButton>
                            </ListItem>
                        )}
                        <ListItem disablePadding>
                            <ListItemButton onClick={signOut}>
                                <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('common.navigation-bar.options.exit')} onClick={signOut} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </SwipeableDrawer>

            <SwipeableDrawer
                classes={{
                    paper: classes.userSettingsDrawer,
                }}
                PaperProps={{
                    style: {
                        height: 'auto',
                        width: '360px',
                    },
                }}
                anchor={'right'}
                open={userSettingsOpen}
                onClose={handleUserSettingsDrawerClose}
                onOpen={handleUserSettingsDrawerOpen}>
                <Box sx={{ padding: '1em' }}>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                {avatarSrc ? (
                                    <Avatar alt={userName} src={avatarSrc} />
                                ) : (
                                    <Avatar alt={userName} src={avatarSrc} {...stringAvatar(userName)} />
                                )}
                            </ListItemAvatar>
                            <ListItemText
                                primary={userName}
                                secondaryTypographyProps={{
                                    style: {
                                        wordBreak: 'break-all',
                                    },
                                }}
                                secondary={getFilteredNcrUserRoles(data?.roles)?.map(role => (
                                    <Fragment key={role}>
                                        <Chip
                                            size="small"
                                            label={role}
                                            sx={{
                                                marginBottom: '0.5em',
                                                marginRight: '0.5em',
                                            }}
                                        />
                                    </Fragment>
                                ))}
                            />
                        </ListItem>
                    </List>

                    <Typography variant="overline">{t('userSettings.themeMode.label')}</Typography>
                    <br />
                    <Box sx={{ marginBottom: '1em' }}>
                        <ToggleButtonGroup color="info" exclusive onChange={handleThemeChange}>
                            <ToggleButton
                                sx={{ width: '100px' }}
                                size="small"
                                value={light}
                                selected={themeMode === light}
                                color="info">
                                <LightModeIcon />
                                <span>{t('userSettings.themeMode.light')}</span>
                            </ToggleButton>
                            <ToggleButton
                                sx={{ width: '100px' }}
                                size="small"
                                value={dark}
                                selected={themeMode === dark}
                                color="info">
                                <DarkModeIcon />
                                <span>{t('userSettings.themeMode.dark')}</span>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                    <Typography variant="overline">{t('userSettings.language.label')}</Typography>
                    <br />
                    <Box>
                        <ToggleButtonGroup color="info" exclusive onChange={handleLanguageChange}>
                            <ToggleButton
                                sx={{ width: '100px' }}
                                size="small"
                                value={'de'}
                                selected={language === 'de'}
                                color="info">
                                {t('userSettings.language.de')}
                            </ToggleButton>
                            <ToggleButton
                                sx={{ width: '100px' }}
                                size="small"
                                value={'en'}
                                selected={language === 'en'}
                                color="info">
                                {t('userSettings.language.en')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                    <br />
                    <Typography variant="overline">{t('userSettings.notifications.label')}</Typography>
                    <Divider sx={{ marginLeft: '-1em', marginRight: '-1em' }} />
                    <Box>
                        <List>
                            <ListItem disablePadding>
                                <ListItemText primary={t('userSettings.notifications.email')} />
                                <Switch
                                    edge="end"
                                    onChange={event => handleEmailChange(event.target.checked)}
                                    checked={data?.notifications?.email || false}
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Typography variant="overline">{t('userSettings.reports.label')}</Typography>
                    <Divider sx={{ marginLeft: '-1em', marginRight: '-1em' }} />
                    <List>
                        <ListItem disablePadding>
                            <ListItemText primary={t('userSettings.reports.daily')} />
                            <Switch
                                edge="end"
                                onChange={event => handleDailyTicketOverviewChange(event.target.checked)}
                                checked={data?.notifications?.dailyTicketOverview || false}
                            />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemText primary={t('userSettings.reports.weekly')} />
                            <Switch
                                edge="end"
                                onChange={event =>
                                    handleWeeklyReportChange({
                                        active: event.target.checked,
                                        weekdays: weeklyOverdueTicketsMailValue,
                                    })
                                }
                                checked={
                                    data?.notifications?.overdueTicketsMail?.active &&
                                    data?.notifications?.overdueTicketsMail?.weekdays?.length ===
                                        daysCountWeeklyNotification
                                }
                            />
                        </ListItem>
                    </List>
                </Box>
                <LinearProgress color="primary" sx={{ visibility: userUpdateLoading ? 'visible' : 'hidden' }} />
                <Divider />
                <List>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemText primary={t('common.navigation-bar.options.exit')} onClick={signOut} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </SwipeableDrawer>
            <Box
                component="main"
                onClick={handleUserSettingsDrawerClose}
                onKeyDown={handleUserSettingsDrawerClose}
                sx={{ margin: '2em auto', filter: open || userSettingsOpen ? 'blur(4px)' : 'blur(0)' }}>
                <Offset />
                {children}
            </Box>
            <div id="floating-button" />
        </Box>
    );
};

export default GeneralLayout;
