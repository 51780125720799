import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { NcrTemplateContext } from '../../../../common/contexts/ncrTemplate/store/context';
import { useGetTagsOptions } from './../../../../common/components/ncr/hooks/useGetTagsOptions';

const TagsDropdown = ({ isLoading }) => {
    const { t } = useTranslation();

    const { setNCR } = useContext(NcrTemplateContext);

    const [tagsOptions, loadingTagsOptions] = useGetTagsOptions();

    const handleChange = data => {
        setNCR({ value: data, path: 'tags' });
    };

    return (
        <Autocomplete
            multiple
            disableCloseOnSelect
            limitTags={3}
            loading={isLoading || loadingTagsOptions}
            disabled={isLoading || loadingTagsOptions}
            options={tagsOptions}
            getOptionLabel={option => option.label}
            variant="outlined"
            fullWidth
            renderInput={params => <TextField {...params} label={t('report.form.tags')} />}
            onChange={(_, data) => handleChange(data)}
        />
    );
};

export default TagsDropdown;
