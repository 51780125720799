import i18n from '../../utils/i18n';

const { t } = i18n;

export const stepStatus = {
    OPEN: 'open',
    IN_WORK: 'inWork',
    DONE: 'done',
    DELAYED: 'delayed',
    WAITING: 'waiting',
};

export const stepMeasureStatusOptions = [
    {
        label: t(`statuses.${stepStatus.OPEN}`),
        value: stepStatus.OPEN,
    },
    {
        label: t(`statuses.${stepStatus.IN_WORK}`),
        value: stepStatus.IN_WORK,
    },
    {
        label: t(`statuses.${stepStatus.DONE}`),
        value: stepStatus.DONE,
    },
];
