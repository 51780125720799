import i18next from 'i18next';

export const notificationsSeverity = {
    WARNING: 'warning',
    SUCCESS: 'success',
    ERROR: 'error',
};

export const notificationsSeverityByRequestStatus = {
    [true]: notificationsSeverity.SUCCESS,
    [false]: notificationsSeverity.ERROR,
};

export const notificationMessageBySeverity = {
    [notificationsSeverity.ERROR]: i18next.t(`common.message.error-message`),
    [notificationsSeverity.SUCCESS]: i18next.t(`common.message.success-message`),
    [notificationsSeverity.WARNING]: i18next.t(`common.message.partial-success-message`),
};

export const DEFAULT_AUTO_HIDE_DURATION = 2000;
