import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';

import { useModal } from '../../../hooks/useModal';

import AutoSaveTextField from '../../AutoSaveTextField';
import BarcodeReaderModal from '../../BarcodeReaderModal';

const UpdateReferenceInputComponent = ({ value, disabled, handleUpdate }) => {
    const { t } = useTranslation();

    const [isOpened, toggleModal] = useModal(false);

    const [isError, setIsError] = useState(false);

    const handleBlur = event => {
        if (!event.target.value !== isError) {
            setIsError(!event.target.value);
        }

        if (!!event.target.value) {
            handleUpdate('basicInformation', {
                basicInformation: {
                    externalReference: event.target.value,
                },
            });
        }
    };

    const handleResult = result => {
        if (!!result && isOpened) {
            handleUpdate('basicInformation', {
                basicInformation: {
                    externalReference: result,
                },
            });
            toggleModal();
        }
    };

    return (
        <>
            <AutoSaveTextField
                error={isError}
                required
                value={value}
                disabled={disabled}
                InputProps={{
                    endAdornment: <QrCodeRoundedIcon onClick={toggleModal} />,
                }}
                label={t('report.form.reference')}
                onBlur={handleBlur}
                onKeyPress={event => {
                    event.key === 'Enter' && handleBlur(event);
                }}
                fullWidth
            />
            <BarcodeReaderModal isOpen={isOpened} handleModalClose={toggleModal} handleResult={handleResult} />
        </>
    );
};

const UpdateReferenceInput = memo(UpdateReferenceInputComponent);

export default UpdateReferenceInput;
