import { useContext, useMemo, useReducer } from 'react';

import {
    setNcrMyTasksMyTask,
    resetNcrMyTasksFilters,
    setNcrMyTasksTablePage,
    setNrcMyTaskSelectedTeams,
    setNcrMyTasksTableSorting,
    setNcrMyTasksSearchFilter,
    setNcrMyTasksDateRangeFilter,
    setNcrMyTasksFailureCategoryFilter,
} from './NCRMyTasksActions';
import { NcrMyTasksContext } from './NCRMyTasksContext';
import { ncrMyTasksInitialState, ncrMyTasksReducer } from './NCRMyTasksReducer';

const NCRMyTasksProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ncrMyTasksReducer, ncrMyTasksInitialState);

    const NcrMyTasksContextValue = useMemo(() => {
        return {
            ...state,
            myTask: state.myTask,
            teams: state.selectedTeams,
            page: state.pagination.page,
            order: state.pagination.order,
            sortBy: state.pagination.sortBy,
            resetNcrMyTasksFilters: () => dispatch(resetNcrMyTasksFilters()),
            setNcrMyTasksMyTask: myTask => dispatch(setNcrMyTasksMyTask(myTask)),
            setNrcMyTasksSelectedTeams: teams => dispatch(setNrcMyTaskSelectedTeams(teams)),
            setNcrMyTasksSearchFilter: filter => dispatch(setNcrMyTasksSearchFilter(filter)),
            setNcrMyTasksDateRangeFilter: filter => dispatch(setNcrMyTasksDateRangeFilter(filter)),
            setNcrMyTasksFailureCategoryFilter: filter => dispatch(setNcrMyTasksFailureCategoryFilter(filter)),

            setNcrMyTasksTablePage: page => dispatch(setNcrMyTasksTablePage(page)),
            setNcrMyTasksTableSorting: payload => dispatch(setNcrMyTasksTableSorting(payload)),
        };
    }, [state]);

    return <NcrMyTasksContext.Provider value={NcrMyTasksContextValue}>{children}</NcrMyTasksContext.Provider>;
};

export default NCRMyTasksProvider;

export const useNcrMyTasksContext = () => {
    return useContext(NcrMyTasksContext);
};
