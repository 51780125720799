import { gql } from '@apollo/client';

export const REMOVE_SECTION_FROM_NCR = gql`
    mutation RemoveSectionFromNCR($ncrId: ID!, $sectionId: ID!) {
        removeSectionFromNCR(ncrId: $ncrId, sectionId: $sectionId) {
            code
            success
            message
            ncr {
                _id
            }
        }
    }
`;
