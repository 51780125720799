import Quagga from 'quagga';

export class BarcodeReader {
    static init(isFrontCamera = false) {
        Quagga.init(
            {
                inputStream: {
                    name: 'Live',
                    type: 'LiveStream',
                    target: document.querySelector('#scanner-container'),
                    constraints: {
                        width: 600,
                        height: 360,
                        facingMode: isFrontCamera ? 'right' : 'environment',
                    },
                },
                decoder: {
                    readers: ['code_39_reader', 'code_39_vin_reader'],
                },
            },
            err => {
                if (err) {
                    alert('You need a camera to scan barcodes.');

                    document.querySelector('#scanner-container').innerHTML = '';

                    return null;
                }

                Quagga.start();
            }
        );
    }

    static onDetected(handleResult) {
        if (!handleResult) {
            return null;
        }

        Quagga.onDetected(result => {
            handleResult(result.codeResult.code);

            Quagga.stop();

            this.offDetected();
        });
    }

    static offDetected() {
        Quagga.offDetected();
    }
}
