import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

import StepDueDate from '../../../../../pages/ncr-info/components/tabs/ncr-immediate/components/inputs/StepDueDate';
import UserDropdown from '../../../UserDropdown';

import { notificationsSeverityByRequestStatus } from '../../../../providers/notification/constants';
import { useNotificationContext } from '../../../../providers/notification/store/notification.context';

import { useUpdateWorkflowStepInTransitionMode } from '../hooks/useUpdateWorkflowStepInTransitionMode';

const TransitionModal = ({ ncrId, sectionId, isOpened, toggleModal, workflowStepId, nextWorkflowStepId, workflowId }) => {
    const { t } = useTranslation();

    const { setNotification } = useNotificationContext();

    const [handleUpdateWorkflowStepInTransitionMode, loading] = useUpdateWorkflowStepInTransitionMode();

    const [responsible, setResponsible] = useState({
        avatar: '',
        fullName: '',
        value: '',
    });

    const [dueDate, setDueDate] = useState(null);

    const resetState = () => {
        setResponsible({
            avatar: '',
            fullName: '',
            value: '',
        });
        setDueDate(null);
    };

    const handleCloseModal = () => {
        resetState();
        toggleModal();
    };

    const handleTransitionAction = () => {
        handleUpdateWorkflowStepInTransitionMode({
            currentWorkflowStepId: workflowStepId,
            nextWorkflowStepId,
            responsibleId: responsible.value,
            dueDate,
        })
            .then(response => {
                const isUpdateCurrentStepDone = response.data.updateCurrentStep.success;
                const isUpdateNextStepDone = response.data.updateNextStep.success;
                const success = isUpdateCurrentStepDone && isUpdateNextStepDone;

                setNotification({ severity: notificationsSeverityByRequestStatus[success] });
                handleCloseModal();
            })
            .catch(_ => {
                setNotification({ severity: notificationsSeverityByRequestStatus[false] });
            });
    };

    return (
        <Dialog fullWidth maxWidth="sm" onClose={handleCloseModal} open={isOpened}>
            <DialogTitle sx={{ textAlign: 'center', marginBottom: '2em' }}>
                <Typography variant="h5">{t('ncrInfo.immediate.actions.transition.title')}</Typography>
            </DialogTitle>
            <DialogContent sx={{ margin: '0 auto' }}>
                <Typography variant="body1" sx={{ marginBottom: '1em' }}>
                    {t('ncrInfo.immediate.actions.transition.description')}
                </Typography>
                <Box sx={{ maxWidth: '220px', margin: 'auto', marginBottom: '2em' }}>
                    <Stack spacing={2} direction="column">
                        <UserDropdown value={responsible} onChange={setResponsible} required />
                        <StepDueDate value={dueDate} handleUpdate={setDueDate} />
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', direction: 'row', justifyContent: 'center', paddingBottom: '2em' }}>
                <Button sx={{ cursor: 'pointer' }} onClick={toggleModal} variant="outlined" color="primary">
                    <Typography variant="button" sx={{ cursor: 'pointer' }}>
                        {t('ncrInfo.immediate.actions.transition.backButton')}
                    </Typography>
                </Button>
                <LoadingButton
                    loading={loading}
                    disabled={loading || !responsible?.value}
                    loadingPosition="start"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleTransitionAction}
                    startIcon={<SaveIcon />}
                    variant="contained"
                    color="primary">
                    <Typography variant="button" sx={{ cursor: 'pointer' }}>
                        {t('ncrInfo.immediate.actions.transition.submitButton')}
                    </Typography>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default TransitionModal;
