import { useCallback, useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';

import { GET_OVERVIEW_PAGINATED_NCRS } from '../../../../../ncr-overview/operations/queries/getOverviewPaginatedNCRs';

import { paginatedNCRsQuery } from '../../../../../inbox/cache';

import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../../common/constants/constants';

export const useLazyGetDeletedNCRs = () => {
    const [getSingleNCR, { data, loading }] = useLazyQuery(GET_OVERVIEW_PAGINATED_NCRS, {
        onError: error => {
            console.log(error);
        },
    });

    const fetchData = useCallback(
        (page, orderBy, order) => {
            let sort = undefined;
            const sortOrder = order === 'asc' ? 1 : -1;

            if (orderBy === 'failureCategory') {
                sort = JSON.stringify({
                    'basicInformation.failureCategory.name': sortOrder,
                    'basicInformation.failureCategory.failureMode': sortOrder,
                });
            } else {
                sort = JSON.stringify({
                    [orderBy]: sortOrder,
                });
            }

            const variables = {
                skip: DEFAULT_TABLE_PAGE_SIZE * page,
                limit: DEFAULT_TABLE_PAGE_SIZE,
                sort,
                filter: JSON.stringify({ deleted: true }),
            };

            getSingleNCR({
                fetchPolicy: 'network-only',
                variables,
            }).then(() => {
                paginatedNCRsQuery({
                    query: GET_OVERVIEW_PAGINATED_NCRS,
                    variables,
                });
            });
        },
        [getSingleNCR]
    );

    const items = useMemo(() => data?.getPaginatedNCRs, [data?.getPaginatedNCRs]);

    return [items, loading, fetchData];
};
