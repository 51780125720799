import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button } from '@mui/material';

import { ncrStatus } from '../../../../../../../../common/enums/ncrStatus';

import { notificationsSeverityByRequestStatus } from '../../../../../../../../common/providers/notification/constants';
import { NotificationContext } from '../../../../../../../../common/providers/notification/store/notification.context';

import { useUpdateNCR } from '../../../../../../hooks/useUpdateNCR';

import { InboxContext } from '../../../../../../store/inbox.context';

import { deleteNCRFromCache } from '../../../../utils';

const CreateNCRButton = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { selectedNCRId } = useContext(InboxContext);
    const { setNotification } = useContext(NotificationContext);

    const [updateNCR] = useUpdateNCR(deleteNCRFromCache);

    const handleClick = () => {
        updateNCR(selectedNCRId, { status: ncrStatus.IN_WORK }).then(response => {
            setNotification({ severity: notificationsSeverityByRequestStatus[response.data.updateNCR.success] });

            if (response.data.updateNCR.success) {
                navigate(`/ncr-overview/${selectedNCRId}`);
            }
        });
    };

    return (
        <Button onClick={handleClick} startIcon={<WarningAmberIcon />} color="error" variant="contained">
            {t('inbox.infoForm.actionsButton.createNCR')}
        </Button>
    );
};

export default CreateNCRButton;
