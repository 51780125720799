import { memo, useContext, useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';

import i18n from '../../utils/i18n';

import { UserSettingsContext } from './userSettings/store/userSettings.context';

const withTranslationProvider = ComposedComponent => {
    return memo(props => {
        const { language } = useContext(UserSettingsContext);

        useMemo(() => i18n.changeLanguage(language), [language]);

        return (
            <I18nextProvider i18n={i18n}>
                <ComposedComponent {...props} />
            </I18nextProvider>
        );
    });
};

export default withTranslationProvider;
