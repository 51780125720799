import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCollapseRowDiveder = styled(Divider)(({ theme }) => ({
    width: 'auto',
    height: 1,
    margin: '4px 0 8px 32px',
}));

const CollapseRow = ({ isExpanded, collapse, title }) => {
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={collapse}>
                {isExpanded ? <ArrowDropUpIcon htmlColor="#747475" /> : <ArrowDropDownIcon htmlColor="#747475" />}
                <Box sx={{ marginLeft: '8px', fontSize: '12px' }}>{title}</Box>
            </Box>
            <StyledCollapseRowDiveder />
        </>
    );
};

export default CollapseRow;
