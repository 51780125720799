import NcrActivitiesTabs from '../../../../../../ncr-info/components/tabs/ncr-info/activities/NcrActivitiesTabs';

import { useInboxContext } from '../../../../../store/inbox.context';

const ActivityTab = () => {
    const { selectedNCRId } = useInboxContext();

    return <NcrActivitiesTabs ncrId={selectedNCRId} />;
};

export default ActivityTab;
