import i18n from '../../utils/i18n';

export const ncrArchiveStatus = {
    ARCHIVED: 'archived',
    ACTIVE: 'active',
    ALL: 'all',
};

export const getNcrArchivedOptions = () => [
    {
        title: i18n.t('archiveStatuses.archived'),
        value: ncrArchiveStatus.ARCHIVED,
    },
    {
        title: i18n.t('archiveStatuses.active'),
        value: ncrArchiveStatus.ACTIVE,
    },
    {
        title: i18n.t('archiveStatuses.all'),
        value: ncrArchiveStatus.ALL,
    },
];

export const ncrArchiveFilterValue = {
    [ncrArchiveStatus.ARCHIVED]: { archived: true },
    [ncrArchiveStatus.ACTIVE]: { archived: false },
    [ncrArchiveStatus.ALL]: {},
};
