import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import UploadNewAttachment from '../../../../../../../../common/components/fileUploader/UploadNewAttachment';

import { allowedFileTypes } from '../../../../../../../../common/constants/fileUpload';
import { findTileElement, getFileExtension } from '../../../../../../../../common/utils/fileManagement';
import { AWS_S3_BUCKET_NCR_MEDIA_FOLDER } from '../../../../../../../../configs/env';

import { useAttachmentsAtomicNCR } from '../../../../../../../../common/hooks/useAttachmentsAtomicNCR';
import { useFiles } from '../../../../../../../../common/hooks/useFiles';

import { notificationsSeverityByRequestStatus } from '../../../../../../../../common/providers/notification/constants';
import { useNotificationContext } from '../../../../../../../../common/providers/notification/store/notification.context';
import { useUserSettingsContext } from '../../../../../../../../common/providers/userSettings/store/userSettings.context';
import { useInboxContext } from '../../../../../../store/inbox.context';

import AttachmentItem from '../../../../../../../report/components/attachments/AttachmentItem';

const AttachmentSection = ({ mediaInputs, disabled }) => {
    const { ncrId } = useParams();
    const { t } = useTranslation();

    const { selectedNCRId } = useInboxContext();

    const id = ncrId || selectedNCRId;

    const { themeMode, language } = useUserSettingsContext();
    const { setNotification } = useNotificationContext();

    const [handleAtomicUpdate, loading] = useAttachmentsAtomicNCR();

    const accessFolder = useMemo(() => `${AWS_S3_BUCKET_NCR_MEDIA_FOLDER}/${id}/mediaInput`, [id]);

    const [handleFetch, files] = useFiles(accessFolder);

    const [medias, setMedias] = useState([]);

    useEffect(() => {
        handleFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaInputs, id]);

    useEffect(() => {
        if (mediaInputs && files) {
            setMedias(
                mediaInputs?.map(media => ({
                    ...media,
                    mediaType: getFileExtension(media.mediaName),
                    img: findTileElement(media.mediaName, files)?.img,
                }))
            );
        }
    }, [mediaInputs, files]);

    const handleDeleteMedia = mediaId => () => {
        handleAtomicUpdate({
            ncrId: id,
            operation: 'remove',
            path: 'basicInformation.mediaInputs',
            values: [
                {
                    media: {
                        _id: mediaId,
                    },
                },
            ],
        })
            .then(result => {
                setNotification({ severity: notificationsSeverityByRequestStatus[result.data.atomicNCR.success] });
            })
            .catch(() => {
                setNotification({ severity: notificationsSeverityByRequestStatus[false] });
            });
    };

    const handleAddAttachment = (uppy, imageUrl) => {
        const files = Object.entries(uppy).map(([_uppyId, file]) => {
            return {
                mediaName: file.name,
                mediaType: file.type,
                imageUrl: file.uploadURL,
            };
        });

        handleAtomicUpdate({
            ncrId: id,
            operation: 'add',
            path: 'basicInformation.mediaInputs',
            values: files?.map(file => {
                return { media: { mediaName: file?.mediaName } };
            }),
        })
            .then(result => {
                setNotification({ severity: notificationsSeverityByRequestStatus[result.data.atomicNCR.success] });
            })
            .catch(() => {
                setNotification({ severity: notificationsSeverityByRequestStatus[false] });
            });
    };

    return (
        <>
            <Box paddingBottom="1em">
                <UploadNewAttachment
                    label={t('report.attachments.attachments')}
                    language={language}
                    themeMode={themeMode}
                    targetFolder={accessFolder}
                    loading={loading}
                    disabled={disabled}
                    addAttachments={handleAddAttachment}
                    allowedTypes={[...allowedFileTypes.attachments, ...allowedFileTypes.videos]}
                />
            </Box>
            <Box width="100%" sx={{ marginBottom: '1em' }}>
                {medias?.map(file => (
                    <Box marginBottom="1em" key={file?._id}>
                        <AttachmentItem disabled={disabled} handleRemoveAttachment={handleDeleteMedia} media={file} />
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default AttachmentSection;
