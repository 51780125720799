import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';
import NearMeIcon from '@mui/icons-material/NearMe';
import { Button, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CREATE_ACTIVITY } from '../../../../../../common/gqlQueries/queries';
import { notificationsSeverityByRequestStatus } from '../../../../../../common/providers/notification/constants';
import { setNotification } from '../../../../../../common/providers/notification/store/notification.actions';
import { onlyUnique } from '../../../../../../common/utils/arrayHelper';

import { useCurrentUserBySub } from '../../../../../../utils/awsAuth';
import { isArrayWithItems } from '../../../../../../utils/common';

const StyledRootDiv = styled('div')(({ theme }) => ({
    margin: '24px 32px',

    display: 'flex',
}));

const StyledCreateButton = styled(Button)(({ theme }) => ({
    borderRadius: '0px 8px 8px 0px !important',
    padding: '16px !important',
    boxShadow: 'none !important',
}));

const StyledCreateTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        padding: '0 !important',
    },
}));

const mentionElementRegex = /(@\{\{).+?(\}\})/g;
const mentionValuesRegex = /\{\{([^)]+)\}\}/;

const CreateMessageActivity = ({ ncrId, disabled }) => {
    const { t } = useTranslation();

    const [message, setMessage] = useState('');

    const [submitMessage] = useMutation(CREATE_ACTIVITY, {
        onCompleted: data => {
            if (data?.createActivity?.code) {
                setNotification({ severity: notificationsSeverityByRequestStatus[true] });
            }
        },
    });

    const handlMessageChange = event => {
        setMessage(event?.target?.value);
    };

    const handleMessageCreate = () => {
        if (message) {
            handlePostMessage();
            setMessage('');
        }
    };

    const user = useCurrentUserBySub();

    const getMentions = message => {
        const mentions = [];

        const mentionElements = [...message.matchAll(mentionElementRegex)];

        if (isArrayWithItems(mentionElements)) {
            mentionElements.forEach(element => {
                const userInfo = mentionValuesRegex.exec(element[0]);

                if (isArrayWithItems(userInfo)) {
                    const userId = userInfo[1].split('||')[0];
                    mentions.push(userId);
                }
            });
        }

        return mentions.filter(onlyUnique);
    };

    const handlePostMessage = () => {
        submitMessage({
            variables: {
                activity: {
                    activityActor: {
                        actorType: 'user',
                        actorID: user?.data?._id,
                    },
                    activityVerb: 'added',
                    activityObject: {
                        objectType: 'comment',
                        value: message,
                        atMentions: getMentions(message),
                    },
                    activityTarget: {
                        targetType: 'machineMaintenanceTicket',
                        foreignID: ncrId,
                    },
                },
            },
        });
    };

    return (
        <StyledRootDiv>
            <StyledCreateTextField
                disabled={disabled}
                label={t('activities.actions.createComment')}
                value={message}
                fullWidth
                onChange={handlMessageChange}
                onKeyPress={event => {
                    event.key === 'Enter' && handleMessageCreate();
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <StyledCreateButton disabled={disabled} variant="contained" color="primary" onClick={handleMessageCreate}>
                                <NearMeIcon />
                            </StyledCreateButton>
                        </InputAdornment>
                    ),
                }}
            />
        </StyledRootDiv>
    );
};

export default CreateMessageActivity;
